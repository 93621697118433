export const LogoSvg = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="371"
      height="154"
      viewBox="0 0 371 154"
      fill="none"
      className={`${className} logo`}
    >
      <path
        d="M122.554 154H1.6639V127.001L38.7884 89.5132C46.4837 81.4826 52.4458 75.0443 56.6747 70.1982C60.973 65.3522 63.9887 61.2331 65.7219 57.8409C67.455 54.4486 68.3216 50.918 68.3216 47.2488C68.3216 42.7489 66.8658 39.4952 63.954 37.4875C61.0423 35.4799 57.6106 34.4761 53.659 34.4761C48.8755 34.4761 44.0226 35.8606 39.1004 38.6298C34.2475 41.3297 28.736 45.345 22.5659 50.6757L6.0861e-05 24.2994C4.57563 20.2149 9.42851 16.3381 14.5587 12.6689C19.6889 8.93055 25.755 5.88447 32.757 3.53068C39.759 1.17689 48.2862 0 58.3386 0C68.6683 0 77.6114 1.79995 85.168 5.39987C92.794 8.99977 98.6868 13.9497 102.846 20.2495C107.006 26.4801 109.086 33.5761 109.086 41.5374C109.086 50.3295 107.457 58.187 104.198 65.1099C101.009 71.9636 96.191 78.8519 89.7436 85.7748C83.2962 92.6285 75.2543 100.417 65.6179 109.14L54.4909 119.109V120.147L122.554 119.109V154Z"
        fill="white"
      />
      <path
        d="M226.246 154H113.075V127.001L150.199 89.5132C157.895 81.4826 163.857 75.0443 168.086 70.1982C172.384 65.3522 175.4 61.2331 177.133 57.8409C178.866 54.4486 179.733 50.918 179.733 47.2488C179.733 42.7489 178.277 39.4952 175.365 37.4875C172.453 35.4799 169.022 34.4761 165.07 34.4761C160.286 34.4761 155.433 35.8606 150.511 38.6298C145.658 41.3297 140.147 45.345 133.977 50.6757L111.411 24.2994C115.987 20.2149 120.839 16.3381 125.97 12.6689C131.1 8.93055 137.166 5.88447 144.168 3.53068C151.17 1.17689 159.697 0 169.749 0C180.079 0 189.022 1.79995 196.579 5.39987C204.205 8.99977 210.098 13.9497 214.257 20.2495C218.417 26.4801 220.497 33.5761 220.497 41.5374C220.497 50.3295 218.867 58.187 215.609 65.1099C212.42 71.9636 207.602 78.8519 201.154 85.7748C194.707 92.6285 186.665 100.417 177.029 109.14L165.902 119.109V120.147H236.237L226.246 154Z"
        fill="white"
      />
      <path
        d="M371 154H317.133L261.602 47.0411H260.666C261.013 50.2949 261.325 54.2063 261.602 58.7755C261.88 63.3446 262.122 68.0175 262.33 72.7943C262.538 77.5019 262.642 81.7595 262.642 85.5671V154H226.246V2.18071H279.905L335.227 107.686H335.851C335.643 104.363 335.435 100.521 335.227 96.1591C335.019 91.7285 334.811 87.2632 334.603 82.7633C334.465 78.2634 334.395 74.2828 334.395 70.8213V2.18071H371V154Z"
        fill="white"
      />
    </svg>
  );
};

export const StarSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M12.3767 18.9547L8.68012 20.8715C8.29678 21.0743 7.83169 21.0428 7.4792 20.7901C7.12671 20.5375 6.94749 20.1072 7.01643 19.679L7.84177 14.933L4.34531 11.5726C4.03283 11.2723 3.92012 10.8195 4.05537 10.4078C4.19062 9.99605 4.5499 9.69828 4.97958 9.6418L9.81159 8.94951L11.9695 4.62771C12.1662 4.24051 12.5636 3.99658 12.9979 3.99658C13.4322 3.99658 13.8297 4.24051 14.0263 4.62771L16.1892 8.94951L21.0213 9.6398C21.4507 9.69626 21.8098 9.99371 21.9452 10.4051C22.0806 10.8165 21.9684 11.2691 21.6565 11.5696L19.6837 13.4654"
        stroke="#80737A"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5949 16.4096C20.3658 17.1787 20.5972 18.3367 20.181 19.343C19.7648 20.3494 18.7831 21.0057 17.6941 21.0057C16.6051 21.0057 15.6235 20.3494 15.2073 19.343C14.7911 18.3367 15.0224 17.1787 15.7933 16.4096C16.8436 15.3609 18.5447 15.3609 19.5949 16.4096"
        stroke="#80737A"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3835 22.0041L19.5947 20.2153"
        stroke="#80737A"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TrendingCheckSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99902 4.99668L10.2996 3.37101C10.4896 3.13391 10.7768 2.99585 11.0809 2.99585H14.9215C15.2256 2.99585 15.5127 3.13391 15.7028 3.37101L17.0024 4.99668V4.99668V5.9971C17.0024 6.54933 16.5542 6.99752 16.0019 6.99752H9.99944C9.44721 6.99752 8.99902 6.54933 8.99902 5.9971V4.99668V4.99668Z"
        stroke="#262123"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99874 4.99658H6.9979C5.89244 4.99658 4.99707 5.89195 4.99707 6.99742V20.0028C4.99707 21.1083 5.89244 22.0037 6.9979 22.0037H19.0029C20.1084 22.0037 21.0037 21.1083 21.0037 20.0028V6.99742C21.0037 5.89195 20.1084 4.99658 19.0029 4.99658H17.0021"
        stroke="#262123"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0017 13.5002L12.5006 16.0013L11 14.5007"
        stroke="#262123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TrendingStarSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        x="4"
        y="3"
        width="16"
        height="18"
        rx="2"
        stroke="#262123"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17H16"
        stroke="#262123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2152 6.98783C11.3626 6.68912 11.6668 6.5 11.9999 6.5C12.333 6.5 12.6372 6.68912 12.7845 6.98783L13.2297 7.89031C13.3572 8.14861 13.6035 8.32766 13.8886 8.36909L14.8844 8.51384C15.2139 8.56176 15.4877 8.79256 15.5906 9.10922C15.6936 9.42588 15.6079 9.77353 15.3695 10.006L14.6486 10.7092C14.4425 10.9102 14.3485 11.1998 14.3972 11.4835L14.5673 12.4751C14.6235 12.8033 14.4886 13.1351 14.2192 13.3308C13.9498 13.5266 13.5927 13.5524 13.2979 13.3975L12.4069 12.9293C12.1521 12.7954 11.8477 12.7954 11.5928 12.9293L10.7019 13.3975C10.4071 13.5524 10.0499 13.5266 9.78051 13.3308C9.51111 13.1351 9.37619 12.8033 9.43246 12.4751L9.60257 11.4835C9.65123 11.1998 9.55721 10.9102 9.35113 10.7092L8.63022 10.006C8.39187 9.77353 8.30616 9.42588 8.4091 9.10922C8.51204 8.79256 8.78579 8.56176 9.1153 8.51384L10.1112 8.36909C10.3962 8.32766 10.6426 8.14861 10.77 7.89031L11.2152 6.98783Z"
        stroke="#262123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InfoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.9995 4C5.9305 4 5.8745 4.056 5.875 4.125C5.875 4.194 5.931 4.25 6 4.25C6.069 4.25 6.125 4.194 6.125 4.125C6.125 4.056 6.069 4 5.9995 4"
        stroke="#EEEEFC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{' '}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10.5V10.5C3.5145 10.5 1.5 8.4855 1.5 6V6C1.5 3.5145 3.5145 1.5 6 1.5V1.5C8.4855 1.5 10.5 3.5145 10.5 6V6C10.5 8.4855 8.4855 10.5 6 10.5Z"
        stroke="#EEEEFC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6V8.5"
        stroke="#EEEEFC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{' '}
    </svg>
  );
};
