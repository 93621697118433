import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";

export default function FAQSection() {
  const { t } = useTranslation();

  const generalFaqItems = [
    {
      question: t("faq_question_0"),
      answer: t("faq_answer_0"),
    },
    {
      question: t("faq_question_1"),
      answer: t("faq_answer_1"),
    },
    {
      question: t("faq_question_2"),
      answer: t("faq_answer_2"),
    },
    {
      question: t("faq_question_3"),
      answer: t("faq_answer_3"),
    },
    {
      question: t("faq_question_4"),
      answer: t("faq_answer_4"),
    },
    {
      question: t("faq_question_5"),
      answer: t("faq_answer_5"),
    },
    {
      question: t("faq_question_6"),
      answer: t("faq_answer_6"),
    },
    {
      question: t("faq_question_7"),
      answer: t("faq_answer_7"),
    },
    {
      question: t("faq_question_8"),
      answer: t("faq_answer_8"),
    },
  ];

  const spinAndWinFaqItems = [
    {
      question: "Who can participate in Spin & Win?",
      answer: (
        <>
          Only registered 22Night community members with an active account can
          participate. Participants must be at least 18 years old.
        </>
      ),
    },
    {
      question: "How often can I spin?",
      answer: (
        <>
          Each participant is allowed one spin per day. Any attempt to bypass
          this limit (e.g., using multiple accounts) may result in
          disqualification.
        </>
      ),
    },
    {
      question: "What prizes can I win?",
      answer: (
        <>
          By spinning the wheel, you may win:
          <ul className="good-list">
            <li>No prize</li>
            <li>
              Alternative prizes (e.g., discount codes for third-party brands
              such as Nike)
            </li>
            <li>
              A discount between 1% and 100% on a ticket for a featured
              event/game
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "How do I receive my discount code?",
      answer: (
        <>
          If you win a discount code:
          <ul className="good-list">
            <li>It will be immediately displayed after spinning.</li>
            <li>It will be sent to your registered email address.</li>
            <li>
              You can also find it in your account under Profile {">"} My
              Discount Codes.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "How long is my discount code valid?",
      answer: (
        <>
          Your discount code must be used within 24 hours at the designated
          partner's platform. After that, it will automatically expire and
          cannot be reissued.
        </>
      ),
    },
    {
      question: "What happens if I don't use my discount code in time?",
      answer: (
        <>
          If you do not redeem your discount code within 24 hours, it will
          become invalid. Once expired, it cannot be reactivated or replaced.
        </>
      ),
    },
    {
      question: "Can I transfer my discount code to someone else?",
      answer: (
        <>
          No, discount codes are personal and non-transferable. Sharing,
          selling, or attempting to transfer a code is considered abuse and may
          result in disqualification.
        </>
      ),
    },
    {
      question: "Where can I use my discount code?",
      answer: (
        <>
          Discount codes can only be used on the designated partner's platform
          for the specific event or game linked to the prize.
        </>
      ),
    },
    {
      question:
        "I didn't receive my discount code via email. What should I do?",
      answer: (
        <>
          First, check your spam/junk folder in case the email was filtered. If
          you still don't see it:
          <ul className="good-list">
            <li>Go to Profile {">"} My Discount Codes to retrieve it.</li>
            <li>
              If it's not there, contact 22Night customer support for
              assistance.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "Can I exchange my prize for money or a different reward?",
      answer: (
        <>
          No, all prizes and discount codes are final and cannot be exchanged
          for cash, alternative prizes, or any other benefits.
        </>
      ),
    },
    {
      question: "What if I experience technical issues while spinning?",
      answer: (
        <>
          If you encounter technical issues, refresh the page and try again. If
          the problem persists, contact 22Night customer support with a
          screenshot and description of the issue.
        </>
      ),
    },
    {
      question: "Can I be disqualified from Spin & Win?",
      answer: (
        <>
          Yes. You may be disqualified if you:
          <ul className="good-list">
            <li>
              Attempt to manipulate the game (e.g., using bots or multiple
              accounts).
            </li>
            <li>Sell, share, or transfer discount codes or tickets.</li>
            <li>Violate the Terms & Conditions of the promotion.</li>
          </ul>
        </>
      ),
    },
    {
      question: "What happens if I resell my won or claimed ticket?",
      answer: (
        <>
          Reselling a won or claimed ticket is strictly prohibited. If we
          determine that a participant has resold a ticket, a €5,000 penalty
          will be imposed.
          <br />
          <br />
          <strong>
            ⚠ By participating in Spin & Win, you agree to this condition. If
            reselling is detected, we reserve the right to take legal action and
            enforce the penalty immediately.
          </strong>
        </>
      ),
    },
    {
      question: "What happens if I win but don't claim my code immediately?",
      answer: (
        <>
          Your discount code is automatically stored under Profile {">"} My
          Discount Codes. However, you still must use it within 24 hours before
          it expires.
        </>
      ),
    },
  ];

  return (
    <>
      <h1 className="max-lg:!mt-[-10vh] lg:!mt-[-10vh] 2xl:!mt-[-15vh] !text-[28px] font-semibold text-white text-center w-full">
        {t("faq_title")}
      </h1>
      <div className="mt-8 w-full divide-y divide-white/5 rounded-xl bg-white/5">
        {/* General FAQ Section */}
        <Disclosure as="div" className="p-10" defaultOpen>
          <DisclosureButton className="group flex w-full items-center justify-between">
            <span className="max-w-[85%] text-start text-[20px] lg:text-[24px] font-medium text-white group-data-[hover]:text-white/80">
              22Night FAQ
            </span>
            <Icon
              icon="heroicons:chevron-down"
              className="h-10 w-10 text-white group-data-[hover]:text-white/50 group-data-[open]:rotate-180"
            />
          </DisclosureButton>
          <DisclosurePanel>
            <div className="space-y-4 pt-4">
              {generalFaqItems.map((item, index) => (
                <Disclosure as="div" key={index} defaultOpen={index === 0}>
                  <DisclosureButton className="group flex w-full items-center justify-between py-[20px]">
                    <span className="max-w-[85%] text-start text-[16px] lg:text-[20px] font-medium text-white group-data-[hover]:text-white/80">
                      {item.question}
                    </span>
                    <Icon
                      icon="heroicons:chevron-down"
                      className="h-10 w-10 text-white group-data-[hover]:text-white/50 group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="text-[14px] lg:text-[18px] text-white/50 whitespace-pre-wrap">
                    {item.answer}
                  </DisclosurePanel>
                </Disclosure>
              ))}
            </div>
          </DisclosurePanel>
        </Disclosure>

        {/* Spin & Win FAQ Section */}
        <Disclosure as="div" className="p-10">
          <DisclosureButton className="group flex w-full items-center justify-between">
            <span className="max-w-[85%] text-start text-[20px] lg:text-[24px] font-medium text-white group-data-[hover]:text-white/80">
              Spin & Win FAQ
            </span>
            <Icon
              icon="heroicons:chevron-down"
              className="h-10 w-10 text-white group-data-[hover]:text-white/50 group-data-[open]:rotate-180"
            />
          </DisclosureButton>
          <DisclosurePanel>
            <div className="space-y-4 pt-4">
              {spinAndWinFaqItems.map((item, index) => (
                <Disclosure as="div" key={index} defaultOpen={index === 0}>
                  <DisclosureButton className="group flex w-full items-center justify-between py-[20px]">
                    <span className="max-w-[85%] text-start text-[16px] lg:text-[20px] font-medium text-white group-data-[hover]:text-white/80">
                      {item.question}
                    </span>
                    <Icon
                      icon="heroicons:chevron-down"
                      className="h-10 w-10 text-white group-data-[hover]:text-white/50 group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="text-[14px] lg:text-[18px] text-white/50 whitespace-pre-wrap">
                    {item.answer}
                  </DisclosurePanel>
                </Disclosure>
              ))}
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </>
  );
}
