import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import Icon from "../components/Icon";

export default function ManageTrendingEvents() {
  const [trendingEvents, setTrendingEvents] = useState([]);

  useEffect(() => {
    fetchTrendingEvents();
  }, []);

  const fetchTrendingEvents = async () => {
    const now = new Date().toISOString();

    const { data: events, error } = await supabase
      .from("Events")
      .select("*")
      .eq("trending", true)
      .gte("datetime", now)
      .order("trending_index", { ascending: true });

    if (error) {
      console.error("Error fetching trending events:", error);
      return;
    }

    const eventsWithIndex = events || [];
    let needsUpdate = false;

    // Check if indices don't start from 1 or have gaps/duplicates
    const usedIndices = new Set();
    eventsWithIndex.forEach((event, index) => {
      const expectedIndex = index + 1; // We want indices to start from 1
      if (!event.trending_index ||
          event.trending_index !== expectedIndex ||
          usedIndices.has(event.trending_index)) {
        needsUpdate = true;
      }
      usedIndices.add(event.trending_index);
    });

    // Reassign all indices to ensure they start from 1 and are sequential
    if (needsUpdate) {
      const updates = eventsWithIndex.map((event, index) => {
        return supabase
          .from("Events")
          .update({ trending_index: index + 1 }) // Start from 1
          .eq("id", event.id);
      });

      try {
        await Promise.all(updates);

        // Fetch updated events
        const { data: updatedEvents } = await supabase
          .from("Events")
          .select("*")
          .eq("trending", true)
          .gte("datetime", now)
          .order("trending_index", { ascending: true });

        setTrendingEvents(updatedEvents || []);
      } catch (error) {
        console.error("Error updating trending indices:", error);
      }
    } else {
      setTrendingEvents(eventsWithIndex);
    }
  };

  const moveEvent = async (eventId, direction) => {
    try {
      // Get the current event's trending_index
      const currentEvent = trendingEvents.find(event => event.id === eventId);
      if (!currentEvent) return;

      // Get all trending events sorted by index
      const { data: allTrendingEvents } = await supabase
        .from("Events")
        .select("*")
        .eq("trending", true)
        .order("trending_index", { ascending: true });

      if (!allTrendingEvents) return;

      // Find the current position and calculate target position
      const currentPosition = allTrendingEvents.findIndex(event => event.id === eventId);
      const targetPosition = direction === 'up' ? currentPosition - 1 : currentPosition + 1;

      // Check if move is valid
      if (targetPosition < 0 || targetPosition >= allTrendingEvents.length) return;

      // Create array of updates with sequential indices
      const updates = allTrendingEvents.map((event, index) => {
        let newIndex = index + 1; // Default sequential index

        // Swap the indices for the two affected events
        if (index === currentPosition) {
          newIndex = targetPosition + 1;
        } else if (index === targetPosition) {
          newIndex = currentPosition + 1;
        }

        return supabase
          .from("Events")
          .update({ trending_index: newIndex })
          .eq("id", event.id);
      });

      await Promise.all(updates);
      await fetchTrendingEvents();
    } catch (error) {
      console.error("Error updating trending indices:", error);
    }
  };

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <h1 className="text-white !text-5xl font-semibold !text-start w-fit mb-8">
        Manage Trending Events
      </h1>

      <Table className="mt-8">
        <Table.Head>
          <Table.HeadCell className="text-3xl w-[5%] capitalize">
            Order
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[40%] capitalize">
            Event Name
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[20%] capitalize">
            Date
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[20%] capitalize">
            City
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[15%] capitalize">
            Actions
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {trendingEvents.map((event, index) => (
            <Table.Row key={event.id} className={index % 2 === 0 ? "bg-white" : "bg-gray-200"}>
              <Table.Cell className="text-2xl">
                {event.trending_index}
              </Table.Cell>
              <Table.Cell className="text-2xl font-medium text-gray-900">
                {event.name}
              </Table.Cell>
              <Table.Cell className="text-2xl">
                {dayjs(event.datetime).format("DD MMM YYYY HH:mm")}
              </Table.Cell>
              <Table.Cell className="text-2xl">
                {event.city}
              </Table.Cell>
              <Table.Cell className="flex items-center gap-2">
                <button
                  onClick={() => moveEvent(event.id, 'up')}
                  disabled={index === 0}
                  className={`p-2 hover:bg-neutral-300 rounded-lg transition-all ${index === 0 ? 'opacity-50' : ''}`}
                >
                  <Icon icon="heroicons:arrow-up" className="h-10 w-10" />
                </button>
                <button
                  onClick={() => moveEvent(event.id, 'down')}
                  disabled={index === trendingEvents.length - 1}
                  className={`p-2 hover:bg-neutral-300 rounded-lg transition-all ${index === trendingEvents.length - 1 ? 'opacity-50' : ''}`}
                >
                  <Icon icon="heroicons:arrow-down" className="h-10 w-10" />
                </button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}