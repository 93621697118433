import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <div className="flex items-center flex-col min-h-screen pb-[100px] pt-[100px] !text-white">
      <div className="w-[95vw] max-w-[1200px] text-start">
        <div className="text-[50px] font-semibold">22NIGHT Privacy Policy</div>
        <p className="text-[20px] font-bold">
          DISCLAIMER: THIS PRIVACY POLICY BELONGS TO 22NIGHT B.V., AND COVERS
          THE PRIVACY PRACTICES FOR THE 22NIGHT WEBSITE, HOSTED AT{" "}
          <span className="font-bold">
            <Link
              to="https://22night.com"
              className="text-blue-500 font-bold"
              target="_blank"
            >
              https://22night.com
            </Link>
          </span>
          .
        </p>
        <p className="text-[20px] mt-4">Last updated on: 19-03-2025</p>
        <h3 className="text-[30px] font-semibold !mt-8">Algemeen</h3>
        <p className="text-[20px] mt-4">
          22night B.V. respecteert jouw persoonsgegevens en draagt er zorg voor
          dat de persoonlijke informatie die aan ons wordt verstrekt of
          anderszins wordt verkregen, vertrouwelijk wordt behandeld. Met deze
          privacyverklaring wordt uitvoering gegeven aan de in de Algemene
          Verordening Gegevensbescherming (hierna: “AVG”) neergelegde
          informatieplicht aan de betrokkene(n) wiens persoonsgegevens 22night
          B.V. verwerkt
        </p>
        <p className="text-[20px] mt-2">
          Deze privacyverklaring is op jou van toepassing als je onze website
          bezoekt en gebruikmaakt van onze diensten.
        </p>
        <h3 className="text-[30px] font-semibold !mt-8">
          Identiteit van de verwerkingsverantwoordelijke
        </h3>
        <p className="text-[20px] font-semibold mt-4">
          22night B.V., is de
          verwerkingsverantwoordelijke voor de verwerking van persoonsgegevens
          zoals weergegeven in deze privacyverklaring.
        </p>
        <h3 className="text-[30px] font-semibold !mt-8">Definities</h3>
        <p className="text-[20px] mt-4">
          In deze Privacyverklaring worden de volgende definities gehanteerd:
        </p>
        <ul className="!list-disc">
          <li className="text-[20px] mt-4">
            <span className="font-semibold">22night</span>: 22night B.V.
          </li>
          <li className="text-[20px] mt-4">
            <span className="font-semibold">22night website</span>:
            www.22night.com
          </li>
          <li className="text-[20px] mt-4">
            <span className="font-semibold">22night platform:</span>
            de 22night website en de verschillende sociale mediakanalen en
            -groepen alsmede WhatsApp-groepen van 22night.
          </li>{" "}
          <li className="text-[20px] mt-4">
            <span className="font-semibold">Organisator:</span>
            een partij wiens evenementen worden aangeboden op het 22night
            platform.
          </li>{" "}
          <li className="text-[20px] mt-4">
            <span className="font-semibold">Evenement:</span>
            een evenement aangeboden door een Organisator.
          </li>{" "}
          <li className="text-[20px] mt-4">
            <span className="font-semibold">22night diensten:</span>
            het aanbieden en promoten van Evenementen van derden tegen
            gereduceerde tarieven en het aanbieden van relevante vacatures van
            derden
          </li>
        </ul>
        <h3 className="text-[30px] font-semibold !mt-8">
          Welke persoonsgegevens worden verwerkt?
        </h3>
        <p className="text-[20px] mt-4">
          22night verwerkt van jou de volgende persoonsgegevens
        </p>
        <ul className="!list-disc">
          <li className="text-[20px] mt-4">
            Accountgegevens: gegevens waarmee je een account aanmaakt en
            gebruikt, zoals e- mailadres en wachtwoord, accountgegevens waarmee
            je optioneel je account verrijkt, zoals naam, leeftijd, studie,
            studiejaar, locatie en telefoonnummer.
          </li>
          <li className="text-[20px] mt-4">
            Automatisch gegenereerde gegevens: bijvoorbeeld IP-adressen, device
            ID en browsergegevens die we onder meer door gebruik van cookies
            verzamelen.
          </li>
          <li className="text-[20px] mt-4">
            Gebruiksgegevens: gegevens over de wijze waarop je het 22night
            platform gebruikt. Bijvoorbeeld welke diensten je gebruikt en welke
            Evenementen je interessant vindt, om hiermee een profiel op te
            bouwen op basis waarvan we het 22night platform en de 22night
            diensten kunnen personaliseren.
          </li>
          <li className="text-[20px] mt-4">
            Feedbackgegevens: bijvoorbeeld gegevens die je invult op
            feedbackformulieren of surveys met betrekking tot ons platform en
            onze diensten of gegevens die je ons verstrekt.
          </li>
          <li className="text-[20px] mt-4">
            Correspondentiegegevens: bijvoorbeeld gegevens in e-mails die je
            verstuurt aan 22night of berichten die je via social media aan ons
            richt
          </li>
        </ul>
        <p className="text-[20px] mt-4">
          22night verzamelt verder geen gegevens van personen jonger dan 16 jaar
          en geen bijzondere persoonsgegevens.
        </p>
        <h3 className="text-[30px] font-semibold !mt-8">
          Doel en grondslag voor de gegevensverwerking
        </h3>
        <p className="text-[20px] mt-4">
          Voor de verwerking van persoonsgegevens is een zogeheten grondslag
          vereist. Deze zijn te vinden in artikel 6 AVG. Hieronder is
          weergegeven voor welke doeleinden 22night je persoonsgegevens verwerkt
          en op basis van welke grondslag dit gebeurt.
        </p>
        <ol className="!list-decimal">
          <li className="text-[20px] mt-4">
            Voor het leveren van de 22night diensten. Hieronder vallen
            Accountgegevens en Gebruiksgegevens. De grondslag voor deze
            verwerking is toestemming.
          </li>
          <li className="text-[20px] mt-4">
            Ter verbetering van de 22night diensten. Hieronder vallen
            Accountgegevens, Automatisch gegenereerde gegevens,
            Gebruiksgegevens, Feedbackgegevens en Correspondentiegegevens. De
            grondslag voor deze gegevensverwerking is ons gerechtvaardigde
            belang. We gebruiken de gegevens om onze dienstverlening zo goed
            mogelijk af te stemmen op de behoeftes van onze gebruikers. Om de
            privacy-impact te beperken gebruiken we waar mogelijk gegevens op
            geaggregeerd niveau. Als we hier derden voor inschakelen maken we
            met hen goede afspraken om de privacy te waarborgen. Voor meer
            informatie over de belangenafweging die we maken voor het doen van
            deze verwerking kan je contact opnemen met 22night.
          </li>
          <li className="text-[20px] mt-4">
            Voor de veiligheid en betrouwbaarheid van het 22night platform.
            Hieronder vallen de volgende gegevens: Voornaam, achternaam,
            e-mailadres, telefoonnummer. 22night heeft een gerechtvaardigd
            belang voor deze verwerking omdat wij de kwaliteit van onze
            dienstverlening en de beschikbaarheid, integriteit en
            vertrouwelijkheid van de (persoons)gegevens die wij verwerken te
            waarborgen.
          </li>
          <li className="text-[20px] mt-4">
            Voor klantcontact. Hieronder vallen Correspondentiegegevens. De
            grondslag voor dit doel is ons gerechtvaardigde belang. Wij
            verwerken deze gegevens om jou goede service te verlenen.
          </li>
          <li className="text-[20px] mt-4">
            Voor marktanalyses en statistisch onderzoek. Hieronder vallen
            Accountgegevens, Automatisch gegenereerde gegevens,
            Gebruiksgegevens, Feedbackgegevens en Correspondentiegegevens.
            Hiervoor beroepen wij ons op de grondslag gerechtvaardigd belang.
            Wij gebruiken de gegevens om analyses en bedrijfsrapportages te
            maken om zo goed zicht te houden op het functioneren van ons
            platform en ter optimalisering van onze bedrijfsvoering
          </li>
          <li className="text-[20px] mt-4">
            Voor het toesturen van relevante nieuwsbrieven. Hieronder vallen
            Accountgegevens om de e-mail aan jou te richten en Gebruiksgegevens
            om de nieuwsbrief te personaliseren. Verder verwerken wij
            Automatisch gegenereerde gegevens om te kunnen nagaan op welke
            onderdelen uit de nieuwsbrief jij hebt geklikt, hoe vaak en wanneer.
            De grondslag voor deze verwerking is ons gerechtvaardigde belang,
            namelijk om jou op jouw verzoek te kunnen informeren, het aanbod op
            jouw wensen af te stemmen en onze nieuwsbrieven te verbeteren.
          </li>
          <li className="text-[20px] mt-4">
            Voor het tonen van gerichte advertenties. Hieronder vallen
            Accountgegevens, Automatisch gegenereerde gegevens en
            Gebruiksgegevens. De grondslag hiervoor is toestemming. We gebruiken
            de (gepseudonimiseerde) persoonsgegevens om advertenties relevant te
            maken voor jou.
          </li>
          <li className="text-[20px] mt-4">
            Voor de werving en selectie van nieuwe medewerkers (sollicitatie).
            Hieronder vallen onder andere gegevens uit jouw cv, motivatiebrief
            en gegevens die je tijdens eventuele gesprekken met ons deelt. De
            grondslag voor deze verwerking is ons gerechtvaardigd belang.
          </li>
        </ol>
        <h3 className="text-[30px] font-semibold !mt-8">Bewaartermijn</h3>
        <p className="text-[20px] mt-4">
          We bewaren je persoonsgegevens alleen zolang we ze nodig hebben voor
          het doel waarvoor we ze verwerken of om aan onze wettelijke plichten
          te voldoen. We hanteren de volgende (criteria bij het vaststellen van
          de) bewaartermijnen:
        </p>
        <ul className="!list-disc">
          <li className="text-[20px] mt-4">
            Als je gebruik maakt van de 22night diensten bewaren we je
            persoonsgegevens zolang we ze nodig hebben voor het leveren van onze
            diensten. Dat is meestal voor de duur dat je een account bij ons
            hebt. Indien je geen gebruik meer maakt van de 22night diensten
            zullen we de gegevens na maximaal een periode van twee jaar
            verwijderen. Ook verwijderen wij je gegevens als jij daarom vraagt,
            tenzij wij wettelijk verplicht zijn die gegevens langer te bewaren.
          </li>
          <li className="text-[20px] mt-4">
            De gegevens die we verwerken om onze diensten te verbeteren en te
            beveiligen, bewaren we maximaal voor een periode van zes maanden
            nadat we ze hebben verkregen.
          </li>
          <li className="text-[20px] mt-4">
            Als je toestemming hebt gegeven voor het ontvangen van marketing
            e-mails/nieuwsbrieven, bewaren we je e-mailadres en het bewijs dat
            je toestemming hebt gegeven totdat je die toestemming intrekt. Zodra
            je dat doet, bewaart 22night voor een periode van vijf jaar nog wel
            persoonsgegevens die nodig zijn om aan te kunnen tonen wanneer je
            toestemming hebt gegeven en wanneer je deze hebt ingetrokken
          </li>
          <li className="text-[20px] mt-4">
            Als je bij ons hebt gesolliciteerd, bewaren wij die gegevens
            gedurende de wervings- en selectieprocedure. In geval van een
            afwijzing worden de gegevens uiterlijk vier weken na het beëindigen
            dan wel afronden van de sollicitatieprocedure verwijderd. Als je
            daarvoor toestemming geeft, bewaren wij jouw sollicitatiegegevens
            voor een termijn van een jaar om je te kunnen informeren over een
            eventuele andere positie binnen 22night
          </li>
          <li className="text-[20px] mt-4">
            De persoonsgegevens die staan genoemd bij ‘klantcontact’ bewaren wij
            maximaal één maand na het afhandelen van de vraag.
          </li>
        </ul>
        <p className="text-[20px] mt-4">
          Voorgaande betreft de criteria om de bewaartermijnen te bepalen. Mocht
          een verwerking niet genoemd zijn en jij meer informatie willen over de
          desbetreffende termijn, kun je contact met ons opnemen via het
          e-mailadres zoals hierboven in het privacy statement staat vermeld.
        </p>
        <h3 className="text-[30px] font-semibold !mt-8">
          Met wie deelt 22night persoonsgegevens?
        </h3>
        <p className="text-[20px] mt-4">
          De volgende derde partijen kunnen toegang verkrijgen tot je
          persoonsgegevens
        </p>
        <ul className="!list-disc">
          <li className="text-[20px] mt-4">
            Medewerkers. voor zover nodig voor het uitvoeren van de functie,
            verkrijgen onze medewerkers inzage in jouw gegevens.
          </li>
          <li className="text-[20px] mt-4">
            Verwerkers. We schakelen partijen in die namens ons persoonsgegevens
            verwerken (verwerkers), bijvoorbeeld om de 22night website te
            hosten, persoonsgegevens op te slaan en te beheren en nieuwsbrieven
            te versturen.
          </li>
          <li className="text-[20px] mt-4">
            Andere samenwerkingspartners. We delen ook gegevens met partijen die
            (gedeeltelijk) als Verwerkingsverantwoordelijke kwalificeren. Omdat
            onze samenwerkingspartners zelf (gedeeltelijk) verantwoordelijk zijn
            voor het verwerken van jouw persoonsgegevens, moeten zij jou daar
            zelf over informeren. Deze informatie kun je veelal vinden op de
            website van de desbetreffende partij. Het gaat dan met name om
            Organisatoren van Evenementen en de aanbieder(s) van vacatures
          </li>
        </ul>
        <p className="text-[20px] mt-4">
          Mocht het voorkomen dat (één of meerdere onderdelen van) de
          onderneming van 22night worden overgedragen aan een derde, dan kunnen
          als onderdeel van deze overname ook de persoonsgegevens die 22night
          verwerkt, worden overgedragen aan deze derde. Als deze situatie zich
          voordoet, zal 22night je hierover tijdig informeren via het 22night
          platform.
        </p>
        <h3 className="text-[30px] font-semibold !mt-8">
          Links naar websites van derden / social media
        </h3>
        <p className="text-[20px] mt-4">
          Het is mogelijk dat je via advertenties of links wordt doorverwezen
          naar sociale mediakanalen en/of naar websites van derden. 22night is
          niet verantwoordelijk voor de verwerking van persoonsgegevens door
          deze partijen en 22night verwijst voor verdere informatie naar de
          privacy statements van deze derden.
        </p>
        <h3 className="text-[30px] font-semibold !mt-8">
          Internationale doorgifte van persoonsgegevens
        </h3>
        <p className="text-[20px] mt-4">
          22night is een Nederlandse partij, net als veel van de partijen
          waarmee we samenwerken. Sommige van onze dienstverleners of partners
          verwerken persoonsgegevens op een locatie buiten de Europese
          Economische Ruimte (“EER”), namelijk in geen. Wij maken daarbij een
          zorgvuldige afweging om een afdoende niveau van bescherming van jouw
          gegevens te bieden. Waar nodig leggen wij afspraken vast in een
          doorgifte overeenkomst op basis van modelcontractbepalingen die door
          de Europese Commissie zijn vastgesteld (artikel 46 lid 2 sub c AVG).
        </p>
        <p className="text-[20px] mt-4">
          Voor meer informatie over de waarborgen inzake internationale
          doorgifte kunt u contact met ons opnemen via de contactgegevens die
          staan vermeld bovenaan in deze Privacyverklaring
        </p>
        <h3 className="text-[30px] font-semibold !mt-8">
          Wat zijn je rechten?
        </h3>
        <p className="text-[20px] mt-4">
          {" "}
          Op basis van de privacywetgeving heb jij bepaalde rechten ten aanzien
          van jouw persoonsgegevens. Jij hebt de volgende rechten:
        </p>
        <ul className="!list-disc">
          <li className="text-[20px] mt-4">
            Recht van inzage. Je hebt het recht te weten welke persoonsgegevens
            we van jou hebben. We verschaffen je op verzoek een kopie van jouw
            persoonsgegevens in een gestructureerd, gangbaar en machine leesbaar
            formaat.
          </li>
          <li className="text-[20px] mt-4">
            Recht op rectificatie. Als je persoonsgegevens niet kloppen of
            onvolledig zijn, heb je het recht ons te vragen deze bij te werken.
          </li>
          <li className="text-[20px] mt-4">
            Recht van bezwaar. Je hebt het recht bezwaar te maken tegen onze
            verwerking van jouw persoonsgegevens.
          </li>
          <li className="text-[20px] mt-4">
            Recht op het wissen van jouw gegevens. Je kunt ons ook vragen jouw
            persoonsgegevens te wissen of ons gebruik ervan te beperken of
            tijdelijk te laten stoppen
          </li>
          <li className="text-[20px] mt-4">
            Recht op dataportabiliteit. Dit is het recht om je persoonsgegevens
            van ons te ontvangen zodat je deze gegevens door kunt sturen aan een
            andere partij
          </li>
          <li className="text-[20px] mt-4">
            Intrekken toestemming. Waar wij je persoonsgegevens gebruiken op
            basis van je toestemming, heb je het recht die toestemming te allen
            tijde in te trekken. Als je bijvoorbeeld geabonneerd bent op een
            22night nieuwsbrief en die niet meer wilt ontvangen, kun je je
            altijd uitschrijven (en zo je toestemming intrekken) door in een
            nieuwsbrief op ‘afmelden’ te klikken. Je kunt je ook afmelden via je
            account.
          </li>
        </ul>
        <p className="text-[20px] mt-4">
          Je kunt jouw rechten uitoefenen door contact met ons op te nemen via
          de contactgegevens zoals bovenaan in dit privacy statement staan
          genoemd. Als je een verzoek doet dat betrekking heeft op hoe we omgaan
          met je persoonsgegevens, kunnen we je aanvullende vragen stellen om
          ons ervan te verzekeren dat wij de gegevens aan de juiste persoon
          verstrekken
        </p>
        <p className="text-[20px] mt-4">
          Wij zijn niet altijd verplicht om jouw verzoek(en) toe te wijzen. Wel
          reageren wij altijd op jouw verzoek, binnen één maand. Alleen in
          bijzondere gevallen mogen wij er langer over doen, maar we laten dan
          wel binnen een maand iets van ons horen.
        </p>
        <h3 className="text-[30px] font-semibold !mt-8">Cookies</h3>
        <p className="text-[20px] mt-4">
          Wij verzamelen informatie over jouw bezoek van de 22night website via
          cookies. Cookies zijn kleine (tekst)bestanden die via jouw computer,
          tablet, smartphone of ander elektronisch apparaat worden opgeslagen
          bij het bezoek aan de 22night website of het 22night platform. Door
          het gebruik van cookies wordt jouw apparaat herkend. Op die manier
          kunnen wij meten hoe onze 22night website wordt gebruikt en kunnen wij
          verbeteringen aanbrengen
        </p>
        <p className="text-[20px] mt-4">
          22night maakt gebruik van onderstaande cookies:
        </p>
        <ul className="!list-disc">
          <li className="text-[20px] mt-4">Analytische cookies</li>
        </ul>
        <p className="text-[20px] mt-4">
          Geplaatste cookies kunnen via jouw browser-instellingen op ieder
          moment door jou worden verwijderd.
        </p>
        <h3 className="text-[30px] font-semibold !mt-8">
          Vragen, opmerkingen of klachten?
        </h3>
        <p className="text-[20px] mt-4">
          Heb je vragen of opmerkingen over privacy bij 22night? Ben je het niet
          eens met het feit dat of de manier waarop 22night persoonsgegevens van
          jou verwerkt?
        </p>
        <p className="text-[20px] mt-4">
          Stuur dan een e-mail naar info@22night.com.
        </p>
        <p className="text-[20px] mt-4">
          We nemen alle vragen, opmerkingen en verzoeken serieus en blijven
          graag met je in contact over jouw privacy bij 22night, ook als je
          ergens niet tevreden over bent. Ook streven we ernaar binnen vier
          weken op jouw klacht te reageren. We lossen klachten uiteraard het
          liefst zelf direct met jou op, maar je hebt daarnaast ook de
          mogelijkheid om een klacht in te dienen bij de Autoriteit
          Persoonsgegevens.
        </p>
        <p className="text-[20px] mt-4 italic">
          Dit privacybeleid is voor het laatst gewijzigd op 1 maart 2023, en is
          daarmee de 1e versie. 22night behoudt zich het recht voor dit
          privacybeleid te wijzigen en we raden je dan ook aan om regelmatig op
          deze pagina te kijken. Bij substantiële wijzigingen in ons
          privacybeleid word je ook zo veel mogelijk per e-mail of op het
          22night platform geïnformeerd.
        </p>
      </div>
    </div>
  );
}
