import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabaseClient";
import { selectUser } from "../slices/userReducer";
import { useSelector } from "react-redux";

export default function SuggestionsModal({ show, setShow, isFeedback = false }) {
  const { t } = useTranslation();

  const [suggestion, setSuggestion] = useState("");
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [error, setError] = useState(false);

  const user = useSelector(selectUser);

  const handleSubmit = () => {
    if (isFeedback) {
      if (!feedback || !email || !phoneNumber) {
        setError(true);
        return;
      }

      supabase
        .from("Suggestion")
        .insert({
          event: feedback,
          email,
          phone_number: phoneNumber,
          is_feedback: true,
        })
        .then((res) => {
          if (res.error) {
            console.log(res.error);
          } else {
            setShow(false);
            setFeedback("");
          }
        });
    } else {
      if (!suggestion || !email || !phoneNumber) {
        setError(true);
        return;
      }

      supabase
        .from("Suggestion")
        .insert({
          event: suggestion,
          email,
          phone_number: phoneNumber,
          is_feedback: false,
        })
        .then((res) => {
          if (res.error) {
            console.log(res.error);
          } else {
            setShow(false);
            setSuggestion("");
          }
        });
    }
  };

  useEffect(() => {
    if (user.email && user.phone_number) {
      setEmail(user.email);
      setPhoneNumber(user.phone_number);
    }
  }, [user, show]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[10000000000000000] inset-0 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-center justify-center h-[100vh] pt-4 px-4 pb-20 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full h-screen bg-[#00000080] fixed z-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-neutral-200 rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[90vw] w-[500px] sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="rounded-md text-neutral-800 hover:text-neutral-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-12 w-12" aria-hidden="true" />
                </button>
              </div>
              <div className="mb-3 flex justify-center w-full">
                <div className="flex-col flex items-center w-full">
                  <div className="text-center w-full">
                    <h1 className="text-[24px] text-black leading-[28px] font-bold w-full">
                      {isFeedback ? t("leave_feedback") : t("leave_a_suggestion")}
                    </h1>
                  </div>
                </div>
              </div>

              <hr className="border-neutral-600/50 mt-[20px] max-lg:mt-[15px]" />

              <div className="mt-[12px] text-[16px] text-neutral-700">
                <p className="mb-[12px] text-[20px]">
                  {isFeedback ? t("feedback_description") : t("suggestions_description")}
                </p>
                {isFeedback ? (
                  <textarea
                    className="mt-4 rounded-[8px] w-full p-[12px] bg-white border border-neutral-400 text-[16px]"
                    rows="5"
                    onChange={(e) => {
                      setFeedback(e.target.value);
                    }}
                    placeholder={t("feedback_placeholder")}
                    value={feedback}
                  />
                ) : (
                  <input
                    className="mt-4 rounded-[8px] w-full p-[12px] bg-white border border-neutral-400 text-[16px]"
                    type="text"
                    onChange={(e) => {
                      setSuggestion(e.target.value);
                    }}
                    placeholder={t("event_name")}
                    value={suggestion}
                  />
                )}
                {!user.id && (
                  <>
                    <input
                      className="mt-4 rounded-[8px] w-full p-[12px] bg-white border border-neutral-400 text-[16px]"
                      placeholder={t("email")}
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                    <input
                      className="mt-4 rounded-[8px] w-full p-[12px] bg-white border border-neutral-400 text-[16px]"
                      placeholder={t("phone_number")}
                      type="text"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      value={phoneNumber}
                    />
                  </>
                )}
              </div>

              {error && (
                <p className="text-red-500 font-semibold text-[16px] mt-[12px]">
                  Vul alle velden in.
                </p>
              )}

              <button
                onClick={handleSubmit}
                className="!text-white mt-[13px] !disabled:cursor-not-allowed disabled:bg-[#56466B] text-[16px] font-semibold btn enabled:bg-gradient-to-r shadow-md enabled:to-[#7721E5] enabled:from-[#6A1BD1] text-white w-full px-[20px] py-[14px] rounded-[8px]"
              >
                {t("send")}
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
