import React from "react";
import FAQSection from "../components/FAQSection";
import TopBar from "../components/TopBar";
import MobileMenu from "../components/MobileMenu";
import { useLocation } from "react-router-dom";
import useWidth from "../hooks/useWidth";

export default function FAQ({ language, setLanguage }) {
  const location = useLocation();
  const { width, breakpoints } = useWidth();

  return (
    <div className="bg-[#0a0215] min-h-screen">
      <div className="hero-section min-h-screen !z-[0]">
        {/* Mobile TopBar */}
        <TopBar setLanguage={setLanguage} />

        <div className={`content-wrapper transition-all duration-150`}>
          <div className="lg:pt-[80px] max-lg:pt-[130px] lg:flex px-4 lg:px-8 relative z-10 pb-[40px]">
            <div className="w-full flex justify-center">
              <div className="w-[800px] max-w-[95vw] max-lg:mt-[60px] lg:mt-[40px]">
                <FAQSection />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {width < breakpoints.lg && <MobileMenu path={location.pathname} />}
    </div>
  );
}
