import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { supabase } from "../supabaseClient";

const CHAT_CATEGORIES = [
  { id: 'guestlist_chat', label: 'Guestlist chats' },
  { id: 'community_deals', label: 'Community deals' },
  { id: 'find_housemate', label: 'Find housemate' }
];

const EditChatModal = ({ show, setShow, create = false, currentChat = null, onSuccess }) => {
  const [formData, setFormData] = useState({
    name: "",
    link: "",
    active: true,
    category: "guestlist_chat", // Default category
    index: 0 // Add default index
  });

  useEffect(() => {
    if (!create && currentChat) {
      setFormData({
        name: currentChat.name,
        link: currentChat.link,
        active: currentChat.active,
        category: currentChat.category || "guestlist_chat",
        index: currentChat.index || 0
      });
    }
  }, [currentChat, create]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const operation = create
      ? supabase.from("CommunityChat").insert([formData])
      : supabase
          .from("CommunityChat")
          .update(formData)
          .eq("id", currentChat.id);

    const { error } = await operation;

    if (!error) {
      setShow(false);
      if (onSuccess) onSuccess();
    }
  };

  return (
    <Modal show={show} onClose={() => setShow(false)} size="xl">
      <Modal.Header>
        <h2 className="text-3xl">{create ? "Create" : "Edit"} Community Chat</h2>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4">
            <div>
              <label className="text-xl text-black mb-2 block">Category</label>
              <select
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                className="w-full text-2xl p-4 rounded-lg border-2 border-gray-300"
                required
              >
                {CHAT_CATEGORIES.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="text-xl text-black mb-2 block">Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full text-2xl p-4 rounded-lg border-2 border-gray-300"
                required
              />
            </div>
            <div>
              <label className="text-xl text-black mb-2 block">Link</label>
              <input
                type="url"
                value={formData.link}
                onChange={(e) => setFormData({ ...formData, link: e.target.value })}
                className="w-full text-2xl p-4 rounded-lg border-2 border-gray-300"
                required
              />
            </div>
            <div>
              <label className="text-xl text-black mb-2 block">Display order (lower numbers appear first)</label>
              <input
                type="number"
                value={formData.index || 0}
                onChange={(e) => setFormData({ ...formData, index: parseInt(e.target.value) || 0 })}
                className="w-full text-2xl p-4 rounded-lg border-2 border-gray-300"
              />
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.active}
                onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                className="w-6 h-6"
              />
              <label className="text-xl text-black">Active</label>
            </div>
            <button
              type="submit"
              className="bg-purple-600 text-white py-4 px-8 rounded-lg text-2xl hover:bg-purple-700"
            >
              {create ? "Create" : "Save"} Chat
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditChatModal;