import React, { useRef, useState, useEffect } from "react";
import Icon from "./Icon";
import EventCardWithBanner from "./EventCardWithBanner";

export default function TrendingEvents({ trendingEvents }) {
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollButtons = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      const maxScroll = container.scrollWidth - container.clientWidth;
      setCanScrollRight(container.scrollLeft < maxScroll - 1);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", checkScrollButtons);
      checkScrollButtons();
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", checkScrollButtons);
      }
    };
  }, []);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth * 0.8;
      const newScrollPosition =
        container.scrollLeft +
        (direction === "right" ? scrollAmount : -scrollAmount);
      container.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="max-lg:px-[16px] relative lg:mb-8">
      <div className="flex justify-between items-center pb-[14px]">
        <h1 className="text-start text-[22px] leading-[26px] lg:text-[24px] lg:leading-[36px] text-white">
          Trending events
        </h1>

        {/* Navigation Buttons */}
        {trendingEvents.length > 1 && (
          <div className="flex items-center gap-5 lg:mr-7">
            <button
              onClick={() => scroll("left")}
              disabled={!canScrollLeft}
              className={`p-2 rounded-full shadow-lg transition-all duration-200 bg-white
                ${
                  canScrollLeft
                    ? "hover:bg-gray-50 lg:cursor-pointer opacity-100"
                    : "cursor-not-allowed opacity-40"
                }`}
              aria-label="Scroll left"
            >
              <Icon
                icon="heroicons:chevron-left"
                className="h-[20px] w-[20px] text-gray-800"
              />
            </button>
            <button
              onClick={() => scroll("right")}
              disabled={!canScrollRight}
              className={`p-2 rounded-full shadow-lg transition-all duration-200 bg-white
                ${
                  canScrollRight
                    ? "hover:bg-gray-50 lg:cursor-pointer opacity-100"
                    : "cursor-not-allowed opacity-40"
                }`}
              aria-label="Scroll right"
            >
              <Icon
                icon="heroicons:chevron-right"
                className="h-[20px] w-[20px] text-gray-800"
              />
            </button>
          </div>
        )}
      </div>

      {/* Scroll Container */}
      <div
        ref={scrollContainerRef}
        className="w-full flex overflow-x-auto gap-6 scroll-smooth hide-scrollbar"
        style={{
          scrollSnapType: "x mandatory",
          touchAction: "auto",
          overflowY: "visible"
        }}
      >
        {trendingEvents
          .sort((a, b) => (a.trending_index || 0) - (b.trending_index || 0))
          .map((event, index) => (
            <div
              key={index}
              className="flex-none w-full md:w-[calc(33.33%-16px)] scroll-snap-align-start py-1 lg:pl-2"
              style={{
                scrollSnapAlign: "start",
                userSelect: "auto",
                pointerEvents: "auto",
                touchAction: "auto"
              }}
            >
              <EventCardWithBanner eventCardData={event} />
            </div>
          ))}
      </div>

      {/* Add this CSS to your global styles */}
      <style>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        /* Fix for mobile vertical scrolling */
        @media (max-width: 768px) {
          .event-content {
            touch-action: auto !important;
            pointer-events: auto !important;
            -webkit-overflow-scrolling: touch !important;
          }

          /* Ensure the body and html can scroll */
          html, body {
            touch-action: auto !important;
            overflow-y: auto !important;
            -webkit-overflow-scrolling: touch !important;
          }

          /* Make sure the container doesn't block vertical scrolling */
          .w-full.flex.overflow-x-auto {
            touch-action: pan-x pan-y !important;
            -webkit-overflow-scrolling: touch !important;
          }
        }
      `}</style>
    </section>
  );
}
