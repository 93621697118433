import React, { useState, useEffect } from "react";
import { Table } from "flowbite-react";
import { supabase } from "../supabaseClient";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { TrashIcon } from "@heroicons/react/24/outline";

export default function ManageInternships() {
  const [internships, setInternships] = useState([]);
  const [dates, setDates] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDatesModal, setShowDatesModal] = useState(false);
  const [newInternship, setNewInternship] = useState({
    name: "",
    description: "",
    link: "",
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [showApplicationsModal, setShowApplicationsModal] = useState(false);
  const [selectedInternshipApplications, setSelectedInternshipApplications] = useState([]);

  useEffect(() => {
    fetchInternships();
    fetchDates();
  }, []);

  const fetchInternships = async () => {
    const { data, error } = await supabase.from("Internship").select("*");
    if (error) console.error("Error fetching internships:", error);
    else setInternships(data);
  };

  const fetchDates = async () => {
    const { data, error } = await supabase.from("InternshipDates").select("*");
    if (error) console.error("Error fetching dates:", error);
    else setDates(data);
  };

  const addInternship = async () => {
    const { error } = await supabase.from("Internship").insert(newInternship);
    if (error) console.error("Error adding internship:", error);
    else {
      fetchInternships();
      setShowAddModal(false);
      setNewInternship({ name: "", description: "", link: "" });
    }
  };

  const addDate = async () => {
    const { error } = await supabase
      .from("InternshipDates")
      .insert({ date: selectedDate });
    if (error) console.error("Error adding date:", error);
    else {
      fetchDates();
      setShowDatesModal(false);
      setSelectedDate(null);
    }
  };

  const deleteInternship = async (id) => {
    const { error } = await supabase
      .from("Internship")
      .delete()
      .eq("id", id);

    if (error) console.error("Error deleting internship:", error);
    else fetchInternships();
  };

  const viewApplications = async (internshipId) => {
    const { data, error } = await supabase
      .from("InternshipApplicant")
      .select("*")
      .eq("internship_id", internshipId);

    if (error) {
      console.error("Error fetching applications:", error);
    } else {
      setSelectedInternshipApplications(data);
      setShowApplicationsModal(true);
    }
  };

  return (
    <div className="w-full text-white mt-[48px] overflow-x-auto p-[20px]">
      <div className="flex justify-between items-center mb-[32px]">
        <h1 className="text-white text-[48px] font-semibold">
          Manage Internships
        </h1>
        <div className="flex gap-[16px]">
          <button
            onClick={() => setShowAddModal(true)}
            className="bg-purple-600 px-[16px] py-[8px] rounded-lg text-[16px]"
          >
            Add Internship
          </button>
          <button
            onClick={() => setShowDatesModal(true)}
            className="bg-purple-600 px-[16px] py-[8px] rounded-lg text-[16px]"
          >
            Manage Dates
          </button>
        </div>
      </div>

      {/* Internships Table */}
      <Table>
        <Table.Head>
          <Table.HeadCell className="text-[16px]">Name</Table.HeadCell>
          <Table.HeadCell className="text-[16px]">Link</Table.HeadCell>
          <Table.HeadCell className="text-[16px]">Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body className="bg-white">
          {internships.map((internship) => (
            <Table.Row key={internship.id}>
              <Table.Cell className="text-[16px]">{internship.name}</Table.Cell>
              <Table.Cell className="text-[16px]">{internship.link}</Table.Cell>
              <Table.Cell>
                <div className="flex gap-2 text-[14px]">
                  <button
                    onClick={() => viewApplications(internship.id)}
                    className="text-purple-500 hover:text-purple-400 transition-colors"
                  >
                    View Applications
                  </button>
                  <button
                    onClick={() => deleteInternship(internship.id)}
                    className="text-red-500 hover:text-red-400 transition-colors"
                  >
                    <TrashIcon className="w-[20px] h-[20px]" />
                  </button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {/* Add Internship Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#130826] p-[32px] rounded-lg w-[500px]">
            <h2 className="text-[24px] mb-[16px]">Add New Internship</h2>
            <input
              type="text"
              placeholder="Name"
              className="w-full mb-[16px] p-[8px] bg-[#262123] rounded text-[16px]"
              value={newInternship.name}
              onChange={(e) =>
                setNewInternship({ ...newInternship, name: e.target.value })
              }
            />
            <textarea
              placeholder="Description"
              className="w-full mb-[16px] p-[8px] bg-[#262123] rounded text-[16px]"
              value={newInternship.description}
              onChange={(e) =>
                setNewInternship({
                  ...newInternship,
                  description: e.target.value,
                })
              }
            />
            <input
              type="text"
              placeholder="Link"
              className="w-full mb-[16px] p-[8px] bg-[#262123] rounded text-[16px]"
              value={newInternship.link}
              onChange={(e) =>
                setNewInternship({ ...newInternship, link: e.target.value })
              }
            />
            <div className="flex justify-end gap-[16px]">
              <button
                onClick={() => setShowAddModal(false)}
                className="bg-gray-600 px-[16px] py-[8px] rounded text-[16px]"
              >
                Cancel
              </button>
              <button
                onClick={addInternship}
                className="bg-purple-600 px-[16px] py-[8px] rounded text-[16px]"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Manage Dates Modal */}
      {showDatesModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#130826] p-[32px] rounded-lg w-[500px]">
            <h2 className="text-[24px] mb-[16px]">Manage Display Dates</h2>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              className="w-full mb-[16px] p-[8px] bg-[#262123] rounded text-[16px]"
            />
            <div className="mb-[16px]">
              <h3 className="text-[20px] mb-[8px]">Current Dates:</h3>
              <div className="flex flex-wrap gap-[8px]">
                {dates.map((date) => (
                  <div
                    key={date.id}
                    className="bg-purple-600 px-[12px] py-[4px] rounded-full text-[14px]"
                  >
                    {dayjs(date.date).format("D MMMM YYYY")}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end gap-[16px]">
              <button
                onClick={() => setShowDatesModal(false)}
                className="bg-gray-600 px-[16px] py-[8px] rounded text-[16px]"
              >
                Cancel
              </button>
              <button
                onClick={addDate}
                className="bg-purple-600 px-[16px] py-[8px] rounded text-[16px]"
              >
                Add Date
              </button>
            </div>
          </div>
        </div>
      )}

      {showApplicationsModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#130826] p-[32px] rounded-lg w-[800px]">
            <h2 className="text-[24px] mb-[16px]">Applications</h2>
            <div className="max-h-[600px] overflow-y-auto">
              <Table>
                <Table.Head>
                  <Table.HeadCell className="text-[16px]">Full Name</Table.HeadCell>
                  <Table.HeadCell className="text-[16px]">Email</Table.HeadCell>
                  <Table.HeadCell className="text-[16px]">Phone Number</Table.HeadCell>
                  <Table.HeadCell className="text-[16px]">Date Applied</Table.HeadCell>
                </Table.Head>
                <Table.Body className="bg-white">
                  {selectedInternshipApplications.map((application) => (
                    <Table.Row key={application.id}>
                      <Table.Cell className="text-[16px]">{application.full_name}</Table.Cell>
                      <Table.Cell className="text-[16px]">{application.email}</Table.Cell>
                      <Table.Cell className="text-[16px]">{application.phone_number}</Table.Cell>
                      <Table.Cell className="text-[16px]">
                        {new Date(application.created_at).toLocaleDateString()}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
            <div className="flex justify-end mt-[16px]">
              <button
                onClick={() => setShowApplicationsModal(false)}
                className="bg-gray-600 px-[16px] py-[8px] rounded text-[16px]"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
