import { Dialog, Transition, Disclosure } from "@headlessui/react";
import { XMarkIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabaseClient";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";

export default function InternshipModal({ show, onClose, internships }) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [selectedInternship, setSelectedInternship] = useState(null);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
  });
  const [error, setError] = useState(false);

  const handleApply = async (internship) => {
    setSelectedInternship(internship);
    if (user.id) {
      // If user is logged in, submit application with user data
      await supabase.from("InternshipApplicant").insert({
        internship_id: internship.id,
        full_name: user.first_name + " " + user.last_name,
        email: user.email,
        phone_number: user.phone_number,
      });

    }
  };

  const handleSubmitApplication = async () => {
    if (!formData.fullName || !formData.email || !formData.phoneNumber) {
      setError(true);
      return;
    }

    const { error } = await supabase.from("InternshipApplicant").insert({
      internship_id: selectedInternship.id,
      full_name: formData.fullName,
      email: formData.email,
      phone_number: formData.phoneNumber,
    });

    if (error) {
      console.error("Error submitting application:", error);
    } else {
      // setSelectedInternship(null);
      setFormData({ fullName: "", email: "", phoneNumber: "" });
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[10000000000000000] inset-0 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full h-screen bg-[#00000080] fixed z-0" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-top bg-neutral-200 rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[90vw] w-[500px] sm:p-6 max-h-[90vh] overflow-y-auto">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="rounded-md text-neutral-800 hover:text-neutral-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-12 w-12" aria-hidden="true" />
                </button>
              </div>

              {!selectedInternship ? (
                <>
                  <div className="mb-3 flex justify-center w-full">
                    <div className="flex-col flex items-center w-full">
                      <div className="text-center w-full">
                        <h1 className="text-[24px] text-black leading-[28px] font-bold w-full">
                          Stages bij 22NIGHT
                        </h1>
                      </div>
                    </div>
                  </div>

                  <hr className="border-neutral-600/50 mt-[20px] max-lg:mt-[15px]" />

                  <div className="mt-[12px] text-[16px] text-neutral-700">
                    <div className="space-y-4">
                      {internships.map((internship, index) => (
                        <Disclosure
                          key={internship.id}
                          as="div"
                          defaultOpen={index === 0}
                          onClick={() => {
                            setOpenAccordion(internship.id);
                          }}
                        >
                          {({ open }) => (
                            <div className="bg-white rounded-lg overflow-hidden">
                              <Disclosure.Button className="w-full p-4 text-left flex justify-between items-center">
                                <div className="text-[18px] font-bold text-black">
                                  {internship.name}
                                </div>
                                <ChevronUpIcon
                                  className={`${
                                    open ? 'rotate-180 transform' : ''
                                  } h-5 w-5 text-black`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pb-4">
                                <p className="text-[14px] text-neutral-600 mb-4 whitespace-pre-wrap">
                                  {internship.description}
                                </p>
                                <button
                                  onClick={() => handleApply(internship)}
                                  className="w-full hover:text-white !disabled:cursor-not-allowed disabled:bg-[#56466B] text-[14px] font-semibold btn enabled:bg-gradient-to-r shadow-md enabled:to-[#7721E5] enabled:from-[#6A1BD1] text-white px-[20px] py-[10px] rounded-[8px]"
                                >
                                  Meld je aan
                                </button>
                              </Disclosure.Panel>
                            </div>
                          )}
                        </Disclosure>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-3 flex justify-center w-full">
                    <div className="flex-col flex items-center w-full">
                      <div className="text-center w-full">
                        <h1 className="text-[24px] text-black leading-[28px] font-bold w-full">
                          {selectedInternship.name}
                        </h1>
                      </div>
                    </div>
                  </div>

                  <hr className="border-neutral-600/50 mt-[20px] max-lg:mt-[15px]" />

                  <div className="mt-[12px] text-[16px] text-neutral-700">
                    {user.id ? (
                      <p className="text-center text-[18px]">
                        Thank you for applying! We'll contact you soon.
                      </p>
                    ) : (
                      <>
                        <input
                          className="!pb-[12px] mt-4 rounded-[8px] w-full p-[12px] bg-white border border-neutral-400 text-[16px]"
                          placeholder="Full Name"
                          type="text"
                          value={formData.fullName}
                          onChange={(e) =>
                            setFormData({ ...formData, fullName: e.target.value })
                          }
                        />
                        <input
                          className="!pb-[12px] mt-4 rounded-[8px] w-full p-[12px] bg-white border border-neutral-400 text-[16px]"
                          placeholder="Email"
                          type="email"
                          value={formData.email}
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                        />
                        <input
                          className="!pb-[12px] mt-4 rounded-[8px] w-full p-[12px] bg-white border border-neutral-400 text-[16px]"
                          placeholder="Phone Number"
                          type="text"
                          value={formData.phoneNumber}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              phoneNumber: e.target.value,
                            })
                          }
                        />

                        {error && (
                          <p className="text-red-500 font-semibold text-[16px] mt-[12px]">
                            Please fill in all fields.
                          </p>
                        )}

                        <button
                          onClick={handleSubmitApplication}
                          className="!text-white mt-[13px] !disabled:cursor-not-allowed disabled:bg-[#56466B] text-[16px] font-semibold btn enabled:bg-gradient-to-r shadow-md enabled:to-[#7721E5] enabled:from-[#6A1BD1] text-white w-full px-[20px] py-[14px] rounded-[8px]"
                        >
                          Submit Application
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
