import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { supabase } from "../supabaseClient";
import * as XLSX from "xlsx";

export default function UploadCodesModal({ show, setShow, dealId, onSuccess }) {
  const [codeFile, setCodeFile] = useState(null);
  const [codes, setCodes] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columns, setColumns] = useState([]);
  const [previewCodes, setPreviewCodes] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setCodeFile(file);
    setError("");

    if (file.name.endsWith(".csv")) {
      // Handle CSV files
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const text = event.target.result;
          processCSVData(text);
        } catch (err) {
          setError("Error reading CSV file. Please make sure it's valid.");
        }
      };
      reader.readAsText(file);
    } else if (file.name.match(/\.(xlsx|xls)$/)) {
      // Handle Excel files
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          processExcelData(jsonData);
        } catch (err) {
          setError("Error reading Excel file. Please make sure it's valid.");
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      setError("Please upload a valid CSV or Excel file.");
    }
  };

  const processCSVData = (text) => {
    // Split by newlines and filter out empty lines
    const rows = text.split("\n").filter((line) => line.trim());
    // Process each row, removing empty cells and trimming values
    const data = rows.map((row) =>
      row
        .split(",")
        .map((cell) => cell.trim())
        .filter(cell => cell !== "" && cell !== ";") // Remove empty cells and lone semicolons
    );
    processData(data);
  };

  const processExcelData = (data) => {
    // Clean the Excel data by removing empty cells and trimming values
    const cleanedData = data.map(row =>
      row
        .map(cell => cell?.toString().trim())
        .filter(cell => cell && cell !== ";")
    );
    processData(cleanedData);
  };

  const processData = (data) => {
    // Filter out empty rows
    const filteredData = data.filter((row) =>
      row.some((cell) => cell?.toString().trim())
    );

    if (filteredData.length < 2) {
      setError("File must contain at least one header row and one data row");
      return;
    }

    setCodes(filteredData);
    setColumns(filteredData[0].map((col) => col?.toString().trim()));
    setSelectedColumn("");
    setPreviewCodes([]);
  };

  const updatePreviewCodes = (column) => {
    if (codes.length > 0) {
      const columnIndex = columns.indexOf(column);
      const preview = codes.slice(1, 4).map((row) => row[columnIndex].trim());
      setPreviewCodes(preview);
    }
  };

  const handleSave = async () => {
    if (!selectedColumn) {
      setError("Please select a column containing the coupon codes");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const columnIndex = columns.indexOf(selectedColumn);
      const rawCodes = codes
        .slice(1)
        .map((row) => row[columnIndex]?.toString().trim().replace(/;+$/, '')) // Remove trailing semicolons
        .filter((code) => code && code !== ";"); // Filter out empty codes and lone semicolons

      // Remove duplicates from the input
      const uniqueCodes = [...new Set(rawCodes)];
      const duplicatesRemoved = rawCodes.length - uniqueCodes.length;

      // Check for existing codes in the database
      const { data: existingCodes, error: fetchError } = await supabase
        .from("UniqueCouponCode")
        .select("code")
        .eq("deal_id", dealId)
        .in("code", uniqueCodes);

      if (fetchError) throw fetchError;

      // Filter out codes that already exist
      const existingCodeSet = new Set(existingCodes.map((c) => c.code));
      const newCodes = uniqueCodes.filter((code) => !existingCodeSet.has(code));
      const existingCodesRemoved = uniqueCodes.length - newCodes.length;

      const couponsToInsert = newCodes.map((code) => ({
        code: code,
        deal_id: dealId,
        email: null,
        user_id: null,
      }));

      if (couponsToInsert.length === 0) {
        setError(
          "No new valid codes to insert - all codes already exist for this deal"
        );
        setLoading(false);
        return;
      }

      const { error: uploadError } = await supabase
        .from("UniqueCouponCode")
        .insert(couponsToInsert);

      if (uploadError) throw uploadError;

      onSuccess?.();
      setShow(false);

      let message = "";
      if (duplicatesRemoved > 0) {
        message += `${duplicatesRemoved} duplicate code${
          duplicatesRemoved === 1 ? " was" : "s were"
        } removed. `;
      }
      if (existingCodesRemoved > 0) {
        message += `${existingCodesRemoved} code${
          existingCodesRemoved === 1 ? " was" : "s were"
        } skipped (already exists).`;
      }
      if (message) {
        alert(`Upload successful. ${message}`);
      }
    } catch (err) {
      setError("Error uploading codes. Please try again.");
      console.error("Error uploading codes:", err);
    }

    setLoading(false);
  };

  const handleDeleteAllCodes = async () => {
    setLoading(true);
    setError("");

    try {
      const { error: deleteError } = await supabase
        .from("UniqueCouponCode")
        .delete()
        .eq("deal_id", dealId);

      if (deleteError) throw deleteError;

      onSuccess?.();
      setShow(false);
      alert("All codes have been successfully deleted.");
    } catch (err) {
      setError("Error deleting codes. Please try again.");
      console.error("Error deleting codes:", err);
    }

    setLoading(false);
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => setShow(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-2xl leading-6 font-medium text-gray-900 mb-4">
                    Upload Coupon Codes
                  </h3>

                  <div className="mt-4">
                    <input
                      type="file"
                      accept=".csv, .xlsx, .xls"
                      onChange={handleFileUpload}
                      className="w-full text-lg text-black bg-white border-2 border-gray-300 p-2 rounded-lg"
                    />
                    <p className="text-sm text-gray-500 mt-2">
                      Note: Duplicate codes will be automatically ignored during
                      upload.
                    </p>
                  </div>

                  {columns.length > 0 && (
                    <div className="mt-4">
                      <select
                        className="w-full text-lg text-black bg-white border-2 border-gray-300 p-2 rounded-lg"
                        value={selectedColumn}
                        onChange={(e) => {
                          setSelectedColumn(e.target.value);
                          updatePreviewCodes(e.target.value);
                        }}
                      >
                        <option value="">Select code column</option>
                        {columns.map((column) => (
                          <option key={column} value={column}>
                            {column}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {previewCodes.length > 0 && (
                    <div className="mt-4 p-4 bg-gray-100 rounded-lg text-left">
                      <div className="flex justify-between items-center mb-2">
                        <p className="text-lg text-black">
                          {codes.length - 1} codes found
                        </p>
                        <button
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete ALL codes for this deal? This action cannot be undone."
                              )
                            ) {
                              if (
                                window.confirm(
                                  "Please confirm again that you want to delete ALL codes. This is irreversible."
                                )
                              ) {
                                handleDeleteAllCodes();
                              }
                            }
                          }}
                          className="px-3 py-1 text-sm text-red-600 hover:text-red-800 font-medium"
                        >
                          Delete All Codes
                        </button>
                      </div>
                      <p className="text-lg text-black">
                        Preview: {previewCodes.join(", ")}
                        {codes.length > 4 && ` ... (${codes.length - 4} more)`}
                      </p>
                    </div>
                  )}

                  {error && (
                    <p className="mt-4 text-red-500 text-lg">{error}</p>
                  )}
                </div>
              </div>

              <div className="mt-5 sm:mt-6 flex justify-end gap-3">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-lg"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#07af71] text-base font-medium text-white hover:bg-[#38c28f] focus:outline-none sm:text-lg"
                  onClick={handleSave}
                  disabled={loading}
                >
                  {loading ? "Uploading..." : "Upload Codes"}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
