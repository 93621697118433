import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import DealCard from "./DealCard";

export default function DealsOfTheWeek({ dealsOfTheWeek }) {
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const { t } = useTranslation();

  const checkScrollButtons = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      const maxScroll = container.scrollWidth - container.clientWidth;
      setCanScrollRight(container.scrollLeft < maxScroll - 1);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScrollButtons);
      checkScrollButtons();
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScrollButtons);
      }
    };
  }, []);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth * 0.8;
      const newScrollPosition = container.scrollLeft + (direction === 'right' ? scrollAmount : -scrollAmount);
      container.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section className="max-lg:px-[16px] relative">
      <h1 className="pb-[20px] text-start text-[22px] leading-[26px] lg:leading-[36px] w-full">
        {t('deals_of_the_week')}
      </h1>

      {/* Navigation Buttons */}
      {dealsOfTheWeek.length > 1 && (
        <>
          <button
            onClick={() => scroll('left')}
            disabled={!canScrollLeft}
            className={`absolute left-6 top-1/2 z-10 p-2 rounded-full transform -translate-y-1/2 shadow-lg transition-all duration-200 bg-white
              ${canScrollLeft
                ? 'hover:bg-gray-50 cursor-pointer opacity-100'
                : 'cursor-not-allowed opacity-40'
              }`}
            aria-label="Scroll left"
          >
            <Icon icon="heroicons:arrow-long-left" className="h-[25px] w-[25px] text-gray-800" />
          </button>
          <button
            onClick={() => scroll('right')}
            disabled={!canScrollRight}
            className={`absolute right-6 top-1/2 z-10 p-2 rounded-full transform -translate-y-1/2 shadow-lg transition-all duration-200 bg-white
              ${canScrollRight
                ? 'hover:bg-gray-50 cursor-pointer opacity-100'
                : 'cursor-not-allowed opacity-40'
              }`}
            aria-label="Scroll right"
          >
            <Icon icon="heroicons:arrow-long-right" className="h-[25px] w-[25px] text-gray-800" />
          </button>
        </>
      )}

      {/* Scroll Container */}
      <div
        ref={scrollContainerRef}
        className="flex overflow-x-auto gap-6 scroll-smooth hide-scrollbar"
        style={{ scrollSnapType: 'x mandatory', touchAction: 'pan-x' }}
      >
        {dealsOfTheWeek
          .filter(deal => deal.active)
          .sort((a, b) => a.index - b.index)
          .map((item, index) => (
            <div
              key={index}
              className="flex-none w-[90vw] max-w-[400px] scroll-snap-align-start py-1 lg:pl-2"
              style={{ scrollSnapAlign: 'start', userSelect: 'none' }}
            >
              <DealCard deal={item} />
            </div>
          ))}
      </div>

      {/* Add this CSS to your global styles */}
      <style>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>
    </section>
  );
}
