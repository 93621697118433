import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { v4 as uuidv4 } from "uuid";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Button,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";

export default function EditDealModal({ show, setShow, create, currentDeal }) {
  const [categories, setCategories] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    supabase
      .from("LifestyleCategory")
      .select("*")
      .then((res) => {
        setCategories(res.data);
      });

    // Fetch cities from the City table
    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data.map((city) => city.name));
      });

    // If editing existing deal, split cities string into array
    if (!create && currentDeal?.cities) {
      setSelectedCities(
        currentDeal.cities.split(",").map((city) => city.trim())
      );
    }
  }, [currentDeal, create]);

  const [formData, setFormData] = useState({
    name: "",
    category_id: 0,
    ticket_link: "",
    sold_out: false,
    dealOfTheWeek: false,
    image_filename: "",
    original_price: "0",
    our_price: "0",
    teaser: "",
    index: 0,
    advantage_1_title: "",
    advantage_1_description: "",
    advantage_2_title: "",
    advantage_2_description: "",
    advantage_3_title: "",
    advantage_3_description: "",
    currency: "€",
    redeem_category: "",
    coupon_code: "",
    active: true,
  });

  const [error, setError] = useState({
    name: false,
    category: false,
    ticket_link: false,
    sold_out: false,
    dealOfTheWeek: false,
    image_filename: false,
    original_price: false,
    our_price: false,
    teaser: false,
    index: false,
    redeem_category: false,
    coupon_code: false,
  });

  useEffect(() => {
    if (!create && currentDeal) {
      setFormData({ ...currentDeal });
    }
  }, [currentDeal, create]);

  useEffect(() => {
    const clearError = (field) => {
      if (error[field]) {
        setError((prev) => ({ ...prev, [field]: false }));
      }
    };

    Object.keys(formData).forEach((field) => {
      clearError(field);
    });
  }, [formData]);

  const saveDeal = async () => {
    if (
      Object.values(formData).some((item, index) => {
        if (
          item === "" &&
          ![
            "advantage_1_title",
            "advantage_2_title",
            "advantage_3_title",
            "advantage_1_description",
            "advantage_2_description",
            "advantage_3_description",
            "ticket_link",
            "coupon_code",
          ].includes(Object.keys(formData)[index])
        ) {
          setError({ ...error, [Object.keys(formData)[index]]: true });
          return true;
        }
      })
    ) {
      return;
    }

    if (
      formData.redeem_category === "unique_coupon_code" &&
      (!codes.length || !selectedColumn)
    ) {
      setError({ ...error, coupon_codes: true });
      return;
    }

    try {
      // First save the deal
      let dealId;
      if (create === true) {
        const { data, error: dealError } = await supabase
          .from("Deals")
          .insert({
            ...formData,
            original_price: formData.original_price.replace(",", "."),
            our_price: formData.our_price.replace(",", "."),
          })
          .select()
          .single();

        if (dealError) throw dealError;
        dealId = data.id;
      } else {
        dealId = currentDeal.id;
        const { error: dealError } = await supabase
          .from("Deals")
          .update({ ...formData })
          .eq("id", dealId);

        if (dealError) throw dealError;
      }

      // If it's a unique coupon code deal, save the codes
      if (formData.redeem_category === "unique_coupon_code") {
        const couponsToInsert = codes.map((row) => ({
          code: row[selectedColumn],
          deal_id: dealId,
          email: null,
          user_id: null,
        }));

        const { error: codesError } = await supabase
          .from("UniqueCouponCode")
          .insert(couponsToInsert);

        if (codesError) throw codesError;
      }

      setShow(false);
    } catch (error) {
      console.error("Error saving deal:", error);
      setError(true);
    }
  };

  const uploadImage = (e) => {
    const uuid = uuidv4();
    const file = e.target.files[0];

    const asset_name = `${uuid}-${file.name}`;

    const { data, error } = supabase.storage
      .from("images")
      .upload(asset_name, file, { upsert: true });

    setFormData({ ...formData, image_filename: asset_name });
  };

  const [codeFile, setCodeFile] = useState(null);
  const [codes, setCodes] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columns, setColumns] = useState([]);
  const [previewCodes, setPreviewCodes] = useState([]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setCodeFile(file);

    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      const rows = text.split("\n");
      const data = rows.map((row) => row.split(","));
      setCodes(data);
      setColumns(data[0]);
    };
    reader.readAsText(file);
  };

  const updatePreviewCodes = (column) => {
    if (codes.length > 0) {
      const columnIndex = columns.indexOf(column);
      const preview = codes.slice(1, 4).map((row) => row[columnIndex]);
      setPreviewCodes(preview);
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto "
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full h-screen bg-[#00000040] fixed z-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-12 w-12" aria-hidden="true" />
                </button>
              </div>
              <div className="mb-3 flex justify-center w-full">
                <div className="flex-col flex items-center w-full">
                  <div className="text-center w-full">
                    <h1 className="text-5xl font-bold text-black w-full">
                      {create ? "Create a new deal" : "Edit deal"}
                    </h1>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Deal name</label>
                <input
                  placeholder="Deal name"
                  value={formData.name}
                  type="text"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.name ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-neutral-500 mb-0">
                  Category
                </label>
                <select
                  className={`dark-input ${
                    error.category ? "border-red-500" : "border-gray-300"
                  }`}
                  value={
                    categories.find(
                      (category) => category.id === formData.category_id
                    )?.id
                  }
                  onChange={(e) => {
                    setFormData({ ...formData, category_id: e.target.value });
                  }}
                >
                  <option value="">Choose deal category</option>
                  {categories.map((category) => {
                    return <option value={category.id}>{category.name}</option>;
                  })}
                </select>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Deal of the week
                </label>
                <input
                  checked={formData.dealOfTheWeek}
                  type="checkbox"
                  className={`text-2xl w-8 h-8 text-black bg-white border-2 ${
                    error.dealOfTheWeek ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      dealOfTheWeek: e.target.checked,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Sold out</label>
                <input
                  checked={formData.sold_out}
                  type="checkbox"
                  className={`text-2xl w-8 h-8 text-black bg-white border-2 ${
                    error.sold_out ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, sold_out: e.target.checked });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Original price
                </label>
                <input
                  value={formData.original_price}
                  type="text"
                  placeholder="50"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.original_price ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      original_price: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Our price</label>
                <input
                  value={formData.our_price}
                  type="text"
                  placeholder="22,50"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.our_price ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, our_price: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-neutral-500 mb-0">
                  Currency
                </label>
                <select
                  className={`dark-input`}
                  value={formData.currency}
                  onChange={(e) => {
                    setFormData({ ...formData, currency: e.target.value });
                  }}
                >
                  <option value="€">€</option>
                  <option value="Rp">Rp</option>
                  <option value="£">£</option>
                </select>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Teaser</label>
                <textarea
                  value={formData.teaser}
                  placeholder="Teaser"
                  rows="1"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.teaser ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, teaser: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-neutral-500 mb-0">
                  Display order (lower numbers appear first)
                </label>
                <input
                  placeholder="0"
                  value={formData.index}
                  type="text"
                  className={`dark-input ${
                    error.index ? "border-red-500" : "border-gray-300"
                  }`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      index: parseInt(e.target.value) || 0,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 1 title
                </label>
                <textarea
                  value={formData.advantage_1_title}
                  placeholder="Advantage"
                  rows="1"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_1_title: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 1 description
                </label>
                <textarea
                  value={formData.advantage_1_description}
                  placeholder="Advantage"
                  rows="3"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_1_description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 2 title
                </label>
                <textarea
                  value={formData.advantage_2_title}
                  placeholder="Advantage"
                  rows="1"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_2_title: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 2 description
                </label>
                <textarea
                  value={formData.advantage_2_description}
                  placeholder="Advantage"
                  rows="3"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_2_description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 3 title
                </label>
                <textarea
                  value={formData.advantage_3_title}
                  placeholder="Advantage"
                  rows="1"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_3_title: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 3 description
                </label>
                <textarea
                  value={formData.advantage_3_description}
                  placeholder="Advantage"
                  rows="3"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_3_description: e.target.value,
                    });
                  }}
                />
              </div>

              {/* Event image */}
              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Deal image</label>
                <input
                  type="file"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.image_filename ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-neutral-500 mb-0">Cities</label>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    className="btn btn-dropdown dropdown-toggle text-left text-2xl text-black bg-white border-2 p-4 rounded-lg"
                    rightIcon={
                      <Icon icon="heroicons:chevron-down" className="w-6 h-6" />
                    }
                  >
                    <span className="selectedOption">
                      {selectedCities.length > 0
                        ? `${selectedCities.length} cities selected`
                        : "Select cities"}
                    </span>
                  </MenuButton>
                  <MenuList
                    className="menu-list menu-checkbox-list"
                    maxH="300px"
                    overflowY="auto"
                  >
                    <MenuOptionGroup
                      type="checkbox"
                      value={selectedCities}
                      onChange={(values) => {
                        setSelectedCities(values);
                        setFormData({
                          ...formData,
                          cities: values.join(", "),
                        });
                      }}
                    >
                      {cities.map((city) => (
                        <MenuItemOption key={city} value={city}>
                          {city}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-neutral-500 mb-0">
                  Redeem Category
                </label>
                <select
                  className={`dark-input`}
                  value={formData.redeem_category}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      redeem_category: e.target.value,
                    });
                  }}
                >
                  <option value="">Choose redeem category</option>
                  <option value="link">Link</option>
                  <option value="general_coupon_code">
                    General Coupon Code
                  </option>
                  <option value="unique_coupon_code">Unique Coupon Code</option>
                </select>
              </div>

              {/* Show ticket link input if link or general_coupon_code is selected */}
              {(formData.redeem_category === "link" ||
                formData.redeem_category === "general_coupon_code") && (
                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Ticket link{" "}
                    {formData.redeem_category === "general_coupon_code" &&
                      "(optional)"}
                  </label>
                  <input
                    placeholder="Ticket link"
                    value={formData.ticket_link || ""}
                    type="text"
                    className={`dark-input ${
                      error.ticket_link ? "border-red-500" : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setFormData({ ...formData, ticket_link: e.target.value });
                    }}
                  />
                </div>
              )}

              {/* Show coupon code input if general_coupon_code is selected */}
              {formData.redeem_category === "general_coupon_code" && (
                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-black mb-0">Coupon Code</label>
                  <input
                    placeholder="Enter coupon code"
                    value={formData.coupon_code}
                    type="text"
                    className={`text-2xl text-black bg-white border-2 ${
                      error.coupon_code ? "border-red-500" : "border-gray-300"
                    } p-4 rounded-lg`}
                    onChange={(e) => {
                      setFormData({ ...formData, coupon_code: e.target.value });
                    }}
                  />
                </div>
              )}

              {/* Placeholder for unique coupon code file upload */}
              {formData.redeem_category === "unique_coupon_code" && (
                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-black mb-0">
                    Upload Coupon Codes
                  </label>
                  <input
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    className={`text-2xl text-black bg-white border-2 ${
                      error.coupon_codes ? "border-red-500" : "border-gray-300"
                    } p-4 rounded-lg`}
                    onChange={handleFileUpload}
                  />

                  {columns.length > 0 && (
                    <div className="mt-4">
                      <label className="text-xl text-black mb-2">
                        Select Code Column
                      </label>
                      <select
                        className="w-full text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                        value={selectedColumn}
                        onChange={(e) => {
                          setSelectedColumn(e.target.value);
                          updatePreviewCodes(e.target.value);
                        }}
                      >
                        <option value="">Select column</option>
                        {columns.map((column) => (
                          <option key={column} value={column}>
                            {column}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {previewCodes.length > 0 && (
                    <div className="mt-4 p-4 bg-gray-100 rounded-lg">
                      <p className="text-xl text-black mb-2">
                        {codes.length} codes found
                      </p>
                      <p className="text-xl text-black">
                        Preview: {previewCodes.join(", ")}
                        {codes.length > 3 && ` ... (${codes.length - 3} more)`}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Active</label>
                <input
                  checked={formData.active}
                  type="checkbox"
                  className="text-2xl w-8 h-8 text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      active: e.target.checked,
                    });
                  }}
                />
              </div>

              {Object.values(error).some((item) => item) && (
                <p className="text-3xl font-semibold mt-5 text-red-500">
                  Not all fields are valid. Invalid fields:{" "}
                  {Object.keys(error)
                    .filter((key) => error[key])
                    .map(
                      (key) =>
                        key.replace(/_/g, " ").charAt(0).toUpperCase() +
                        key.replace(/_/g, " ").slice(1)
                    )
                    .join(", ")}
                </p>
              )}

              <div className="flex items-center justify-end w-full pt-9">
                <button
                  onClick={() => {
                    saveDeal();
                  }}
                  className="text-white transition-all px-6 lg:px-12 py-3 rounded-lg text-3xl font-semibold hover:bg-[#38c28f] bg-[#07af71]"
                >
                  Save deal
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
