import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/nl";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
dayjs.extend(utc);
dayjs.extend(timezone);

const formatPrice = (price) => {
  return parseFloat(price || 0).toFixed(2);
};

const EventCard = ({ eventCardData, singleEvent, disableLink = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let eventName = eventCardData.name;
  eventName = eventName.charAt(0) + eventName.slice(1);

  const eventTime = dayjs(eventCardData.datetime).locale("nl").format("HH:mm");
  const eventDate = dayjs(eventCardData.datetime).locale("nl").format("D MMM");

  const cityName = eventCardData.city;
  const clubName =
    eventCardData.venue.name.charAt(0).toUpperCase() +
    eventCardData.venue.name.slice(1).toLowerCase();

  const handleItemClick = () => {
    // if (eventCardData.OutofStock['sold-out'] !== true && eventCardData.OutofStock['sold-out'] !== "true") {
    if (!disableLink) {
      navigate(`/single-event?eventId=${eventCardData.id}`, {
        state: eventCardData.id,
      });
    }
    // return null;
  };

  return (
    <div
      className={`max-lg:!p-[16px] event-content text-white ${
        !singleEvent ? "doubles" : null
      } ${
        eventCardData.trending ? "trending-event" : "border border-[#AD7AEF]"
      } lg:min-h-[172px] lg:hover:scale-[102%] transition-all duration-300 relative bg-gradient-to-r from-[#AD7AEF]/[0.06] via-[#C2A5FF]/[0.4] to-[#554B6A]/[0.1855]`}
      onClick={handleItemClick}
    >
      {eventCardData.trending && (
        <div className="text-[#7721E5] absolute top-[16px] lg:top-[28px] right-[20px] flex items-center gap-2 bg-[#F7F2FD] px-2 py-1 rounded-[6px] p-1 z-10">
          <Icon icon="gravity-ui:star-fill" className="h-[16px] w-[16px]" />
          <div className="text-[14px] font-bold bg-transparent">Trending</div>
        </div>
      )}
      <a>
        <h5
          className={`truncate elipsis lg:text-[18px] lg:leading-[24px] text-[16px] leading-[22px] text-white font-[700] ${
            eventCardData.trending ? "max-w-[70%]" : "max-w-[100%]"
          }`}
        >
          {eventName}
          {/* {eventTime} */}
        </h5>
        <h6 className="!text-white lg:text-[16px] text-[14px] font-normal leading-[16px]">
          {disableLink && `${eventDate} • `}
          {eventTime} • {clubName} • {cityName}
        </h6>
        <div className="lg:absolute bottom-[24px] left-[19px]">
          {eventCardData.sold_out === true ||
          eventCardData.sold_out === "true" ? (
            <div className="!rounded-[4px] Sould Out">
              <span className="!text-[14px] !px-[6px] !py-[4px]">Sold out</span>
            </div>
          ) : (
            <div className="flex items-center justify-start gap-2 mt-[14px]">
              <div className="!rounded-[4px] text-white bg-green-600">
                <span className="!text-[14px] !px-[6px] !py-[4px] !bg-transparent min-w-[55px] flex items-center justify-center">
                  {eventCardData.currency}
                  {formatPrice(eventCardData.our_price)}
                </span>
              </div>
              <div className="!rounded-[4px] text-white bg-gray-600">
                <span className="line-through !text-[14px] !px-[6px] !py-[4px] !bg-transparent min-w-[55px] flex items-center justify-center">
                  {eventCardData.currency}
                  {formatPrice(eventCardData.original_price)}
                </span>
              </div>
              {/* <span>{eventTime}</span>
            <span>{age}+</span>
            <div className="tool">
              <span className="gen">{t(category)}</span>{" "}
              <span className="tooltips">
                {" "}
                <InfoSvg />
                {category === "Community ticket" ? (
                  <span className="tooltiptext tooltipText wa Vriendenticket">
                    {t("community_ticket_guarantee")}
                  </span>
                ) : (
                  <span className="tooltiptext tooltipText wa Vriendenticket">
                  {t("guestlist_entry")}
                  </span>
                )}
              </span>
            </div> */}
            </div>
          )}
        </div>
      </a>
    </div>
  );
};

export default EventCard;
