import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  MultiSelect,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import { supabase } from "../supabaseClient";
import { useTranslation } from "react-i18next";
import Icon from "../components/Icon";
import { availableGenres } from "../helpers/helpers";

export default function TokenDefaultFilters({
  tokenId,
  existingFilters = null,
}) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [cities, setCities] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState({
    landing_page: "events",
    header_page: "events",
    datum: "Alle Datums",
    categorie: "Alle events",
    genre: [],
    leeftijd: "Alle leeftijden",
    cities: [],
    language: "en",
  });

  // Load cities from database
  useEffect(() => {
    const fetchCities = async () => {
      const { data, error } = await supabase.from("City").select("*");

      if (error) {
        console.error("Error fetching cities:", error);
        return;
      }

      setCities(data.map((city) => ({ value: city.name, label: city.name })));
    };

    fetchCities();
  }, []);

  // Load existing filters if available
  useEffect(() => {
    if (existingFilters) {
      setDefaultFilters((prev) => ({
        ...prev,
        ...existingFilters,
        cities: existingFilters.cities || [],
      }));
    }
  }, [existingFilters]);

  const saveDefaultFilters = async () => {
    setSaving(true);

    try {
      const { error } = await supabase
        .from("Token")
        .update({
          default_filters: defaultFilters,
        })
        .eq("id", tokenId);

      if (error) {
        console.error("Error saving default filters:", error);
        alert("Failed to save default filters");
      } else {
        alert("Default filters saved successfully");
      }
    } catch (err) {
      console.error("Error saving default filters:", err);
      alert("Failed to save default filters");
    } finally {
      setSaving(false);
    }
  };

  const filterStyles = {
    fontSize: "16px",
    padding: "8px 12px",
    borderRadius: "8px",
    backgroundColor: "#130826",
    color: "white",
  };

  const labelStyles = {
    fontSize: "16px",
    marginBottom: "8px",
    color: "white",
  };

  return (
    <div className="mb-6 bg-[#130826] border border-[#AD7AEF] rounded-lg p-6 mt-6">
      <h2 className="text-2xl font-semibold text-white mb-6 text-[24px]">
        Default token presets
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-[16px]">
        <FormControl>
          <FormLabel className="text-white text-[16px]">Landing page</FormLabel>
          <select
            className="p-3 text-[14px] w-full bg-[#1D1726] text-white border border-[#AD7AEF] rounded-lg"
            value={defaultFilters.landing_page}
            onChange={(e) =>
              setDefaultFilters((prev) => ({
                ...prev,
                landing_page: e.target.value,
              }))
            }
          >
            <option value="events">Events</option>
            <option value="deals">Deals</option>
          </select>
        </FormControl>
        <FormControl>
          <FormLabel className="text-white text-[16px]">Cities</FormLabel>
          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              className="p-3 w-full text-left bg-[#1D1726] text-white border border-[#AD7AEF] rounded-lg"
              rightIcon={<Icon icon="heroicons:chevron-down" />}
            >
              {defaultFilters.cities.length > 0
                ? `${defaultFilters.cities.length} ${t("cities_selected")}`
                : t("select_cities")}
            </MenuButton>
            <MenuList className="text-start p-3 text-white bg-[#1D1726] border border-[#AD7AEF]">
              <MenuOptionGroup
                type="checkbox"
                className="!text-white"
                value={defaultFilters.cities}
                onChange={(values) =>
                  setDefaultFilters((prev) => ({ ...prev, cities: values }))
                }
              >
                {cities.map((city) => (
                  <MenuItemOption
                    key={city.value}
                    value={city.value}
                    className="text-white hover:bg-[#AD7AEF]"
                  >
                    {city.label}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </FormControl>
        <FormControl>
          <FormLabel style={labelStyles}>Date range</FormLabel>
          <select
            style={filterStyles}
            className="w-full"
            value={defaultFilters.datum}
            onChange={(e) =>
              setDefaultFilters((prev) => ({ ...prev, datum: e.target.value }))
            }
          >
            <option value="Alle Datums">{t("all_dates")}</option>
            <option value="Vandaag">{t("today")}</option>
            <option value="Morgen">{t("tomorrow")}</option>
            <option value="Deze week">{t("this_week")}</option>
            <option value="Volgende week">{t("next_week")}</option>
            <option value="Deze maand">{t("this_month")}</option>
          </select>
        </FormControl>
        <FormControl>
          <FormLabel style={labelStyles}>Event type</FormLabel>
          <select
            style={filterStyles}
            className="w-full"
            value={defaultFilters.categorie}
            onChange={(e) =>
              setDefaultFilters((prev) => ({
                ...prev,
                categorie: e.target.value,
              }))
            }
          >
            <option value="Alle events">{t("all_events")}</option>
            <option value="Dag event">{t("day_event")}</option>
            <option value="Nacht event">{t("night_event")}</option>
            <option value="Festival">{t("festival")}</option>
          </select>
        </FormControl>
        <FormControl>
          <FormLabel style={labelStyles}>Age requirements</FormLabel>
          <select
            style={filterStyles}
            className="w-full"
            value={defaultFilters.leeftijd}
            onChange={(e) =>
              setDefaultFilters((prev) => ({
                ...prev,
                leeftijd: e.target.value,
              }))
            }
          >
            <option value="Alle leeftijden">{t("all_ages")}</option>
            <option value="18+">18+</option>
            <option value="21+">21+</option>
            <option value="23+">23+</option>
          </select>
        </FormControl>
        <FormControl>
          <FormLabel className="text-white text-[16px]">Language</FormLabel>
          <select
            className="p-3 text-[14px] w-full bg-[#1D1726] text-white border border-[#AD7AEF] rounded-lg"
            value={defaultFilters.language}
            onChange={(e) =>
              setDefaultFilters((prev) => ({
                ...prev,
                language: e.target.value,
              }))
            }
          >
            <option value="en">🇺🇸 English</option>
            <option value="nl">🇳🇱 Nederlands</option>
          </select>
        </FormControl>
        <FormControl gridColumn="span 2">
          <FormLabel style={labelStyles}>Genres</FormLabel>
          <div className="flex flex-wrap gap-2">
            {availableGenres.map((genre) => (
              <div
                key={genre}
                className={`cursor-pointer rounded-full px-4 py-2 text-white text-[16px] ${
                  defaultFilters.genre.includes(genre)
                    ? "bg-[#AD7AEF]"
                    : "bg-[#130826]"
                }`}
                onClick={() => {
                  setDefaultFilters((prev) => {
                    const newGenres = prev.genre.includes(genre)
                      ? prev.genre.filter((g) => g !== genre)
                      : [...prev.genre, genre];
                    return { ...prev, genre: newGenres };
                  });
                }}
              >
                {genre}
              </div>
            ))}
          </div>
        </FormControl>
      </div>

      <button
        className="text-[16px] font-semibold premium-button mt-6 text-white px-12 py-3 rounded-lg"
        onClick={saveDefaultFilters}
        disabled={saving}
      >
        {saving ? "Saving..." : "Save filters"}
      </button>
    </div>
  );
}
