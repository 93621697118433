import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { supabase } from "../supabaseClient";
import { Link } from "react-router-dom";
import SpinIcon from "../assets/spin-and-win.png";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import ProfileSidebar from "../components/ProfileSidebar";
import { CustomSelect } from "./home/Home";
import Logo from "../components/Logo";
import AllDealsIcon from "../assets/all-deals-icon.svg";
import EmailIcon from "../assets/email.png";
import { useTranslation } from "react-i18next";

const WonPrizeCard = ({ prize }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-[6px] bg-[#1D1726] border border-[#AD7AEF50] rounded-[8px] p-[16px_20px] mb-[12px] text-left">
      <div className="flex items-start mb-2 gap-[12px]">
        <img src={AllDealsIcon} className="w-[24px] h-[24px]" alt="Ticket" />
        <div className="flex flex-col">
          <span className="text-white font-bold">Jouw prijs</span>
          <p className="text-gray-300 mt-1">
            {prize.prize?.title || "Frenzy x Vulged"}
          </p>
        </div>
      </div>

      <hr className="my-[12px] border-[#292433]" />

      <div className="flex items-start mb-2 gap-[12px]">
        <img src={EmailIcon} className="w-[24px] h-[24px]" alt="Email" />
        <div className="flex flex-col">
          <span className="text-white font-bold">Spin the wheel</span>
          <p className="text-gray-300 mt-1">
            Let op, de code voor jouw prijs is maar 1 keer geldig. Verzilver je
            code binnen 24 uur.
          </p>
          <div className="w-full max-lg:!pb-2 mt-4 bg-[#0B0415] border border-[#AD7AEF50] rounded-[4px] pl-4 pr-2 py-2 flex items-center justify-between">
            <p className="mr-4 text-[16px] font-bold text-white">
              {prize.code.code}
            </p>
            <button
              className="hover:text-white !disabled:cursor-not-allowed disabled:bg-[#56466B] text-[16px] font-semibold btn enabled:bg-gradient-to-r shadow-md enabled:to-[#7721E5] enabled:from-[#6A1BD1] text-white px-[10px] py-[8px] rounded-[8px]"
              onClick={() => {
                navigator.clipboard.writeText(prize.code.code);
              }}
            >
              {t("copy_code")}
            </button>
          </div>
          <Link
            to={prize.prize.redirect_url}
            target="_blank"
            className="text-center mt-6 text-white text-[16px] font-semibold premium-button py-4 rounded-[8px]"
          >
            {t("redeem_your_code")}
          </Link>
        </div>
      </div>
    </div>
  );
};

const ProfilePrizes = ({ language, setLanguage }) => {
  const user = useSelector(selectUser);
  const [wonPrizes, setWonPrizes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWonPrizes = async () => {
      if (!user.id) return;

      setLoading(true);

      const { data, error } = await supabase
        .from("SpinWinParticipants")
        .select(
          `
          *,
          prize:SpinWinPrizes(*),
          code:SpinWinCodes(*),
          event:Events(*)
        `
        )
        .eq("user_id", user.id)
        .not("prize_id", "is", null)
        .order("spun_at", { ascending: false });

      if (!error) {
        setWonPrizes(data || []);
      }

      setLoading(false);
    };

    fetchWonPrizes();
  }, [user.id]);

  return (
    <div className="bg-[#12041f]">
      <div
        className="hero-section relative min-h-[100vh] "
        style={{ background: "#0A0315" }}
      >
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/profile">
              <Icon
                icon="heroicons:arrow-left"
                className="text-white h-12 w-12"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="lg:w-[80%] max-lg:w-full">
            <div className="flex flex-col items-start text-start max-lg:hidden pt-[60px]">
              <h1 className="text-start text-[50px]">
                Hallo {user.first_name}
              </h1>
              <hr className="border-[#302640] mt-[20px] w-full " />
            </div>
            <div className="flex max-lg:flex-col w-full gap-12">
              <div className="max-lg:hidden w-[20%]">
                <ProfileSidebar user={user} language={language} setLanguage={setLanguage} />
              </div>
              <div className="lg:w-[80%]">
                {/* Events here */}
                <div className="mb-[100px] mt-12 flex flex-col items-center">
                  <div className="w-[90%]">
                    <h2 className="lg:text-[32px] max-lg:text-[26px] text-white">
                      My Discount Codes
                    </h2>
                    {wonPrizes.length > 0 ? (
                      <div className="w-full flex max-lg:flex-col lg:flex-row gap-12">
                        <div className="lg:w-[600px] max-lg:w-full mt-[16px] zoek-evenementen !p-0 m-0">
                          {wonPrizes.map((prize) => (
                            <div className="mt-3 w-full" key={prize.id}>
                              <WonPrizeCard prize={prize} />
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center flex-col justify-center mt-[40px] text-[18px] text-center text-white">
                        Je hebt nog geen prijzen gewonnen.
                        <Link
                          to="/"
                          className="premium-button px-12 py-4 mt-2 rounded-[8px]"
                        >
                          Events bekijken
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePrizes;
