import DatumIcon from "../assets/image svg/Calendar-N.svg";
import CategoryIcon from "../assets/image svg/party.svg";
import GenreIcon from "../assets/image svg/Music.svg";
import UserIcon from "../assets/image svg/User.svg";

import ArrowDownIcon from "../assets/image svg/chevron down.svg";
import LocationIcon from "../assets/image svg/Locatie.svg";
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabaseClient";

const DiscoverEvent = ({ filters, setFilters, city, genres, changeCity }) => {
  const { t } = useTranslation();

  // Initialize selectedCities with filters.cities if it exists
  const [selectedCities, setSelectedCities] = useState(filters.cities || []);

  // Update selectedCities when filters.cities changes
  useEffect(() => {
    setSelectedCities(filters.cities || []);
  }, [filters.cities]);

  const filterMenus = {
    datum: [
      t("today"),
      t("tomorrow"),
      t("this_week"),
      t("next_week"),
      t("this_month"),
      t("all_dates"),
    ],
    categorie: [
      t("day_event"),
      t("night_event"),
      t("festival"),
      t("all_events"),
    ],
    leeftijd: ["18+", "21+", "23+", t("all_ages")],
  };

  // const genreList = filterMenus.genre;
  const genreList = genres;
  const [selectedGenres, setSelectedGenres] = useState([]);

  const [cities, setCities] = useState([]);

  // Check if genres contains data, and if so, set it as the initial state
  useEffect(() => {
    if (genres && genres.length > 0) {
      setSelectedGenres(filters.genre || []);
    }
  }, [genres, filters.genre]);

  const genreChange = (selectedItems) => {
    setSelectedGenres(selectedItems);

    // Always update with exactly what was selected
    // Don't add any special logic for empty arrays
    updateFilters(selectedItems, "genre");
  };

  const updateFilters = (item, category) => {
    setFilters((prev) => {
      return { ...prev, [category]: item };
    });
  };

  useEffect(() => {
    supabase
      .from("City")
      .select("*")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          setCities(data.map((city) => city.name));
        }
      });
  }, []);

  useEffect(() => {
    // Force hardware acceleration for Safari on iOS
    const menuLists = document.querySelectorAll(".menu-list");
    menuLists.forEach((menu) => {
      menu.style.transform = "translateZ(0)";
      menu.style.webkitTransform = "translateZ(0)";
      menu.style.backfaceVisibility = "hidden";
      menu.style.webkitBackfaceVisibility = "hidden";
    });
  }, []);

  return (
    <div className="relative px-[15px] flex items-center justify-start gap-3 max-lg:overflow-x-auto max-lg:w-full max-lg:pb-2 max-lg:scrollbar-hide">
      {/* City Filter */}
      <div className="side filter-date mr-5">
        <Menu placement="bottom" strategy="fixed">
          <MenuButton
            as={Button}
            className="btn btn-dropdown dropdown-toggle !rounded-[10px] bg-[#1D1726] !border-transparent"
            leftIcon={<img src={LocationIcon} alt="locatie" />}
            rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
          >
            <span className="selectedOption" id="button1Text">
              {filters.cities?.length === 1
                ? filters.cities[0]
                : filters.cities?.length > 1
                ? `${filters.cities.length} ${t("cities_selected")}`
                : t("all_cities")}
            </span>
          </MenuButton>
          <Portal>
            <MenuList
              className="menu-list"
              zIndex={99999999999999}
              position="fixed"
              width="auto"
              minWidth="150px"
              maxWidth="250px"
              style={{
                transform: "translateZ(0)",
              }}
            >
              <MenuOptionGroup
                type="checkbox"
                value={selectedCities}
                onChange={(values) => {
                  setSelectedCities(values);
                  updateFilters(values, "cities");
                  if (values.length > 0) {
                    changeCity(values[0]); // Set first selected city as current
                  }
                }}
              >
                {cities.map((city) => (
                  <MenuItemOption
                    key={city}
                    value={city}
                    className="text-white hover:bg-[#AD7AEF]"
                  >
                    {city}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </div>

      {/* Date filter */}
      <div className="side filter-date mr-5">
        <Menu placement="bottom" strategy="fixed">
          <MenuButton
            as={Button}
            className="btn btn-dropdown dropdown-toggle !rounded-[10px] bg-[#1D1726] !border-transparent"
            leftIcon={<img src={DatumIcon} alt="datum" />}
            rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
          >
            <span className="selectedOption" id="button1Text">
              {filters.datum}
            </span>
          </MenuButton>
          <Portal>
            <MenuList
              className="menu-list"
              zIndex={9999999}
              position="fixed"
              width="auto"
              minWidth="150px"
              maxWidth="250px"
              style={{
                transform: "translateZ(0)",
              }}
            >
              <MenuOptionGroup
                className=""
                defaultValue={t("all_dates")}
                type="radio"
              >
                {filterMenus.datum.map((item, index) => (
                  <MenuItemOption
                    key={index}
                    onClick={() => {
                      updateFilters(item, "datum");
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </div>

      {/* Category filter */}
      <div className="side filter-category mr-5">
        <Menu placement="bottom" strategy="fixed">
          <MenuButton
            as={Button}
            className="btn btn-dropdown dropdown-toggle !rounded-[10px] bg-[#1D1726] !border-transparent"
            leftIcon={<img src={CategoryIcon} alt="datum" />}
            rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
          >
            <span className="selectedOption" id="button1Text">
              {filters.categorie}
            </span>
          </MenuButton>
          <Portal>
            <MenuList
              className="menu-list"
              zIndex={9999999}
              position="fixed"
              width="auto"
              minWidth="150px"
              maxWidth="250px"
              style={{
                transform: "translateZ(0)",
              }}
            >
              <MenuOptionGroup
                className=""
                defaultValue={t("all_events")}
                type="radio"
              >
                {filterMenus.categorie.map((item, index) => (
                  <MenuItemOption
                    key={index}
                    onClick={() => {
                      updateFilters(item, "categorie");
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </div>

      {/* Genre filter */}
      <div className="side filter-genre mr-5">
        <Menu placement="bottom" strategy="fixed" closeOnSelect={false}>
          <MenuButton
            as={Button}
            className="btn btn-dropdown dropdown-toggle !rounded-[10px] bg-[#1D1726] !border-transparent"
            leftIcon={<img src={GenreIcon} alt="datum" />}
            rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
          >
            <span className="selectedOption" id="button1Text">
              {selectedGenres.length > 0
                ? `${selectedGenres.length} ${t("genres_selected")}`
                : "Genre"}
            </span>
          </MenuButton>
          <Portal>
            <MenuList
              className="menu-list menu-checkbox-list"
              zIndex={9999999}
              position="fixed"
              width="auto"
              minWidth="150px"
              maxWidth="250px"
              style={{
                transform: "translateZ(0)",
              }}
            >
              <MenuOptionGroup
                className=""
                type="checkbox"
                aria-multiselectable
                value={selectedGenres}
                onChange={(selectedItems) => genreChange(selectedItems)}
              >
                {genreList.map((item, index) => (
                  <MenuItemOption key={index} value={item}>
                    {item === "Hitjes" ? t("hits") : item}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </div>

      {/* Age filter */}
      <div className="side filter-age">
        <Menu placement="bottom" strategy="fixed">
          <MenuButton
            as={Button}
            className="btn btn-dropdown dropdown-toggle !rounded-[10px] bg-[#1D1726] !border-transparent"
            leftIcon={<img src={UserIcon} alt="datum" />}
            rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
          >
            <span className="selectedOption" id="button1Text">
              {filters.leeftijd}
            </span>
          </MenuButton>
          <Portal>
            <MenuList
              className="menu-list"
              zIndex={9999999}
              position="fixed"
              width="auto"
              minWidth="150px"
              maxWidth="250px"
              style={{
                transform: "translateZ(0)",
              }}
            >
              <MenuOptionGroup
                className=""
                defaultValue={t("all_ages")}
                type="radio"
              >
                {filterMenus.leeftijd.map((item, index) => (
                  <MenuItemOption
                    key={index}
                    onClick={() => {
                      updateFilters(item, "leeftijd");
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </div>
    </div>
  );
};

export default DiscoverEvent;
