import EventCard from "./EventCard";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/nl";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SuggestionsModal from "./SuggestionsModal";
import InternshipModal from "./InternshipModal";

dayjs.extend(utc);
dayjs.extend(timezone);

const EventItem = ({ eventsGroupData, currentCity, language, showInternshipCard, internships }) => {
  const { t } = useTranslation();
  const today = dayjs.utc().locale(language).format("D MMMM");
  const dayName =
    eventsGroupData.date === today ? t("today") : eventsGroupData.day;
  const date = eventsGroupData.date;
  const events = eventsGroupData.data;

  const [showSuggestionsModal, setShowSuggestionsModal] = useState(false);
  const [showInternshipModal, setShowInternshipModal] = useState(false);



  return (
    <div className="tab">
      <SuggestionsModal
        show={showSuggestionsModal}
        setShow={() => {
          setShowSuggestionsModal(false);
        }}
      />
      <InternshipModal
        show={showInternshipModal}
        onClose={() => setShowInternshipModal(false)}
        internships={internships}
      />
      <div className="tab-header">
        <div className="row justify-content-between text-white">
          <div className="days">
            <h3 className="donderdag">{dayName}</h3>
          </div>
          <div className="date">
            <h5 className="date pr-8">{date}</h5>
          </div>
        </div>
      </div>
      <div className="tab-body">
        <div className="event-box">
          <div className="row">
            {events.map((event) => (
              <EventCard
                key={event.id}
                eventCardData={event}
                singleEvent={false}
              />
            ))}
            {events.length === 1 && (
              <button
                onClick={() => {
                  setShowSuggestionsModal(true);
                }}
                className="lg:min-h-[172px] max-lg:h-[110px] text-center event-content text-white doubles flex flex-col items-center justify-center"
              >
                <h2 className="text-[18px] text-white font-[600] leading-[24px] tracking-normal">
                  {t("event_not_on_the_list")}
                </h2>
                <p className="mt-1 text-[14px] lg:text-[16px] font-normal leading-[22px] tracking-normal">
                  {t("let_us_know")}
                </p>
              </button>
            )}
            {showInternshipCard && (
              <button
                onClick={() => {
                  setShowInternshipModal(true);
                }}
                className="lg:min-h-[172px] max-lg:h-[110px] text-center event-content text-white doubles flex flex-col items-center justify-center"
              >
                <h2 className="text-[18px] text-white font-[600] leading-[24px] tracking-normal">
                  {t("internship_opportunities")}
                </h2>
                <p className="mt-1 text-[14px] lg:text-[16px] font-normal leading-[22px] tracking-normal">
                  {t("click_to_view_internships")}
                </p>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventItem;
