import React, { useState, useEffect } from "react";
import clsx from "clsx";
import AllDealsIcon from "../../assets/all-deals-icon.svg";
import DealsOfTheWeek from "../../components/DealsOfTheWeek";
import DealOverview from "../../components/DealOverview";
import DealSearchBar from "../../components/DealSearchBar";
import { useTranslation } from "react-i18next";
import { supabase } from "../../supabaseClient";
import Icon from "../../components/Icon";
import LocationIcon from "../../assets/image svg/Locatie.svg";
import ArrowDownIcon from "../../assets/image svg/chevron down.svg";
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import TopBar from "../../components/TopBar";
import DealFilters from "../../components/DealFilters";
import { useSelector } from "react-redux";
import { selectUser } from "../../slices/userReducer";

export default function Lifestyle({ language, setLanguage, deals }) {
  const [queryParams, setQueryParams] = useState(
    new URLSearchParams(window.location.search)
  );
  const categoryFromUrl = queryParams.get("category");
  const cityFromUrl = queryParams.get("city");

  const [currentCategory, setCurrentCategory] = useState(
    categoryFromUrl || "all_deals"
  );
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState(cityFromUrl ? [cityFromUrl] : []);
  const [filteredDeals, setFilteredDeals] = useState(deals);
  const [tokenDefaultFiltersApplied, setTokenDefaultFiltersApplied] = useState(false);
  const user = useSelector(selectUser);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setQueryParams(params);
    const cityParam = params.get("city");

    if (cityParam) {
      setSelectedCities([cityParam]);
    }
  }, [window.location.search]);

  useEffect(() => {
    const category = queryParams.get("category");
    if (category === "all_deals" || !category) {
      setCurrentCategory("all_deals");
    } else {
      const matchedCategory = categories.find((c) => c.name === category);
      if (matchedCategory) {
        setCurrentCategory(matchedCategory.id);
      }
    }
  }, [queryParams, categories]);

  useEffect(() => {
    supabase
      .from("LifestyleCategory")
      .select("*")
      .order("index", { ascending: true })
      .then((res) => {
        setCategories(res.data);
      });
  }, []);

  useEffect(() => {
    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data.map((city) => city.name));
      });
  }, []);

  useEffect(() => {
    if (!selectedCities.length) {
      setFilteredDeals(deals);
    } else {
      setFilteredDeals(
        deals.filter((deal) => {
          const dealCities = deal.cities?.split(",").map((c) => c.trim()) || [];
          return selectedCities.some(city => dealCities.includes(city));
        })
      );
    }
  }, [selectedCities, deals]);

  useEffect(() => {
    if (
      !tokenDefaultFiltersApplied &&
      user?.token &&
      !localStorage.getItem("userSetFilters")
    ) {
      const fetchTokenDefaultFilters = async () => {
        try {
          const { data, error } = await supabase
            .from("Token")
            .select("default_filters")
            .eq("name", user.token)
            .single();

          if (error) {
            console.error("Error fetching token default filters:", error);
            return;
          }

          if (data?.default_filters?.cities) {
            const defaultCities = Array.isArray(data.default_filters.cities)
              ? data.default_filters.cities
              : data.default_filters.cities.split(",").map(c => c.trim());

            const newParams = new URLSearchParams(window.location.search);
            if (defaultCities.length > 0) {
              newParams.set("city", defaultCities[0]);
            }
            window.history.pushState(
              {},
              "",
              `${window.location.pathname}?${newParams}`
            );

            setSelectedCities(defaultCities);
          }

          setTokenDefaultFiltersApplied(true);
        } catch (err) {
          console.error("Error fetching token default filters:", err);
        }
      };

      fetchTokenDefaultFilters();
    }
  }, [user, tokenDefaultFiltersApplied]);

  useEffect(() => {
    if (!tokenDefaultFiltersApplied) return;
    localStorage.setItem("userSetFilters", "true");
  }, [selectedCities]);

  const handleCategoryChange = (category) => {
    const newParams = new URLSearchParams(window.location.search);
    if (category === "all_deals") {
      newParams.delete("category");
    } else {
      const categoryObj = categories.find((c) => c.id === category);
      if (categoryObj) {
        newParams.set("category", categoryObj.name);
      }
    }
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${newParams}`
    );
    setCurrentCategory(category);
  };

  const handleCityChange = (cities) => {
    const newParams = new URLSearchParams(window.location.search);
    if (!cities.length) {
      newParams.delete("city");
    } else {
      newParams.set("city", cities[0]); // Keep URL simple with first city only
    }
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${newParams}`
    );
    setSelectedCities(cities);
  };

  const { t } = useTranslation();

  return (
    <div className="bg-[#2a0744] min-h-screen pb-[80px]">
      <div className="bg-[#0B0415] flex items-center justify-center relative flex-col">
        <div className="max-lg:w-[100vw] lg:w-[95%] max-w-[1400px]">
          <TopBar setLanguage={setLanguage} />
          <div className="max-lg:pb-[20px] max-lg:pt-[90px] lg:py-[60px]">
            <div className="max-lg:px-[16px] flex items-center justify-between">
              <div className="flex items-center justify-start gap-3 max-lg:overflow-x-auto max-lg:w-full max-lg:pb-2 max-lg:scrollbar-hide">
                {/* Filters */}
                <DealFilters
                  selectedCities={selectedCities}
                  cities={cities}
                  categories={categories}
                  onCityChange={handleCityChange}
                  onCategoryChange={handleCategoryChange}
                  selectedCategory={currentCategory}
                />
              </div>
              <div className="max-lg:hidden flex items-center justify-end gap-3">
                {/* Search Bar */}
                {/* <DealSearchBar
                  deals={filteredDeals}
                  formattedCurrentDate={""}
                /> */}
              </div>
            </div>
            <h1 className="max-lg:px-[16px] text-start w-full pt-[20px] lg:pt-[40px] text-white lg:text-[72px] text-[36px] leading-[40px] lg:leading-[80px]">
              {t("best_deals_for_our")} <br className="max-lg:hidden" />
              {t("community_in")}{" "}
              <span className="text-[#C9A6F5]">
                {selectedCities.length === 1
                  ? selectedCities[0]
                  : selectedCities.length > 1
                      ? `${selectedCities.length} cities`
                      : t("all_cities")}
              </span>
            </h1>
          </div>
          <hr className="absolute left-0 right-0 w-full border-r border-[#AD7AEF80]" />
        </div>

        <div
          className="min-h-[66.6vh] flex justify-center w-full"
          style={{
            background:
              "linear-gradient(to bottom, #0B0415 0%, #1d0630 40vh, #2a0744 60vh)",
          }}
        >
          <div className="max-w-[1400px] lg:w-[95%] max-lg:w-[100vw]">
            <h2 className="text-[32px] max-lg:hidden text-white max-lg:!px-[16px] pt-[50px]">
              {t("discover_community_deals")}
            </h2>
            <section className="max-lg:!mt-[24px] lg:!mt-[32px] pb-[80px]">
              {/* Alle deals */}
              {currentCategory === "all_deals" && filteredDeals.length > 0 && (
                <>
                  <DealsOfTheWeek
                    dealsOfTheWeek={filteredDeals.filter(
                      (deal) => deal.dealOfTheWeek === true && deal.active
                    )}
                  />
                  {categories.map((category) => {
                    const categoryDeals = filteredDeals
                      .filter(
                        (deal) =>
                          deal.category_id === category.id && deal.active
                      )
                      .sort((a, b) => a.index - b.index);

                    if (categoryDeals.length > 0) {
                      return (
                        <DealOverview
                          key={category.id}
                          deals={categoryDeals}
                          title={`${category.name} deals`}
                          isCarousel={true}
                        />
                      );
                    }
                    return null;
                  })}
                </>
              )}

              {currentCategory !== "all_deals" &&
                filteredDeals.filter(
                  (deal) => deal.category_id === currentCategory
                ) && (
                  <DealOverview
                    deals={filteredDeals
                      .filter((deal) => deal.category_id === currentCategory)
                      .sort((a, b) => a.index - b.index)}
                    title={`${
                      categories.find((cat) => cat.id === currentCategory)?.name
                    } deals`}
                    isCarousel={false}
                  />
                )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
