import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import Icon from "../components/Icon";
import { supabase } from "../supabaseClient";
import EditChatModal from "../components/EditChatModal";

export default function ManageCommunityChats() {
  const [chats, setChats] = useState([]);
  const [showCreateChatModal, setShowCreateChatModal] = useState(false);
  const [activeTab, setActiveTab] = useState("published");
  const [currentChat, setCurrentChat] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    fetchChats();
  }, [activeTab]);

  const fetchChats = async () => {
    const { data } = await supabase
      .from("CommunityChat")
      .select("*")
      .eq("active", activeTab === "published");

    setChats(data || []);
  };

  const deleteChat = async (chatId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this chat?"
    );

    if (!isConfirmed) return;

    const { error } = await supabase
      .from("CommunityChat")
      .delete()
      .eq("id", chatId);

    if (!error) {
      setChats((prev) => prev.filter((chat) => chat.id !== chatId));
    }
  };

  const toggleChatStatus = async (chatId, currentStatus) => {
    const { error } = await supabase
      .from("CommunityChat")
      .update({ active: !currentStatus })
      .eq("id", chatId);

    if (!error) {
      setChats((prev) => prev.filter((chat) => chat.id !== chatId));
    }
  };

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <EditChatModal
        show={showCreateChatModal}
        setShow={setShowCreateChatModal}
        create={true}
        onSuccess={fetchChats}
      />

      <EditChatModal
        show={showEditModal}
        setShow={setShowEditModal}
        create={false}
        currentChat={currentChat}
        onSuccess={fetchChats}
      />

      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Manage Community Chats
        </h1>
        <button
          onClick={() => setShowCreateChatModal(true)}
          className="bg-white py-5 px-12 rounded-lg shadow-md font-semibold text-black !text-[20px] flex items-center justify-center gap-2"
        >
          <Icon icon="heroicons:plus" className="h-9 w-9" />
        </button>
      </div>

      <div className="flex justify-start items-center gap-3 mt-12 text-[18px]">
        <button
          onClick={() => setActiveTab("published")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "published" ? "border-white" : "border-transparent"
          }`}
        >
          Published
        </button>
        <button
          onClick={() => setActiveTab("archive")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "archive" ? "border-white" : "border-transparent"
          }`}
        >
          Archive
        </button>
      </div>

      <Table className="mt-8 bg-white">
        <Table.Head>
          <Table.HeadCell className="text-3xl w-[30%]">Name</Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[30%]">Category</Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[20%]">Display Order</Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[20%]">Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {chats.map((chat) => (
            <Table.Row key={chat.id}>
              <Table.Cell className="text-2xl">{chat.name}</Table.Cell>
              <Table.Cell className="text-2xl">{chat.category?.charAt(0).toUpperCase() + chat.category?.slice(1).replace(/_/g, ' ')}</Table.Cell>
              <Table.Cell className="text-2xl">{chat.index || 0}</Table.Cell>
              <Table.Cell className="flex items-center gap-2">
                <button
                  onClick={() => toggleChatStatus(chat.id, chat.active)}
                  className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                >
                  <Icon
                    icon={chat.active ? "heroicons:archive-box" : "heroicons:arrow-uturn-up"}
                    className="h-10 w-10"
                  />
                </button>
                <button
                  onClick={() => {
                    setCurrentChat(chat);
                    setShowEditModal(true);
                  }}
                  className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                >
                  <Icon icon="heroicons:pencil-square" className="h-10 w-10" />
                </button>
                <button
                  onClick={() => deleteChat(chat.id)}
                  className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                >
                  <Icon icon="heroicons:trash" className="h-10 w-10" />
                </button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}