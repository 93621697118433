import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import { Table } from "flowbite-react";
import Icon from "./Icon";

const SpinWinAnalytics = ({ event }) => {
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalSpins: 0,
    totalWins: 0,
    prizesDistributed: {},
  });

  useEffect(() => {
    fetchParticipants();
  }, [event]);

  const fetchParticipants = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from("SpinWinParticipants")
        .select(`
          *,
          prize:SpinWinPrizes(title, is_main_prize),
          code:SpinWinCodes(code, claimed_at)
        `)
        .eq("event_id", event.id)
        .order("id", { ascending: false });

      if (error) throw error;

      // After getting participants, fetch corresponding User data
      const participantsWithUserData = await Promise.all(
        data.map(async (participant) => {
          // Get User table data
          const { data: userData } = await supabase
            .from("User")
            .select("first_name, last_name, email")
            .eq("auth_user_id", participant.user_id)
            .single();


          return {
            ...participant,
            user: {
              ...userData
            }
          };
        })
      );

      setParticipants(participantsWithUserData || []);

      // Calculate statistics
      const totalSpins = participantsWithUserData.length;
      const totalWins = participantsWithUserData.filter((p) => p.prize_id).length;
      const prizesDistributed = participantsWithUserData.reduce((acc, p) => {
        if (p.prize?.title) {
          acc[p.prize.title] = (acc[p.prize.title] || 0) + 1;
        }
        return acc;
      }, {});

      setStats({
        totalSpins,
        totalWins,
        prizesDistributed,
      });

    } catch (error) {
      console.error("Error fetching participants:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="mt-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="bg-white rounded-lg p-6 shadow">
          <h3 className="text-[18px] font-semibold mb-2">Total Spins</h3>
          <p className="text-3xl font-bold text-purple-600">{stats.totalSpins}</p>
        </div>
        <div className="bg-white rounded-lg p-6 shadow">
          <h3 className="text-[18px] font-semibold mb-2">Total Wins</h3>
          <p className="text-3xl font-bold text-green-600">{stats.totalWins}</p>
          <p className="text-sm text-gray-500">
            ({((stats.totalWins / stats.totalSpins) * 100).toFixed(1)}% win rate)
          </p>
        </div>
        <div className="bg-white rounded-lg p-6 shadow">
          <h3 className="text-[18px] font-semibold mb-2">Prizes Distributed</h3>
          <div className="space-y-2">
            {Object.entries(stats.prizesDistributed).map(([prize, count]) => (
              <div key={prize} className="flex justify-between text-[12px]">
                <span>{prize}</span>
                <span className="font-semibold">{count}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Participants Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <Table>
            <Table.Head>
              <Table.HeadCell className="text-[14px] normal-case">User</Table.HeadCell>
              <Table.HeadCell className="text-[14px] normal-case">Email</Table.HeadCell>
              <Table.HeadCell className="text-[14px] normal-case">Prize</Table.HeadCell>
              <Table.HeadCell className="text-[14px] normal-case">Code</Table.HeadCell>
              <Table.HeadCell className="text-[14px] normal-case">Spin Date</Table.HeadCell>
              <Table.HeadCell className="text-[14px] normal-case">Claimed Date</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {participants.map((participant) => (
                <Table.Row key={participant.id}>
                  <Table.Cell className="text-[14px]">
                    <div>
                      <p className="font-medium">{participant.user?.first_name} {participant.user?.last_name || 'N/A'}</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-[14px]">
                    {participant.user?.email || 'N/A'}
                  </Table.Cell>
                  <Table.Cell className="text-[14px]">
                    {participant.prize ? (
                      <div className="flex items-center">
                        {participant.prize.is_main_prize && (
                          <Icon
                            icon="heroicons:star"
                            className="w-5 h-5 text-yellow-500 mr-1"
                          />
                        )}
                        {participant.prize.title}
                      </div>
                    ) : (
                      <span className="text-gray-500">No prize</span>
                    )}
                  </Table.Cell>
                  <Table.Cell className="text-[14px]">
                    {participant.code?.code || 'N/A'}
                  </Table.Cell>
                  <Table.Cell className="text-[14px]">
                    {dayjs(participant.created_at).format('DD/MM HH:mm')}
                  </Table.Cell>
                  <Table.Cell className="text-[14px]">
                    {participant.code?.claimed_at
                      ? dayjs(participant.code.claimed_at).format('DD/MM HH:mm')
                      : 'Not claimed'}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default SpinWinAnalytics;