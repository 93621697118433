import { useState } from "react";
import { Button } from "@chakra-ui/react";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import ErrorSvg from "../../assets/image svg/error.svg";
import { useTranslation } from "react-i18next";
import Icon from "../../components/Icon";
import AppleAuth from "../../components/AppleAuth";
import GoogleAuth from "../../components/GoogleAuth";
import { supabase } from "../../supabaseClient";
import FAQSection from "../../components/FAQSection";
import { CustomSelect } from "../home/Home";
import { useDispatch } from "react-redux";
import { setUser } from "../../slices/userReducer";

const EmailLogin = ({ setLanguage }) => {
  const [error, setError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [emailExists, setEmailExists] = useState(false);

  const dispatch = useDispatch();

  const [emailError, setEmailError] = useState(false);

  const nextStep = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!email || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setEmailError(true);
      return;
    }

    const getUser = async () => {
      const possibleUser = await supabase
        .from("User")
        .select("email")
        .eq("email", email)
        .limit(1)
        .single();

      return possibleUser;
    };

    getUser().then((user) => {
      if (!user.data) {
        navigate(`/create-account?email=${email}`);
      } else {
        setEmailExists(true);
      }
    });
  };

  const signIn = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) {
      setError(true);
    } else {
      dispatch(setUser({ loading: true }));
      navigate("/");
    }
  };

  return (
    <>
      <header className="login">
        <div className="z-[1000] absolute top-[30px] right-[20px] lg:top-[50px] lg:right-[50px]">
          <CustomSelect
            options={[
              { value: "nl", label: "🇳🇱 Nederlands" },
              { value: "en", label: "🇺🇸 English" },
            ]}
            onSelect={(value) => {
              setLanguage(value);
            }}
          />
        </div>
        <div className="row">
          <div className="logo-log flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="371"
              height="154"
              viewBox="0 0 371 154"
              fill="none"
              className="lg:h-[40px] h-[25px] w-auto"
            >
              <path
                d="M122.554 154H1.6639V127.001L38.7884 89.5132C46.4837 81.4826 52.4458 75.0443 56.6747 70.1982C60.973 65.3522 63.9887 61.2331 65.7219 57.8409C67.455 54.4486 68.3216 50.918 68.3216 47.2488C68.3216 42.7489 66.8658 39.4952 63.954 37.4875C61.0423 35.4799 57.6106 34.4761 53.659 34.4761C48.8755 34.4761 44.0226 35.8606 39.1004 38.6298C34.2475 41.3297 28.736 45.345 22.5659 50.6757L6.0861e-05 24.2994C4.57563 20.2149 9.42851 16.3381 14.5587 12.6689C19.6889 8.93055 25.755 5.88447 32.757 3.53068C39.759 1.17689 48.2862 0 58.3386 0C68.6683 0 77.6114 1.79995 85.168 5.39987C92.794 8.99977 98.6868 13.9497 102.846 20.2495C107.006 26.4801 109.086 33.5761 109.086 41.5374C109.086 50.3295 107.457 58.187 104.198 65.1099C101.009 71.9636 96.191 78.8519 89.7436 85.7748C83.2962 92.6285 75.2543 100.417 65.6179 109.14L54.4909 119.109V120.147L122.554 119.109V154Z"
                fill="white"
              />
              <path
                d="M226.246 154H113.075V127.001L150.199 89.5132C157.895 81.4826 163.857 75.0443 168.086 70.1982C172.384 65.3522 175.4 61.2331 177.133 57.8409C178.866 54.4486 179.733 50.918 179.733 47.2488C179.733 42.7489 178.277 39.4952 175.365 37.4875C172.453 35.4799 169.022 34.4761 165.07 34.4761C160.286 34.4761 155.433 35.8606 150.511 38.6298C145.658 41.3297 140.147 45.345 133.977 50.6757L111.411 24.2994C115.987 20.2149 120.839 16.3381 125.97 12.6689C131.1 8.93055 137.166 5.88447 144.168 3.53068C151.17 1.17689 159.697 0 169.749 0C180.079 0 189.022 1.79995 196.579 5.39987C204.205 8.99977 210.098 13.9497 214.257 20.2495C218.417 26.4801 220.497 33.5761 220.497 41.5374C220.497 50.3295 218.867 58.187 215.609 65.1099C212.42 71.9636 207.602 78.8519 201.154 85.7748C194.707 92.6285 186.665 100.417 177.029 109.14L165.902 119.109V120.147H236.237L226.246 154Z"
                fill="white"
              />
              <path
                d="M371 154H317.133L261.602 47.0411H260.666C261.013 50.2949 261.325 54.2063 261.602 58.7755C261.88 63.3446 262.122 68.0175 262.33 72.7943C262.538 77.5019 262.642 81.7595 262.642 85.5671V154H226.246V2.18071H279.905L335.227 107.686H335.851C335.643 104.363 335.435 100.521 335.227 96.1591C335.019 91.7285 334.811 87.2632 334.603 82.7633C334.465 78.2634 334.395 74.2828 334.395 70.8213V2.18071H371V154Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="login_form max-lg:!items-start max-lg:mt-12">
          <form
            className="form w-full"
            name="f1"
            onSubmit={emailExists ? signIn : nextStep}
            method="post"
          >
            <div className="lg">
              <div className="">
                <p className="flex protected">
                  <span className="ml-1.5 text-[24px]">
                    {t("enter_your_email")}
                  </span>
                </p>
                <div>
                  <input
                    type="email"
                    name="name"
                    className={clsx(
                      "!py-[15px] outline-none !text-white !border-none !bg-[#130826]",
                      error || emailError ? (email ? "" : "error-border") : ""
                    )}
                    id="validationCustom01"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError(false);
                      setEmailError(false);
                    }}
                  />
                </div>
                {emailError && (
                  <div id="namelocation" className="flex items-center">
                    <img src={ErrorSvg} alt="error" className="danger" />
                    <span className="ml-1">{t("invalid_email")}</span>
                  </div>
                )}
                {emailExists && (
                  <div className="mt-2">
                    <input
                      type="password"
                      name="password"
                      className={clsx(
                        "!py-[15px] outline-none !text-white !border-none !bg-[#130826]",
                        error ? (password ? "" : "error-border") : ""
                      )}
                      id="validationCustom01"
                      placeholder={t("password")}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setError(false);
                      }}
                    />
                  </div>
                )}
                {error && (
                  <div id="namelocation" className="flex items-center">
                    <img src={ErrorSvg} alt="error" className="danger" />
                    <span className="ml-1">{t("invalid_password")}</span>
                  </div>
                )}
                <div>
                  <Button
                    type="submit"
                    className="text-[16px] font-semibold mt-5 btn bg-gradient-to-r shadow-md to-[#7721E5] from-[#6A1BD1] text-white w-full px-[20px] py-[14px] rounded-[8px]"
                  >
                    {t("continue")}
                  </Button>
                  <Link
                    to="/password-forgot"
                    className="text-[#B9B6BF] text-[14px]"
                  >
                    {t("password_forgot")}
                  </Link>
                </div>
              </div>
              <div className="mt-[30px] w-full flex justify-between items-center">
                <div className="w-[42%] bg-[#B9B6BF] h-1 rounded-full"></div>
                <p className="text-[#B9B6BF] font-semibold">{t("or")}</p>
                <div className="w-[42%] bg-[#B9B6BF] h-1 rounded-full"></div>
              </div>
              <div className="mt-[30px] flex flex-col gap-3">
                {/* <AppleAuth /> */}
                <GoogleAuth />
                {/* <button
                  onClick={() => {
                    navigate("/phone-login");
                  }}
                  className="rounded-[8px] text-white text-[16px] font-semibold gap-3 flex items-center w-full bg-transparent justify-center p-[14px] border-2 border-[#B9B6BF]"
                >
                  <Icon
                    icon="heroicons:device-phone-mobile"
                    className="h-8 w-8"
                  />
                  Doorgaan met telefoonnummer
                </button> */}
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="rounded-[8px] text-white text-[16px] font-semibold gap-3 flex items-center w-full bg-transparent justify-center p-[14px] border-2 border-[#B9B6BF]"
                >
                  <Icon icon="heroicons:key" className="h-8 w-8" />
                  {t("continue_with")} token
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="w-full flex justify-center mt-12">
          <div className="w-[800px] max-w-[95vw]">
            <FAQSection />
            <div className="w-full flex justify-center mt-12">
              <Link
                target="_blank"
                className="text-white text-[16px] font-semibold relative"
                to="/privacy"
              >
                Privacy policy
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default EmailLogin;
