import { useState, useEffect, useRef } from "react";
import { supabase } from "../supabaseClient";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import TicketIcon from "../assets/ticket-icon.png";
import AllDealsIcon from "../assets/all-deals-icon.svg";
import EmailIcon from "../assets/email.png";
import { useNavigate } from "react-router-dom";
import { adminUserIds } from "../helpers/helpers";

// Custom WheelCanvas component to replace react-custom-roulette
const WheelCanvas = ({
  data,
  width,
  height,
  mustStartSpinning,
  prizeNumber,
  onStopSpinning,
  outerBorderColor,
  outerBorderWidth,
  innerBorderColor,
  innerRadius = 20,
  radiusLineColor,
  radiusLineWidth,
  fontSize,
  textDistance,
  disableInitialAnimation = false,
  isIndefiniteSpin = false,
}) => {
  // Calculate initial rotation angle based on prizeNumber
  const segmentSize = (2 * Math.PI) / data.length;
  const initialTargetAngle =
    Math.PI * 1.5 - segmentSize * prizeNumber - segmentSize / 2;

  const canvasRef = useRef(null);
  const [rotationAngle, setRotationAngle] = useState(initialTargetAngle);
  const [isSpinning, setIsSpinning] = useState(false);
  const requestRef = useRef();

  // Draw the wheel on the canvas
  const drawWheel = () => {
    const canvas = canvasRef.current;
    if (!canvas || !data.length) return;

    const ctx = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const outsideRadius = canvas.width / 2 - 10;
    const clampedInsideRadius = innerRadius;
    const insideRadius = (outsideRadius * clampedInsideRadius) / 100;
    const clampedTextDistance = textDistance;
    const contentRadius = (outsideRadius * clampedTextDistance) / 100;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate(rotationAngle);
    ctx.translate(-centerX, -centerY);

    // Draw each segment
    const arcSize = (2 * Math.PI) / data.length;
    let startAngle = 0;

    for (let i = 0; i < data.length; i++) {
      const endAngle = startAngle + arcSize;

      // Draw segment
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, outsideRadius, startAngle, endAngle, false);
      ctx.closePath();

      // Fill with background color or gradient
      if (data[i].style.backgroundGradient) {
        const gradient = ctx.createRadialGradient(
          centerX,
          centerY,
          insideRadius,
          centerX,
          centerY,
          outsideRadius
        );

        // Add color stops from gradient definition
        data[i].style.backgroundGradient.forEach((stop) => {
          gradient.addColorStop(stop.position, stop.color);
        });

        ctx.fillStyle = gradient;
      } else {
        ctx.fillStyle = data[i].style.backgroundColor;
      }
      ctx.fill();

      // Draw radius lines
      ctx.save();
      ctx.strokeStyle = radiusLineColor;
      ctx.lineWidth = radiusLineWidth;
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.lineTo(
        centerX + outsideRadius * Math.cos(startAngle),
        centerY + outsideRadius * Math.sin(startAngle)
      );
      ctx.stroke();
      ctx.restore();

      // Draw content (for jsxOption we'll render elsewhere)
      if (!data[i].jsxOption) {
        ctx.save();
        ctx.translate(
          centerX + Math.cos(startAngle + arcSize / 2) * contentRadius,
          centerY + Math.sin(startAngle + arcSize / 2) * contentRadius
        );
        ctx.rotate(startAngle + arcSize / 2 + Math.PI / 2);
        ctx.font = `${fontSize * 2}px Arial`;
        ctx.fillStyle = data[i].style.textColor || "white";
        ctx.textAlign = "center";
        ctx.fillText(data[i].option || "", 0, 0);
        ctx.restore();
      }

      startAngle = endAngle;
    }

    // Draw outer border
    if (outerBorderWidth > 0) {
      ctx.save();
      ctx.strokeStyle = outerBorderColor;
      ctx.lineWidth = outerBorderWidth;
      ctx.beginPath();
      ctx.arc(
        centerX,
        centerY,
        outsideRadius - ctx.lineWidth / 2,
        0,
        2 * Math.PI
      );
      ctx.stroke();
      ctx.restore();
    }

    // Draw inner border
    if (innerBorderColor) {
      ctx.save();
      ctx.strokeStyle = innerBorderColor;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.arc(centerX, centerY, insideRadius, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.restore();
    }

    ctx.restore();
  };

  // Updated spin animation
  const spin = () => {
    if (!isSpinning) return;

    if (isIndefiniteSpin) {
      // For indefinite spinning, continuously rotate without stopping
      const animate = (timestamp) => {
        // Keep spinning at a constant speed
        setRotationAngle((prevAngle) => prevAngle + 0.22);

        // Continue animation if still in indefinite spin mode
        if (isIndefiniteSpin) {
          requestRef.current = requestAnimationFrame(animate);
        }
      };

      cancelAnimationFrame(requestRef.current);
      requestRef.current = requestAnimationFrame(animate);
      return;
    }

    // Normal prize-targeted spinning
    const segmentSize = (2 * Math.PI) / data.length;
    const targetAngle =
      Math.PI * 1.5 - segmentSize * prizeNumber - segmentSize / 2;

    // Add multiple rotations for effect
    const fullRotations = 6 * 2 * Math.PI;
    const finalAngle = fullRotations + targetAngle;

    // Animate with easing
    let startTime = null;
    const duration = 4000; // 4 seconds spin

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);

      // Easing function (cubic ease-out)
      const easeOut = (t) => 1 - Math.pow(1 - t, 3);
      const currentRotation =
        progress < 1
          ? rotationAngle + (finalAngle - rotationAngle) * easeOut(progress)
          : finalAngle;

      setRotationAngle(currentRotation);

      if (progress < 1) {
        requestRef.current = requestAnimationFrame(animate);
      } else {
        setIsSpinning(false);
        onStopSpinning();
      }
    };

    cancelAnimationFrame(requestRef.current);
    requestRef.current = requestAnimationFrame(animate);
  };

  // Start spinning when mustStartSpinning changes to true
  useEffect(() => {
    if (mustStartSpinning && !isSpinning) {
      setIsSpinning(true);
    }
  }, [mustStartSpinning]);

  // Run spin animation when isSpinning changes or when isIndefiniteSpin changes
  useEffect(() => {
    if (isSpinning) {
      spin();
    }
  }, [isSpinning, isIndefiniteSpin]);

  // Cancel animation frame on unmount
  useEffect(() => {
    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  // Initial draw and redraw on changes
  useEffect(() => {
    drawWheel();
  }, [data, rotationAngle, width, height]);

  return (
    <div className="relative">
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        className="wheel-canvas"
      />
      {/* Render JSX elements for each segment */}
      {data.map((segment, index) => {
        if (!segment.jsxOption) return null;

        const segmentSize = (2 * Math.PI) / data.length;
        const angle = segmentSize * index + segmentSize / 2 + rotationAngle;
        const radius = (width / 2 - 10) * (textDistance / 100);

        const x = width / 2 + radius * Math.cos(angle);
        const y = height / 2 + radius * Math.sin(angle);

        return (
          <div
            key={index}
            className="absolute transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
            style={{
              left: `${x}px`,
              top: `${y}px`,
              transform: `translate(-50%, -50%) rotate(${
                angle + Math.PI / 2
              }rad)`,
            }}
          >
            {segment.jsxOption}
          </div>
        );
      })}
    </div>
  );
};

const SpinningWheel = ({
  event,
  hasSpun,
  setHasSpun,
  spinInProgress,
  setSpinInProgress,
}) => {
  const { t } = useTranslation();
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const [prizes, setPrizes] = useState([]);
  const [previousResult, setPreviousResult] = useState(null);
  const [isIndefiniteSpin, setIsIndefiniteSpin] = useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const isAdmin = adminUserIds.includes(user.id);

  const navigate = useNavigate();

  useEffect(() => {
    const checkPreviousSpin = async () => {
      if (!user.id || !event) return;

      setLoading(true);

      // Check if user has already spun
      const { data, error } = await supabase
        .from("SpinWinParticipants")
        .select(`*, prize:SpinWinPrizes(*), code:SpinWinCodes(*)`)
        .eq("event_id", event.id)
        .eq("user_id", user.id)
        .single();

      if (data) {
        setHasSpun(true);
        setPreviousResult(data);

        // Get prizes for display
        const { data: prizesData } = await supabase
          .from("SpinWinPrizes")
          .select("*")
          .eq("event_id", event.id);

        if (prizesData) {
          // Find the main prize and reorder the array to put it at index 0
          const mainPrizeIndex = prizesData.findIndex(
            (prize) => prize.is_main_prize
          );
          if (mainPrizeIndex > 0) {
            // If main prize exists and is not already at index 0, reorder
            const mainPrize = prizesData.splice(mainPrizeIndex, 1)[0];
            prizesData.unshift(mainPrize);
          }
          setPrizes(prizesData);

          // Set the prize number to show the wheel in the correct position
          if (data.prize_id) {
            const winningPrizeIndex = prizesData.findIndex(
              (prize) => prize.id === data.prize_id
            );
            if (winningPrizeIndex !== -1) {
              setPrizeNumber(winningPrizeIndex);
            }
          } else {
            setPrizeNumber(prizesData.length);
          }
        }
      } else {
        // New user - Get prizes for display
        const { data: prizesData } = await supabase
          .from("SpinWinPrizes")
          .select("*")
          .eq("event_id", event.id);

        if (prizesData) {
          // Find the main prize and reorder the array to put it at index 0
          const mainPrizeIndex = prizesData.findIndex(
            (prize) => prize.is_main_prize
          );
          if (mainPrizeIndex > 0) {
            // If main prize exists and is not already at index 0, reorder
            const mainPrize = prizesData.splice(mainPrizeIndex, 1)[0];
            prizesData.unshift(mainPrize);
          }
          setPrizes(prizesData);
        }
      }

      setLoading(false);
    };

    checkPreviousSpin();
  }, [user.id, event]);

  const handleSpin = async () => {
    if (hasSpun || spinInProgress) return;

    setSpinInProgress(true);

    // Start indefinite spinning immediately
    setIsIndefiniteSpin(true);
    setMustSpin(true);

    try {
      // Call the edge function to determine prize
      const { data, error } = await supabase.functions.invoke(
        "spinWheelEvent",
        {
          body: JSON.stringify({
            eventId: event.id,
            userId: user.id,
          }),
        }
      );

      console.log("SPINNING DATA", data);
      console.log("SPINNING ERROR", error);

      if (error) {
        console.error("Error in spin wheel event:", error);
        setSpinInProgress(false);
        setIsIndefiniteSpin(false);
        setMustSpin(false);
        return;
      }

      // If user already spun
      if (data.alreadySpun) {
        console.log("USER ALREADY SPUN");
        setHasSpun(true);
        setPreviousResult(data);
        setSpinInProgress(false);
        setIsIndefiniteSpin(false);
        setMustSpin(false);
        return;
      }

      // Find the index of the prize in our prizes array
      let winningPrizeIndex = 0;
      console.log("DATA", data);
      if (data.result === "prize_won") {
        console.log("FOUND PRIZE");
        winningPrizeIndex =
          prizes.findIndex((prize) => prize.id === data.prize.id);
        // If prize not found in the array, default to no prize (index 0)
        if (winningPrizeIndex === -1)
          winningPrizeIndex = data.segmentSize.length - 1;

        // Save the result for displaying after spin
        setPreviousResult({
          prize_id: data.prize.id,
          prize: data.prize,
          code: data.prize.code ? { code: data.prize.code } : null,
        });

        // Send email with prize code to the user if they won a prize
        if (user.email && data.prize.code) {
          try {
            await supabase.functions.invoke("sendPrizeEmail", {
              body: JSON.stringify({
                email: user.email,
                code: data.prize.code,
                link: data.prize.redirect_url,
              }),
            });
            console.log("Prize email sent successfully");
          } catch (emailError) {
            console.error("Error sending prize email:", emailError);
            // Continue with the spinning process even if email fails
          }
        }
      } else {
        // No prize won
        console.log("NO PRIZE WON");
        winningPrizeIndex = prizes.length;
      }

      // Stop indefinite spinning and start the final spin to prize
      setIsIndefiniteSpin(false);
      setPrizeNumber(winningPrizeIndex);
      // Don't need to set mustSpin again as it's already spinning
    } catch (err) {
      console.error("Failed to process spin:", err);
      setSpinInProgress(false);
      setIsIndefiniteSpin(false); // Stop indefinite spinning
      setMustSpin(false);
    }
  };

  const onStopSpinning = () => {
    setMustSpin(false);
    setSpinInProgress(false);
    setHasSpun(true);
    // The result is already saved in previousResult from the edge function call
  };

  // Add a function to handle claim prize button
  const handleClaimPrize = () => {
    if (!previousResult?.prize?.redirect_url) return;

    // Go to the thankspage with the code
    navigate(
      `/thanksSpin?code=${previousResult.code.code}&link=${previousResult.prize.redirect_url}`
    );
  };

  const handleResetPrize = async () => {
    if (
      !window.confirm(
        "Are you sure you want to reset this prize? The user will be able to spin again."
      )
    ) {
      return;
    }

    try {
      // First, get the participation record to access code_id and prize_id
      const { data: participation, error: participationError } = await supabase
        .from("SpinWinParticipants")
        .select("*")
        .eq("event_id", event.id)
        .eq("user_id", user.id)
        .single();

      if (participationError || !participation) {
        return;
      }

      const codeId = participation.code_id;
      const prizeId = participation.prize_id;

      // If code exists, reset it
      if (codeId) {
        await supabase
          .from("SpinWinCodes")
          .update({
            is_claimed: false,
            claimed_by: null,
            claimed_at: null,
          })
          .eq("id", codeId);
      }

      // If prize exists, increment remaining quantity
      if (prizeId) {
        const { data: prize } = await supabase
          .from("SpinWinPrizes")
          .select("remaining_quantity")
          .eq("id", prizeId)
          .single();

        if (prize) {
          await supabase
            .from("SpinWinPrizes")
            .update({
              remaining_quantity: prize.remaining_quantity + 1,
            })
            .eq("id", prizeId);
        }
      }

      // Delete the participation record
      await supabase
        .from("SpinWinParticipants")
        .delete()
        .eq("id", participation.id);

      // Reset local state
      setHasSpun(false);
      setPreviousResult(null);

      // Reload the prizes
      const { data: prizesData } = await supabase
        .from("SpinWinPrizes")
        .select("*")
        .eq("event_id", event.id);

      if (prizesData) {
        const mainPrizeIndex = prizesData.findIndex(
          (prize) => prize.is_main_prize
        );
        if (mainPrizeIndex > 0) {
          const mainPrize = prizesData.splice(mainPrizeIndex, 1)[0];
          prizesData.unshift(mainPrize);
        }
        setPrizes(prizesData);
      }

      window.location.reload();
    } catch (error) {
      console.error("Error resetting prize:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[300px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  // Define alternating colors and gradients for segments
  const segmentColors = ["#C9A6F5", "#7721E5", "#924DEA"];
  const segmentGradients = [
    [
      { position: 0, color: "#53179B" },
      { position: 1, color: "#C9A6F5" },
    ],
    [
      { position: 0, color: "#53179B" },
      { position: 1, color: "#924DEA" },
    ],
    [
      { position: 0, color: "#390873" },
      { position: 1, color: "#7721E5" },
    ],
  ];

  const data = [
    // Add no prize segment first
    // Then map the actual prizes
    ...prizes.map((prize, index) => {
      let colorIndex;
      const cyclePosition = index % 4;

      if (cyclePosition === 0) colorIndex = 0;
      else if (cyclePosition === 1) colorIndex = 1;
      else if (cyclePosition === 2) colorIndex = 2;
      else colorIndex = 1;

      return {
        jsxOption: (
          <div className="mb-[30px] flex items-center justify-center">
            <div
              className={`p-[8px] rounded-[10px] border border-white !pb-[8px] ${
                prize.is_main_prize
                  ? "bg-[#FDBC64] text-fuchsia-600"
                  : "bg-[#FFFAF480] text-black"
              }`}
            >
              {prize.is_main_prize ? (
                <img
                  src={TicketIcon}
                  className="w-[34px] h-[34px]"
                  alt="Ticket"
                />
              ) : (
                <Icon icon={prize.icon} className="text-[28px]" />
              )}
            </div>
          </div>
        ),
        style: {
          backgroundColor: segmentColors[colorIndex],
          backgroundGradient: segmentGradients[colorIndex],
          textColor: "white",
        },
      };
    }),
    {
      jsxOption: (
        <div className="mb-[30px] flex items-center justify-center">
          <div className="p-[8px] rounded-[10px] border border-white !pb-[8px] bg-[#FFFAF480] text-black">
            <Icon icon="mdi:close" className="text-[28px]" />
          </div>
        </div>
      ),
      style: {
        backgroundColor: segmentColors[2],
        backgroundGradient: segmentGradients[2],
        textColor: "white",
      },
    },
  ];

  return (
    <>
      <div className="text-center relative">
        {/* Always render the wheel */}
        <div className="border-[2px] border-[#AD7AEF70] rounded-full bg-[#0B0415]">
          <WheelCanvas
            width={350}
            height={350}
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={data}
            onStopSpinning={onStopSpinning}
            outerBorderColor="#0B0415"
            outerBorderWidth={0}
            innerRadius={20}
            innerBorderColor="#0B0415"
            radiusLineColor="#0B0415"
            radiusLineWidth={2}
            fontSize={16}
            textDistance={60}
            isIndefiniteSpin={isIndefiniteSpin}
          />

          {/* Render center button (disabled when user has already spun) */}
          <div
            className={`p-[6px] absolute inset-0 m-auto w-[120px] h-[120px] bg-[#0B0415] rounded-full flex items-center justify-center z-20`}
            onClick={!hasSpun ? handleSpin : undefined}
          >
            <div className="rounded-full bg-[#1D1726] border-[2px] border-[#924DEA80] w-full h-full flex items-center justify-center">
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="w-0 h-0 border-l-[15px] border-r-[15px] border-b-[30px] border-l-transparent border-r-transparent border-b-black"></div>
              </div>
              <span className="text-[#C9A6F5] font-extrabold text-[32px]">
                SPIN
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Show prize result if user has already spun */}
      {hasSpun && (
        <div className="mt-6">
          {previousResult?.prize_id && !spinInProgress ? (
            <>
              <h3 className="text-[22px] font-semibold text-white">
                Gefeliciteerd!
              </h3>
              <div className="mt-[6px] bg-[#1D1726] border border-[#AD7AEF50] rounded-[8px] p-[16px_20px] mb-[12px] text-left">
                <div className="flex items-start mb-2 gap-[12px]">
                  <img
                    src={AllDealsIcon}
                    className="w-[24px] h-[24px]"
                    alt="Ticket"
                  />
                  <div className="flex flex-col">
                    <span className="text-white font-bold">Jouw prijs</span>
                    <p className="text-gray-300 mt-1">
                      {previousResult.prize?.title || "Frenzy x Vulged"}
                    </p>
                  </div>
                </div>

                <hr className="my-[12px] border-[#292433]" />

                <div className="flex items-start mb-2 gap-[12px]">
                  <img
                    src={EmailIcon}
                    className="w-[24px] h-[24px]"
                    alt="Email"
                  />
                  <div className="flex flex-col">
                    <span className="text-white font-bold">
                      Code ontvangen?
                    </span>
                    <p className="text-gray-300 mt-1">
                      Druk op 'claim jouw prijs' en je ontvangt binnen enkele
                      minuten een e-mail van ons met een code die je kunt
                      gebruiken om je ticket te verzilveren.
                    </p>
                  </div>
                </div>
              </div>

              <button
                onClick={handleClaimPrize}
                className="mt-[12px] w-full premium-button !text-[17px] !py-[12px] text-white rounded-[8px] font-semibold"
              >
                Claim jouw prijs
              </button>
            </>
          ) : (
            <div className="min-w-full">
              <h3 className="text-[20px] font-semibold text-white">
                {t("better_luck")}
              </h3>
              <p className="text-[16px] text-gray-300 mb-4">
                {t("no_prize_this_time")}
              </p>
            </div>
          )}
          {isAdmin && (
            <button
              onClick={handleResetPrize}
              className="mt-[12px] w-full premium-button !text-[17px] !py-[12px] text-white rounded-[8px] font-semibold"
            >
              Reset prijs
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default SpinningWheel;
