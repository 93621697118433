import React from "react";
import { useNavigate } from "react-router-dom";
import { getPublicImageUrl } from "../helpers/helpers";

export default function DealCard({ deal, className = "" }) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/deal?deal_id=${deal.id}`)}
      className={`w-full border border-[#AD7AEF]  hover:cursor-pointer h-[200px] relative hover:scale-[102%] transition-all duration-500 rounded-2xl overflow-hidden ${className}`}
    >
      <img
        src={getPublicImageUrl(deal.image_filename)}
        className="absolute top-0 left-0 w-full h-full object-cover"
        alt={deal.name}
      />
      <div className="w-full flex flex-col gap-y-1 absolute left-0 bottom-0 p-[20px] z-10 bg-gradient-to-t from-black/70 via-black/50 to-transparent pt-[40px]">
        <h2 className="text-[18px] leading-[22px] text-white font-semibold">
          {deal.name}
        </h2>
        <p className="text-[14px] leading-[16px] text-white font-medium line-clamp-2">
          {deal.teaser}
        </p>
      </div>
    </div>
  );
}