import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import { CustomSelect } from "./home/Home";
import Icon from "../components/Icon";
import EventCard from "../components/EventCard";
import { supabase } from "../supabaseClient";
import ProfileSidebar from "../components/ProfileSidebar";

export default function ProfileAlerts({ language, setLanguage }) {
  const user = useSelector(selectUser);

  const [ticketAlerts, setTicketAlerts] = useState([]);

  useEffect(() => {
    supabase
      .from("TicketAlert")
      .select("*, event:Events (*, venue:Venue (*))")
      .eq("user_id", user.user_info_id)
      .then((res) => {
        console.log("res", res.data);
        // Filter events to only include those with dates in the future
        const currentDate = new Date();
        const futureEvents = res.data
          .map((alert) => alert.event)
          .filter((event) => {
            const eventDate = new Date(event.date);
            return eventDate > currentDate;
          });
        setTicketAlerts(futureEvents);
      });
  }, []);

  return (
    <div className="bg-[#12041f]">
      <div
        className="hero-section relative min-h-[100vh] "
        style={{ background: "#0A0315" }}
      >
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/profile">
              <Icon
                icon="heroicons:arrow-left"
                className="text-white h-12 w-12"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="lg:w-[80%] max-lg:w-full">
            <div className="flex flex-col items-start text-start max-lg:hidden pt-[60px]">
              <h1 className="text-start text-[50px]">
                Hallo {user.first_name}
              </h1>
              <hr className="border-[#302640] mt-[20px] w-full " />
            </div>
            <div className="flex max-lg:flex-col w-full gap-12">
              <div className="max-lg:hidden w-[20%]">
                <ProfileSidebar user={user} language={language} setLanguage={setLanguage} />
              </div>
              <div className="lg:w-[80%]">
                {/* Events here */}
                <div className="mb-[100px] mt-12 flex flex-col items-center">
                  <div className="w-[90%]">
                    <h2 className="lg:text-[32px] max-lg:text-[26px] text-white">
                      Ticket alerts
                    </h2>
                    {ticketAlerts.length > 0 ? (
                      <div className="w-full flex max-lg:flex-col lg:flex-row gap-12">
                        <div className="lg:w-[600px] max-lg:w-full mt-[16px] zoek-evenementen !p-0 m-0">
                          {ticketAlerts.map((event) => {
                            return (
                              <div className="mt-3 w-full">
                                <div className="event-box">
                                  <div className="row">
                                    <EventCard
                                      key={event.id}
                                      eventCardData={event}
                                      singleEvent={true}
                                      disableLink={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center flex-col justify-center mt-[40px] text-[18px] text-center text-white">
                        Je hebt je nog niet aangemeld voor een ticket alert.
                        <Link
                          to="/"
                          className="premium-button px-12 py-4 mt-2 rounded-[8px]"
                        >
                          Events bekijken
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
