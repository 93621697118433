import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "flowbite-react";
import Icon from "../components/Icon";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import TokenDefaultFilters from "../components/TokenDefaultFilters";

export default function ManageTokenDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [eventSignups, setEventSignups] = useState([]);

  useEffect(() => {
    fetchTokenDetails();
  }, [id]);

  const fetchTokenDetails = async () => {
    // Fetch token details
    const { data: tokenData, error: tokenError } = await supabase
      .from("Token")
      .select("*")
      .eq("id", id)
      .single();

    if (tokenError) {
      console.error("Error fetching token:", tokenError);
      return;
    }

    setToken(tokenData);

    // Fetch event signups
    const { data: signupsData, error: signupsError } = await supabase
      .from("EventSignUp")
      .select("*, event:Events(*)")
      .eq("token_id", id);

    if (signupsError) {
      console.error("Error fetching event signups:", signupsError);
      return;
    }

    // Group signups by event
    const eventMapping = {};
    for (const signup of signupsData) {
      if (!eventMapping[signup.event_id]) {
        eventMapping[signup.event_id] = {
          event: signup.event,
          signups: []
        };
      }
      eventMapping[signup.event_id].signups.push(signup);
    }

    setEventSignups(Object.values(eventMapping));
  };

  if (!token) {
    return <div className="p-6 text-white">Loading token details...</div>;
  }

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-4xl font-bold text-white">{token.name}</h1>
          <p className="text-gray-300">
            {token.active ? "Active" : "Archived"} token - Created on {dayjs(token.created_at).format("MMM D, YYYY")}
          </p>
        </div>
        <button
          onClick={() => navigate("/admin/tokens")}
          className="flex items-center gap-2 bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded"
        >
          <Icon icon="heroicons:arrow-left" className="h-5 w-5" />
          Back to Tokens
        </button>
      </div>

      {/* Add the TokenDefaultFilters component */}
      <TokenDefaultFilters tokenId={id} existingFilters={token.default_filters} />

      <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Event Signups</h2>
      <Table>
        <Table.Head>
          <Table.HeadCell className="text-xl">
            Event Name
          </Table.HeadCell>
          <Table.HeadCell className="text-xl">
            Event Date
          </Table.HeadCell>
          <Table.HeadCell className="text-xl">
            Signups
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {eventSignups.map(({ event, signups }, index) => (
            <Table.Row
              key={event.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-200"}`}
            >
              <Table.Cell className="p-12 text-2xl whitespace-nowrap font-medium text-gray-900">
                {event.name}
              </Table.Cell>
              <Table.Cell className="p-12 text-2xl whitespace-nowrap text-gray-900">
                {dayjs(event.datetime).format("DD MMMM YYYY")}
              </Table.Cell>
              <Table.Cell className="p-12 text-2xl whitespace-nowrap text-gray-900">
                {signups.length}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}