import { useEffect, useState, useRef } from "react";
// import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

import LocateImg from "../assets/image svg/Locatie.svg";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import { CustomSelect } from "../pages/home/Home";
import Logo from "../components/Logo";

import { useTranslation } from "react-i18next";
import Icon from "../components/Icon";
import { getPublicImageUrl } from "../helpers/helpers";
import { selectUser } from "../slices/userReducer";
import { useSelector } from "react-redux";
import EventSignupForm from "./EventSignupForm";
import EventMemberInfo from "./EventMemberInfo";
import { supabase } from "../supabaseClient";
import { validateName, validateEmail } from "../helpers/helpers";
import CouponModal from "./CouponModal";

dayjs.extend(utc);

const SingleEvent = ({ dealData, setLanguage, language, token }) => {
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(window.location.search);
  const pathname = searchParams.get("deal_id");

  console.log("pathname", pathname);

  const navigate = useNavigate();
  const [deal, setDeal] = useState([]);

  const [soldOutStatus, setSoldOutStatus] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [eFormData, setEFormData] = useState({
    Firstname: "",
    Lastname: "",
    Email: "",
    dob: "",
    gender: "",
    event: "",
    event_id: "",
    token: token,
  });
  const [setEventLink] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const refForm = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  //   Find the deal that is supposed to be displayed in the deal page.
  useEffect(() => {
    if (
      typeof pathname !== typeof 0 &&
      pathname &&
      dealData.find((d) => d.id === Number(pathname))
    ) {
      // Find the chosen deal with find()
      const chosenDeal = dealData.find((d) => d.id === Number(pathname));

      // setCookie("token", "AMSLIJST", {
      //   path: "/",
      //   expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000), // (10 * 60 * 60 * 1000) 10 minutes in milliseconds
      // });

      setDeal(chosenDeal);

      //   Configure sold out and ticket link properties
      const soldOutText = chosenDeal.sold_out["sold-out"];
      const ticketLink = chosenDeal.ticketDeal || "";
      if (soldOutText === true || soldOutText === "true") {
        setSoldOutStatus(true);
      }
      if (ticketLink !== "") {
        if (ticketLink.includes("http://localhost/")) {
          const modifiedLink = ticketLink.replace("http://localhost/", "");
          setEventLink(modifiedLink);
        } else {
          setEventLink(ticketLink);
        }
      }
      setEFormData({
        ...eFormData,
        event: chosenDeal.title,
        event_id: chosenDeal.id,
      });

      // Check if this deal has a coupon code that should be displayed
      if (chosenDeal.redeem_category === "general_coupon_code" && chosenDeal.coupon_code) {
        setCouponCode(chosenDeal.coupon_code);
        setShowCouponModal(true);
      }
    } else {
      navigate("/lifestyle");
    }
  }, [dealData, pathname]);

  const user = useSelector(selectUser);

  useEffect(() => {
    if (user.id) {
      setEFormData({
        ...eFormData,
        Firstname: user.first_name,
        Lastname: user.last_name,
        Email: user.email,
        gender: user.gender,
        dob:
          user.date_of_birth === "Invalid Date" || user.date_of_birth === "None"
            ? "01-01-2000"
            : user.date_of_birth,
        agreement: true,
      });
    }
  }, [user]);

  useEffect(() => {
    if (deal?.image_filename) {
      const img = new Image();
      img.onload = () => {
        console.log("Deal image loaded");
        setImageLoaded(true);
      };
      img.src = getPublicImageUrl(deal.image_filename);
    }
  }, [deal]);

  const getThanks = (redeem_category, redeem_data) => {
    if (redeem_category === "link") {
      navigate(`/thanksDeal?link=${redeem_data}`);
    }
    if (redeem_category === "code") {
      // If this is a code redemption, set the coupon code and show modal
      setCouponCode(redeem_data);
      setShowCouponModal(true);
      navigate(`/thanksDeal?code=${redeem_data}`);
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!validateName(eFormData.Firstname)) {
      console.log("INVALID NAME");
      errors.Firstname = true;
      isValid = false;
    }

    if (!validateName(eFormData.Lastname)) {
      console.log("INVALID LAST NAME");
      errors.Lastname = true;
      isValid = false;
    }

    if (!validateEmail(eFormData.Email)) {
      console.log("INVALID EMAIL");
      errors.Email = true;
      isValid = false;
    }

    console.log("");

    if (!eFormData.dob || eFormData.dob.length < 10) {
      console.log("INVALID DOB");
      errors.dob = true;
      isValid = false;
    }

    if (!["Man", "Vrouw", "Onzijdig"].includes(eFormData.gender)) {
      console.log("INVALID GENDER");
      errors.gender = true;
      isValid = false;
    }

    if (!eFormData.agreement) {
      console.log("INVALID AGREEMENT");
      errors.agreement = true;
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  const handleSubmit = async (e, isScroll = false) => {
    setBtnDisabled(true);
    e.preventDefault();

    if (isScroll) {
      refForm.current?.scrollIntoView({ behavior: "smooth" });
    }

    const { agreement, ...dataToSend } = eFormData;

    if (!validateForm()) {
      setBtnDisabled(false);
      return;
    }

    // Start the edge function
    const { data, error } = await supabase.functions.invoke("claimDeal", {
      body: JSON.stringify({
        deal: deal,
        dataObject: dataToSend,
        user: user,
      }),
    });

    if (error) {
      console.error("Error claiming deal:", error);
      setBtnDisabled(false);
      // Set sold out status if the deal is no longer available
      setSoldOutStatus(true);
      return;
    }

    setBtnDisabled(false);

    if (data.redeem_category === "link") {
      var newTab = window.open(deal.ticket_link, "_blank");
      if (newTab) {
        newTab.opener = null; // Set opener to null for security
        newTab.location.href = deal.ticket_link; // Set the location explicitly
      } else {
        // Fallback: Open link in the current window if the new tab couldn't be opened
        window.location.href = deal.ticket_link;
      }
      getThanks("link", deal.ticket_link);
    }

    if (data.redeem_category === "code") {
      if (deal.ticket_link) {
        var newTab = window.open(deal.ticket_link, "_blank");
        if (newTab) {
          newTab.opener = null; // Set opener to null for security
          newTab.location.href = deal.ticket_link; // Set the location explicitly
        } else {
          // Fallback: Open link in the current window if the new tab couldn't be opened
          window.location.href = deal.ticket_link;
        }
      }

      // Set the coupon code and show the modal
      setCouponCode(data.code);
      setShowCouponModal(true);

      getThanks("code", data.code);
    }
  };

  const eventOriginalPrice = deal.original_price;
  const eventOurPrice = deal.our_price;
  const eventPriceLabel = `${t("instead_of")} ` || "";

  const priceText = `${eventPriceLabel} €${eventOriginalPrice}`;
  const eventPriceClass =
    soldOutStatus === true
      ? "soldout row justify-content-between"
      : "row justify-content-between";

  const shareContent = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: deal.name,
          text: `Check out ${deal.name} on 22night!`,
          url: `${window.location.href}&token=AMSLIJST`,
        });
        console.log("Content shared successfully!");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      console.error("Web Share API not supported");
    }
  };

  if (!deal || !imageLoaded) {
    return (
      <div className="h-screen w-screen flex items-center justify-center hero-section">
        <Logo />
      </div>
    );
  }

  return (
    <div
      className="relative bg-[#12041f] min-h-screen lg:pb-[100px] animate-fadeIn"
      style={{
        animation: "fadeIn 0.5s ease-in-out",
      }}
    >
      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(10px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          .animate-fadeIn {
            animation: fadeIn 0.5s ease-in-out forwards;
          }
        `}
      </style>
      <CouponModal
        show={showCouponModal}
        setShow={setShowCouponModal}
        couponCode={couponCode}
      />
      <div className="absolute hero-section w-full h-[600px] !z-[0]"></div>
      <div className="lg:pt-24 hero-section-single event-page">
        <div className="custom-container">
          <div className="lg:hidden w-full flex items-center justify-center pt-12 relative z-[1000000]">
            <div className="flex justify-between w-[90%] items-center">
              <Link to="/lifestyle">
                <Icon
                  icon="heroicons:arrow-left"
                  className="w-12 h-12 text-white"
                  alt="Whatsapp logo"
                />
              </Link>
              <Link to="/lifestyle">
                <Logo />
              </Link>
              <CustomSelect
                options={[
                  { value: "nl", label: "🇳🇱 Nederlands" },
                  { value: "en", label: "🇺🇸 English" },
                ]}
                onSelect={(value) => {
                  setLanguage(value);
                }}
              />
            </div>
          </div>
          <div className="max-lg:hidden absolute top-[50px] left-[50px] z-[1000000]">
            <Link to="/lifestyle">
              <Icon
                icon="heroicons:arrow-left"
                className="w-12 h-12 text-white"
                alt="Whatsapp logo"
              />
            </Link>
          </div>
          <div className="absolute left-0 top-0 w-full h-[300px] max-lg:h-[460px] sm:h-[550px] flex justify-center !-pb-6 max-lg:!border-b !border-[#302640]">
            <div className="bg-black w-full h-full border-gray-900 border-b-2 overflow-hidden">
              <img
                src={getPublicImageUrl(deal.image_filename)}
                alt={"Event image"}
                className="w-full h-full object-cover blur-2xl"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-white/0 via-black/5 to-black/20"></div>
            </div>
          </div>
          <div className="max-lg:mt-[30px] event-img text-center relative max-lg:h-[350px]">
            <div className="w-full flex justify-center lg:items-center lg:h-[430px]">
              <div className="relative w-[500px] max-w-[95vw] max-h-[430px] bg-[#130826] max-lg:p-[16px] lg:p-[24px] rounded-[12px]">
                <img
                  src={getPublicImageUrl(deal.image_filename)}
                  alt={"Event image"}
                  className="!w-full !h-auto !rounded-[8px]"
                />
                <div className="absolute max-lg:top-[25px] max-lg:right-[-10px] lg:top-[40px] lg:right-[40px]">
                  <li
                    className="event date cursor-pointer !bg-transparent"
                    onClick={shareContent}
                  >
                    <a className="shadow-md border-2 border-white !p-3 bg-neutral-200 text-white flex items-start border-2 gap-2 border-white rounded-full">
                      {/* <span className="text-color">Share</span> */}
                      <Icon icon="mi:share" className="h-8 w-8 text-black" />
                    </a>
                  </li>
                </div>
                <h1 className="line-clamp-2 text-start w-full text-[26px] text-white font-bold !p-0 !mt-[15px] !leading-[32px]">
                  {deal.name}
                </h1>
                <div className="event-information-box">
                  <ul className="row !pt-4">
                    <li className="event date">
                      <a className="text-white flex items-start">
                        <img src={LocateImg} alt="Locatie" />
                        <span className="text-color ml-1 mt-1 truncate elipsis">
                          {deal.cities}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="lg:hidden"> */}
            {/* <img src={getPublicImageUrl(eventImage)} alt={"Event image"} /> */}
            {/* </div> */}
          </div>
          <div className="xl:flex justify-between gap-3 lg:mt-[48px]">
            <div className="relative max-lg:px-[15px]">
              <div className="mt-12 pt-[24px] mr-3 lg:mr-12 border-[#302640]">
                <div className="multipan-event-box vriendenticket">
                  <div className="events-content">
                    <h5 className="text-white !text-[16px] !leading-[22px]">
                      {deal.advantage_1_title}
                    </h5>
                    <p className="text-neutral-300 !text-[14px] !leading-[20px] whitespace-pre-wrap">
                      {deal.advantage_1_description}
                    </p>
                  </div>
                </div>
                <div className="multipan-event-box vriendenticket">
                  <div className="events-content">
                    <h5 className="text-white !text-[16px] !leading-[22px]">
                      {deal.advantage_2_title}
                    </h5>
                    <p className="text-neutral-300 !text-[14px] !leading-[20px] whitespace-pre-wrap">
                      {deal.advantage_2_description}
                    </p>
                  </div>
                </div>
                <div className="multipan-event-box vriendenticket">
                  <div className="events-content">
                    <h5 className="text-white !text-[16px] !leading-[22px]">
                      {deal.advantage_3_title}
                    </h5>
                    <p className="text-neutral-300 !text-[14px] !leading-[20px] whitespace-pre-wrap">
                      {deal.advantage_3_description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:hidden w-full flex items-center justify-center px-[15px] py-[12px]">
              <hr className="border-gray-800 w-full" />
            </div>
            {!user.id ? (
              <EventSignupForm
                eFormData={eFormData}
                formErrors={formErrors}
                setEFormData={setEFormData}
                setFormErrors={setFormErrors}
                event={deal}
                refForm={refForm}
                soldOutStatus={false}
                setBtnDisabled={setBtnDisabled}
                handleSubmit={handleSubmit}
                btnDisabled={btnDisabled}
                showMobile={true}
                isDeal={true}
              />
            ) : (
              <div className="max-lg:hidden">
                <EventMemberInfo
                  eventSignups={0}
                  event={deal}
                  handleSubmit={handleSubmit}
                  soldOutStatus={soldOutStatus}
                  btnDisabled={btnDisabled}
                  isDeal={true}
                />
              </div>
            )}
          </div>
        </div>
        <div className="event-price-box mobile ">
          <div className={eventPriceClass}>
            <div className="price-box text-white">
              <h3>{soldOutStatus === true && "This deal is sold out"}</h3>
            </div>

            <div className="price-button !w-full ">
              <button
                className="button openPopupButtons !w-full"
                type="button"
                onClick={handleSubmit}
                disabled={
                  soldOutStatus === true || btnDisabled === true
                    ? "disabled"
                    : ""
                }
              >
                {/* Koop vriendenticket */}
                Claim deal
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleEvent;
