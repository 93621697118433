import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/nl";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import { getPublicImageUrl } from "../helpers/helpers";

dayjs.extend(utc);
dayjs.extend(timezone);

const formatPrice = (price) => {
  return parseFloat(price || 0).toFixed(2);
};

const SpinWinEventCard = ({
  eventCardData,
  singleEvent,
  disableLink = false,
  showBanner = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let eventName = eventCardData.name;
  eventName = eventName.charAt(0) + eventName.slice(1);

  const eventTime = dayjs(eventCardData.datetime).locale("nl").format("HH:mm");
  const eventDate = dayjs(eventCardData.datetime).locale("nl").format("D MMM");

  const cityName = eventCardData.city;
  const clubName =
    eventCardData.venue.name.charAt(0).toUpperCase() +
    eventCardData.venue.name.slice(1).toLowerCase();

  const handleItemClick = () => {
    if (!disableLink) {
      navigate(`/single-event?eventId=${eventCardData.id}`, {
        state: eventCardData.id,
      });
    }
  };

  return (
    <div
      className={`max-lg:!p-[16px] event-content text-white border border-[#AD7AEF] transition-all duration-300 relative bg-gradient-to-r from-[#AD7AEF]/[0.06] via-[#C2A5FF]/[0.4] to-[#554B6A]/[0.1855] ${
        showBanner ? "min-h-[320px]" : ""
      }`}
      onClick={handleItemClick}
    >
      {showBanner && (
        <div className="w-full max-w-[95vw] max-h-[430px] mb-4 overflow-hidden rounded-[6px] lg:rounded-[8px]">
          <img
            src={getPublicImageUrl(eventCardData.event_image_filename)}
            alt={eventName}
            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
          />
        </div>
      )}

      {eventCardData.trending && (
        <div className="text-[#7721E5] absolute top-[25px] lg:right-[28px] lg:top-[32px] right-[25px] shadow flex items-center gap-2 bg-[#F7F2FD] px-2 py-1 rounded-[6px] p-1 z-10">
          <Icon icon="gravity-ui:star-fill" className="h-[16px] w-[16px]" />
          <div className="text-[14px] font-bold bg-transparent">Trending</div>
        </div>
      )}
      <a>
        <h5 className="truncate elipsis lg:text-[18px] lg:leading-[24px] text-[16px] leading-[22px] text-white font-[700]">
          {eventName}
        </h5>
        <h6 className="truncate elipsis !text-white lg:text-[16px] text-[14px] font-normal leading-[16px]">
          {disableLink && `${eventDate} • `}
          {eventTime} • {clubName} • {cityName}
        </h6>
        {eventCardData.sold_out === true ||
        eventCardData.sold_out === "true" ? (
          <div className="!rounded-[4px] Sould Out">
            <span className="!text-[14px] !px-[6px] !py-[4px]">Sold out</span>
          </div>
        ) : (
          <div className="flex items-center justify-start gap-2 mt-[14px]">
            {/* <div className="!rounded-full text-white !pb-0 border border-[#C9A6F5] bg-[#7721E5]">
              <span className="!text-[14px] !px-[8px] !py-[2px] !bg-transparent min-w-[55px] flex items-center justify-center">
                {eventCardData.currency}
                {formatPrice(eventCardData.our_price)}
              </span>
            </div>
            <div className="!rounded-[4px] text-white bg-[#3A3640]">
              <span className="font-normal line-through !text-[14px] !px-[8px] !py-[2px] !bg-transparent min-w-[55px] flex items-center justify-center">
                {eventCardData.currency}
                {formatPrice(eventCardData.original_price)}
              </span>
            </div> */}
          </div>
        )}
        <div className="mt-[16px] py-[10px] w-full flex items-center justify-center border-gradient rounded-[8px] overflow-hidden">
          <div className="text-[14px] font-semibold text-white">
            Win tickets
          </div>
        </div>
      </a>
    </div>
  );
};

export default SpinWinEventCard;
