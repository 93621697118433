import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import { supabase } from "../supabaseClient";
import Icon from "../components/Icon";

export default function ManageSuggestions() {
  const [suggestions, setSuggestions] = useState([]);
  const [activeTab, setActiveTab] = useState("new");

  useEffect(() => {
    supabase
      .from("Suggestion")
      .select("*")
      .order("created_at", { ascending: false })
      .then((res) => {
        setSuggestions(res.data);
      });
  }, []);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      supabase
        .from("Suggestion")
        .delete()
        .eq("id", id)
        .then((res) => {
          setSuggestions(
            suggestions.filter((suggestion) => suggestion.id !== id)
          );
        });
    }
  };

  const getFilteredItems = () => {
    if (activeTab === "new") {
      return suggestions.filter(item => !item.checked && !item.is_feedback);
    } else if (activeTab === "checked") {
      return suggestions.filter(item => item.checked && !item.is_feedback);
    } else if (activeTab === "feedback") {
      return suggestions.filter(item => item.is_feedback);
    }
    return [];
  };

  const filteredItems = getFilteredItems();

  return (
    <div className="w-full text-white bg-black text-3xl mt-12 overflow-x-auto p-5">
      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Suggestions & Feedback
        </h1>
      </div>

      <div className="flex justify-start items-center gap-3 mt-12 text-[18px]">
        <button
          onClick={() => setActiveTab("new")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "new" ? "border-white" : "border-transparent"
          }`}
        >
          New Suggestions ({suggestions.filter(s => !s.checked && !s.is_feedback).length})
        </button>
        <button
          onClick={() => setActiveTab("checked")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "checked" ? "border-white" : "border-transparent"
          }`}
        >
          Archived Suggestions ({suggestions.filter(s => s.checked && !s.is_feedback).length})
        </button>
        <button
          onClick={() => setActiveTab("feedback")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "feedback" ? "border-white" : "border-transparent"
          }`}
        >
          Feedback ({suggestions.filter(s => s.is_feedback).length})
        </button>
      </div>

      <Table className="mt-8">
        <Table.Head>
          <Table.HeadCell className="text-3xl capitalize w-[80%]">
            {activeTab === "feedback" ? "Feedback" : "Event"}
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize w-[80%]">
            Email
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize w-[10%]">
            Phone number
          </Table.HeadCell>
          {activeTab !== "feedback" && (
            <Table.HeadCell className="text-3xl capitalize w-[10%]">
              Checked
            </Table.HeadCell>
          )}
          <Table.HeadCell className="text-3xl capitalize w-[10%]">
            Delete
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {filteredItems.map((item, index) => {
            return (
              <Table.Row
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-200"}`}
              >
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                  {item.event}
                </Table.Cell>
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                  {item.email}
                </Table.Cell>
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                  {item.phone_number}
                </Table.Cell>
                {activeTab !== "feedback" && (
                  <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900">
                    <input
                      type="checkbox"
                      className="w-8 h-8 hover:cursor-pointer"
                      checked={item.checked}
                      onChange={(e) => {
                        const newChecked = e.target.checked;

                        supabase
                          .from("Suggestion")
                          .update({ checked: newChecked })
                          .eq("id", item.id)
                          .then((res) => {
                            if (res.error) {
                              console.error(
                                "Error updating suggestion:",
                                res.error
                              );
                              return;
                            }

                            setSuggestions(
                              suggestions.map((s) =>
                                s.id === item.id
                                  ? { ...s, checked: newChecked }
                                  : s
                              )
                            );
                          });
                      }}
                    />
                  </Table.Cell>
                )}
                <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-red-500">
                  <button onClick={() => handleDelete(item.id)}>
                    <Icon icon="heroicons:trash" className="w-10 h-10" />
                  </button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
