import React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
} from "@chakra-ui/react";
import LocationIcon from "../assets/image svg/Locatie.svg";
import ArrowDownIcon from "../assets/image svg/chevron down.svg";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";

export default function DealFilters({
  selectedCities,
  cities,
  categories,
  onCityChange,
  onCategoryChange,
  selectedCategory
}) {
  const { t } = useTranslation();

  const handleCategoryClick = (categoryId) => {
    if (selectedCategory === categoryId) {
      onCategoryChange("all_deals");
    } else {
      onCategoryChange(categoryId);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center justify-start gap-3 max-lg:overflow-x-auto max-lg:w-full max-lg:pb-2 max-lg:scrollbar-hide">
        {/* City Filter Dropdown */}
        <div className="side filter-date !mr-0">
          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              className="btn btn-dropdown dropdown-toggle !rounded-[10px] bg-[#1D1726] border !border-[#AD7AEF]"
              leftIcon={<img src={LocationIcon} alt="locatie" />}
              rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
            >
              <span className="selectedOption">
                {selectedCities?.length === 1
                  ? selectedCities[0]
                  : selectedCities?.length > 1
                  ? `${selectedCities.length} ${t("cities_selected")}`
                  : t("all_cities")}
              </span>
            </MenuButton>
            <Portal>
              <MenuList
                className="menu-list"
                zIndex={99999999999999}
                position="fixed"
                width="auto"
                minWidth="150px"
                maxWidth="250px"
                style={{
                  transform: "translateZ(0)",
                }}
              >
                <MenuOptionGroup
                  type="checkbox"
                  value={selectedCities}
                  onChange={(values) => onCityChange(values)}
                >
                  {cities.map((city) => (
                    <MenuItemOption
                      key={city}
                      value={city}
                      className="text-white hover:bg-[#AD7AEF]"
                    >
                      {city}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Portal>
          </Menu>
        </div>

        {categories && categories.map((category) => (
          <div key={category.id} className="side filter-category !mr-0">
            <Menu>
              <MenuButton
                as={Button}
                className={`btn btn-dropdown dropdown-toggle !rounded-[10px] bg-[#1D1726] ${
                  selectedCategory === category.id ? "!bg-[#AD7AEF]" : "!border-transparent"
                }`}
                leftIcon={<Icon icon={category.icon} className="h-[20px] w-[20px]" alt={category.name} />}
                onClick={() => handleCategoryClick(category.id)}
              >
                <span className="selectedOption">
                  {category.name}
                </span>
              </MenuButton>
            </Menu>
          </div>
        ))}
      </div>
    </div>
  );
}
