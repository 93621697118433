import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import Icon from "../components/Icon";
import { useNavigate } from "react-router-dom";

export default function ManageSpinWinEvents() {
  const [spinWinEvents, setSpinWinEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSpinWinEvents();
  }, []);

  const fetchSpinWinEvents = async () => {
    setLoading(true);

    try {
      // Fetch all spin & win events
      const { data: events, error } = await supabase
        .from("Events")
        .select(`
          *,
          venue:Venue(name),
          spin_stats:SpinWinParticipants(count)
        `)
        .eq("is_spin_win", true)
        .order("spin_win_display_order", { ascending: true });

      if (error) {
        console.error("Error fetching spin & win events:", error);
        return;
      }

      // Initialize display order for events that don't have one
      const eventsWithOrder = events || [];
      let needsUpdate = false;

      for (let i = 0; i < eventsWithOrder.length; i++) {
        if (!eventsWithOrder[i].spin_win_display_order) {
          needsUpdate = true;
          const { error: updateError } = await supabase
            .from("Events")
            .update({ spin_win_display_order: i + 1 })
            .eq("id", eventsWithOrder[i].id);

          if (updateError) {
            console.error("Error updating display order:", updateError);
          }
          eventsWithOrder[i].spin_win_display_order = i + 1;
        }
      }

      // If we updated any orders, fetch the events again to get the updated order
      if (needsUpdate) {
        const { data: updatedEvents } = await supabase
          .from("Events")
          .select(`
            *,
            venue:Venue(name),
            spin_stats:SpinWinParticipants(count)
          `)
          .eq("is_spin_win", true)
          .order("spin_win_display_order", { ascending: true });

        console.log(updatedEvents);

        setSpinWinEvents(updatedEvents || []);
      } else {
        setSpinWinEvents(eventsWithOrder);
      }
    } catch (err) {
      console.error("Failed to fetch spin & win events:", err);
    } finally {
      setLoading(false);
    }
  };

  const moveEvent = async (eventId, direction) => {
    // Find the current event and its index
    const currentIndex = spinWinEvents.findIndex(event => event.id === eventId);
    if (currentIndex === -1) return;

    // Determine the target index based on direction
    const targetIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

    // Check if the target index is valid
    if (targetIndex < 0 || targetIndex >= spinWinEvents.length) return;

    // Get the target event
    const targetEvent = spinWinEvents[targetIndex];
    const currentEvent = spinWinEvents[currentIndex];

    // Swap display orders
    const currentOrder = currentEvent.spin_win_display_order;
    const targetOrder = targetEvent.spin_win_display_order;

    // Update the database
    const updates = [
      supabase
        .from("Events")
        .update({ spin_win_display_order: targetOrder })
        .eq("id", currentEvent.id),
      supabase
        .from("Events")
        .update({ spin_win_display_order: currentOrder })
        .eq("id", targetEvent.id)
    ];

    try {
      await Promise.all(updates);
      // Refresh the events list
      fetchSpinWinEvents();
    } catch (error) {
      console.error("Error updating event order:", error);
    }
  };

  const handleViewDetails = (eventId) => {
    navigate(`/admin/event/${eventId}`);
  };

  const getStatusBadge = (event) => {
    if (event.spin_win_live) {
      return (
        <span className="px-2 py-1 text-sm font-medium rounded-full bg-green-100 text-green-800">
          Live
        </span>
      );
    } else {
      return (
        <span className="px-2 py-1 text-sm font-medium rounded-full bg-gray-100 text-gray-800">
          Offline
        </span>
      );
    }
  };

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit mb-8">
          Manage Spin & Win Events
        </h1>
        <button
          onClick={fetchSpinWinEvents}
          className="bg-white py-5 px-12 rounded-lg shadow-md font-semibold text-black !text-[20px] flex items-center justify-center gap-2"
        >
          <Icon icon="heroicons:arrow-path" className="h-9 w-9" />
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      ) : spinWinEvents.length === 0 ? (
        <div className="bg-white rounded-lg shadow p-6 text-center">
          <p className="text-gray-700 text-lg">No Spin & Win events found.</p>
          <p className="text-gray-500 mt-2">
            Create a new event and enable the Spin & Win feature to see it here.
          </p>
        </div>
      ) : (
        <Table className="mt-8">
          <Table.Head>
            <Table.HeadCell className="text-3xl w-[5%] capitalize">
              Order
            </Table.HeadCell>
            <Table.HeadCell className="text-3xl w-[10%] capitalize">
              Status
            </Table.HeadCell>
            <Table.HeadCell className="text-3xl w-[30%] capitalize">
              Event Name
            </Table.HeadCell>
            <Table.HeadCell className="text-3xl w-[15%] capitalize">
              Date
            </Table.HeadCell>
            <Table.HeadCell className="text-3xl w-[15%] capitalize">
              City
            </Table.HeadCell>
            <Table.HeadCell className="text-3xl w-[10%] capitalize">
              Spins
            </Table.HeadCell>
            <Table.HeadCell className="text-3xl w-[15%] capitalize">
              Actions
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {spinWinEvents.map((event, index) => (
              <Table.Row key={event.id} className={index % 2 === 0 ? "bg-white" : "bg-gray-200"}>
                <Table.Cell className="text-2xl">
                  {event.spin_win_display_order}
                </Table.Cell>
                <Table.Cell>
                  {getStatusBadge(event)}
                </Table.Cell>
                <Table.Cell className="text-2xl font-medium text-gray-900">
                  {event.name}
                </Table.Cell>
                <Table.Cell className="text-2xl">
                  {dayjs(event.datetime).format("DD MMM YYYY HH:mm")}
                </Table.Cell>
                <Table.Cell className="text-2xl">
                  {event.city}
                </Table.Cell>
                <Table.Cell className="text-2xl">
                  {event.spin_stats ? event.spin_stats.length : 0}
                </Table.Cell>
                <Table.Cell className="flex items-center gap-2">
                  <button
                    onClick={() => handleViewDetails(event.id)}
                    className="p-2 hover:bg-blue-100 rounded-lg transition-all"
                  >
                    <Icon icon="heroicons:eye" className="h-10 w-10" />
                  </button>
                  <button
                    onClick={() => moveEvent(event.id, 'up')}
                    disabled={index === 0}
                    className={`p-2 hover:bg-neutral-300 rounded-lg transition-all ${index === 0 ? 'opacity-50' : ''}`}
                  >
                    <Icon icon="heroicons:arrow-up" className="h-10 w-10" />
                  </button>
                  <button
                    onClick={() => moveEvent(event.id, 'down')}
                    disabled={index === spinWinEvents.length - 1}
                    className={`p-2 hover:bg-neutral-300 rounded-lg transition-all ${index === spinWinEvents.length - 1 ? 'opacity-50' : ''}`}
                  >
                    <Icon icon="heroicons:arrow-down" className="h-10 w-10" />
                  </button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
}