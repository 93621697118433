import { useState, useEffect } from "react";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import WhatsappLogo from "../assets/whatsapp-logo.svg";
import FeedbackIcon from "../assets/feedback-icon.svg";
import DealIcon from "../assets/deal-icon.png";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { adminUserIds } from "../helpers/helpers";
import CalendarIcon from "../assets/Calendar.svg";
import UserIcon from "../assets/Profile.svg";
import SpinAndWinIcon from "../assets/spin-and-win.png";
import { supabase } from "../supabaseClient";
import RequestIcon from "../assets/request-icon.png";
import InstagramIcon from "../assets/instagram-icon.png";
import TiktokIcon from "../assets/tiktok-icon.png";
import useWidth from "../hooks/useWidth";
import SuggestionsModal from "./SuggestionsModal";

const Sidebar = ({ language, setLanguage, isMobile, onClose }) => {
  const { width, breakpoints } = useWidth();
  const currentSlug = window.location.href.split("/").pop();
  const user = useSelector(selectUser);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [showSuggestionsModal, setShowSuggestionsModal] = useState(false);

  useEffect(() => {
    // Fetch cities that have active events
    supabase
      .from("Events")
      .select("city")
      .gte("datetime", new Date().toISOString())
      .then((res) => {
        if (res.data) {
          // Get unique cities with active events
          const uniqueCities = [
            ...new Set(res.data.map((event) => event.city)),
          ];

          // Fetch full city details for cities with active events
          supabase
            .from("City")
            .select("*")
            .in("name", uniqueCities)
            .order("index", { ascending: true })
            .then((cityRes) => {
              setCities(cityRes.data || []);
            });
        }
      });

    // Fetch categories that have active deals
    supabase
      .from("Deals")
      .select("category_id")
      .eq("active", true)
      .then((res) => {
        if (res.data) {
          // Get unique category IDs with active deals
          const uniqueCategoryIds = [
            ...new Set(res.data.map((deal) => deal.category_id)),
          ];

          // Fetch full category details for categories with active deals
          supabase
            .from("LifestyleCategory")
            .select("*")
            .in("id", uniqueCategoryIds)
            .order("index", { ascending: true })
            .then((categoryRes) => {
              setCategories(categoryRes.data || []);
            });
        }
      });
  }, []);

  const sidebarContent = (
    <div className="sidebar-menu h-auto mt-[32px]">
      <Link
        to="/spin-and-win"
        className="focus:text-white active:text-white font-normal hover:font-bold hover:text-white transition-all w-full mb-[16px] p-[12px] flex items-center justify-between border-gradient rounded-lg"
      >
        <div className="flex items-center justify-start gap-[12px]">
          <img
            src={SpinAndWinIcon}
            className="w-[30px] h-[30px]"
            alt="Win tickets"
          />
          <p className="text-white text-[16px]">Win tickets</p>
        </div>
      </Link>
      {!user.id ? (
        <Link
          to="/email-login"
          className={`mb-[12px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
            currentSlug === "email-login"
              ? "bg-[#D0ADFB20] !font-bold"
              : "hover:bg-[#D0ADFB20] hover:!font-bold"
          } hover:shadow-lg transition-all`}
        >
          <img src={UserIcon} className="h-[30px] w-[30px]" alt="User icon" />
          <p className="text-[16px]">Log in</p>
        </Link>
      ) : (
        <Link
          to="/profile"
          className={`mb-[12px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
            currentSlug === "profile"
              ? "bg-[#D0ADFB20] !font-bold"
              : "hover:bg-[#D0ADFB20] hover:!font-bold"
          } hover:shadow-lg transition-all`}
        >
          <img src={UserIcon} className="h-[30px] w-[30px]" alt="User icon" />
          <p className="text-[16px]">{user.first_name}</p>
        </Link>
      )}

      <hr className="my-[36px] border-t-[1px] border-[#292433] w-full" />

      {/* Events Button & Dropdown */}
      <button
        onClick={() => {
          setActiveDropdown(activeDropdown === "events" ? null : "events");
        }}
        className={`w-full hover:text-white mb-[16px] p-[12px] flex items-center justify-between gap-[12px] rounded-lg font-normal !text-white ${
          currentSlug.split("?")[0] === ""
            ? "bg-[#D0ADFB20] !font-bold"
            : "hover:bg-[#D0ADFB20] hover:!font-bold"
        } hover:shadow-lg transition-all`}
      >
        <div className="flex items-center gap-[12px]">
          <img src={CalendarIcon} className="h-[30px] w-[30px]" alt="DJ Set" />
          <p className="text-[16px]">Events</p>
        </div>
        <Icon
          icon={
            activeDropdown === "events"
              ? "heroicons:chevron-up"
              : "heroicons:chevron-down"
          }
          className="h-[20px] w-[20px]"
        />
      </button>

      <div
        className="overflow-hidden transition-all duration-300 pl-[32px]"
        style={{
          maxHeight: activeDropdown === "events" ? "300px" : "0px",
          opacity: activeDropdown === "events" ? 1 : 0,
        }}
      >
        <div className="max-h-[300px] overflow-y-auto pr-2 custom-scrollbar">
          <Link
            to={
              window.location.pathname.includes("admin")
                ? "/admin/events"
                : "/?city=all_cities"
            }
            onClick={() => {
              if (isMobile && onClose) {
                onClose();
                console.log("clicked");
              }
            }}
            className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg`}
          >
            <p className="text-[14px]">All Events</p>
          </Link>
          {cities.map((city) => (
            <Link
              key={city.id}
              to={
                window.location.pathname.includes("admin")
                  ? `/admin/events/${city.name}`
                  : `/?city=${city.name}`
              }
              onClick={() => {
                if (isMobile && onClose) {
                  onClose();
                }
              }}
              className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg`}
            >
              <p className="text-[14px]">{city.name}</p>
            </Link>
          ))}
        </div>
      </div>

      {/* Lifestyle Button & Dropdown */}
      <button
        onClick={() => {
          setActiveDropdown(activeDropdown === "deals" ? null : "deals");
        }}
        className={`w-full hover:text-white mb-[16px] p-[12px] flex items-center justify-between gap-[12px] rounded-lg font-normal !text-white ${
          currentSlug.split("?")[0] === "lifestyle"
            ? "bg-[#D0ADFB20] !font-bold"
            : "hover:bg-[#D0ADFB20] hover:!font-bold"
        } hover:shadow-lg transition-all`}
      >
        <div className="flex items-center gap-[12px]">
          <img
            src={DealIcon}
            className="h-[30px] w-[30px]"
            alt="Shopping bag"
          />
          <p className="text-[16px]">Deals</p>
        </div>
        <Icon
          icon={
            activeDropdown === "deals"
              ? "heroicons:chevron-up"
              : "heroicons:chevron-down"
          }
          className="h-[20px] w-[20px]"
        />
      </button>

      <div
        className="overflow-hidden transition-all duration-300 pl-[32px]"
        style={{
          maxHeight: activeDropdown === "deals" ? "300px" : "0px",
          opacity: activeDropdown === "deals" ? 1 : 0,
        }}
      >
        <div className="max-h-[300px] overflow-y-auto pr-2 custom-scrollbar">
          <Link
            to="/lifestyle"
            className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg`}
          >
            <p className="text-[14px]">All Deals</p>
          </Link>
          {cities.map((city) => (
            <Link
              key={city.id}
              to={`/lifestyle?city=${city.name}`}
              onClick={() => {
                if (isMobile && onClose) {
                  onClose();
                }
              }}
              className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg`}
            >
              <p className="text-[14px]">{city.name}</p>
            </Link>
          ))}
        </div>
      </div>

      <button
        onClick={() => setShowSuggestionsModal(true)}
        className="w-full transition-all mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg !text-white hover:text-white hover:!font-bold"
      >
        <img
          src={RequestIcon}
          className="h-[30px] w-[30px]"
          alt="Request event"
        />
        <p className="text-[16px]">Request event</p>
      </button>
      {adminUserIds.includes(user.id) ? (
        <Link
          to="/admin/events"
          className=" mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg !text-white"
        >
          <Icon
            icon="heroicons:cog-6-tooth"
            className="h-[30px] w-[30px]"
            alt="Feedback icon"
          />
          <p className="text-[16px]">Admin panel</p>
        </Link>
      ) : (
        <Link
          to="https://wa.me/31617476764"
          target="_blank"
          // to="/"
          className="transition-all mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg !text-white hover:text-white hover:!font-bold"
        >
          <img
            src={FeedbackIcon}
            className="h-[30px] w-[30px]"
            alt="Feedback icon"
          />
          <p className="text-[16px]">Give feedback</p>
        </Link>
      )}
      {/* <div className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] !text-white rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg hover:cursor-pointer">
        <img src={LanguageIcon} className="h-[30px] w-[30px] text-white" />
        <select
          value={"language"}
          onChange={(e) => {
            setLanguage(e.target.value);
          }}
          className="focus:ring-0 !outline-none !border-none w-[90%] cursor-pointer pl-[12px] text-[16px] rounded-lg font-normal !text-white bg-transparent appearance-none"
        >
          <option value="language" disabled>
            Language
          </option>
          <option value="nl">Nederlands</option>
          <option value="en">English</option>
        </select>
      </div> */}
      <div className="mt-[48px] min-[2100px]:hidden">
        <Link
          to="https://www.tiktok.com/@22night.worldwide"
          target="_blank"
          className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
        >
          <img
            src={TiktokIcon}
            className="h-[30px] w-[30px]"
            alt="Tiktok logo"
          />
          <p className="text-[16px]">Tiktok</p>
        </Link>
        <Link
          to="https://www.instagram.com/22night.worldwide/"
          target="_blank"
          className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
        >
          <img
            src={InstagramIcon}
            className="h-[30px] w-[30px]"
            alt="Instagram logo"
          />
          <p className="text-[16px]">Instagram</p>
        </Link>
        <Link
          to="/community-chats"
          className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
        >
          <img
            src={WhatsappLogo}
            className="h-[30px] w-[30px]"
            alt="Whatsapp logo"
          />
          <p className="text-[16px]">Join community chat</p>
        </Link>
      </div>
    </div>
  );

  // Mobile layout
  if (width < breakpoints.lg) {
    return (
      <div className="flex flex-col h-full">
        <SuggestionsModal
          show={showSuggestionsModal}
          setShow={setShowSuggestionsModal}
        />
        <div className="flex justify-center mt-[32px]">
          <Link to="/">
            <Logo className="!h-auto !w-[50px]" />
          </Link>
        </div>
        <div className="flex-1 overflow-y-auto py-[32px] px-[24px]">
          <div className="sidebar-menu h-auto mt-[32px]">
            <Link
              to="/spin-and-win"
              className="focus:text-white active:text-white font-normal hover:font-bold hover:text-white transition-all w-full mb-[16px] p-[12px] flex items-center justify-between border-gradient rounded-lg"
            >
              <div className="flex items-center justify-start gap-[12px]">
                <img
                  src={SpinAndWinIcon}
                  className="w-[30px] h-[30px]"
                  alt="Win tickets"
                />
                <p className="text-white text-[16px]">Win tickets</p>
              </div>
              {/* <div className="leading-[16px] text-white py-2 px-4 bg-[#5559DF60] font-medium rounded-[6px] text-[13px]">
                Coming soon
              </div> */}
            </Link>
            {!user.id ? (
              <Link
                to="/email-login"
                className={`mb-[12px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
                  currentSlug === "email-login"
                    ? "bg-[#D0ADFB20] !font-bold"
                    : "hover:bg-[#D0ADFB20] hover:!font-bold"
                } hover:shadow-lg transition-all`}
              >
                <img
                  src={UserIcon}
                  className="h-[30px] w-[30px]"
                  alt="User icon"
                />
                <p className="text-[16px]">Log in</p>
              </Link>
            ) : (
              <Link
                to="/profile"
                className={`mb-[12px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
                  currentSlug === "profile"
                    ? "bg-[#D0ADFB20] !font-bold"
                    : "hover:bg-[#D0ADFB20] hover:!font-bold"
                } hover:shadow-lg transition-all`}
              >
                <img
                  src={UserIcon}
                  className="h-[30px] w-[30px]"
                  alt="User icon"
                />
                <p className="text-[16px]">{user.first_name}</p>
              </Link>
            )}

            <hr className="my-[36px] border-t-[1px] border-[#292433] w-full" />

            {/* Events Button & Dropdown */}
            <button
              onClick={() => {
                setActiveDropdown(
                  activeDropdown === "events" ? null : "events"
                );
              }}
              className={`w-full hover:text-white mb-[16px] p-[12px] flex items-center justify-between gap-[12px] rounded-lg font-normal !text-white ${
                currentSlug.split("?")[0] === ""
                  ? "bg-[#D0ADFB20] !font-bold"
                  : "hover:bg-[#D0ADFB20] hover:!font-bold"
              } hover:shadow-lg transition-all`}
            >
              <div className="flex items-center gap-[12px]">
                <img
                  src={CalendarIcon}
                  className="h-[30px] w-[30px]"
                  alt="DJ Set"
                />
                <p className="text-[16px]">Events</p>
              </div>
              <Icon
                icon={
                  activeDropdown === "events"
                    ? "heroicons:chevron-up"
                    : "heroicons:chevron-down"
                }
                className="h-[20px] w-[20px]"
              />
            </button>

            <div
              className="overflow-hidden transition-all duration-300 pl-[32px]"
              style={{
                maxHeight: activeDropdown === "events" ? "300px" : "0px",
                opacity: activeDropdown === "events" ? 1 : 0,
              }}
            >
              <div className="max-h-[300px] overflow-y-auto pr-2 custom-scrollbar">
                <Link
                  to={
                    window.location.pathname.includes("admin")
                      ? "/admin/events"
                      : "/?city=all_cities"
                  }
                  onClick={() => {
                    if (isMobile && onClose) {
                      onClose();
                    }
                  }}
                  className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg`}
                >
                  <p className="text-[14px]">All Events</p>
                </Link>
                {cities.map((city) => (
                  <Link
                    key={city.id}
                    to={
                      window.location.pathname.includes("admin")
                        ? `/admin/events/${city.name}`
                        : `/?city=${city.name}`
                    }
                    onClick={() => {
                      if (isMobile && onClose) {
                        onClose();
                      }
                    }}
                    className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg`}
                  >
                    <p className="text-[14px]">{city.name}</p>
                  </Link>
                ))}
              </div>
            </div>

            {/* Lifestyle Button & Dropdown */}
            <button
              onClick={() => {
                setActiveDropdown(activeDropdown === "deals" ? null : "deals");
              }}
              className={`w-full hover:text-white mb-[16px] p-[12px] flex items-center justify-between gap-[12px] rounded-lg font-normal !text-white ${
                currentSlug.split("?")[0] === "lifestyle"
                  ? "bg-[#D0ADFB20] !font-bold"
                  : "hover:bg-[#D0ADFB20] hover:!font-bold"
              } hover:shadow-lg transition-all`}
            >
              <div className="flex items-center gap-[12px]">
                <img
                  src={DealIcon}
                  className="h-[30px] w-[30px]"
                  alt="Shopping bag"
                />
                <p className="text-[16px]">Deals</p>
              </div>
              <Icon
                icon={
                  activeDropdown === "deals"
                    ? "heroicons:chevron-up"
                    : "heroicons:chevron-down"
                }
                className="h-[20px] w-[20px]"
              />
            </button>

            <div
              className="overflow-hidden transition-all duration-300 pl-[32px]"
              style={{
                maxHeight: activeDropdown === "deals" ? "300px" : "0px",
                opacity: activeDropdown === "deals" ? 1 : 0,
              }}
            >
              <div className="max-h-[300px] overflow-y-auto pr-2 custom-scrollbar">
                <Link
                  to="/lifestyle"
                  onClick={() => {
                    if (isMobile && onClose) {
                      onClose();
                    }
                  }}
                  className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg`}
                >
                  <p className="text-[14px]">All Deals</p>
                </Link>
                {cities.map((city) => (
                  <Link
                    key={city.id}
                    to={`/lifestyle?city=${city.name}`}
                    onClick={() => {
                      if (isMobile && onClose) {
                        onClose();
                      }
                    }}
                    className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg`}
                  >
                    <p className="text-[14px]">{city.name}</p>
                  </Link>
                ))}
              </div>
            </div>

            <button
              onClick={() => {
                setShowSuggestionsModal(true);
                onClose();
              }}
              className="w-full transition-all mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg !text-white hover:text-white hover:!font-bold"
            >
              <img
                src={RequestIcon}
                className="h-[30px] w-[30px]"
                alt="Request event"
              />
              <p className="text-[16px]">Request event</p>
            </button>
            {adminUserIds.includes(user.id) ? (
              <Link
                to="/admin/events"
                className=" mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg !text-white"
              >
                <Icon
                  icon="heroicons:cog-6-tooth"
                  className="h-[30px] w-[30px]"
                  alt="Feedback icon"
                />
                <p className="text-[16px]">Admin panel</p>
              </Link>
            ) : (
              <Link
                to="https://wa.me/31617476764"
                target="_blank"
                // to="/"
                className="transition-all mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg !text-white hover:text-white hover:!font-bold"
              >
                <img
                  src={FeedbackIcon}
                  className="h-[30px] w-[30px]"
                  alt="Feedback icon"
                />
                <p className="text-[16px]">Give feedback</p>
              </Link>
            )}
            <div className="w-full mt-[36px]">
              <Link
                to="https://www.tiktok.com/@22night.worldwide"
                target="_blank"
                className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
              >
                <img
                  src={TiktokIcon}
                  className="h-[30px] w-[30px]"
                  alt="Tiktok logo"
                />
                <p className="text-[16px]">Tiktok</p>
              </Link>
              <Link
                to="https://www.instagram.com/22night.worldwide/"
                target="_blank"
                className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
              >
                <img
                  src={InstagramIcon}
                  className="h-[30px] w-[30px]"
                  alt="Instagram logo"
                />
                <p className="text-[16px]">Instagram</p>
              </Link>
              <Link
                to="/community-chats"
                className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
              >
                <img
                  src={WhatsappLogo}
                  className="h-[30px] w-[30px]"
                  alt="Whatsapp logo"
                />
                <p className="text-[16px]">Join community chat</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Desktop layout
  return (
    <div>
      <SuggestionsModal
        show={showSuggestionsModal}
        setShow={setShowSuggestionsModal}
      />
      <div className="max-[2100px]:overflow-y-auto fixed top-0 z-[999] h-screen bg-[#00000040] py-[64px] px-[24px] text-white lg:w-[350px] shadow-lg lg:border-r lg:border-[#AD7AEF80]">
        <Link to="/" className="">
          <div className="ml-6">
            <Logo />
          </div>
        </Link>
        {sidebarContent}
        <div className="max-[2100px]:hidden w-full absolute bottom-[40px] left-0 px-[24px]">
          <Link
            to="https://www.tiktok.com/@22night.worldwide"
            target="_blank"
            className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
          >
            <img
              src={TiktokIcon}
              className="h-[30px] w-[30px]"
              alt="Tiktok logo"
            />
            <p className="text-[16px]">Tiktok</p>
          </Link>
          <Link
            to="https://www.instagram.com/22night.worldwide/"
            target="_blank"
            className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
          >
            <img
              src={InstagramIcon}
              className="h-[30px] w-[30px]"
              alt="Instagram logo"
            />
            <p className="text-[16px]">Instagram</p>
          </Link>
          <Link
            to="/community-chats"
            className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
          >
            <img
              src={WhatsappLogo}
              className="h-[30px] w-[30px]"
              alt="Whatsapp logo"
            />
            <p className="text-[16px]">Join community chat</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
