import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPublicImageUrl } from "../helpers/helpers";
import Icon from "./Icon";
import DealCard from "./DealCard";

export default function DealOverview({ deals, isCarousel, title }) {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollButtons = () => {
    const container = scrollContainerRef.current;
    if (container) {
      // Check if we can scroll left
      setCanScrollLeft(container.scrollLeft > 0);

      // Check if we can scroll right
      const maxScroll = container.scrollWidth - container.clientWidth;
      setCanScrollRight(container.scrollLeft < maxScroll - 1); // -1 for rounding errors
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScrollButtons);
      // Initial check
      checkScrollButtons();
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScrollButtons);
      }
    };
  }, []);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth * 0.8;
      const newScrollPosition = container.scrollLeft + (direction === 'right' ? scrollAmount : -scrollAmount);
      container.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container && window.innerWidth <= 768) {
      let scrollTimeout;

      const handleScrollEnd = () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          // Calculate the closest snap point
          const itemWidth = window.innerWidth;
          const scrollLeft = container.scrollLeft;
          const closestSnapPoint = Math.round(scrollLeft / itemWidth) * itemWidth;

          // Snap to the closest point
          container.scrollTo({
            left: closestSnapPoint,
            behavior: 'smooth'
          });
        }, 150); // Small delay after scrolling stops
      };

      container.addEventListener('scroll', handleScrollEnd);

      return () => {
        container.removeEventListener('scroll', handleScrollEnd);
        clearTimeout(scrollTimeout);
      };
    }
  }, []);

  return (
    <section className="max-lg:px-[16px] relative">
      <h1 className="w-full lg:pt-[40px] pt-[24px] pb-[10px] text-start text-[22px] leading-[26px]">
        {title}
      </h1>

      {deals.length === 0 && (
        <div className="max-lg:h-[110px] lg:h-[150px] text-center event-content text-white doubles flex flex-col items-center justify-center">
          <h2 className="text-[18px] text-white font-[600] leading-[24px] tracking-normal">
            Is your deal not listed?
          </h2>
          <p className="mt-1 text-[14px] lg:text-[16px] font-normal leading-[22px] tracking-normal">
            Let us know which deal you are looking for.
          </p>
        </div>
      )}

      {isCarousel ? (
        <div className="relative">
          {deals.length > 1 && (
            <>
              <button
                onClick={() => scroll('left')}
                disabled={!canScrollLeft}
                className={`absolute left-6 top-1/2 z-10 p-2 rounded-full transform -translate-y-1/2 shadow-lg transition-all duration-200 bg-white
                  ${canScrollLeft
                    ? 'hover:bg-gray-50 opacity-100'
                    : 'cursor-not-allowed opacity-40'
                  }`}
                aria-label="Scroll left"
              >
                <Icon
                  icon="heroicons:arrow-long-left"
                  className="h-[25px] w-[25px] text-gray-800"
                />
              </button>
              <button
                onClick={() => scroll('right')}
                disabled={!canScrollRight}
                className={`absolute right-6 top-1/2 z-10 p-2 rounded-full transform -translate-y-1/2 shadow-lg transition-all duration-200 bg-white
                  ${canScrollRight
                    ? 'hover:bg-gray-50 opacity-100'
                    : 'cursor-not-allowed opacity-40'
                  }`}
                aria-label="Scroll right"
              >
                <Icon
                  icon="heroicons:arrow-long-right"
                  className="h-[25px] w-[25px] text-gray-800"
                />
              </button>
            </>
          )}

          <div
            ref={scrollContainerRef}
            className="flex overflow-x-auto overflow-y-visible gap-6 scroll-smooth hide-scrollbar"
            style={{ scrollSnapType: 'x mandatory', touchAction: 'pan-x' }}
          >
            {deals.filter(deal => deal.active).map((item, index) => (
              <div
                key={index}
                className="flex-none w-[90vw] max-w-[400px] scroll-snap-align-start py-1 lg:pl-2"
                style={{ scrollSnapAlign: 'start', userSelect: 'none' }}
              >
                <DealCard deal={item} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 w-full max-lg:!gap-x-[0px] gap-[20px] pb-[80px]">
          {deals.filter(deal => deal.active).map((item, index) => (
            <div key={index} className="w-full">
              <DealCard deal={item} className="h-[220px]" />
            </div>
          ))}
        </div>
      )}

      <style>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
          -webkit-user-select: none;
          user-select: none;
        }
      `}</style>
    </section>
  );
}
