import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import EventLoginForm from "./EventLoginForm";
import SpinningWheel from "./SpinningWheel";
import { useTranslation } from "react-i18next";

const SpinningWheelSection = ({ event, redirectUrl }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [hasSpun, setHasSpun] = useState(false);
  const [spinInProgress, setSpinInProgress] = useState(false);

  if (!user.id) {
    return <EventLoginForm redirectUrl={redirectUrl} />;
  }

  return (
    <div className="z-[10000] flex items-center flex-col social-proof-card py-[24px] px-[16px] mx-6 lg:mt-20 mt-12 max-w-[95vw] lg:w-[450px]">
      <div className="flex items-center justify-between mb-[30px] w-full">
        <div className="text-[26px] font-semibold text-white text-start">
          {t("spin_win_tickets", "Spin & win tickets")}
        </div>
        <div className="ml-2 text-[16px] bg-[#918D99] text-clip px-[8px] py-[2px] font-bold rounded-[4px] uppercase">
          Beta
        </div>
      </div>

      <SpinningWheel
        hasSpun={hasSpun}
        setHasSpun={setHasSpun}
        event={event}
        spinInProgress={spinInProgress}
        setSpinInProgress={setSpinInProgress}
      />

      {!hasSpun && !spinInProgress ? (
        <div className="mt-[30px]">
          <div className="flex items-center justify-between">
            <div className="text-[22px] font-semibold text-white">
              Hoe werkt het?
            </div>
          </div>
          <div className="mt-[12px] grid grid-cols-2 gap-4">
            <div className="bg-[#1D1726] p-4 rounded-lg border border-[#AD7AEF40]">
              <h3 className="text-[14px] font-bold text-white mb-2">
                {t("spin_the_wheel", "Spin the wheel")}
              </h3>
              <p className="text-[13px] text-neutral-300">
                {t(
                  "spin_wheel_instruction",
                  "Druk op de knop en maak direct of je een prijs wint."
                )}
              </p>
            </div>
            <div className="bg-[#1D1726] p-4 rounded-lg border border-[#AD7AEF40]">
              <h3 className="text-[14px] font-bold text-white mb-2">
                {t("win_prizes", "Win te gekke prijzen")}
              </h3>
              <p className="text-[13px] text-neutral-300">
                {t(
                  "win_prizes_description",
                  "Maak kans op tickets, speciale acties, meet & greets en vele verrassingen."
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        spinInProgress && (
          <div className="mt-[20px] text-left w-full">
            <div className="text-left text-[22px] font-semibold text-white">
              Aan het spinnen...
            </div>
            <div className="text-[16px] text-neutral-200 mt-2">
              Okaaayyyy, let's go!
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default SpinningWheelSection;
