import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "../components/Icon";
import { getPublicImageUrl } from "../helpers/helpers";
import { v4 as uuidv4 } from "uuid";
import SuccessPopup from "../components/SuccessPopup";
import { Table } from "flowbite-react";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Button,
} from "@chakra-ui/react";
import UploadCodesModal from "../components/UploadCodesModal";

export default function ManageDealDetail() {
  const { id } = useParams();

  const [deal, setDeal] = useState();

  const navigate = useNavigate();

  // Can be details, signups, or codes
  const [activeTab, setActiveTab] = useState("details");
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [couponCodes, setCouponCodes] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);

  useEffect(() => {
    supabase
      .from("Deals")
      .select("*, signups:LifestyleSignUp(*)")
      .eq("id", id)
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          setDeal({ ...data[0] });
          // Set selected cities when deal data is loaded
          if (data[0]?.cities) {
            setSelectedCities(
              data[0].cities.split(",").map((city) => city.trim())
            );
          }
        }
      });

    supabase
      .from("LifestyleCategory")
      .select("*")
      .then((res) => {
        setCategories(res.data);
      });

    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data.map((city) => city.name));
      });
  }, [id]);

  useEffect(() => {
    if (
      deal?.redeem_category === "unique_coupon_code" &&
      activeTab === "codes"
    ) {
      supabase
        .from("UniqueCouponCode")
        .select("*")
        .eq("deal_id", deal.id)
        .order("created_at", { ascending: false })
        .then(({ data, error }) => {
          if (!error) {
            setCouponCodes(data);
          }
        });
    }
  }, [deal?.id, deal?.redeem_category, activeTab]);

  const saveDeal = () => {
    const optionalFields = [
      "advantage_1_title",
      "advantage_1_description",
      "advantage_2_title",
      "advantage_2_description",
      "advantage_3_title",
      "advantage_3_description",
      "ticket_link",
      "coupon_code",
    ];

    // Error handling
    let hasError = false;
    setError([]); // Reset errors at start

    Object.entries(deal).forEach(([key, value]) => {
      if (value === "" && !optionalFields.includes(key)) {
        console.log(`Validation failed for field: ${key} - value is empty`);
        setError((prev) => [...prev, key]);
        hasError = true;
      }
    });

    if (hasError) {
      console.log("Form validation failed - stopping save");
      return;
    }

    const { signups, ...dealToSave } = deal;

    console.log("Saving deal with data:", dealToSave);

    supabase
      .from("Deals")
      .update(dealToSave)
      .eq("id", deal.id)
      .then((response) => {
        if (response.error) {
          console.error("Save failed:", response.error);
        } else {
          console.log("Save successful");
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        }
      });
  };

  const uploadImage = (file) => {
    const uuid = uuidv4();

    const asset_name = `${uuid}-${file.name}`;

    supabase.storage.from("images").upload(asset_name, file, { upsert: true });

    setDeal({ ...deal, image_filename: asset_name });
  };

  const validateAndUploadImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width === 650 && img.height === 350) {
          // Proceed with upload if the dimensions are correct
          uploadImage(file);
        } else {
          setError((prev) => {
            return [...prev, "image_filename"];
          });
          alert(
            `Image must be 650px by 350px! The image you uploaded is ${img.width}px by ${img.height}px.`
          );
        }

        URL.revokeObjectURL(objectUrl); // Clean up the object URL
      };

      img.src = objectUrl;
    }
  };

  const formatDateTime = (datetime) => {
    return dayjs(datetime).local("nl").format("D MMMM YYYY");
  };

  return (
    <>
      {deal && (
        <div className="px-[15px] lg:px-[100px] py-[50px]">
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center justify-start gap-5">
              <button
                onClick={() => navigate(-1)}
                className="p-2 hover:bg-white/20 rounded-lg"
              >
                <Icon
                  icon="heroicons:arrow-long-left"
                  className="text-white w-12 h-12"
                />
              </button>
              <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
                {deal?.name}
              </h1>
            </div>
          </div>
          <div className="flex justify-start items-center gap-3 mt-12 text-[18px]">
            <button
              onClick={() => setActiveTab("details")}
              className={`border-b-2 p-3 text-white ${
                activeTab === "details" ? "border-white" : "border-transparent"
              }`}
            >
              Details
            </button>
            <button
              onClick={() => setActiveTab("signups")}
              className={`border-b-2 p-3 text-white ${
                activeTab === "signups" ? "border-white" : "border-transparent"
              }`}
            >
              Signups ({deal?.signups?.length})
            </button>
            {deal?.redeem_category === "unique_coupon_code" && (
              <button
                onClick={() => setActiveTab("codes")}
                className={`border-b-2 p-3 text-white ${
                  activeTab === "codes" ? "border-white" : "border-transparent"
                }`}
              >
                Coupon Codes
              </button>
            )}
          </div>
          {activeTab === "details" && (
            <div className="mt-12">
              <h2 className="text-white text-[25px] font-semibold">Details</h2>
              <div className="">
                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Deal name
                  </label>
                  <input
                    placeholder="Deal name"
                    value={deal.name}
                    type="text"
                    className={`dark-input ${
                      error.includes("name") ? "border-red-500" : ""
                    }`}
                    onChange={(e) => {
                      setDeal({ ...deal, name: e.target.value });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Display order (lower numbers appear first)
                  </label>
                  <input
                    placeholder="0"
                    value={deal.index}
                    type="number"
                    className={`dark-input ${
                      error.includes("index")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setDeal({
                        ...deal,
                        index: parseInt(e.target.value) || 0,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Currency
                  </label>
                  <select
                    className={`dark-input`}
                    value={deal.currency}
                    onChange={(e) => {
                      setDeal({ ...deal, currency: e.target.value });
                    }}
                  >
                    <option value="€">€</option>
                    <option value="Rp">Rp</option>
                    <option value="£">£</option>
                  </select>
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Deal teaser
                  </label>
                  <textarea
                    value={deal.teaser}
                    placeholder="Deal teaser"
                    rows="5"
                    className={`dark-input ${
                      error.includes("teaser") ? "border-red-500" : ""
                    }`}
                    onChange={(e) => {
                      setDeal({ ...deal, teaser: e.target.value });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Category
                  </label>
                  <select
                    className={`dark-input ${
                      error.includes("category")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    value={
                      categories.find(
                        (category) => category.id === deal.category_id
                      )?.id
                    }
                    onChange={(e) => {
                      setDeal({ ...deal, category_id: e.target.value });
                    }}
                  >
                    <option value="">Choose deal category</option>
                    {categories.map((category) => {
                      return (
                        <option value={category.id}>{category.name}</option>
                      );
                    })}
                  </select>
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Redeem Category
                  </label>
                  <select
                    className={`dark-input`}
                    value={deal.redeem_category}
                    onChange={(e) => {
                      setDeal({ ...deal, redeem_category: e.target.value });
                    }}
                  >
                    <option value="">Choose redeem category</option>
                    <option value="link">Link</option>
                    <option value="general_coupon_code">
                      General Coupon Code
                    </option>
                    <option value="unique_coupon_code">
                      Unique Coupon Code
                    </option>
                  </select>
                </div>

                {/* Show ticket link input if link or general_coupon_code is selected */}
                {(deal.redeem_category === "link" || deal.redeem_category === "general_coupon_code") && (
                  <div className="mt-6 flex flex-col justify-start gap-1">
                    <label className="text-xl text-neutral-500 mb-0">
                      Ticket link {deal.redeem_category === "general_coupon_code" && "(optional)"}
                    </label>
                    <input
                      placeholder="Ticket link"
                      value={deal.ticket_link}
                      type="text"
                      className={`dark-input ${
                        error.includes("ticket_link")
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      onChange={(e) => {
                        setDeal({ ...deal, ticket_link: e.target.value });
                      }}
                    />
                  </div>
                )}

                {/* Show coupon code input if general_coupon_code is selected */}
                {deal.redeem_category === "general_coupon_code" && (
                  <div className="mt-6 flex flex-col justify-start gap-1">
                    <label className="text-xl text-neutral-500 mb-0">
                      Coupon Code
                    </label>
                    <input
                      placeholder="Enter coupon code"
                      value={deal.coupon_code}
                      type="text"
                      className={`dark-input ${
                        error.includes("coupon_code")
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      onChange={(e) => {
                        setDeal({ ...deal, coupon_code: e.target.value });
                      }}
                    />
                  </div>
                )}

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Deal of the week
                  </label>
                  <input
                    checked={deal.dealOfTheWeek}
                    type="checkbox"
                    className={`dark-checkbox ${
                      error.includes("dealOfTheWeek")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setDeal({
                        ...deal,
                        dealOfTheWeek: e.target.checked,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Sold out
                  </label>
                  <input
                    checked={deal.sold_out}
                    type="checkbox"
                    className={`dark-checkbox ${
                      error.includes("sold_out")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setDeal({ ...deal, sold_out: e.target.checked });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Original price
                  </label>
                  <input
                    value={deal.original_price}
                    type="text"
                    placeholder="50"
                    className={`dark-input ${
                      error.includes("original_price")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setDeal({
                        ...deal,
                        original_price: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Our price
                  </label>
                  <input
                    value={deal.our_price}
                    type="text"
                    placeholder="22.50"
                    className={`dark-input ${
                      error.includes("our_price")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      setDeal({ ...deal, our_price: e.target.value });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Cities
                  </label>
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      className="btn btn-dropdown dropdown-toggle text-left text-2xl text-black bg-white border-2 p-4 rounded-lg"
                      rightIcon={
                        <Icon
                          icon="heroicons:chevron-down"
                          className="w-6 h-6"
                        />
                      }
                    >
                      <span className="selectedOption">
                        {selectedCities.length > 0
                          ? `${selectedCities.length} cities selected`
                          : "Select cities"}
                      </span>
                    </MenuButton>
                    <MenuList
                      className="menu-list menu-checkbox-list"
                      maxH="300px"
                      overflowY="auto"
                    >
                      <MenuOptionGroup
                        type="checkbox"
                        value={selectedCities}
                        onChange={(values) => {
                          setSelectedCities(values);
                          setDeal({
                            ...deal,
                            cities: values.join(", "),
                          });
                        }}
                      >
                        {cities.map((city) => (
                          <MenuItemOption key={city} value={city}>
                            {city}
                          </MenuItemOption>
                        ))}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </div>

                {/* Deal image */}
                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-neutral-500 mb-0">
                    Deal image (ignore that it doesnt show the title)
                  </label>
                  <input
                    type="file"
                    accept=".jpeg, .jpg, .png"
                    className={`dark-input ${
                      error.includes("image_filename")
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onChange={(e) => {
                      validateAndUploadImage(e);
                    }}
                  />
                  {deal.image_filename && (
                    <img
                      src={getPublicImageUrl(deal.image_filename)}
                      className="w-[200px] h-auto"
                      alt={""}
                    />
                  )}
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-black mb-0">
                    Advantage 1 title
                  </label>
                  <textarea
                    value={deal.advantage_1_title}
                    placeholder="Advantage"
                    rows="1"
                    className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setDeal({
                        ...deal,
                        advantage_1_title: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-black mb-0">
                    Advantage 1 description
                  </label>
                  <textarea
                    value={deal.advantage_1_description}
                    placeholder="Advantage"
                    rows="3"
                    className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                    onChange={(e) => {
                      setDeal({
                        ...deal,
                        advantage_1_description: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-black mb-0">
                    Advantage 2 title
                  </label>
                  <textarea
                    value={deal.advantage_2_title}
                    placeholder="Advantage"
                    rows="1"
                    className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                    onChange={(e) => {
                      setDeal({
                        ...deal,
                        advantage_2_title: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-black mb-0">
                    Advantage 2 description
                  </label>
                  <textarea
                    value={deal.advantage_2_description}
                    placeholder="Advantage"
                    rows="3"
                    className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                    onChange={(e) => {
                      setDeal({
                        ...deal,
                        advantage_2_description: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-black mb-0">
                    Advantage 3 title
                  </label>
                  <textarea
                    value={deal.advantage_3_title}
                    placeholder="Advantage"
                    rows="1"
                    className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                    onChange={(e) => {
                      setDeal({
                        ...deal,
                        advantage_3_title: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-6 flex flex-col justify-start gap-1">
                  <label className="text-xl text-black mb-0">
                    Advantage 3 description
                  </label>
                  <textarea
                    value={deal.advantage_3_description}
                    placeholder="Advantage"
                    rows="3"
                    className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                    onChange={(e) => {
                      setDeal({
                        ...deal,
                        advantage_3_description: e.target.value,
                      });
                    }}
                  />
                </div>

                {error.length > 0 && (
                  <p className="text-3xl font-semibold mt-5 text-red-500">
                    Please fill in all fields correctly.
                  </p>
                )}

                <div className="max-lg:mb-[50px] flex items-center justify-end w-full pt-9">
                  <button
                    onClick={() => {
                      saveDeal();
                    }}
                    className="text-white transition-all px-6 lg:px-12 py-3 rounded-lg text-3xl font-semibold hover:bg-[#38c28f] bg-[#07af71]"
                  >
                    Save deal
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeTab === "signups" && (
            <div className="mt-12">
              <Table className="mt-8 bg-black">
                <Table.Head>
                  <Table.HeadCell className="text-3xl capitalize">
                    Date of signup
                  </Table.HeadCell>
                  <Table.HeadCell className="text-3xl capitalize">
                    Name
                  </Table.HeadCell>
                  <Table.HeadCell className="text-3xl capitalize">
                    Email
                  </Table.HeadCell>
                  <Table.HeadCell className="text-3xl capitalize">
                    Date of birth
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {deal?.signups?.map((signup) => {
                    return (
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {formatDateTime(signup.created_at)}
                        </Table.Cell>
                        <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {`${signup.first_name} ${signup.last_name}`}
                        </Table.Cell>
                        <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {signup.email}
                        </Table.Cell>
                        <Table.Cell className="p-8 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {signup.date_of_birth}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          )}
          {activeTab === "codes" && (
            <div className="mt-12">
              <div className="flex justify-between items-center">
                <h2 className="text-white text-[25px] font-semibold">
                  Coupon Codes
                </h2>
                <button
                  onClick={() => setShowUploadModal(true)}
                  className="text-white transition-all px-6 py-3 rounded-lg text-xl font-semibold hover:bg-[#38c28f] bg-[#07af71]"
                >
                  Upload Codes
                </button>
              </div>

              <div className="mt-6 bg-white rounded-lg overflow-hidden">
                <Table>
                  <Table.Head>
                    <Table.HeadCell>Code</Table.HeadCell>
                    <Table.HeadCell>Claimed by</Table.HeadCell>
                  </Table.Head>
                  <Table.Body>
                    {couponCodes.map((code) => (
                      <Table.Row key={code.id}>
                        <Table.Cell>{code.code}</Table.Cell>
                        <Table.Cell>{code.email || "Not claimed"}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          )}
        </div>
      )}
      <SuccessPopup show={success} message="Event updated successfully" />
      <UploadCodesModal
        show={showUploadModal}
        setShow={setShowUploadModal}
        dealId={deal?.id}
        onSuccess={() => {
          // Refresh the coupon codes list
          supabase
            .from("UniqueCouponCode")
            .select("*")
            .eq("deal_id", deal.id)
            .order("created_at", { ascending: false })
            .then(({ data, error }) => {
              if (!error) {
                setCouponCodes(data);
              }
            });
        }}
      />
    </>
  );
}
