export const cities = [
      "Aadorp",
      "Aagtdorp",
      "Aagtekerke",
      "Aalbeek",
      "Aalden",
      "Aaldonk",
      "Aalsmeer",
      "Aalst (Buren)",
      "Aalst (Noord-Brabant)",
      "Aalst (Zaltbommel)",
      "Aalsum (Noardeast-Fryslân)",
      "Aalsum (Westerkwartier)",
      "Aalten",
      "Aam",
      "Aan de Rijksweg",
      "Aan de Zuwe",
      "Aan het Broek",
      "Aardenburg",
      "Aarlanderveen",
      "Aarle",
      "Aarle-Rixtel",
      "Aartswoud",
      "Aasterberg",
      "Abbega",
      "Abbegaasterketting",
      "Abbekerk",
      "Abbekerkeweere",
      "Abbekinderen",
      "Abbenbroek",
      "Abbenes",
      "Abbengawier",
      "Abbestede",
      "Abbeweer",
      "Abbewier",
      "Abcoude",
      "Abcoven",
      "Abeltjeshuis",
      "Absdale",
      "Abshoven",
      "Abtswoude",
      "Achlum",
      "Acht",
      "Achter het Klooster",
      "Achter 't Hout",
      "Achterberg",
      "Achterbos",
      "Achterbosch (Asten)",
      "Achterbosch (Laarbeek)",
      "Achterbroek (Noord-Brabant)",
      "Achterbroek (Zuid-Holland)",
      "Achterdichting (Katwoude)",
      "Achterdichting (Oostzaan)",
      "Achterdiep",
      "Achterdijk (Noord-Brabant)",
      "Achterdijk (Utrecht)",
      "Achter-Drempt",
      "Achtereind",
      "Achterhoek (Nijkerk)",
      "Achterhoek (Overijssel)",
      "Achter-Lindt",
      "Achtersloot",
      "Achterste Brug",
      "Achterste Diesdonk",
      "Achterste Erm",
      "Achterste Hees",
      "Achterste Heide",
      "Achterste Heikant",
      "Achterste Hermalen",
      "Achterste Rith",
      "Achter-Thesinge",
      "Achterveld (Barneveld)",
      "Achterveld (Leusden)",
      "Achterwetering",
      "Achthoven (Leiderdorp)",
      "Achthoven (Montfoort)",
      "Achthoven (Vijfheerenlanden)",
      "Achthuizen",
      "Achtmaal",
      "Achttienhoven (Utrecht)",
      "Achttienhoven (Zuid-Holland)",
      "Acquoy",
      "Adorp",
      "Aduard",
      "Aduarder Voorwerk",
      "Aduarderzijl",
      "Aegum",
      "Aekinga",
      "Aerdenburg",
      "Aerdenhout",
      "Aerdt",
      "Afferden (Gelderland)",
      "Afferden (Limburg)",
      "Agelo",
      "Agodorp",
      "Aijen",
      "Akersloot",
      "Akker",
      "Akkerput",
      "Akkerwoude",
      "Akkrum",
      "Akmarijp",
      "Albergen",
      "Alblasserdam",
      "Alem",
      "Alendorp",
      "Alinghuizen",
      "Alkmaar",
      "Allardsoog",
      "Allersma",
      "Allingawier",
      "Almelo",
      "Almen",
      "Almenum",
      "Almere",
      "Almkerk",
      "Alphen aan den Rijn",
      "Alphen (Gelderland)",
      "Alphen (Noord-Brabant)",
      "Alphen-Oosterwijk",
      "Altena (Drenthe)",
      "Altena (Terneuzen)",
      "Alteveer (De Wolden)",
      "Alteveer (Hoogeveen)",
      "Alteveer (Noordenveld)",
      "Alteveer (Stadskanaal)",
      "Altforst",
      "Alting",
      "Altweerterheide",
      "Alverna",
      "Alvershool",
      "Ameide",
      "Amen",
      "America",
      "Amerika",
      "Amerongen",
      "Amersfoort",
      "Ammerstol",
      "Ammerzoden",
      "Ampsen",
      "Amstelhoek",
      "Amstelveen",
      "Amstenrade",
      "Amsterdam",
      "Amsterdamscheveld",
      "Amsweer",
      "Andel",
      "Andelst",
      "Anderen",
      "Anderwereld",
      "Andijk",
      "Ane",
      "Anerveen",
      "Anevelde",
      "Angeren",
      "Angerlo",
      "Anholt",
      "Anjum",
      "Ankeveen",
      "Ankum",
      "Anloo",
      "Anna Jacobapolder",
      "Anna Paulowna",
      "Anneburen",
      "Anneburen",
      "Annen",
      "Annerveenschekanaal",
      "Anreep",
      "Ansen",
      "Antum",
      "Apeldoorn",
      "Apenhuizen",
      "Aperloo",
      "Appel",
      "Appelscha",
      "Appeltern",
      "Appen",
      "Appingedam",
      "Arcen",
      "Archem",
      "Arendnest",
      "Arensgenhout",
      "Arkel",
      "Arkens",
      "Arkum",
      "Armhoede",
      "Armweide",
      "Arnemuiden",
      "Arnhem",
      "Arnoud",
      "Arriën",
      "Arriërveld",
      "Arum",
      "Arwerd (Delfzijl)",
      "Arwerd (Het Hogeland)",
      "Asbroek",
      "Asch",
      "Asdonk",
      "Asenray",
      "Asperen",
      "Asschat",
      "Assel",
      "Asselt",
      "Assen",
      "Assendelft",
      "Assum",
      "Asten",
      "Atzeburen",
      "Augsbuurt",
      "Augustinusga",
      "Austerlitz",
      "Avendorp",
      "Avenhorn",
      "Averlo en Frieswijk",
      "Avest",
      "Axel",
      "Axwijk",
      "Azelo",
      "Azewijn",
      "Baaiduinen",
      "Baak",
      "Baakhoven",
      "Baaks-Sweijer",
      "Baakwoning",
      "Baal",
      "Baalhoek",
      "Baambrugge",
      "Baambrugse Zuwe",
      "Baamsum",
      "Baanhoek",
      "Baard",
      "Baarderburen",
      "Baarland",
      "Baarle-Nassau",
      "Baarle-Nassau Grens",
      "Baarlo (Limburg)",
      "Baarlo (Steenwijkerland)",
      "Baarlo (Zwartewaterland)",
      "Baarn",
      "Baars (gehucht)",
      "Baarschot (Deurne)",
      "Baarschot (Hilvarenbeek)",
      "Baarschot (Oosterhout)",
      "Baarsdorp",
      "Baarsdorpermeer",
      "Babberich",
      "Baburen",
      "Babyloniënbroek",
      "Bad Nieuweschans",
      "Badhoevedorp",
      "Baexem",
      "Baflo",
      "Bahr",
      "Baijum",
      "Bakel",
      "Bakelsebrug",
      "Bakertand",
      "Bakhuisbos",
      "Bakhuizen",
      "Bakkersdam",
      "Bakkeveen",
      "Bakkum",
      "Bakkum-Noord",
      "Bakovensmee",
      "Balgoij",
      "Bali",
      "Balinge",
      "Balk",
      "Balkbrug",
      "Ballingbuur",
      "Balloërveld",
      "Balloo",
      "Ballum",
      "Balmahuizen",
      "Baneheide",
      "Bangeweer",
      "Banholt",
      "Bankert",
      "Bant",
      "Bantega",
      "Barchem",
      "Barendrecht",
      "Barendrechtse Veer",
      "Bareveld",
      "Bargebek",
      "Bargen",
      "Barger-Compascuum",
      "Barger-Erfscheidenveen",
      "Barger-Oosterveen",
      "Barger-Oosterveld",
      "Barlage",
      "Barlaque",
      "Barlo",
      "Barnegaten",
      "Barneveld",
      "Barnflair",
      "Barnheem",
      "Barnwerd",
      "Barrier",
      "Barrum",
      "Barsbeek",
      "Barsingerhorn",
      "Bartlehiem",
      "Barwoutswaarder",
      "Basse",
      "Basserveld",
      "Batadorp",
      "Batenburg",
      "Bath",
      "Bathmen",
      "Battenoord",
      "Bavel",
      "Bavinkel",
      "Bazuin",
      "Beckum",
      "Bedaf",
      "Bedum",
      "Beegden",
      "Beek en Donk",
      "Beek (Berg en Dal)",
      "Beek (Laarbeek)",
      "Beek (Limburg)",
      "Beek (Montferland)",
      "Beekbergen",
      "Beekdorp",
      "Beekheuvel",
      "Beekkant",
      "Beekveld",
      "Beemdkant",
      "Beemte",
      "Beemte-Broekland",
      "Beerenplaat",
      "Beers (Friesland)",
      "Beers (Noord-Brabant)",
      "Beerschoten",
      "Beersterhoogen",
      "Beerta",
      "Beertsenhoven",
      "Beerze",
      "Beerzerveld",
      "Beesd",
      "Beesel",
      "Beetgum",
      "Beetgumermolen",
      "Beets",
      "Beetsterzwaag",
      "Behelp",
      "Beilen",
      "Beilervaart",
      "Beinsdorp",
      "Bekelaar",
      "Bekenes",
      "Bekveld",
      "Beldert",
      "Belfeld",
      "Belgenhoek",
      "Belgeren",
      "Bellingeweer",
      "Bellingwolde",
      "Beltrum",
      "Belt-Schutsloot",
      "Belvert",
      "Bemelen",
      "Bemmel",
      "Bemmer",
      "Benderse",
      "Beneden Veensloot",
      "Benedenberg",
      "Benedeneind",
      "Beneden-Haastrecht",
      "Benedenheul",
      "Benedenkerk",
      "Beneden-Leeuwen",
      "Benedenvaart",
      "Bennebroek",
      "Bennekom",
      "Benneveld",
      "Benningbroek",
      "Benschop",
      "Bent",
      "Bentelo",
      "Benthem",
      "Benthuizen",
      "Bentveld",
      "Benzenrade",
      "Berdelingebuurte",
      "Berenbroek",
      "Berg aan de Maas",
      "Berg en Dal",
      "Berg (Cranendonck)",
      "Berg (Eijsden-Margraten)",
      "Berg (Valkenburg aan de Geul)",
      "Bergakker",
      "Bergambacht",
      "Bergeijk",
      "Bergen (Limburg)",
      "Bergen (Noord-Holland)",
      "Bergen aan Zee",
      "Bergen op Zoom",
      "Bergenhuizen",
      "Bergenshuizen",
      "Bergentheim",
      "Bergerden",
      "Bergermeer",
      "Bergharen",
      "Berghem (Limburg)",
      "Berghem (Noord-Brabant)",
      "Berghoek",
      "Berghof",
      "Berghuizen (De Wolden)",
      "Berghuizen (Heerde)",
      "Berghum",
      "Bergsche Heide",
      "Bergschenhoek",
      "Bergstoep",
      "Bergum",
      "Berik",
      "Beringe",
      "Berkeind",
      "Berkeindje",
      "Berkel en Rodenrijs",
      "Berkelaar",
      "Berkel-Enschot",
      "Berkenwoude",
      "Berkhout",
      "Berkmeer",
      "Berkt (Bernheze)",
      "Berkt (Veldhoven)",
      "Berlicum",
      "Berlikum",
      "Bern",
      "Besselaar",
      "Best",
      "Besthmen",
      "Beswerd",
      "Bethlehem",
      "Beugen",
      "Beugt",
      "Beukelaar",
      "Beuningen (Gelderland)",
      "Beuningen (Overijssel)",
      "Beusbergen",
      "Beuseberg",
      "Beusichem",
      "Beusum",
      "Beutenaken",
      "Bevermeer",
      "Beverwijk",
      "Biddinghuizen",
      "Biert",
      "Bierum",
      "Biervliet",
      "Biessum",
      "Biesthoek",
      "Biest-Houtakker",
      "Biestraat",
      "Biezelinge",
      "Biezenheuvel",
      "Biezenmortel",
      "Biggekerke",
      "Bijsteren",
      "Bikbergen",
      "Bikkershorn",
      "Bilderdam",
      "Billinghuizen",
      "Bilthoven",
      "Bingelrade",
      "Bingerden",
      "Binnen Ae",
      "Binnen Moerdijk",
      "Binnenwijzend",
      "Birdaard",
      "Bisschopswetering",
      "Bisselt",
      "Bissen",
      "Blaaksedijk",
      "Bladel",
      "Blaker",
      "Blankenberg",
      "Blankenham",
      "Blankeweer",
      "Blaricum",
      "Blauw",
      "Blauwe Hand",
      "Blauwe Sluis (Drimmelen)",
      "Blauwe Sluis (Steenbergen)",
      "Blauwenhoek",
      "Blauwesluis",
      "Blauwhuis (buurtschap)",
      "Blauwhuis (dorp)",
      "Blauwkapel",
      "Blauwverlaat",
      "Bleijenbeek",
      "Bleijs",
      "Bleiswijk",
      "Blekslage",
      "Blenkert",
      "Blerick",
      "Blesdijke",
      "Bleskensgraaf",
      "Blessum",
      "Bliekenbos",
      "Blija",
      "Blijdenstein",
      "Blijham",
      "Blitterswijck",
      "Bloemberg",
      "Bloemendaal",
      "Bloemendaal aan Zee",
      "Blokdijk",
      "Blokhuizen",
      "Blokland (Montfoort)",
      "Blokland (Nieuwkoop)",
      "Blokum",
      "Blokzijl",
      "Bobeldijk",
      "Bocholtz",
      "Bocholtzerheide",
      "Boddenbroek",
      "Bodegraven",
      "Boeiink",
      "Boekel (Noord-Brabant)",
      "Boekel (Noord-Holland)",
      "Boekelo",
      "Boekelo",
      "Boekend",
      "Boeket",
      "Boelenslaan",
      "Boer",
      "Boerakker",
      "Boerdam",
      "Boerdonk",
      "Boerengat",
      "Boerenhoek",
      "Boerenhol",
      "Boerenstreek",
      "Boerhaar",
      "Boerlaan",
      "Boermastreek",
      "Boesingheliede",
      "Boijl",
      "Bokhoven",
      "Boksheide",
      "Boksum",
      "Bokt",
      "Bokum",
      "Bolberg (Breda)",
      "Bolberg (Gilze en Rijen)",
      "Bollingawier",
      "Bolnes",
      "Bolshuizen",
      "Bolst",
      "Bolsward",
      "Boltklap",
      "Bommelskous",
      "Bommerig",
      "Bong",
      "Bonkwerd",
      "Bonnen",
      "Bonnerveen",
      "Bonrepas",
      "Bontebok",
      "Bontebrug",
      "Bontekoe",
      "Bontemorgen",
      "Boomen",
      "Boomhoek",
      "Boompjesdijk",
      "Booneschans",
      "Boord",
      "Boornbergum",
      "Boornzwaag",
      "Borculo",
      "Borger",
      "Borgercompagnie",
      "Borgertange",
      "Borgerveld",
      "Borgharen",
      "Borgsweer",
      "Borgweg",
      "Borkel",
      "Borkeld",
      "Born",
      "Borne",
      "Bornerbroek",
      "Bornwird",
      "Bornwirdhuizen",
      "Borssele",
      "Borteldonk",
      "Bosch",
      "Bosch en Duin",
      "Boschdorp",
      "Boscheind",
      "Boschhoven (Baarle-Nassau)",
      "Boschhoven (Heeze-Leende)",
      "Boschoord",
      "Boshoek",
      "Boshoven (Alphen-Chaam)",
      "Boshoven (Bergeijk)",
      "Boshoven (Weert)",
      "Bosje",
      "Boskamp",
      "Boskant (Meierijstad)",
      "Boskant (Wijchen)",
      "Boskoop",
      "Boslust",
      "Bosschenhoofd",
      "Bosschenhuizen",
      "Bosscherheide",
      "Boteburen",
      "Boterveen",
      "Boterwijk",
      "Bouchauterhaven",
      "Boukoul",
      "Bourtange",
      "Bouwberg",
      "Bouwerschap",
      "Bouwtenheerd",
      "Boven Pekela",
      "Boven Veensloot",
      "Bovenberg",
      "Bovenburen",
      "Bovendijks",
      "Boveneind",
      "Boveneinde",
      "Boven-Haastrecht",
      "Boven-Hardinxveld",
      "Bovenkarspel",
      "Bovenkerk (Amstelveen)",
      "Bovenkerk (Vlist)",
      "Boven-Leeuwen",
      "Bovenrijge",
      "Bovensluis",
      "Bovensmilde",
      "Bovenste Caumer",
      "Bovenstehuis",
      "Bovenstreek (Drenthe)",
      "Bovenstreek (Midden-Groningen)",
      "Bovenstreek (Westerlee)",
      "Bovenstreek (Westerwolde)",
      "Bovenstreek (Winschoten)",
      "Bovenveen",
      "Boxmeer",
      "Boxtel",
      "Bozum",
      "Braakeind",
      "Braamberg (De Wolden/Hardenberg)",
      "Braamberg (Stadskanaal)",
      "Braambosch",
      "Braamt",
      "Brachterbeek",
      "Brakel",
      "Brakel",
      "Brammelerbroek",
      "Brammelo",
      "Brand (Landerd)",
      "Brand (Nuth)",
      "Brandemolen",
      "Brandwijk",
      "Brantgum",
      "Breda",
      "Bredelaar",
      "Bredevoort",
      "Breede",
      "Breedenbroek",
      "Breedeveen",
      "Breedeweg",
      "Breehees",
      "Breemen",
      "Breemortel",
      "Breeveld",
      "Breezand",
      "Breezanddijk",
      "Bregtdorp",
      "Breklenkamp",
      "Brem",
      "Brembosch",
      "Breskens",
      "Breudijk",
      "Breugel",
      "Breukelen",
      "Breukeleveen",
      "Breust",
      "Brielle",
      "Brigdamme",
      "Brijdorpe",
      "Brillerij",
      "Briltil",
      "Brinkheurne",
      "Brinkhoek",
      "Brinkmanshoek",
      "Britsum",
      "Britswerd",
      "Broek (Brummen)",
      "Broek (De Marne)",
      "Broek (Duiven)",
      "Broek (Friesland)",
      "Broek (Gouda)",
      "Broek (Gulpen-Wittem)",
      "Broek (Laarbeek)",
      "Broek (Simpelveld)",
      "Broek (Tegelen)",
      "Broek (Zederik)",
      "Broek op Langedijk",
      "Broek in Waterland",
      "Broeke",
      "Broekeind",
      "Broekenseind",
      "Broekerhaven",
      "Broekhem",
      "Broekheurne",
      "Broekhoven (Bergeijk)",
      "Broekhoven (Geulle)",
      "Broekhuizen (Drenthe)",
      "Broekhuizen (Gouda)",
      "Broekhuizen (Horst aan de Maas)",
      "Broekhuizen (Landgraaf)",
      "Broekhuizen (Overijssel)",
      "Broekhuizenvorst",
      "Broekkant (Cranendonck)",
      "Broekkant (Sint Anthonis)",
      "Broekkant (Someren)",
      "Broekkant (Laarbeek)",
      "Broekland",
      "Broeksittard",
      "Broeksterwoude",
      "Broekzijde",
      "Broerdijk",
      "Brommelen (Geulle)",
      "Brommelen (Wijnandsrade)",
      "Brongerga",
      "Bronkhorst",
      "Bronkhorst (Altena)",
      "Bronkhorst (Gelderland)",
      "Bronneger",
      "Bronnegerveen",
      "Bronsveen",
      "Brouwershaven",
      "Bruchem",
      "Brucht",
      "Bruchterveld",
      "Bruggen ('s-Hertogenbosch)",
      "Bruggen (Mill en Sint Hubert)",
      "Bruggenhoek",
      "Bruggerhuizen",
      "Bruilweering",
      "Bruinehaar",
      "Bruinisse",
      "Bruisterbosch",
      "Brumholt",
      "Brummen",
      "Brunssum",
      "Brunsting",
      "Bruntinge",
      "Buchten",
      "Budel",
      "Budel-Dorplein",
      "Budel-Schoot",
      "Budschop",
      "Buggenum",
      "Buiksloot",
      "Buinen",
      "Buinerveen",
      "Buitenhuizen (Assendelft)",
      "Buitenhuizen (De Wolden)",
      "Buitenkaag",
      "Buitenpost",
      "Buitenstverlaat",
      "Bulkemsbroek",
      "Bulkenaar",
      "Bullenkamp",
      "Bunde",
      "Bunne",
      "Bunnik",
      "Bunschoten-Spakenburg",
      "Buren (Ameland)",
      "Buren (Gelderland)",
      "Burger Nieuwland",
      "Burgerbrug",
      "Burgersdijk",
      "Burgerveen",
      "Burgervlotbrug",
      "Burgh",
      "Burghorn",
      "Burgt",
      "Burgum",
      "Burgwerd",
      "Burum",
      "Bus (Bernheze)",
      "Bus (Meierijstad)",
      "Busch en Dam",
      "Bussel",
      "Busselte",
      "Bussereind",
      "Bussloo",
      "Bussum",
      "Buttinge",
      "Buurmalsen",
      "Buurse",
      "Buurtje",
      "Buurtsdijk",
      "Buweklooster",
      "Cabauw",
      "Cadier en Keer",
      "Cadzand",
      "Cadzand-Bad",
      "Calfven",
      "Californië (Horst aan de Maas)",
      "Californië (Maasdriel)",
      "Callantsoog",
      "Calslagen",
      "Caluna",
      "Camerig",
      "Camperduin",
      "Capelle (Noord-Brabant)",
      "Capelle (Zeeland)",
      "Capelle aan den IJssel",
      "Cartils",
      "Castelré",
      "Castenray",
      "Casteren",
      "Castert",
      "Castricum",
      "Castricum aan Zee",
      "Catrijp",
      "Catsop",
      "Cattenbroek",
      "Cellemuiden",
      "Ceresdorp",
      "Chaam",
      "Chaamdijk",
      "Cillaarshoek",
      "Clinge",
      "Coevorden",
      "Coldenhove",
      "Colijnsplaat",
      "Collendoorn",
      "Collendoornerveen",
      "Colmont",
      "Colmschate",
      "Colonjes",
      "Commissiepolle",
      "Corle",
      "Cornelissenwerf",
      "Cornjum",
      "Cornwerd",
      "Cortenoever",
      "Cothen",
      "Cottessen",
      "Couwelaar",
      "Crailo",
      "Cranendonck",
      "Crapoel",
      "Craubeek",
      "Creil",
      "Crixhoek",
      "Cromvoirt",
      "Croy",
      "Cruquius",
      "Cuijk",
      "Culemborg",
      "Daarle",
      "Daarlerveen",
      "Dale",
      "Dalem",
      "Dalen",
      "Dalerend",
      "Dalerpeel",
      "Dalerveen",
      "Dalfsen",
      "Dalmsholte",
      "Damwoude",
      "Daniken",
      "Darp",
      "De Bilt",
      "De Blesse",
      "De Bult (Bellingwedde)",
      "De Bult (Steenwijkerland)",
      "De Cocksdorp",
      "De Glind",
      "De Goorn",
      "De Groeve",
      "De Haukes",
      "De Heen",
      "De Heurne (Aalten)",
      "De Heurne (Berkelland)",
      "De Hoef",
      "De Hoek",
      "De Hoeve",
      "De Hulk",
      "De Kar",
      "De Kiel",
      "De Klencke",
      "De Klomp (Ede)",
      "De Knipe",
      "De Koog",
      "De Kooy",
      "De Krim (Apeldoorn)",
      "De Krim (Driebergen)",
      "De Krim (Hardenberg)",
      "De Kwakel",
      "De Lier",
      "De Lutte",
      "De Marshoek",
      "De Meern",
      "De Moer",
      "De Mortel",
      "De Pol (gem. Hardenberg)",
      "De Pol (Drenthe)",
      "De Pol (Steenwijkerland)",
      "De Pollen",
      "De Poppe",
      "De Punt",
      "De Rijp",
      "De Schiphorst",
      "De Stapel",
      "De Steeg",
      "De Stolpen",
      "De Tike",
      "De Valom (Friesland)",
      "De Vecht",
      "De Veenhoop",
      "De Waal",
      "De Weere (Opmeer)",
      "De Weere (Niedorp)",
      "de Wijk (Drenthe)",
      "De Wijk (Gelderland)",
      "De Wilgen",
      "De Wilp",
      "De Woude",
      "De Zande",
      "De Zilk",
      "Dedemsvaart",
      "Dedgum",
      "Deelen",
      "Deersum",
      "Deest",
      "Deil",
      "Deinum",
      "Delden",
      "Deldenerbroek",
      "Delfgauw",
      "Delfstrahuizen",
      "Delft",
      "Delfzijl",
      "Delwijnen",
      "Demen",
      "Den Andel",
      "Den Bommel",
      "Den Burg",
      "Den Dolder",
      "Den Dungen",
      "Den Haag",
      "Den Ham (Zuidhorn)",
      "Den Ham (Overijssel)",
      "Den Helder",
      "Den Hool",
      "Den Hoorn (Midden-Delfland)",
      "Den Hoorn (Texel)",
      "Den Horn",
      "Den Hout",
      "Den Hulst",
      "Den Ilp",
      "Den Kaat",
      "Den Nul",
      "Den Oever",
      "Den Velde",
      "Denekamp",
      "Dennenburg",
      "Deurne",
      "Deurningen",
      "Deursen",
      "Deurze",
      "Deventer",
      "Dichteren",
      "Didam",
      "Diefdijk (Geldermalsen, Leerdam en Vianen)",
      "Diefdijk (Leerdam)",
      "Diemen",
      "Diepenheim",
      "Diepenveen",
      "Dieren (Gelderland)",
      "Diessen (Noord-Brabant)",
      "Dieteren",
      "Diever",
      "Dieverbrug",
      "Diffelen",
      "Dijken",
      "Dijkerhoek",
      "Dijkhuizen (De Wolden)",
      "Dijkhuizen (Epe)",
      "Dinteloord",
      "Dinther",
      "Dinxperlo",
      "Diphoorn",
      "Dirkshorn",
      "Dirksland",
      "Dodewaard",
      "Doenrade",
      "Doesburg",
      "Doetinchem",
      "Doeveren",
      "Doezum",
      "Dokkum",
      "Dokkumer Nieuwe Zijlen",
      "Doldersum",
      "Domburg",
      "Dommelen",
      "Donderen",
      "Dongen",
      "Dongjum",
      "Doniaga",
      "Donk (Limburg)",
      "Donk (Noord-Brabant)",
      "Donkerbroek",
      "Doodstil",
      "Doorn",
      "Doornenburg",
      "Doornhoek (Veghel)",
      "Doornhoek (Sint Michielsgestel)",
      "Doornspijk",
      "Doorwerth",
      "Dordrecht",
      "Dorkwerd",
      "Dorst",
      "Dortherhoek",
      "Douvergenhout",
      "Draaibrug",
      "Drachten",
      "Drachtstercompagnie",
      "Dreischor",
      "Drempt",
      "Dreumel",
      "Drie",
      "Driebergen-Rijsenburg",
      "Drieborg",
      "Driebruggen",
      "Driehuis (Uden)",
      "Driehuis (Velsen)",
      "Driehuizen (Baarle-Nassau)",
      "Driehuizen (Eersel)",
      "Driehuizen (Hilvarenbeek)",
      "Driehuizen (Schermer)",
      "Driehuizen (Texel)",
      "Driehuizen (Veghel)",
      "Driel",
      "Driemond",
      "Driesum",
      "Driewegen",
      "Drijber",
      "Drimmelen",
      "Drogeham",
      "Drogteropslagen",
      "Drongelen",
      "Dronrijp",
      "Dronten",
      "Drouwen",
      "Drouwenermond",
      "Drouwenerveen",
      "Drumpt",
      "Drunen",
      "Druten",
      "Dubbeldam",
      "Duistervoorde",
      "Duiven",
      "Duivendrecht",
      "Duizel",
      "Dulder",
      "Durgerdam",
      "Dussen",
      "Duur",
      "Dwarsgracht",
      "Dwingeloo",
      "Echt",
      "Echteld",
      "Echten (Drenthe)",
      "Echten (Friesland)",
      "Echtenerbrug",
      "Echterbosch",
      "Eck en Wiel",
      "Eckelrade",
      "Edam",
      "Ede",
      "Edens",
      "Ederveen",
      "Ee (Dongeradeel)",
      "Ee (Littenseradeel)",
      "Eede",
      "Eefde",
      "Eelde",
      "Eelderwolde",
      "Eembrugge",
      "Eemdijk",
      "Eemnes",
      "Eemshaven",
      "Eemster",
      "Een",
      "Eenigenburg",
      "Eenrum",
      "Eenum",
      "Een-West",
      "Eerbeek",
      "Eerde (Noord-Brabant)",
      "Eerde (Overijssel)",
      "Eernewoude",
      "Eersel",
      "1e Exloërmond",
      "Ees",
      "Eese",
      "Eesergroen",
      "Eeserveen",
      "Eesterga",
      "Eestrum",
      "Eesveen",
      "Eethen",
      "Eext",
      "Eexterveen",
      "Eexterveenschekanaal",
      "Eexterzandvoort",
      "Egchel",
      "Egmond aan den Hoef",
      "Egmond aan Zee",
      "Egmond-Binnen",
      "Egmondermeer",
      "Eibergen",
      "Eierland",
      "Eijsden",
      "Eindhoven",
      "Einighausen",
      "Ekehaar",
      "Elahuizen",
      "Elburg",
      "Elden",
      "Eldersloo",
      "Eldrik",
      "Eleveld",
      "Elim",
      "Elkenrade",
      "Elkerzee",
      "Ell",
      "Ellecom",
      "Ellemeet",
      "Ellerhuizen",
      "Ellertshaar",
      "Ellewoutsdijk",
      "Elp",
      "Elsen",
      "Elsendorp",
      "Elshof",
      "Elshout",
      "Elsloo (Friesland)",
      "Elsloo (Limburg)",
      "Elspeet",
      "Elst (Gelderland)",
      "Elst (Utrecht)",
      "Emmaberg",
      "Emmadorp",
      "Emmeloord",
      "Emmen",
      "Emmer-Compascuum",
      "Empe",
      "Empel",
      "Emst",
      "Engelbert",
      "Engelen",
      "Engelum",
      "Engwierum",
      "Enkhuizen",
      "Ens",
      "Enschede",
      "Enspijk",
      "Enter",
      "Enumatil",
      "Epe",
      "Epen",
      "Eperheide",
      "Eppenhuizen",
      "Epse",
      "Erica",
      "Erichem",
      "Erlecom",
      "Erm",
      "Ermelo",
      "Erp",
      "Esbeek",
      "Esch",
      "Escharen",
      "Espel",
      "Espelo",
      "Essen",
      "Est",
      "Etenaken",
      "Etsberg",
      "Etten",
      "Etten-Leur",
      "Etzenrade",
      "Eursinge (De Wolden)",
      "Eursinge (Midden-Drenthe)",
      "Eursinge (Westerveld)",
      "Euverem",
      "Everdingen",
      "Evertsoord",
      "Ewijk",
      "Exel",
      "Exloërveen",
      "Exloo",
      "Exmorra",
      "Eygelshoven",
      "Eys",
      "Eyserheide",
      "Ezinge",
      "Ezumazijl",
      "Faan",
      "Farmsum",
      "Feerwerd",
      "Ferwerd",
      "Ferwoude",
      "Fijnaart",
      "Finkum",
      "Finsterwolde",
      "Firdgum",
      "Fleringen",
      "Fluitenberg",
      "Fochteloo",
      "Follega",
      "Folsgare",
      "Formerum",
      "Fort (De Wolden)",
      "Fort (Terneuzen)",
      "Foudgum",
      "Foxham",
      "Foxhol",
      "Foxwolde",
      "Franeker",
      "Frederiksoord",
      "Friens",
      "Frieschepalen",
      "Froombosch",
      "Gaanderen",
      "Gaarkeuken",
      "Gaast",
      "Gaastmeer",
      "Galder",
      "Gameren",
      "Gammelke",
      "Gapinge",
      "Garderen",
      "Garijp",
      "Garmerwolde",
      "Garminge",
      "Garnwerd",
      "Garrelsweer",
      "Garsthuizen",
      "Gassel",
      "Gasselte",
      "Gasselterboerveen",
      "Gasselterboerveenschemond",
      "Gasselternijveen",
      "Gasselternijveenschemond",
      "Gastel",
      "Gasteren",
      "Gasthuis",
      "Gauw",
      "Gebroek",
      "Geelbroek",
      "Geersbroek",
      "Geersdijk",
      "Geertruidenberg",
      "Geervliet",
      "Gees",
      "Geesbrug",
      "Geesteren (Gelderland)",
      "Geesteren (Overijssel)",
      "Geeuwenbrug",
      "Geffen",
      "Gelderingen",
      "Geldermalsen",
      "Gelderswoude",
      "Geldrop",
      "Geleen",
      "Gellicum",
      "Gelselaar",
      "Gemert",
      "Gemonde",
      "Genderen",
      "Gendringen",
      "Gendt",
      "Genemuiden",
      "Genhout",
      "Gennep",
      "Genum",
      "Gerkesklooster",
      "Gerner",
      "Gersloot",
      "Gerwen",
      "Geulhem",
      "Geulle",
      "Geulle aan de Maas",
      "Geverik",
      "Geysteren",
      "Giekerk",
      "Giesbeek",
      "Giessen",
      "Giessenburg",
      "Giessendam",
      "Gieten",
      "Gieterveen",
      "Giethmen",
      "Giethoorn",
      "Gilze",
      "Glane",
      "Glanerbrug",
      "Glimmen",
      "Godlinze",
      "Goedereede",
      "Goënga",
      "Goëngahuizen",
      "Goes",
      "Goingarijp",
      "Goirle",
      "Goor",
      "Gorinchem",
      "Gorpeind",
      "Gorredijk",
      "Gorssel",
      "Gortel",
      "Gouda",
      "Goudriaan",
      "Goudswaard",
      "Gouderak",
      "Goutum",
      "Graauw",
      "Graetheide",
      "Grafhorst",
      "Graft",
      "Gramsbergen",
      "Grashoek",
      "Grathem",
      "Grave",
      "'s-Gravendeel",
      "'s-Gravenzande",
      "'s-Gravenpolder",
      "Greffelkamp",
      "Greonterp",
      "'s-Grevelduin-Capelle",
      "Grevenbicht",
      "Griendtsveen",
      "Grijpskerk",
      "Grijpskerke",
      "Grijzegrubben",
      "Groede",
      "Groenekan",
      "Groeningen",
      "Groenlo",
      "Groenveld (Harenkarspel)",
      "Groenveld (Venlo)",
      "Groesbeek",
      "Groessen",
      "Groet",
      "Groetpolder",
      "Grolloo",
      "Groningen",
      "Gronsveld",
      "Groot-Abeele",
      "Groot Agelo",
      "Groot Dochteren",
      "Groot Dorregeest",
      "Groot Haasdal",
      "Groot-Ammers",
      "Groote Keeten",
      "Grootebroek",
      "Grootegast",
      "Grootschermer",
      "Grosthuizen",
      "Grouw",
      "Grubbenvorst",
      "Gulpen",
      "Guttecoven",
      "Haaften",
      "Haaksbergen",
      "Haakswold",
      "Haalderen",
      "Haamstede",
      "Haanwijk",
      "Haaren",
      "Haarle (Hellendoorn)",
      "Haarle (Tubbergen)",
      "Haarlem",
      "Haarlemmerliede",
      "Haarlo",
      "Haarsteeg",
      "Haart (Aalten)",
      "Haart (Boxmeer)",
      "Haarzuilens",
      "Haastrecht",
      "Haelen",
      "Haerst",
      "Hagestein",
      "Haghorst",
      "Haler",
      "Halfweg (Beemster)",
      "Halfweg (Emmen)",
      "Halfweg (Haarlemmerliede en Spaarnwoude)",
      "Halfweg (Lisse)",
      "Halfweg (Noordwijkerhout)",
      "Halfweg (Staphorst)",
      "Halfweg (Terschelling)",
      "Hall",
      "Halle",
      "Hallum",
      "Halsteren",
      "Hamert",
      "Hamingen",
      "Handel",
      "Hank",
      "Hansweert",
      "Hantum",
      "Hantumeruitburen",
      "Hantumhuizen",
      "Hapert",
      "Haps",
      "Harbrinkhoek",
      "Harculo",
      "Hardegarijp",
      "Hardenberg",
      "Harderwijk (De Weere)",
      "Harderwijk (Gelderland)",
      "Hardinxveld-Giessendam",
      "Haren (Groningen)",
      "Haren (Noord-Brabant)",
      "Harenermolen",
      "Harfsen",
      "Hargen",
      "Harich",
      "Haringhuizen",
      "Harkema",
      "Harkstede",
      "Harlingen",
      "Harmelen",
      "Harreveld",
      "Harskamp",
      "Hartwerd",
      "Haskerdijken",
      "Haskerhorne",
      "Hasselo",
      "Hasselt",
      "Hattem",
      "Hattemerbroek",
      "Haule",
      "Haulerwijk",
      "Hauwert",
      "Havelte",
      "Havelterberg",
      "Hazerswoude-Dorp",
      "Hazerswoude-Rijndijk",
      "Hedel",
      "Hedikhuizen",
      "Hee",
      "Heeg",
      "Heek",
      "Heel",
      "Heelsum",
      "Heelweg",
      "Heemserveen",
      "Heemskerk",
      "Heemstede",
      "Heenvliet",
      "Heer",
      "Heerde",
      "Heerenveen",
      "Heerewaarden",
      "Heerhugowaard",
      "Heerjansdam",
      "Heerle",
      "Heerlen",
      "Heerlerbaan",
      "Heerlerheide",
      "Heesbeen",
      "Heesch",
      "Heeseind",
      "Heesselt",
      "Heeswijk-Dinther",
      "Heeten",
      "Heeze",
      "Hegelsom",
      "Hei- en Boeicop",
      "Heibloem",
      "Heide (Boekel)",
      "Heide (Boxmeer)",
      "Heide (Haelen)",
      "Heide (Helden)",
      "Heide (Heumen)",
      "Heide (Heythuysen)",
      "Heide (Roggel)",
      "Heide (Scharsterland)",
      "Heide (Susteren)",
      "Heide (Swalmen)",
      "Heide (Venray)",
      "Heidenhoek",
      "It Heidenskip",
      "Heijen",
      "Heijenrath",
      "Heijningen",
      "Heikant",
      "Heilig Landstichting",
      "Heiligerlee",
      "Heiloo",
      "Heinenoord",
      "Heinkenszand",
      "Heino",
      "Heistraat (Oosterhout)",
      "Heistraat (buurtschap in Eindhoven)",
      "Hekelingen",
      "Hekendorp",
      "Helden",
      "Helenaveen",
      "Helkant",
      "Hellendoorn",
      "Hellevoetsluis",
      "Hellouw",
      "Hellum",
      "Helmond",
      "Helvoirt",
      "Helwijk",
      "Hem (Drechterland)",
      "Hem (Leeuwarden)",
      "Hemelum",
      "Hemmen",
      "Hemrik",
      "Hendrik-Ido-Ambacht",
      "Hengelo (Gelderland)",
      "Hengelo (Overijssel)",
      "Hengevelde",
      "Hengforden",
      "Hengstdijk",
      "Hennaard",
      "Hensbroek",
      "Henxel",
      "Herbaijum",
      "Herkenbosch",
      "Herkenrade",
      "Herkingen",
      "Hernen",
      "Herpen",
      "Herpt",
      "Herten",
      "Hertme",
      "Herveld",
      "Herwen",
      "Herwijnen",
      "Herxen",
      "Hessum",
      "Het Koegras",
      "Het Woud",
      "Heteren",
      "Heugem",
      "Heukelom (Limburg)",
      "Heukelom (Noord-Brabant)",
      "Heukelum",
      "Heumen",
      "Heusden",
      "Heusden (Asten)",
      "Heveadorp",
      "Heveskes",
      "Heythuysen",
      "Hezingen",
      "Hiaure",
      "Hichtum",
      "Hidaard",
      "Hien",
      "Hierden",
      "Hieslum",
      "Hijken",
      "Hijkersmilde",
      "Hijlaard",
      "Hijum",
      "Hillegom",
      "Hilleshagen",
      "Hilvarenbeek",
      "Hilversum",
      "Hindeloopen",
      "Hintham",
      "Hippolytushoef",
      "Hitzum",
      "Hobrede",
      "Hoedekenskerke",
      "Hoef en Haag",
      "Hoek (Beuningen)",
      "Hoek (Leeuwarden)",
      "Hoek (Roggel en Neer)",
      "Hoek (Terneuzen)",
      "Hoek (Veghel)",
      "Hoek van Holland",
      "Hoenderloo",
      "Hoensbroek",
      "Hoenzadriel",
      "Hoevelaken",
      "Hoeven",
      "Hoge Hexel",
      "Hogebeintum",
      "Hogeveen",
      "Hogeweg (Overijssel)",
      "Hogeweg (Zeeland)",
      "Hollandsche Rading",
      "Hollandscheveld",
      "Hollum",
      "Holset",
      "Holsloot",
      "Holten",
      "Holterhoek",
      "Holthees",
      "Holtheme",
      "Holthone",
      "Holtum",
      "Holwerd",
      "Holwierde",
      "Holysloot",
      "Hommert",
      "Hommerts",
      "Homoet",
      "Hongerige Wolf",
      "Honselersdijk",
      "Honthem",
      "Hoofddorp",
      "Hoofdplaat",
      "Hoog Soeren",
      "Hoogblokland",
      "Hoogcruts",
      "Hooge Mierde",
      "Hooge Zwaluwe",
      "Hoogeloon",
      "Hoogengraven",
      "Hoogenweg",
      "Hoogerheide",
      "Hoogersmilde",
      "Hoogeveen",
      "Hoogezand",
      "Hooghalen",
      "Hoogkarspel",
      "Hoog-Keppel",
      "Hoogkerk",
      "Hoogland",
      "Hooglanderveen",
      "Hoogmade",
      "Hoogvliet",
      "Hoogwoud",
      "Hoonhorst",
      "Hoorn (Noord-Holland)",
      "Hoorn (Terschelling)",
      "Hoorn (Heerde)",
      "Hoornaar",
      "Hoornsterzwaag",
      "Hopel",
      "Horn (Limburg)",
      "Horn (Noord-Holland)",
      "Hornhuizen",
      "Horssen",
      "Horst (Gelderland)",
      "Horst (Limburg)",
      "Hout",
      "Houthem",
      "Hout-Blerick",
      "Houten",
      "Houtigehage",
      "Houwerzijl",
      "Huijbergen",
      "Huinen",
      "Huins",
      "Huis ter Heide (Drenthe)",
      "Huis ter Heide (Utrecht)",
      "Huisduinen",
      "Huisseling",
      "Huissen",
      "Huizen",
      "Huizinge",
      "Hulhuizen",
      "Huls",
      "Hulsberg",
      "Hulsel",
      "Hulsen (Meerssen)",
      "Hulsen (Overijssel)",
      "Hulsen (Nederweert)",
      "Hulshorst",
      "Hulst",
      "Hulten",
      "Hummelo",
      "Hunnecum",
      "Hunsel",
      "Huppel",
      "Hupsel",
      "Hurwenen",
      "Idaard",
      "Idsegahuizum",
      "Idskenhuizen",
      "Idzega",
      "IJhorst",
      "IJlst",
      "IJmuiden",
      "IJpelo",
      "IJsbrechtum",
      "IJsselham",
      "IJsselmuiden",
      "IJsselstein",
      "IJzendijke",
      "IJzendoorn",
      "IJzeren",
      "IJzerlo",
      "IJzevoorde",
      "Illikhoven",
      "Ilpendam",
      "Indijk (Harmelen)",
      "Indijk (Littenseradeel)",
      "Indijk (Tietjerksteradeel)",
      "Indijk (Wonseradeel)",
      "Indijk (Wymbritseradeel)",
      "Indoornik",
      "Ingber",
      "Ingen",
      "Irnsum",
      "Itens",
      "Itteren",
      "Ittersum",
      "Ittervoort",
      "Jaarsveld",
      "Jabeek",
      "Janum",
      "Jellum",
      "Jelsum",
      "Jipsingboermussel",
      "Jipsingboertange",
      "Jipsinghuizen",
      "Jislum",
      "Jisp",
      "Jonkersland",
      "Jonkersvaart",
      "Joppe",
      "Jorwerd",
      "Joure",
      "Jouswier",
      "Jubbega",
      "Julianadorp",
      "Julianadorp aan Zee",
      "Junne",
      "Jutphaas",
      "Jutrijp",
      "Kaag",
      "Kaard",
      "Kaatsheuvel",
      "Kadoelen",
      "Kalenberg",
      "Kallenkote",
      "Kalverdijk",
      "Kamerik",
      "Kampen",
      "Kampereiland",
      "Kamperland",
      "Kamperveen",
      "Kamperzeedijk-Oost",
      "Kamperzeedijk-West",
      "Kantens",
      "Kapel-Avezaath",
      "Kapelle",
      "Kapellebrug",
      "Katlijk",
      "Kats",
      "Kattendijke",
      "Katwijk aan den Rijn",
      "Katwijk aan Zee",
      "Katwijk (Noord-Brabant)",
      "Katwijk (Zuid-Holland)",
      "Katwoude",
      "Kedichem",
      "Keent",
      "Keijenborg",
      "Keinsmerbrug",
      "Kekerdom",
      "Keldonk",
      "Kelmond",
      "Kelpen-Oler",
      "Kerk-Avezaath",
      "Kerkbuurt (Andijk)",
      "Kerkbuurt (Assendelft)",
      "Kerkbuurt (Marken)",
      "Kerkbuurt (Schiedam)",
      "Kerkbuurt (Tuitjenhorn)",
      "Kerkdriel",
      "Kerkenveld",
      "Kerkrade",
      "Kerkwerve",
      "Kerkwijk",
      "Kessel (Limburg)",
      "Kessel (Noord-Brabant)",
      "Kesseleik",
      "Kesteren",
      "Ketelhaven",
      "Kethel",
      "Keutenberg",
      "Kibbelveen",
      "Kiel-Windeweer",
      "Kijkduin",
      "Kijkuit",
      "Kilder",
      "Kimswerd",
      "Kinderdijk",
      "Kinnum",
      "Klaaswaal",
      "Klarenbeek",
      "Klazienaveen",
      "Klein-Bedaf",
      "Klein Dochteren",
      "Klein Dorregeest",
      "Klein Haasdal",
      "Kleine Koolwijk",
      "Klein Zundert",
      "Klein Ulsda",
      "Kleingenhout",
      "Kleine Huisjes",
      "Kleverskerke",
      "Klijndijk",
      "Klimmen",
      "Kloetinge",
      "Kloosterburen",
      "Kloosterhaar",
      "Klooster-Lidlum",
      "Kloosterzande",
      "Klundert",
      "Knegsel",
      "Kockengen",
      "Koedijk",
      "Koekange",
      "Koewacht",
      "Kogerpolder",
      "Kolderveen",
      "Kolderveense Bovenboer",
      "Kolderwolde",
      "Kolham",
      "Kolhorn",
      "Kollum",
      "Kollumerpomp",
      "Kollumerzwaag",
      "Kommerzijl",
      "Koningsbosch",
      "Koningslust",
      "Koog aan de Zaan",
      "Koolwijk (Oss)",
      "Koolwijk (Vlist)",
      "Kootstertille",
      "Kootwijk",
      "Kootwijkerbroek",
      "Kornhorn",
      "Kornwerderzand",
      "Kortehemmen",
      "Kortenhoef",
      "Kortgene",
      "Kostverloren (Groningen)",
      "Kostverloren (Reiderland)",
      "Kotten",
      "Koudekerk aan den Rijn",
      "Koudekerke",
      "Koudum",
      "Koufurderrige",
      "Krabbendam",
      "Krabbendijke",
      "Krachtighuizen",
      "Kraggenburg",
      "Kralendijk",
      "Kranenburg",
      "Kreileroord",
      "Krewerd",
      "Krimpen aan de Lek",
      "Krimpen aan den IJssel",
      "Krommenie",
      "Krommeniedijk",
      "Kronenberg",
      "Kropswolde",
      "Kruiningen",
      "Kruisdijk",
      "Kruisland",
      "Kruisstraat (Halderberge)",
      "Kruisstraat (Nederweert)",
      "Kruisstraat (Roosendaal)",
      "Kruisstraat (Rosmalen)",
      "Kruisweg (Groningen)",
      "Kruisweg (Zuid-Holland)",
      "Kubaard",
      "Kudelstaart",
      "Kuinre",
      "Kuitaart",
      "Kunrade",
      "Kwadendamme",
      "Kwadijk",
      "Kwintsheul",
      "Laag-Keppel",
      "Laag-Soeren",
      "Laag-Zuthem",
      "Laaghalen",
      "Laaghalerveen",
      "Laar (Cranendonck)",
      "Laar (Gerwen)",
      "Laar (Laarbeek)",
      "Laar (Maarheeze)",
      "Laar (Maasbree)",
      "Laar (Nuth)",
      "Laar (Sint-Michielsgestel)",
      "Laar (Tilburg)",
      "Laar (Weert)",
      "Lage Mierde",
      "Lage Vuursche",
      "Lage Zwaluwe",
      "Lageland",
      "Lambertschaag",
      "Lamswaarde",
      "Landerum",
      "Landgraaf",
      "Landhorst",
      "Landsmeer",
      "Langbroek",
      "Langedijke",
      "Langelille",
      "Langelo (Drenthe)",
      "Langelo (Overijssel)",
      "Langenboom",
      "Langeraar",
      "Langerak",
      "Langeveen",
      "Langeweg",
      "Langezwaag",
      "Langweer",
      "Lankhorst",
      "Lansingerland",
      "Laren (Gelderland)",
      "Laren (Noord-Holland)",
      "Lathum",
      "Lattrop-Breklenkamp",
      "Lauwersoog",
      "Lauwerzijl",
      "Ledeacker",
      "Leek",
      "Leende",
      "Leens",
      "Leerbroek",
      "Leerdam",
      "Leermens",
      "Leersum",
      "Leesten",
      "Leeuwarden",
      "Leeuwen",
      "Legemeer",
      "Leggeloo",
      "Leiden",
      "Leiderdorp",
      "Leidschendam",
      "Leimuiden",
      "Leimuiderbrug",
      "Lekkerkerk",
      "Lekkum",
      "Lellens",
      "Lelystad",
      "Lemele",
      "Lemelerveld",
      "Lemiers",
      "Lemmer",
      "Lemselo",
      "Lengel",
      "Lent",
      "Lenthe",
      "Lepelstraat",
      "Lerop",
      "Lettelbert",
      "Lettele",
      "Leuken",
      "Leunen",
      "Leusden",
      "Leuth",
      "Leutingewolde",
      "Leuvenheim",
      "Leuvenum",
      "Leveroy",
      "Lewedorp",
      "Lexmond",
      "Lhee",
      "Lheebroek",
      "Lichtaard",
      "Lichtenvoorde",
      "De Lichtmis",
      "Liempde",
      "Lienden",
      "Lierderholthuis",
      "Lieren",
      "Lierop",
      "Lies (Friesland)",
      "Lies (Noord-Brabant)",
      "Lieshout",
      "Liessel",
      "Lievelde",
      "Lieveren",
      "Lijnden",
      "Limbricht",
      "Limmel",
      "Limmen",
      "Linde (De Wolden)",
      "Linde (Deventer)",
      "Linde (Bronckhorst)",
      "Linde (Twenterand)",
      "Linden",
      "Linne",
      "Linschoten",
      "Lintelo",
      "Lintvelde",
      "Lioessens",
      "Lions",
      "Lippenhuizen",
      "Lisse",
      "Lisserbroek",
      "Lith",
      "Lithoijen",
      "Limmerkoog",
      "Lobith",
      "Lochem",
      "Loenen aan de Vecht",
      "Loenen (Apeldoorn)",
      "Loenersloot",
      "Loënga",
      "Loerbeek",
      "Loil",
      "Lollum",
      "Lomm",
      "London",
      "Longerhouw",
      "Lonneker",
      "Loo (Berkelland)",
      "Loo (Bernheze)",
      "Loo (Deventer)",
      "Loo (Duiven)",
      "Loo (Helden)",
      "Loo (Uden)",
      "Loon (Assen)",
      "Loon (Deurne)",
      "Loon (Waalre)",
      "Loon op Zand",
      "Loosbroek",
      "Loosdrecht",
      "Loosduinen",
      "Loozen",
      "Lopik",
      "Lopikerkapel",
      "Loppersum",
      "Losdorp",
      "Losser",
      "Lottum",
      "Loveren (Baarle-Nassau)",
      "Loveren (Vught)",
      "Lucaswolde",
      "Luddeweer",
      "Luinjeberd",
      "Lunteren",
      "Lutjebroek",
      "Lutjegast",
      "Lutjelollum",
      "Lutjewinkel",
      "Lutkewierum",
      "Luttelgeest",
      "Lutten",
      "Luttenberg",
      "Luxwoude",
      "Luyksgestel",
      "Maarheeze",
      "Maarn",
      "Maarsbergen",
      "Maarssen",
      "Maarssenbroek",
      "Maarsseveen",
      "Maartensdijk",
      "Maasband",
      "Maasbommel",
      "Maasbracht",
      "Maasbree",
      "Maasdam",
      "Maasdijk",
      "Maashees",
      "Maaskantje",
      "Maasland",
      "Maasniel",
      "Maassluis",
      "Maastricht",
      "Maasvlakte",
      "Macharen",
      "Made",
      "Makkinga",
      "Makkum",
      "Malden",
      "Maliskamp",
      "Mamelis",
      "Mander",
      "Manderveen",
      "Mantgum",
      "Mantinge",
      "Maren-Kessel",
      "Margraten",
      "Mariaheide",
      "Maria-Hoop",
      "Mariahout",
      "Mariaparochie",
      "Mariënberg",
      "Mariënheem",
      "Mariënvelde",
      "Marijenkampen",
      "Markelo",
      "Marken",
      "Markenbinnen",
      "Marknesse",
      "Markvelde",
      "Marle (Hellendoorn)",
      "Marle (Olst-Wijhe)",
      "Marrum",
      "Marssum",
      "Martenshoek",
      "Marum",
      "Marwijksoord",
      "Mastenbroek",
      "Matsloot",
      "Maurik",
      "Mechelen",
      "Medemblik",
      "Meddo",
      "Meeden",
      "Meedhuizen",
      "Meerkerk",
      "Meerlo",
      "Meers",
      "Meerstad",
      "Meerssen",
      "Meerveld",
      "Meerveldhoven",
      "Meerwijk",
      "Meeuwen",
      "Megchelen",
      "Megen",
      "Meijel",
      "Melderslo",
      "Melick",
      "Meliskerke",
      "Melissant",
      "Menaldum",
      "Mensingeweer",
      "Meppel",
      "Meppen",
      "Merk",
      "Merkelbeek",
      "Merselo",
      "Merum (Loppersum)",
      "Merum (Roermond)",
      "Mesch",
      "Meteren",
      "Meterik",
      "Metslawier",
      "Mheer",
      "Middelaar",
      "Middelbeers",
      "Middelbert",
      "Middelburg",
      "Middelharnis",
      "Middelie",
      "Middelrode",
      "Middelstum",
      "Middenbeemster",
      "Middenmeer",
      "Midlaren",
      "Midlum",
      "Midsland",
      "Midwolda",
      "Midwolde",
      "Midwoud",
      "Miedum (Franekeradeel)",
      "Miedum (Leeuwarden)",
      "Mierlo",
      "Mierlo-Hout",
      "Mijdrecht",
      "Mijnsheerenland",
      "Mildam",
      "Milheeze",
      "Mill",
      "Millingen aan de Rijn",
      "Milsbeek",
      "Minnertsga",
      "Mirns",
      "Miste",
      "Moddergat",
      "Moerbeek",
      "Moerdijk",
      "Moergestel",
      "Moerkapelle",
      "Moerstraten",
      "Molenaarsgraaf",
      "Molenend",
      "Molenhoek",
      "Molenrij",
      "Molenschot",
      "Molkwerum",
      "Molsberg",
      "Monnickendam",
      "Monster",
      "Montfoort",
      "Montfort",
      "Mook",
      "Mookhoek",
      "Moordrecht",
      "Moorveld",
      "Morra",
      "Muggenbeet",
      "Muiden",
      "Muiderberg",
      "Munnekeburen",
      "Munnekemoer",
      "Munnekezijl",
      "Munstergeleen",
      "Muntendam",
      "Mussel",
      "Musselkanaal",
      "Naaldwijk",
      "Naarden",
      "Nagele",
      "Nattenhoven",
      "Neck",
      "Neder-Hardinxveld",
      "Nederasselt",
      "Nederhemert",
      "Nederhorst den Berg",
      "Nederland",
      "Nederweert",
      "Nederweert-Eind",
      "Nederwetten",
      "Neede",
      "Neer",
      "Neerbeek",
      "Neerijnen",
      "Neeritter",
      "Neerkant",
      "Neerloon",
      "Nes aan de Amstel",
      "Nes (Ameland)",
      "Nes (Boornsterhem)",
      "Nes (Dongeradeel)",
      "Nes (Schagen)",
      "De Nes",
      "Nessersluis",
      "Netersel",
      "Nettelhorst",
      "Netterden Neerbosch",
      "Niawier",
      "Nibbixwoud",
      "Niebert",
      "Niehove",
      "Niekerk (De Marne)",
      "Niekerk (Grootegast)",
      "Nierhoven",
      "Niersen",
      "Nietap",
      "Nieuwaal",
      "Nieuw- en Sint Joosland",
      "Nieuw-Amsterdam",
      "Nieuw-Annerveen",
      "Nieuw-Balinge",
      "Nieuw-Beerta",
      "Nieuw-Beijerland",
      "Nieuw Bergen",
      "Nieuw-Buinen",
      "Nieuw-Dordrecht",
      "Nieuw Heeten",
      "Nieuw-Helvoet",
      "Nieuw-Lekkerland",
      "Nieuw-Loosdrecht",
      "Nieuw-Milligen",
      "Nieuw-Namen",
      "Nieuw-Roden",
      "Nieuw-Scheemda",
      "Nieuw-Schoonebeek",
      "Nieuw-Vennep",
      "Nieuw-Vossemeer",
      "Nieuw-Weerdinge",
      "Nieuw-Wehl",
      "Nieuw-Zwinderen",
      "Nieuwdorp",
      "Nieuwe Krim",
      "Nieuwe Meer",
      "Nieuwe Niedorp",
      "Nieuwe Pekela",
      "Nieuwe Wetering",
      "Nieuwe-Tonge",
      "Nieuwebildtzijl",
      "Nieuwebrug (Friesland)",
      "Nieuwebrug (Noord-Holland)",
      "Nieuwebrug (Overijssel)",
      "Nieuwediep",
      "Nieuwegein",
      "Nieuwehorne",
      "Nieuwendam",
      "Nieuwendijk (Amsterdam)",
      "Nieuwendijk (Werkendam)",
      "Nieuwendijk (Zuid-Holland)",
      "Nieuwenhagen",
      "Nieuwenhoorn",
      "Nieuwer ter Aa",
      "Nieuwerbrug",
      "Nieuwerkerk aan den IJssel",
      "Nieuwerkerk",
      "Nieuweroord",
      "Nieuwersluis",
      "Nieuweschild",
      "Nieuweschoot",
      "Nieuwkoop",
      "Nieuwkuijk",
      "Nieuwland (Dongeradeel)",
      "Nieuwland (Sluis)",
      "Nieuwland (Zederik)",
      "Nieuwlande",
      "Nieuwleusen",
      "Nieuwolda",
      "Nieuwpoort (Noord-Holland)",
      "Nieuwpoort (Zuid-Holland)",
      "Nieuwstadt",
      "Nieuwveen",
      "Nieuwvliet",
      "Niezijl",
      "Niftrik",
      "Nigtevecht",
      "Nij Altoenae",
      "Nij Beets",
      "Nijbroek",
      "Nijeberkoop",
      "Nijega",
      "Nijehaske",
      "Nijeholtpade",
      "Nijeholtwolde",
      "Nijelamer",
      "Nijemirdum",
      "Nijensleek",
      "Nijetrijne",
      "Nijeveen",
      "Nijeveense Bovenboer",
      "Nijezijl",
      "Nijhoven",
      "Nijhuizum",
      "Nijkerk",
      "Nijkerkerveen",
      "Nijland",
      "Nijlande",
      "Nijmegen",
      "Nijnsel",
      "Nijswiller",
      "Nijverdal",
      "Nispen",
      "Nisse",
      "Nistelrode",
      "Nooitgedacht (Aa en Hunze)",
      "Noorbeek",
      "Noord Deurningen",
      "Noordbeemster",
      "Noordbergum",
      "Noordbroek",
      "Noorddijk (Groningen)",
      "Noorddijk (Ursem)",
      "Noordeinde (Graft)",
      "Noordeinde (Liemeer)",
      "Noordeinde (Oldebroek)",
      "Noordeinde (Oostzaan)",
      "Noordeinde (Waddinxveen)",
      "Noordeloos",
      "Noorden",
      "Noordgouwe",
      "Noordhoek (Moerdijk)",
      "Noordhoek (Rucphen)",
      "Noordhorn",
      "Noordijk",
      "Noordlaren",
      "Noord-Scharwoude",
      "Noord-Sleen",
      "Noordscheschut",
      "Noordwelle",
      "Noordwijk (Drenthe)",
      "Noordwijk (Groningen)",
      "Noordwijk (Zuid-Holland)",
      "Noordwijk aan Zee",
      "Noordwijk-Binnen",
      "Noordwijkerhout",
      "Noordwolde (Friesland)",
      "Noordwolde (Groningen)",
      "Nootdorp",
      "Norg",
      "Notter",
      "Nuenen",
      "Nuis",
      "Nuland",
      "Numansdorp",
      "Nunhem",
      "Nunspeet",
      "Nuth",
      "Nutter",
      "Obbicht",
      "Obdam",
      "Ochten",
      "Odijk",
      "Odiliapeel",
      "Odoorn",
      "Odoornerveen",
      "Oeffelt",
      "Oegstgeest",
      "Oeken",
      "Oene",
      "Oenkerk",
      "Oensel (Limburg)",
      "Oensel (Gelderland)",
      "Oerle",
      "Offingawier",
      "Ohé en Laak",
      "Oijen (Limburg)",
      "Oijen (Noord-Brabant)",
      "Oirlo",
      "Oirsbeek",
      "Oirschot",
      "Oisterwijk",
      "Okkenbroek",
      "Olburgen",
      "Oldeberkoop",
      "Oldeboorn",
      "Oldebroek",
      "Oldeholtpade",
      "Oldeholtwolde",
      "Oldehove",
      "Oldekerk",
      "Oldelamer",
      "Oldemarkt",
      "Oldenzaal",
      "Oldenzijl (Eemsmond)",
      "Oldenzijl (Winsum)",
      "Oldeouwer",
      "Oldetrijne",
      "Oler",
      "Olland",
      "Olst",
      "Olterterp",
      "Ommel",
      "Ommen",
      "Ommeren",
      "Ommerschans",
      "Onderdijk",
      "Onderdendam",
      "Onna",
      "Onnen",
      "Onstwedde",
      "Ooij (Neder-Betuwe)",
      "Ooij (Ubbergen)",
      "Ooij (Zevenaar)",
      "Ooijen",
      "Ool",
      "Oolde",
      "Ooltgensplaat",
      "Oost-Graftdijk",
      "Oost-Maarland",
      "Oost-Souburg",
      "Oost-Vlieland",
      "Oostburg",
      "Oostdijk (Reimerswaal)",
      "Oostdijk (Zuid-Holland)",
      "Oosteind",
      "Oosteinde (Aalsmeer)",
      "Oosteinde (Wester-Koggenland)",
      "Oosteinde (Eemsmond)",
      "Oosteinde (De Wolden)",
      "Oostelbeers",
      "Oostendam",
      "Oosterbeek",
      "Oosterbierum",
      "Oosterblokker",
      "Oosterend (Littenseradeel)",
      "Oosterend (Texel)",
      "Oosterend (Terschelling)",
      "Oosterhesselen",
      "Oosterhout (Gelderland)",
      "Oosterhout",
      "Oosterhuizen",
      "Oosterland (Noord-Holland)",
      "Oosterland (Zeeland)",
      "Oosterleek",
      "Oosterlittens",
      "Oostermeer",
      "Oosternieland",
      "Oosternijkerk",
      "Oosterstreek",
      "Oosterwierum",
      "Oosterwijk",
      "Oosterwijtwerd",
      "Oosterwolde (Friesland)",
      "Oosterwolde (Gelderland)",
      "Oosterzee",
      "Oosthem",
      "Oosthuizen",
      "Oostkapelle",
      "Oostknollendam",
      "Oostmahorn",
      "Oostrum (Friesland)",
      "Oostrum (Limburg)",
      "Oostvoorne",
      "Oostwold (Leek)",
      "Oostwold (Oldambt)",
      "Oostwoud",
      "Oostzaan",
      "Ootmarsum",
      "Opeinde",
      "Opende",
      "Ophemert",
      "Opheusden",
      "Ophoven",
      "Opijnen",
      "Oploo",
      "Opmeer",
      "Oppenhuizen",
      "Opperdoes",
      "Oranje",
      "Oranjedorp",
      "Oranjestad",
      "Oranjewoud",
      "Orvelte",
      "Osingahuizen",
      "Ospel",
      "Oss",
      "Ossendrecht",
      "Ossenisse",
      "Ossenwaard",
      "Ossenzijl",
      "Oterdum",
      "Oterleek",
      "Othene",
      "Otterlo",
      "Ottersum",
      "Ottoland",
      "Oud Ade",
      "Oud Avereest",
      "Oud Gastel",
      "Oud Ootmarsum",
      "Oud Osdorp",
      "Oud Sabbinge",
      "Oud-Alblas",
      "Oud-Annerveen",
      "Oud-Beijerland",
      "Oud-Leusden",
      "Oud-Loosdrecht",
      "Oud-Milligen",
      "Oud-Valkenburg",
      "Oud-Vossemeer",
      "Oud-Zevenaar",
      "Oud-Zuilen",
      "Ouddorp",
      "Oudebildtzijl",
      "Oude Leede",
      "Oude Leije",
      "Oude Meer",
      "Oude Niedorp",
      "Oude Pekela",
      "Oude Wetering",
      "Oude Willem (Ooststellingwerf)",
      "Oude Willem (Westerveld)",
      "Oude-Tonge",
      "Oudega (Gaasterland-Sloten)",
      "Oudega (Smallingerland)",
      "Oudega (Wymbritseradeel)",
      "Oudehaske",
      "Oudehorne",
      "Oudelande",
      "Oudemirdum",
      "Oudemolen (Drenthe)",
      "Oudemolen (Noord-Brabant)",
      "Oudenbosch",
      "Oudendijk (Noord-Brabant)",
      "Oudendijk (Noord-Holland)",
      "Oudenhoorn",
      "Ouderkerk aan de Amstel",
      "Ouderkerk aan den IJssel",
      "Oudeschans",
      "Oudeschild",
      "Oudeschip",
      "Oudeschoot",
      "Oudesluis (Cromstrijen)",
      "Oudesluis (Ouwerkerk)",
      "Oudesluis (Zijpe)",
      "Oudewater",
      "Oudezijl",
      "Oudkarspel",
      "Oudkerk",
      "Oudorp",
      "Oudwoude",
      "Ouwerkerk",
      "Ouwsterhaule",
      "Ouwster-Nijega",
      "Overasselt",
      "Overberg",
      "Overdinkel",
      "Overlangel",
      "Overloon",
      "Overschild",
      "Overslag",
      "Overveen",
      "Ovezande",
      "Paarlo",
      "Paasloo",
      "Padhuis",
      "Paesens",
      "Palmstad",
      "Panheel",
      "Pannerden",
      "Panningen",
      "Papekop",
      "Papendrecht",
      "Papenhoven",
      "Papenveer",
      "Papenvoort (Drenthe)",
      "Papenvoort (Noord-Brabant)",
      "Parrega",
      "Partij-Wittem",
      "Paterswolde",
      "Peelo",
      "Peest",
      "Peins",
      "Peize",
      "Peizermade",
      "Pelikaan",
      "Peperga",
      "Pernis",
      "Persingen",
      "Pesse",
      "Petten",
      "Pey",
      "Philippine",
      "Piaam",
      "Piershil",
      "Pieterburen",
      "Pietersbierum",
      "Pieterzijl",
      "Pijnacker",
      "Pikveld",
      "Pingjum",
      "Plasmolen",
      "Poederoijen",
      "Poeldijk",
      "Poeldonk",
      "Polsbroek",
      "Polsbroekerdam",
      "Poortugaal",
      "Poortvliet",
      "Poppekerke",
      "Poppingawier",
      "Posterholt",
      "Prinsenbeek",
      "Puiflijk",
      "Punthorst",
      "Purmer",
      "Purmerend",
      "Purmerland",
      "Putbroek",
      "Puth",
      "Putte",
      "Putten",
      "Puttershoek",
      "Pyramide",
      "Raalte",
      "Raamsdonk",
      "Raamsdonksveer",
      "Raar",
      "Raard",
      "Raath",
      "Radewijk",
      "Radio Kootwijk",
      "Ramspol",
      "Randwijk",
      "Ransdaal",
      "Ransdorp",
      "Rasquert",
      "Ratum",
      "Rauwerd",
      "Ravenstein",
      "Ravenswaaij",
      "Ravenswoud",
      "Rechteren",
      "Rectum",
      "Roordahuizum",
      "Reek",
      "Reeuwijk",
      "Reijmerstok",
      "Reitsum",
      "Rekken",
      "Remmerden",
      "Renesse",
      "Renkum",
      "Renswoude",
      "Ressen",
      "Retersbeek",
      "Retranchement",
      "Reusel",
      "Reutum",
      "Reuver",
      "Rha",
      "Rheden",
      "Rhederveld",
      "Rhee",
      "Rheeze",
      "Rheezerveen",
      "Rhenen",
      "Rhenoy",
      "Rhienderen",
      "Rhoon",
      "Ridderkerk",
      "Ried",
      "Riel",
      "Rien",
      "Riethoven",
      "Rietmolen",
      "Rijckholt",
      "Rijen",
      "Rijkevoort",
      "Rijnsaterwoude",
      "Rijnsburg",
      "Rijperkerk",
      "Rijpwetering",
      "Rijs",
      "Rijsbergen",
      "Rijsenburg",
      "Rijsenhout",
      "Rijsoord",
      "Rijssen",
      "Rijswijk (Gelderland)",
      "Rijswijk (Noord-Brabant)",
      "Rijswijk (Zuid-Holland)",
      "Rilland",
      "Rimburg",
      "Rincon",
      "Rinnegom",
      "Rinsumageest",
      "De Rips",
      "Rockanje",
      "Roden",
      "Roderesch",
      "Roderwolde",
      "Roelofarendsveen",
      "Roermond",
      "Rogat",
      "Roggel",
      "Rohel (De Friese Meren)",
      "Rolde",
      "Roodeschool",
      "Roodhuis",
      "Roodkerk",
      "Roordahuizum",
      "Roosendaal",
      "Roosteren",
      "'t Rooth",
      "'t Rooth",
      "Rosmalen",
      "Rossum (Gelderland)",
      "Rossum (Overijssel)",
      "Roswinkel",
      "Rothem",
      "Rotstergaast",
      "Rotsterhaule",
      "Rotterdam",
      "Rotterdam Albrands",
      "Rottevalle",
      "Ritthem",
      "Rottum (Friesland)",
      "Rottum (Groningen)",
      "Rouveen",
      "Rozenburg (Noord-Holland)",
      "Rozenburg (Zuid-Holland)",
      "Rozendaal",
      "Rucphen",
      "Ruigahuizen",
      "Ruigezand",
      "Ruigoord",
      "Ruinen",
      "Ruinerwold",
      "Ruischerbrug",
      "Rumpen",
      "Rumpt",
      "Rustenburg",
      "Rutten",
      "Ruurlo",
      "Saaksum",
      "Saasveld",
      "Saaxumhuizen",
      "Sambeek",
      "Sandfirden",
      "Santpoort-Noord",
      "Santpoort-Zuid",
      "Sappemeer",
      "Sas van Gent",
      "Sasput",
      "Sassenheim",
      "Sauwerd",
      "Schaarsbergen",
      "Schaesberg",
      "Schaft",
      "Schagen",
      "Schagerbrug",
      "Schagerwaard",
      "Schaijk",
      "Schalkhaar",
      "Schalkwijk",
      "Schalsum",
      "Schandelo",
      "Schardam",
      "Scharendijke",
      "Scharmer",
      "Scharnegoutum",
      "Scharsterbrug",
      "Scharwoude",
      "Schaveren",
      "Scheemda",
      "Scheerwolde",
      "Schelle",
      "Schellingwoude",
      "Schellinkhout",
      "Schelluinen",
      "Schermerhorn",
      "Scherpenisse",
      "Scherpenzeel (Friesland)",
      "Scherpenzeel (Gelderland)",
      "Schettens",
      "Scheulder",
      "Scheveningen",
      "Schiedam",
      "Schiermonnikoog",
      "Schietecoven",
      "Schijf",
      "Schijndel",
      "Schilberg (Echt-Susteren)",
      "Schilberg (Noorbeek)",
      "Schildwolde",
      "Schimmert",
      "Schin op Geul",
      "Schingen",
      "Schinnen",
      "Schinveld",
      "Schipborg",
      "Schiphol",
      "Schiphol-Centrum",
      "Schiphol-Oost",
      "Schiphol-Rijk",
      "Schipluiden",
      "Schipperskerk",
      "Schoonbron",
      "Schoondijke",
      "Schoonebeek",
      "Schoonheten",
      "Schoonhoven",
      "Schoonloo",
      "Schoonoord (Drenthe)",
      "Schoonoord (Noord-Brabant)",
      "Schoonrewoerd",
      "Schoorl",
      "Schoorldam",
      "Schore",
      "Schoterzijl",
      "Schouwerzijl",
      "Schraard",
      "Schuddebeurs (Schouwen-Duiveland)",
      "Schuddebeurs (Hulst)",
      "Schuilenburg (Friesland)",
      "Schuilenburg (Overijssel)",
      "Schuilingsoord",
      "Schuinesloot",
      "Schweiberg",
      "Sebaldeburen",
      "Sellingen",
      "Serooskerke (Schouwen-Duiveland)",
      "Serooskerke (Veere)",
      "Sevenum",
      "Sexbierum",
      "'s-Graveland",
      "'s-Gravendeel",
      "'s-Gravenhage",
      "'s-Gravenmoer",
      "'s-Gravenpolder",
      "'s-Gravenzande",
      "'s-Heer Abtskerke",
      "'s-Heer Arendskerke",
      "'s-Heer Hendrikskinderen",
      "'s-Heerenberg",
      "'s-Heerenbroek",
      "'s-Heerenhoek",
      "'s-Hertogenbosch",
      "Sibbe",
      "Sibculo",
      "Siddeburen",
      "Siebengewald",
      "Siegerswoude (Opsterland)",
      "Siegerswoude (Tietjerksteradeel)",
      "Sijbekarspel",
      "Sijbrandaburen",
      "Sijbrandahuis",
      "Silvolde",
      "Simonshaven",
      "Simpelveld",
      "Sinderen (Oude IJsselstreek)",
      "Sinderen (Voorst)",
      "Sint Agatha",
      "Sint Anna ter Muiden",
      "Sint-Annaland",
      "Sint Annaparochie",
      "Sint Annen",
      "Sint Anthonis",
      "Sint Geertruid",
      "Sint Gerlach",
      "Sint Hubert",
      "Sint Isidorushoeve",
      "Sint-Jacobiparochie",
      "Sint Jansklooster",
      "Sint Jansteen",
      "Sintjohannesga",
      "Sint Joost",
      "Sint Kruis",
      "Sint Laurens",
      "Sint Maarten",
      "Sint Maartensbrug",
      "Sint-Maartensdijk",
      "Sint Maartensvlotbrug",
      "Sint Maartenszee",
      "Sint Michielsgestel",
      "Sint Nicolaasga",
      "Sint Odiliënberg",
      "Sint-Oedenrode",
      "Sint Pancras",
      "Sint Philipsland",
      "Sirjansland",
      "Sittard",
      "Slagharen",
      "Slangenburg",
      "Slappeterp",
      "Sleen",
      "Sleeuwijk",
      "Slek",
      "Slenaken",
      "Sliedrecht",
      "Sliffert",
      "Slijkenburg",
      "Slijk-Ewijk",
      "Slikkerveer",
      "Slochteren",
      "Slootdorp",
      "Sloterdijk (Amsterdam)",
      "Sloten (Noord-Holland)",
      "Sloten (Friesland)",
      "Sluis",
      "Sluiskil",
      "Smakt",
      "Smalle Ee",
      "Smallebrugge",
      "Smilde",
      "Snakkerburen",
      "Sneek",
      "Snelrewaard",
      "Snikzwaag",
      "Soerendonk",
      "Soest",
      "Soestdijk",
      "Soestduinen",
      "Soesterberg",
      "Someren",
      "Someren-Eind",
      "Someren-Heide",
      "Sommelsdijk",
      "Son",
      "Sondel",
      "Sonnega",
      "Spaarndam",
      "Spaarndam-West",
      "Spaarnwoude",
      "Spakenburg",
      "Spanbroek",
      "Spanga",
      "Spankeren",
      "Spannum",
      "Spaubeek",
      "Spekhoek",
      "Speuld",
      "Spier",
      "Spierdijk",
      "Spijk (Aalburg)",
      "Spijk (Delfzijl)",
      "Spijk (West Betuwe)",
      "Spijk (Rijnwaarden)",
      "Spijkenisse",
      "Spijkerboor (Drenthe)",
      "Spijkerboor (Noord-Holland)",
      "Spoolde",
      "Sprang-Capelle",
      "Sprundel",
      "Spui",
      "Stad aan 't Haringvliet",
      "Stadskanaal",
      "Stampersgat",
      "Standdaarbuiten",
      "Staphorst",
      "Starnmeer",
      "Startenhuizen",
      "Stavenisse",
      "Staverden",
      "Stavoren",
      "Stedum",
      "Steenbergen",
      "Steenbergen (De Wolden)",
      "Steenbergen (Noordenveld)",
      "Steendam",
      "Steenderen",
      "Steenenkamer",
      "Steensel",
      "Steenwijk",
      "Steenwijkerwold",
      "Steenwijksmoer",
      "Stegeren",
      "Steggerda",
      "Stein",
      "Stellendam",
      "Stepelo",
      "Sterksel",
      "Stevensbeek",
      "Stevensweert",
      "Steyl",
      "Stieltjeskanaal",
      "Stiens",
      "Stiphout",
      "Stitswerd",
      "St. Johns",
      "Stokhem",
      "Stokkum (Montferland)",
      "Stokkum (Overijssel)",
      "Stolpervlotbrug",
      "Stolwijk",
      "Stompetoren",
      "Stompwijk",
      "Stoutenburg",
      "Stramproy",
      "Streefkerk",
      "Striep",
      "Strijbeek",
      "Strijen",
      "Strijensas",
      "Stroe (Gelderland)",
      "Stroe (Noord-Holland)",
      "Stroet",
      "Stroobos (Friesland)",
      "Stroobos (Groningen)",
      "Strucht",
      "Stuifzand",
      "St. Willebrord",
      "Suameer",
      "Suawoude",
      "Surhuisterveen",
      "Surhuizum",
      "Susteren",
      "Swalmen",
      "Swartbroek",
      "Sweikhuizen",
      "Swichum",
      "Swier",
      "Swifterbant",
      "Swolgen",
      "'t Buurtje",
      "'t Goy",
      "'t Haagje",
      "'t Haantje (Drenthe)",
      "'t Haantje (Zuid-Holland)",
      "'t Harde",
      "'t Loo",
      "'t Rooth",
      "'t Veld",
      "'t Waar",
      "'t Woudt",
      "'t Zand (Alphen-Chaam)",
      "'t Zand (Boxmeer)",
      "'t Zand (Drimmelen)",
      "'t Zand (Lent)",
      "'t Zand (Werkendam)",
      "'t Zand (Zijpe)",
      "'t Zandt",
      "Taarlo",
      "Teeffelen",
      "Teerns",
      "Tegelen",
      "Ten Arlo",
      "Ten Boer",
      "Ten Esschen",
      "Ten Post",
      "Ter Aar",
      "Ter Aard",
      "Ter Apel",
      "Ter Apelkanaal",
      "Ter Heijde",
      "Ter Idzard",
      "Terband",
      "Terblijt",
      "Terborg",
      "Terdiek",
      "Tergracht",
      "Terheijden",
      "Terheijl",
      "Terhole",
      "Terhorne",
      "Terhorst (Drenthe)",
      "Terhorst (Limburg)",
      "Terkaple",
      "Terlinden",
      "Termaar (Voerendaal)",
      "Termaar (Margraten)",
      "Termunten",
      "Termunterzijl",
      "Ternaard",
      "Terneuzen",
      "Teroele",
      "Terschuur",
      "Terzool",
      "Tervoorst",
      "Terwispel",
      "Terwolde",
      "Terziet",
      "Terzool",
      "Teteringen",
      "Teuge",
      "The Bottom",
      "Thesinge",
      "Thij",
      "Tholen",
      "Thorn",
      "Thull",
      "Tibma",
      "Tiel",
      "Tiendeveen",
      "Tienhoven (Stichtse Vecht)",
      "Tienhoven (Texel)",
      "Tienhoven (Vianen)",
      "Tienhoven (Zederik)",
      "Tienray",
      "Tietjerk",
      "Tijnje",
      "Tilburg",
      "Tilligte",
      "Tinallinge",
      "Tinte",
      "Tirns",
      "Tjalhuizum",
      "Tjalleberd",
      "Tjallewal",
      "Tjarnsweer",
      "Tjerkgaast",
      "Tjerkwerd",
      "Tjuchem",
      "Tolbert",
      "Toldijk",
      "Tolkamer",
      "Tolke",
      "Tollebeek",
      "Tommel",
      "Tonden",
      "Tongeren (Gelderland)",
      "Tongeren (Noord-Brabant)",
      "Tongeren (Overijssel)",
      "Tongerlo (Maasbree)",
      "Tongerlo (Sevenum)",
      "Toornwerd",
      "Treebeek",
      "Tricht",
      "Triemen",
      "Trimunt",
      "Trintelen",
      "Tripscompagnie",
      "Tubbergen",
      "Tuil",
      "Tuindorp (Rijnwaarden)",
      "Tuitjenhorn",
      "Tuk",
      "Tull en 't Waal",
      "Tungelroy",
      "2e Exloërmond",
      "2e Valthermond",
      "Twello",
      "Twijzel",
      "Twijzelerheide",
      "Twisk",
      "Tynaarlo",
      "Tzum",
      "Tzummarum",
      "Ubachsberg",
      "Ubbena",
      "Ubbergen",
      "Uddel",
      "Uden",
      "Udenhout",
      "Uffelte",
      "Ugchelen",
      "Uitdam",
      "Uitgeest",
      "Uithoorn",
      "Uithuizen",
      "Uithuizermeeden",
      "Uitwellingerga",
      "Uitwierde",
      "Uitwijk",
      "Ulestraten",
      "Ulft",
      "Ulicoten",
      "Ulrum",
      "Ulsda",
      "Ulvenhout",
      "Ureterp",
      "Urk",
      "Urmond",
      "Ursem",
      "Usquert",
      "Usselo",
      "Utrecht",
      "Vaals",
      "Vaassen",
      "Vaesrade",
      "Valburg",
      "Valkenburg (Limburg)",
      "Valkenburg (Zuid-Holland)",
      "Valkenswaard",
      "Valkkoog",
      "Valthe",
      "Valthermond",
      "Van Ewijcksluis",
      "Varik",
      "Varsen",
      "Varssel",
      "Varsselder",
      "Varsseveld",
      "Vasse",
      "Veelerveen",
      "Veen",
      "Veendam",
      "Veenendaal",
      "Veenhuizen (Coevorden)",
      "Veenhuizen (Heerhugowaard)",
      "Veenhuizen (Noordenveld)",
      "Veenhuizen (Stadskanaal)",
      "Veeningen",
      "Veenklooster",
      "Veenoord",
      "Veenwouden",
      "Veere",
      "Veessen",
      "Vegelinsoord",
      "Veghel",
      "Velddriel",
      "Velden",
      "Veldhoven",
      "Veldhunten",
      "Velp (Gelderland)",
      "Velp (Noord-Brabant)",
      "Velsen-Noord",
      "Velsen-Zuid",
      "Velserbroek",
      "Velswijk",
      "Veltum",
      "Venebrugge",
      "Venhorst",
      "Venhuizen",
      "Venlo",
      "Venray",
      "Ven-Zelderheide",
      "Verwolde",
      "Vessem",
      "Vethuizen",
      "Veulen",
      "Vianen (Noord-Brabant)",
      "Vianen (Utrecht)",
      "Vierakker",
      "Vierhouten",
      "Vierhuizen",
      "Vierlingsbeek",
      "Vierpolders",
      "Vijfhuizen",
      "Vijlen",
      "Vilsteren",
      "Vilt",
      "Vinkega",
      "Vinkel",
      "Vinkenbuurt",
      "Vinkeveen",
      "Visserweert",
      "Visvliet",
      "Vlaardingen",
      "Vlagtwedde",
      "Vledder",
      "Vledderveen (Drenthe)",
      "Vledderveen (Groningen)",
      "Vleuten",
      "Vlieghuis",
      "Vlierden",
      "Vlijmen",
      "Vlissingen",
      "Vlist",
      "Vlodrop",
      "Voerendaal",
      "Vogelenzang",
      "Vogelwaarde",
      "Volendam",
      "Volkel",
      "Vollenhove",
      "Volthe",
      "Vondelingenplaat",
      "Voorburg",
      "Voorhout",
      "Voorschoten",
      "Voorst (Oude IJsselstreek)",
      "Voorst (Voorst)",
      "Voorstonden",
      "Voorthuizen",
      "Vorchten",
      "Vorden",
      "Vorstenbosch",
      "Vortum-Mullem",
      "Voulwames",
      "Vragender",
      "Vredenheim",
      "Vredepeel",
      "Vreeland",
      "Vreeswijk",
      "Vries",
      "Vriescheloo",
      "Vriezenveen",
      "Vriezenveensewijk",
      "Vrijhoeve-Capelle",
      "Vroomshoop",
      "Vrouwenakker",
      "Vrouwenparochie",
      "Vrouwenpolder",
      "Vught",
      "Vuile Riete",
      "Vuren",
      "Waal",
      "Waalre",
      "Waalwijk",
      "Waarde",
      "Waardenburg",
      "Waarder",
      "Waardhuizen",
      "Waarland",
      "Waaxens (Dongeradeel)",
      "Waaxens (Littenseradeel)",
      "Wachtum",
      "Waddinxveen",
      "Wadenoijen",
      "Wadway",
      "Wagenberg",
      "Wagenborgen",
      "Wageningen",
      "Wahlwiller",
      "Walem",
      "Walsoorden",
      "Wamberg",
      "Wamel",
      "Wanneperveen",
      "Wanroij",
      "Wanssum",
      "Wanswerd",
      "Wapenveld",
      "Wapse",
      "Wapserveen",
      "Warder",
      "Warffum",
      "Warfhuizen",
      "Warfstermolen",
      "Warga",
      "Warken",
      "Warm",
      "Warmenhuizen",
      "Warmond",
      "Warns",
      "Warnsveld",
      "Warstiens",
      "Wartena",
      "Waskemeer",
      "Waspik",
      "Wassenaar",
      "Wateren",
      "Watergang",
      "Waterhuizen",
      "Wateringen",
      "Waterlandkerkje",
      "Waubach",
      "Waver",
      "Waverveen",
      "Wechterholt",
      "Wedde",
      "Wedderveer",
      "Weebosch",
      "Weerdinge",
      "Weerselo",
      "Weert",
      "Weert (Meerssen)",
      "Weesp",
      "Wehe-den Hoorn",
      "Wehl",
      "Weidum",
      "Weijerswold",
      "Weiteveen",
      "Weiwerd",
      "Wekerom",
      "Welberg",
      "Well (Gelderland)",
      "Well (Limburg)",
      "Wellerlooi",
      "Welsrijp",
      "Welsum (Dalfsen)",
      "Welsum (Olst-Wijhe)",
      "Welten",
      "Wemeldinge",
      "Wengelo",
      "Wenum-Wiesel",
      "Werkendam",
      "Werkhoven",
      "Wernhout",
      "Wervershoof",
      "Wesepe",
      "Wessel",
      "Wessem",
      "West-Graftdijk",
      "West-Souburg",
      "West-Terschelling",
      "Westbeemster",
      "Westbroek (Limburg)",
      "Westbroek (Utrecht)",
      "Westdorp (Drenthe)",
      "Westdorpe",
      "Westelbeers",
      "Westendorp",
      "Westenholte",
      "Westenschouwen",
      "Westerbeek",
      "Westerblokker",
      "Westerbork",
      "Westerbroek",
      "Westeremden",
      "Westergeest (Friesland)",
      "Westergeest (Noord-Holland)",
      "Westerhaar-Vriezenveensewijk",
      "Westerhoven",
      "Westerklief",
      "Westerland",
      "Westerlee (Groningen)",
      "Westerlee (Zuid-Holland)",
      "Westernieland",
      "Westervelde",
      "Westervoort",
      "Westerwijtwerd",
      "Westhem",
      "Westhoek (Duiveland)",
      "Westhoek (Het Bildt)",
      "Westhoek (Walcheren)",
      "Westkapelle",
      "Westknollendam",
      "Westlaren",
      "Westmaas",
      "Westwoud",
      "Westzaan",
      "Wetering",
      "Weteringbrug",
      "Wetsens",
      "Wetsinge",
      "Weurt",
      "Weustenrade",
      "Wezep",
      "Wezup",
      "Wezuperbrug",
      "Wichmond",
      "Wieken",
      "Wieldrecht",
      "Wiene",
      "Wier",
      "Wierden",
      "Wieringerwaard",
      "Wieringerwerf",
      "Wierum (Groningen)",
      "Wierum (Overijssel)",
      "Wierum (Friesland)",
      "Wiesel",
      "Wieuwerd",
      "Wijbosch",
      "Wijchen",
      "Wijckel",
      "Wijdenes",
      "Wijdewormer",
      "Wijhe",
      "Wijk aan Zee",
      "Wijk bij Duurstede",
      "Wijk en Aalburg",
      "Wijlre",
      "Wijnaldum",
      "Wijnandsrade",
      "Wijnbergen",
      "Wijngaarden",
      "Wijnjewoude",
      "Wijns",
      "Wijnvoorden",
      "Wijster",
      "Wijtgaard",
      "Wijthmen",
      "Wilbertoord",
      "Wildenborch",
      "Wildervanksterdallen",
      "Wildervank",
      "Wilhelminadorp",
      "Wilhelminaoord",
      "Willemsdorp",
      "Willemsoord",
      "Willemstad",
      "Willeskop",
      "Willige Langerak",
      "Wilnis",
      "Wilp",
      "Wilsum",
      "Winde",
      "Windesheim",
      "Windraak",
      "Windwardside",
      "Winkel",
      "Winneweer",
      "Winschoten",
      "Winssen",
      "Winsum (Friesland)",
      "Winsum (Groningen)",
      "Wintelre",
      "Winterswijk",
      "Winthagen",
      "Wirdum (Friesland)",
      "Wirdum (Groningen)",
      "Wissenkerke",
      "Witharen",
      "Witmarsum",
      "Witte Paarden",
      "Wittelte",
      "Witten",
      "Witteveen",
      "Wittewierum",
      "Woensdrecht",
      "Woerden",
      "Woerdense Verlaat",
      "Woeste Hoeve",
      "Woezik",
      "Wogmeer",
      "Wognum",
      "Woldendorp",
      "Wolfhaag",
      "Wolfhagen",
      "Wolfheze",
      "Wolphaartsdijk",
      "Wolsum",
      "Woltersum",
      "Wolvega",
      "Wommels",
      "Wons",
      "Woold",
      "Workum",
      "Wormer",
      "Wormerveer",
      "Woubrugge",
      "Woudbloem",
      "Woudenberg",
      "Woudrichem",
      "Woudsend",
      "Wouterswoude",
      "Wouw",
      "Wouwse Plantage",
      "Yde",
      "Yerseke",
      "Ypecolsga",
      "Ysbrechtum",
      "Ysselsteyn",
      "Zaamslag",
      "Zaandam",
      "Zaandijk",
      "Zalk",
      "Zaltbommel",
      "Zandberg",
      "Zandeweer",
      "Zandhuizen (Friesland)",
      "Zandpol",
      "Zandstraat",
      "Zandvoort",
      "Zeddam",
      "Zeegse",
      "Zeeland",
      "Zeelst",
      "Zeerijp",
      "Zeewolde",
      "Zegge",
      "Zegveld",
      "Zeijen",
      "Zeijerveen",
      "Zeijerveld",
      "Zeilberg",
      "Zeist",
      "Zeldam",
      "Zelhem",
      "Zenderen",
      "Zennewijnen",
      "Zetten",
      "Zevenaar",
      "Zevenbergen",
      "Zevenbergschen Hoek",
      "Zevenhoven",
      "Zevenhuizen (Kaag en Braassem)",
      "Zevenhuizen (Bunschoten)",
      "Zevenhuizen (Eemsmond)",
      "Zevenhuizen (Heeze-Leende)",
      "Zevenhuizen (Kollumerland en Nieuwkruisland)",
      "Zevenhuizen (Leek)",
      "Zevenhuizen (Moerdijk)",
      "Zevenhuizen (Texel)",
      "Zevenhuizen (Tietjerksteradeel)",
      "Zevenhuizen (Zuidplas zuid-holland)",
      "Zevenhuisjes",
      "Zierikzee",
      "Zieuwent",
      "Zijderveld",
      "Zijdewind",
      "Zijldijk",
      "Zijpersluis",
      "Zijtaart",
      "Zions Hill",
      "Zoelen",
      "Zoelmond",
      "Zoetermeer",
      "Zoeterwoude",
      "Zonnemaire",
      "Zorgvlied",
      "Zoutelande",
      "Zoutkamp",
      "Zuid-Beijerland",
      "Zuid-Eierland (Texel)",
      "Zuidbroek (Groningen)",
      "Zuidbroek (Noord-Holland)",
      "Zuiddorpe",
      "Zuideinde",
      "Zuidermeer",
      "Zuiderwoude",
      "Zuidhorn",
      "Zuidlaarderveen",
      "Zuidland",
      "Zuidlaren",
      "Zuidoostbeemster",
      "Zuid-Scharwoude",
      "Zuidschermer",
      "Zuidveen",
      "Zuidveld",
      "Zuidvelde",
      "Zuidwolde (Drenthe)",
      "Zuidwolde (Groningen)",
      "Zuidzande",
      "Zuidzijde (Bodegraven)",
      "Zuidzijde (Hoeksche Waard)",
      "Zuidzijde (Oostflakkee)",
      "Zuilichem",
      "Zuna",
      "Zunderdorp",
      "Zundert",
      "Zurich",
      "Zutphen",
      "Zuurdijk",
      "Zwaag",
      "Zwaagdijk",
      "Zwaagwesteinde",
      "Zwaanshoek",
      "Zwagerbosch",
      "Zwammerdam",
      "Zwanenburg",
      "Zwartsluis",
      "Zwartebroek",
      "Zwarte Haan",
      "Zwartemeer",
      "Zwartewaal",
      "Zweeloo",
      "Zweins",
      "Zwiep",
      "Zwilbroek",
      "Zwiggelte",
      "Zwingelspaan",
      "Zwijndrecht",
      "Zwinderen",
      "Zwolle",
      "Zwolle (Gelderland)"
  ]