import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import ProfileSidebar from "../components/ProfileSidebar";
import TopBar from "../components/TopBar";
export default function Profile({ language, setLanguage }) {
  const user = useSelector(selectUser);

  return (
    <div className="bg-[#0a0215]">
      <div
        className="mt-[80px] !z-[10] hero-section relative min-h-[100vh] "
        style={{ background: "#0a0215" }}
      >
        <TopBar setLanguage={setLanguage} />
        <div className="flex items-center justify-center">
          <div className="lg:w-[80%] max-lg:w-full">
            <div className="flex flex-col items-start text-start max-lg:hidden pt-[60px]">
              <h1 className="text-start text-[50px]">
                Hallo {user.first_name}
              </h1>
              <hr className="border-[#302640] mt-[20px] w-full " />
            </div>
            <div className="lg:max-w-[20%]">
              <ProfileSidebar user={user} language={language} setLanguage={setLanguage}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
