import React, { useRef, useState, useEffect, useCallback } from "react";
import Icon from "./Icon";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import { adjustTime } from "../helpers/helpers";
import SpinWinEventCard from "./SpinWinEventCard";
import { useTranslation } from "react-i18next";
import SuggestionsModal from "./SuggestionsModal";
export default function SpinWinEventSection({
  formattedCurrentDate,
  filters,
  currentCity,
  filterFunctions,
  isCarousel = true,
  showBanner = false,
}) {
  const { t } = useTranslation();
  const scrollContainerRef = useRef(null);
  const [showSuggestionsModal, setShowSuggestionsModal] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [spinWinEvents, setSpinWinEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSpinWinEvents = useCallback(async () => {
    setLoading(true);

    try {
      const { data, error } = await supabase
        .from("Events")
        .select("*, venue:Venue(*)")
        .eq("is_spin_win", true)
        .eq("spin_win_live", true)
        .order("spin_win_display_order", { ascending: true });

      if (error) {
        console.error("Error fetching spin & win events:", error);
      } else {
        // First filter events that are currently active
        let activeEvents = data.filter((event) => {
          const signupUntil = dayjs(adjustTime(event.signup_until));
          const currentTime = dayjs(formattedCurrentDate);
          return currentTime < signupUntil;
        });

        // Then apply all the same filters from the main event list
        if (filterFunctions && filters) {
          activeEvents = filterFunctions.filterByDate(
            activeEvents,
            filters.datum.toLowerCase()
          );
          activeEvents = filterFunctions.filterByCategory(activeEvents);
          activeEvents = filterFunctions.filterByGenre(activeEvents);
          activeEvents = filterFunctions.filterByAge(activeEvents);
          activeEvents = filterFunctions.filterByCity(activeEvents);
        }

        // .reverse() mutates the original array, so both references show the reversed array
        // To log both the original and reversed order, we need to make a copy first
        // Sort by display order, handling null/undefined values
        const sortedEvents = [...activeEvents].sort((a, b) => {
          // If either value is null/undefined, treat it as Infinity to put at end
          const orderA = a.spin_win_display_order ?? Infinity;
          const orderB = b.spin_win_display_order ?? Infinity;
          return orderA - orderB;
        });
        setSpinWinEvents(sortedEvents);
      }
    } catch (err) {
      console.error("Failed to fetch spin & win events:", err);
    } finally {
      setLoading(false);
    }
  }, [formattedCurrentDate, filters, currentCity, filterFunctions]);

  useEffect(() => {
    fetchSpinWinEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, currentCity]);

  useEffect(() => {
    if (spinWinEvents.length > 0) {
      setTimeout(() => {
        checkScrollButtons();
      }, 100);
    }
  }, [spinWinEvents]);

  const checkScrollButtons = useCallback(() => {
    const container = scrollContainerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      const maxScroll = container.scrollWidth - container.clientWidth;
      setCanScrollRight(container.scrollLeft < maxScroll - 1);
    }
  }, []);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", checkScrollButtons);
      checkScrollButtons();
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", checkScrollButtons);
      }
    };
  }, []);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth * 0.8;
      const newScrollPosition =
        container.scrollLeft +
        (direction === "right" ? scrollAmount : -scrollAmount);
      container.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });

      setTimeout(() => {
        checkScrollButtons();
      }, 500);
    }
  };

  if (spinWinEvents.length === 0 && window.location.href.includes("spin-and-win")) {
    return (
      <div className="flex flex-col items-center justify-center pt-12 w-full">
        {/* Add Suggestions Modal */}
        <SuggestionsModal
          show={showSuggestionsModal}
          setShow={setShowSuggestionsModal}
          isFeedback={false}
        />
        <button
          onClick={() => {
            setShowSuggestionsModal(true);
          }}
          className="!w-full max-lg:h-[110px] text-center event-content text-white doubles flex flex-col items-center justify-center"
        >
          <h2 className="text-[18px] text-white font-[600] leading-[24px] tracking-normal">
            {t("event_not_on_the_list")}
          </h2>
          <p className="mt-1 text-[14px] lg:text-[16px] font-normal leading-[22px] tracking-normal">
            {t("let_us_know")}
          </p>
        </button>
      </div>
    );
  }

  return (
    <section
      className={`max-lg:px-[16px] relative ${
        spinWinEvents.length > 0 && "lg:mb-8 mt-[24px] lg:mt-[32px]"
      }`}
    >
      <div
        className={`flex justify-between items-center ${
          spinWinEvents.length > 0 && "pb-[14px]"
        }`}
      >
        {spinWinEvents.length > 0 && (
          <h1 className="w-full text-start text-[22px] leading-[26px] lg:text-[24px] lg:leading-[36px] text-white">
            {t("spin_win_tickets")}
          </h1>
        )}

        {/* Navigation Buttons - Only show in carousel mode */}
        {isCarousel && spinWinEvents.length > 1 && (
          <div className="flex items-center gap-5 lg:mr-7">
            <button
              onClick={() => scroll("left")}
              disabled={!canScrollLeft}
              className={`p-2 rounded-full shadow-lg transition-all duration-200 bg-white
                ${
                  canScrollLeft
                    ? "hover:bg-gray-50 lg:cursor-pointer opacity-100"
                    : "cursor-not-allowed opacity-40"
                }`}
              aria-label="Scroll left"
            >
              <Icon
                icon="heroicons:chevron-left"
                className="h-[20px] w-[20px] text-gray-800"
              />
            </button>
            <button
              onClick={() => scroll("right")}
              disabled={!canScrollRight}
              className={`p-2 rounded-full shadow-lg transition-all duration-200 bg-white
                ${
                  canScrollRight
                    ? "hover:bg-gray-50 lg:cursor-pointer opacity-100"
                    : "cursor-not-allowed opacity-40"
                }`}
              aria-label="Scroll right"
            >
              <Icon
                icon="heroicons:chevron-right"
                className="h-[20px] w-[20px] text-gray-800"
              />
            </button>
          </div>
        )}
      </div>

      {!loading && (
        <>
          {isCarousel ? (
            // Carousel Layout
            <div
              ref={scrollContainerRef}
              className="w-full flex overflow-x-auto gap-6 scroll-smooth hide-scrollbar"
              style={{
                scrollSnapType: "x mandatory",
                touchAction: "auto",
                overflowY: "visible",
              }}
            >
              {spinWinEvents.map((event, index) => (
                <div
                  key={index}
                  className="flex-none w-full md:w-[calc(33.33%-16px)] scroll-snap-align-start py-1 lg:pl-2"
                  style={{
                    scrollSnapAlign: "start",
                    userSelect: "auto",
                    pointerEvents: "auto",
                    touchAction: "auto",
                  }}
                >
                  <SpinWinEventCard
                    eventCardData={event}
                    singleEvent={true}
                    showBanner={true}
                  />
                </div>
              ))}
            </div>
          ) : (
            // Grid Layout
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {spinWinEvents.map((event, index) => (
                <div key={index} className="py-1">
                  <SpinWinEventCard
                    eventCardData={event}
                    singleEvent={true}
                    showBanner={true}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {/* Add this CSS to your global styles */}
      <style>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        /* Fix for mobile vertical scrolling */
        @media (max-width: 768px) {
          .event-content {
            touch-action: auto !important;
            pointer-events: auto !important;
            -webkit-overflow-scrolling: touch !important;
          }

          /* Ensure the body and html can scroll */
          html, body {
            touch-action: auto !important;
            overflow-y: auto !important;
            -webkit-overflow-scrolling: touch !important;
          }

          /* Make sure the container doesn't block vertical scrolling */
          .w-full.flex.overflow-x-auto {
            touch-action: pan-x pan-y !important;
            -webkit-overflow-scrolling: touch !important;
          }
        }
      `}</style>
    </section>
  );
}
