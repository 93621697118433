import React from "react";
import { Link } from "react-router-dom";
import CalendarIcon from "../assets/Calendar.svg";
import DealIcon from "../assets/deal-icon.png";
import SpinAndWinIcon from "../assets/spin-and-win.png";

export default function MobileMenu({ path }) {
  return (
    <div className="z-[100] bottom-navbar text-white fixed bottom-0 w-full gap-[32px] py-[16px] flex items-center justify-center">
      <Link
        to="/"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "/" && "text-[#C9A6F5]"
        }`}
      >
        <img
          src={CalendarIcon}
          style={{
            filter:
              path === "/" || path.includes("/event")
                ? "invert(70%) sepia(21%) saturate(1087%) hue-rotate(215deg) brightness(181%) contrast(92%)"
                : "none",
          }}
          className="h-[30px] w-[30px]"
        />
        Events
      </Link>
      <Link
        to="/spin-and-win"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "/spin-and-win" && "text-[#C9A6F5]"
        }`}
      >
        <img src={SpinAndWinIcon} className="h-[30px] w-[30px]" />
        Win tickets
      </Link>
      <Link
        to="/lifestyle"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "/lifestyle" && "text-[#C9A6F5]"
        }`}
      >
        <img
          src={DealIcon}
          alt="Shopping bag"
          style={{
            filter: path.includes("/lifestyle")
              ? "invert(70%) sepia(21%) saturate(1087%) hue-rotate(215deg) brightness(181%) contrast(92%)"
              : "none",
          }}
          className="h-[30px] w-[30px]"
        />
        Deals
      </Link>
      {/*
      {user.id ? (
        <Link
          to="/profile"
          className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
            path.includes("/profile") && "text-[#C9A6F5]"
          }`}
        >
          <img
            src={UserIcon}
            style={{
              filter: path.includes("/profile")
                ? "invert(70%) sepia(21%) saturate(1087%) hue-rotate(215deg) brightness(251%) contrast(92%)"
                : "none",
            }}
            className="h-[30px] w-[30px]"
          />
          {user.first_name}
        </Link>
      ) : (
        <Link
          to="/email-login"
          className={`text-[12px] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center`}
        >
          <img
            src={UserIcon}
            style={{
              filter: path.includes("/login")
                ? "invert(70%) sepia(21%) saturate(1087%) hue-rotate(215deg) brightness(251%) contrast(92%)"
                : "none",
            }}
            className={`${
              path.includes("/profile") && "fill-[#C9A6F5"
            } h-[30px] w-[30px]`}
          />
          Login
        </Link>
      )} */}
    </div>
  );
}
