import React, { useState, useEffect } from "react";
import { Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { cities } from "../cities";

export default function EventSignupForm({
  eFormData,
  formErrors,
  setEFormData,
  setFormErrors,
  event,
  refForm,
  soldOutStatus,
  setBtnDisabled,
  handleSubmit,
  btnDisabled,
  showMobile = true,
  isDeal = false,
}) {
  const { t } = useTranslation();
  const [dobPlaceHolder, setDobPlaceHolder] = useState(t("date_of_birth"));
  const [citySearch, setCitySearch] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);

  const focusDob = () => {
    setDobPlaceHolder("dd-mm-jjjj");
  };

  const blurDob = () => {
    setDobPlaceHolder(t("date_of_birth"));
  };

  const onChange = (e) => {
    if (soldOutStatus) return; // Prevent changes if sold out
    setEFormData({ ...eFormData, [e.target.name]: e.target.value });
  };

  const isValidDate = (dateString) => {
    // Check format is correct (dd-mm-yyyy)
    if (!/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      return false;
    }

    // Parse the date parts
    const [day, month, year] = dateString.split("-").map(Number);

    // Create date object and verify parts match (this handles invalid dates like 31-04-2024)
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  };

  const changeDob = (e) => {
    const value = e.target.value;
    let formattedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    let placeholder = "dd-mm-jjjj";
    let cursorPosition = formattedValue.length;
    const key = e.nativeEvent.inputType;

    const padWithZero = (number) => {
      return number.toString().padStart(2, "0");
    };

    // Format day (dd)
    if (formattedValue.length >= 2) {
      let day = parseInt(formattedValue.slice(0, 2), 10);

      if (day < 1) {
        day = 1;
      } else if (day > 31) {
        day = 31;
      }

      formattedValue = padWithZero(day) + "-" + formattedValue.slice(2);
      placeholder = formattedValue + placeholder.slice(3);
      cursorPosition = 3;
    }

    // Format month (mm)
    if (formattedValue.length >= 5) {
      let month = parseInt(formattedValue.slice(3, 5), 10);

      if (month < 1) {
        month = 1;
      } else if (month > 12) {
        month = 12;
      }

      formattedValue =
        formattedValue.slice(0, 3) +
        padWithZero(month) +
        "-" +
        formattedValue.slice(5);
      placeholder = placeholder.slice(0, 3) + "-mm-" + placeholder.slice(5);
      cursorPosition = 6;
    }

    // Format year (jjjj)
    if (formattedValue.length >= 10) {
      let year = parseInt(formattedValue.slice(6, 10), 10);

      const minYear = 1930;
      const maxYear = new Date().getFullYear();

      if (year < minYear) {
        year = minYear;
      } else if (year > maxYear) {
        year = maxYear;
      }

      formattedValue =
        formattedValue.slice(0, 6) + year.toString().padStart(4, "0");

      // Validate if the date actually exists
      if (!isValidDate(formattedValue)) {
        // If date is invalid, adjust to last valid date of the month
        const [day, month, year] = formattedValue.split("-").map(Number);
        const lastDayOfMonth = new Date(year, month, 0).getDate();
        formattedValue = `${padWithZero(
          Math.min(day, lastDayOfMonth)
        )}-${padWithZero(month)}-${year}`;
      }
    }

    if (
      key === "deleteContentBackward" &&
      (formattedValue.length === 6 || formattedValue.length === 3)
    ) {
      formattedValue =
        formattedValue.slice(0, cursorPosition - 1) +
        formattedValue.slice(cursorPosition);
      cursorPosition -= 1;
    }

    setEFormData({ ...eFormData, dob: formattedValue });
    setDobPlaceHolder(placeholder);
  };

  useEffect(() => {
    const filtered = cities.filter((city) =>
      city.toLowerCase().startsWith(citySearch.toLowerCase())
    );
    setFilteredCities(filtered);
  }, [citySearch]);

  const changeCitySearch = (e) => {
    setCitySearch(e.target.value);
    onChange({
      target: {
        name: "location",
        value: e.target.value,
      },
    });
  };

  return (
    <section
      className={`event-form-sec w-full lg:max-w-[50%] lg:w-[700px] relative ${
        !isDeal && "lg:mt-20 mt-12"
      } ${!showMobile ? "max-lg:hidden" : ""}`}
    >
      <div>
        <div className="event-information-form " ref={refForm}>
          <h3 className="text-white form-heading">{t("your_details")}</h3>

          {soldOutStatus && (
            <div className="mb-6 text-center">
              <h3 className="text-[22px] font-semibold text-red-500">
                {t("event_sold_out")}
              </h3>
            </div>
          )}

          <form>
            <div className="first-name event-sec-forms">
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder={t("first_name")}
                  name="Firstname"
                  value={eFormData.Firstname}
                  className={formErrors.Firstname ? "sin error-field" : "sin"}
                  onInput={onChange}
                  disabled={soldOutStatus}
                  style={soldOutStatus ? { opacity: 0.6, cursor: "not-allowed" } : {}}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  placeholder={t("last_name")}
                  name="Lastname"
                  value={eFormData.Lastname}
                  className={formErrors.Lastname ? "sin error-field" : "sin"}
                  onInput={onChange}
                  disabled={soldOutStatus}
                  style={soldOutStatus ? { opacity: 0.6, cursor: "not-allowed" } : {}}
                />
              </div>
            </div>
            <div className="email event-sec-form">
              <Input
                type="email"
                name="Email"
                id="email"
                value={eFormData.Email}
                placeholder={t("email")}
                className={formErrors.Email ? "sin error-field" : "sin"}
                onInput={onChange}
                disabled={soldOutStatus}
                style={soldOutStatus ? { opacity: 0.6, cursor: "not-allowed" } : {}}
              />
            </div>
            <div
              className={
                formErrors.dob
                  ? "date event-sec-form error-field"
                  : "date event-sec-form"
              }
            >
              <Input
                type="text"
                maxLength="10"
                id="date"
                name="dob"
                value={eFormData.dob}
                inputMode="numeric"
                className={formErrors.dob ? "sin error-field" : "sin"}
                placeholder={dobPlaceHolder}
                onFocus={focusDob}
                onBlur={blurDob}
                onChange={changeDob}
                autoComplete="off"
                disabled={soldOutStatus}
                style={soldOutStatus ? { opacity: 0.6, cursor: "not-allowed" } : {}}
              />
            </div>

            <div className="geslacht event-sec-form sin">
              <select
                id="geslacht"
                className={
                  formErrors.gender
                    ? "cx-ui-select error-field"
                    : "cx-ui-select"
                }
                name="gender"
                size="1"
                data-filter="false"
                data-placeholder=""
                onChange={onChange}
                disabled={soldOutStatus}
                style={soldOutStatus ? { opacity: 0.6, cursor: "not-allowed" } : {}}
              >
                <option value="gender" disabled selected>
                  {t("gender")}
                </option>
                <option value="Man">{t("male")}</option>
                <option value="Vrouw">{t("female")}</option>
                <option value="Onzijdig">{t("other")}</option>
              </select>
            </div>

            <div className="w-full location event-sec-form sin">
              <div className="relative w-full">
                <Input
                  type="text"
                  value={citySearch}
                  onChange={changeCitySearch}
                  placeholder={t("search_city")}
                  className={formErrors.location ? "sin error-field" : "sin"}
                  autoComplete="off"
                  disabled={soldOutStatus}
                  style={soldOutStatus ? { opacity: 0.6, cursor: "not-allowed" } : {}}
                />
                {!soldOutStatus && citySearch && filteredCities.length > 0 && (
                  <ul className="absolute z-10 w-full bg-[#130826] border border-gray-700 rounded-b-[4px] max-h-[200px] overflow-y-auto">
                    {filteredCities.map((city, index) => (
                      <li
                        key={index}
                        className="px-4 py-2 cursor-pointer text-white hover:bg-gray-700"
                        onClick={() => {
                          setFilteredCities([]);
                          onChange({
                            target: {
                              name: "location",
                              value: city,
                            },
                          });
                          setCitySearch(city);
                        }}
                      >
                        {city}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div
              className={
                formErrors.agreement ? "checkbox error-field" : "checkbox"
              }
            >
              <input
                type="checkbox"
                id="agreement"
                name="agreement"
                onChange={onChange}
                disabled={soldOutStatus}
                style={soldOutStatus ? { opacity: 0.6, cursor: "not-allowed" } : {}}
              />
              <label htmlFor="agreement">
                {t("i_agree_to_the")}{" "}
                <a
                  href="https://22night.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("terms_and_conditions")}
                </a>
              </label>
            </div>

            <div className="max-lg:hidden">
              <hr className="w-full max-lg:hidden !my-[24px] !border-gray-800" />

              <div className="flex justify-between w-full items-center">
                <h3 className="!pb-0 text-[18px] font-semibold text-white">
                  {t("community_price")}
                </h3>
                <h3 className="!pb-0 text-[22px] font-semibold text-white">
                  {event.currency}
                  {isDeal ? event.our_price : event.our_price.toFixed(2)}
                </h3>
              </div>
              <div className="flex justify-between w-full items-center">
                <h3 className="text-[16px] font-semibold text-neutral-300">
                  {t("original_price")}
                </h3>
                <h3 className="text-[16px] line-through font-semibold text-neutral-300">
                  {event.currency}
                  {isDeal
                    ? event.original_price
                    : event.original_price.toFixed(2)}
                </h3>
              </div>
              <div className="price-button w-full ">
                <button
                  className={`mt-[16px] !w-full button openPopupButtons !text-[17px] !py-[24px] !px-[30px] ${
                    soldOutStatus
                      ? "bg-gray-500 cursor-not-allowed"
                      : "premium-button"
                  }`}
                  type="button"
                  onClick={handleSubmit}
                  disabled={soldOutStatus || btnDisabled}
                >
                  {soldOutStatus
                    ? t("event_sold_out")
                    : isDeal
                    ? "Claim deal"
                    : event.category === "Community ticket"
                    ? "Claim ticket"
                    : "Claim Guestlist Spot"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div id="popups" className="popups">
        <div className="popup-contents">
          <img
            src="assets/image svg/Illustration.svg"
            className="Illustration"
          />
          <p className="Hier">{t("here_is_your_community_ticket")}</p>
          <p className="Je">
            Je ontvangt een e-mail van de organisatie zodra de betaling is
            afgerond.
          </p>
          <a href="index.html" className="Terug">
            {t("back_to_home")}
          </a>
        </div>
      </div>
    </section>
  );
}
