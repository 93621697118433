import { useEffect, useState } from "react";
// import axios from 'axios';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import "./Home.css";
// import Cards from '../../components/Cards';
import DiscoverEvent from "../../components/DiscoverEvent";
import Carousel from "../../components/Carousel";
import SearchBar from "../../components/SearchBar";
import EventsList from "../../components/EventsList";
import { adjustTime, adminUserIds } from "../../helpers/helpers";
import NoEvents from "../../components/NoEvents";
import EventItemPreloader from "../../components/EventItemPreloader";
// import eventItems from '../../utils/mockupData.json';
import React from "react";
import { supabase } from "../../supabaseClient";
import LanguageIcon from "../../assets/Language.svg";
import TopBar from "../../components/TopBar";
import { useTranslation } from "react-i18next";
import TrendingEvents from "../../components/TrendingEvents";
import SpinWinEventSection from "../../components/SpinWinEventSection";
import { useSelector } from "react-redux";
import { selectUser } from "../../slices/userReducer";
import { useNavigate } from "react-router-dom";

export const CustomSelect = ({ options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    onSelect(option); // Propagate the selected option to the parent component
    setIsOpen(false); // Close the dropdown
  };

  return (
    <div className="">
      <button
        className="flex justify-between items-center w-full"
        onClick={handleToggleDropdown}
      >
        {/* {selectedOption ? selectedOption.label : "Select an option"} */}
        <img src={LanguageIcon} className="h-12 w-12 text-white" />
      </button>
      {isOpen && (
        <ul className="rounded-[10px] absolute right-[50px] w-64 list-none m-0 p-0 bg-[#130826] z-10">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
              className="px-4 py-2 text-white cursor-pointer"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

dayjs.extend(utc);
dayjs.extend(timezone);

const Home = ({
  token,
  eventData,
  genres,
  eventsLoading,
  setEventsLoading,
  language,
  setLanguage,
}) => {
  // Get city from URL query parameter or localStorage
  const [queryParams, setQueryParams] = useState(
    new URLSearchParams(window.location.search)
  );
  const cityFromUrl = queryParams.get("city");
  const capitalizeFirstLetter = (string) => {
    return string
      ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      : string;
  };
  const defaultCity =
    capitalizeFirstLetter(cityFromUrl) ||
    localStorage.getItem("city") ||
    "Amsterdam";

  // Store city from URL in localStorage if present
  if (cityFromUrl) {
    localStorage.setItem("city", capitalizeFirstLetter(cityFromUrl));
  } else if (!localStorage.getItem("city")) {
    // Ensure we always have a city in localStorage
    localStorage.setItem("city", "all_cities");
  }

  const [onlineEvents, setOnlineEvents] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tabLoading, setTabLoading] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const [filters, setFilters] = useState({
    datum: "Alle Datums",
    categorie: "Alle events",
    genre: [],
    leeftijd: "Alle leeftijden",
    city: defaultCity,
  });

  const [currentCity, setCurrentCity] = useState(defaultCity);

  const [cities, setCities] = useState([]);

  const [currentNetherlandsTime, setCurrentNetherlandsTime] = useState(() => {
    const netherlandsTime = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Amsterdam",
    });

    return netherlandsTime;
  });

  const formattedCurrentDate = dayjs(currentNetherlandsTime).format(
    "YYYY-MM-DD HH:mm:ss"
  );

  const [trendingEvents, setTrendingEvents] = useState([]);

  // Get the current user
  const user = useSelector(selectUser);

  // Add this near the top of your Home component, after other state declarations
  const [tokenDefaultFiltersApplied, setTokenDefaultFiltersApplied] =
    useState(false);

  const [hasScrolled, setHasScrolled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setQueryParams(params);
  }, [window.location.search]);

  useEffect(() => {
    const cityParam = queryParams.get("city");
    const newCity = cityParam || defaultCity;

    // Ensure newCity is never empty or any variation of "all_cities" when it shouldn't be
    if (!newCity || newCity === "" || newCity.toLowerCase() === "all_cities") {
      setCurrentCity(defaultCity);
      setFilters((prev) => ({
        ...prev,
        city: defaultCity,
      }));
    } else {
      setCurrentCity(newCity);
      setFilters((prev) => ({
        ...prev,
        city: newCity,
      }));
    }
  }, [queryParams, defaultCity]);

  useEffect(() => {
    supabase
      .from("City")
      .select("*")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          setCities(data);
        }
      });

    const timer = setInterval(() => {
      setCurrentNetherlandsTime(() => {
        const netherlandsTime = new Date().toLocaleString("en-US", {
          timeZone: "Europe/Amsterdam",
        });
        return netherlandsTime;
      }); // Update current date and time
    }, 1000); // Update every second (adjust as needed)

    return () => {
      clearInterval(timer); // Clean up the timer when the component unmounts
    };
  }, []);

  useEffect(() => {
    filterAll();
    // setFilteredData(eventData);
    if (eventData.length > 0) {
      setEventsLoading(false);
    }
  }, [eventData, filters, currentCity]);

  useEffect(() => {
    if (eventData.length > 0) {
      setTrendingEvents(
        filteredData.filter((event) => event.trending === true)
      );
    }
  }, [filteredData, formattedCurrentDate]);

  // Add this useEffect to fetch and apply token default filters
  useEffect(() => {
    console.log("tokenDefaultFiltersApplied", tokenDefaultFiltersApplied);
    if (
      !tokenDefaultFiltersApplied &&
      user &&
      user.token &&
      !localStorage.getItem("userSetFilters")
    ) {
      const fetchTokenDefaultFilters = async () => {
        console.log("fetching token default filters");
        try {
          const { data, error } = await supabase
            .from("Token")
            .select("default_filters")
            .eq("name", user.token)
            .single();

          if (error) {
            console.error("Error fetching token default filters:", error);
            return;
          }

          console.log("data", data.default_filters);
          if (data && data.default_filters) {
            const defaultFilters = data.default_filters;

            // Handle genres
            let genreValue = Array.isArray(defaultFilters.genre)
              ? defaultFilters.genre
              : defaultFilters.genre?.split(",").map((g) => g.trim()) || [];

            // Apply the filters
            setFilters((prev) => ({
              ...prev,
              datum: defaultFilters.datum || prev.datum,
              categorie: defaultFilters.categorie || prev.categorie,
              genre: genreValue,
              leeftijd: defaultFilters.leeftijd || prev.leeftijd,
              city: defaultFilters.cities?.[0] || prev.city, // Set first city as current
              cities: defaultFilters.cities || [], // Keep the cities array
            }));

            // Update current city
            if (defaultFilters.cities && defaultFilters.cities.length > 0) {
              setCurrentCity(defaultFilters.cities[0]);
            }

            // Apply language setting if present
            console.log("defaultFilters", defaultFilters);
            if (defaultFilters.language) {
              console.log("defaultFilters.language", defaultFilters.language);
              setLanguage(defaultFilters.language);
              localStorage.setItem("language", defaultFilters.language);
            }

            setTokenDefaultFiltersApplied(true);
            filterAll();

            // Handle landing page redirect
            if (
              defaultFilters.landing_page === "deals" &&
              window.location.pathname === "/"
            ) {
              navigate("/lifestyle");
              console.log("redirecting to lifestyle");
              return;
            }
          }
        } catch (err) {
          console.error("Error fetching token default filters:", err);
        }
      };

      fetchTokenDefaultFilters();
    }
  }, [user, tokenDefaultFiltersApplied, navigate, window.location.pathname]);

  // Add this logic to track when user sets their own filters
  useEffect(() => {
    // Track when a user actively changes filters
    if (!tokenDefaultFiltersApplied) return;

    // Save a flag that user has set their own filters
    localStorage.setItem("userSetFilters", "true");
  }, [filters]);

  const filterAll = () => {
    const filteredByDates = filterByDate(eventData);
    const filteredByCategory = filterByCategory(filteredByDates);
    const filteredByGenres = filterByGenre(filteredByCategory);
    const filteredByAge = filterByAge(filteredByGenres);
    const filteredByCity = filterByCity(filteredByAge);

    const filteredByPublished = filteredByCity.filter((event) => {
      const signupUntil = dayjs(adjustTime(event.signup_until));
      const currentTime = dayjs(formattedCurrentDate);

      // If signup_until is between midnight and 4 AM, show the event
      if (signupUntil.hour() >= 0 && signupUntil.hour() <= 4) {
        return currentTime < signupUntil;
      }
      // For other events, ensure current time hasn't passed signup_until
      return currentTime < signupUntil;
    });

    setFilteredData(filteredByPublished);
    setOnlineEvents(
      filteredByCity.filter(
        (event) =>
          formattedCurrentDate < adjustTime(event.signup_from) &&
          event.city === currentCity
      )
    );
  };

  const filterByDate = (
    arr,
    dateFilter = filters["datum"].toLocaleLowerCase()
  ) => {
    let filteredByDates = [];

    const filterStatus = dateFilter;
    // Ensure we're using the same timezone for all date comparisons
    const today = dayjs().tz("Europe/Amsterdam").locale(language);
    const tomorrow = today.add(1, "day");
    const startOfWeek = today.startOf("week");
    const endOfWeek = today.endOf("week");
    const startOfNextWeek = today.add(1, "week").startOf("week");
    const endOfNextWeek = today.add(1, "week").endOf("week");
    const startOfMonth = today.startOf("month");
    const endOfMonth = today.endOf("month");

    // Helper function to safely parse event datetime
    const parseEventDate = (event) => {
      if (!event.datetime) return null;

      // If it's a number, treat as Unix timestamp
      if (typeof event.datetime === "number") {
        return dayjs.unix(event.datetime).tz("Europe/Amsterdam");
      }

      // If it's a string that looks like a number, convert to number first
      if (typeof event.datetime === "string" && /^\d+$/.test(event.datetime)) {
        return dayjs.unix(parseInt(event.datetime, 10)).tz("Europe/Amsterdam");
      }

      // Otherwise try to parse as ISO date
      return dayjs(event.datetime).tz("Europe/Amsterdam");
    };

    switch (filterStatus) {
      case "vandaag":
      case "today": {
        filteredByDates = arr.filter((event) => {
          const eventDate = parseEventDate(event);
          if (!eventDate || !eventDate.isValid()) {
            console.log(`Invalid date for event: ${event.name}`);
            return false;
          }
          return eventDate.isSame(today, "day");
        });
        break;
      }
      case "morgen":
      case "tomorrow": {
        filteredByDates = arr.filter((event) => {
          const eventDate = parseEventDate(event);
          if (!eventDate || !eventDate.isValid()) return false;
          return eventDate.isSame(tomorrow, "day");
        });
        break;
      }
      case "deze week":
      case "this week": {
        filteredByDates = arr.filter((event) => {
          const eventDate = parseEventDate(event);
          if (!eventDate || !eventDate.isValid()) return false;
          return (
            eventDate.isAfter(startOfWeek) &&
            eventDate.isBefore(endOfWeek.add(1, "day"))
          );
        });
        break;
      }
      case "volgende week":
      case "next week": {
        filteredByDates = arr.filter((event) => {
          const eventDate = parseEventDate(event);
          if (!eventDate || !eventDate.isValid()) return false;
          return (
            eventDate.isAfter(startOfNextWeek) &&
            eventDate.isBefore(endOfNextWeek.add(1, "day"))
          );
        });
        break;
      }
      case "deze maand":
      case "this month": {
        filteredByDates = arr.filter((event) => {
          const eventDate = parseEventDate(event);
          if (!eventDate || !eventDate.isValid()) return false;
          return (
            eventDate.isAfter(startOfMonth) &&
            eventDate.isBefore(endOfMonth.add(1, "day"))
          );
        });
        break;
      }
      default:
        filteredByDates = arr;
        break;
    }

    return filteredByDates;
  };

  const filterByCategory = (arr) => {
    let filteredByCategory = [];
    const filterStatus = filters["categorie"].toLowerCase();
    switch (filterStatus) {
      case "dag event":
      case "day event":
        filteredByCategory = arr.filter(
          (event) =>
            event.event_type.toLowerCase() === "day event" ||
            event.event_type.toLowerCase() === "dag event"
        );
        break;
      case "nacht event":
      case "night event":
        filteredByCategory = arr.filter(
          (event) =>
            event.event_type.toLowerCase() === "night event" ||
            event.event_type.toLowerCase() === "nacht event"
        );
        break;
      case "festival":
        filteredByCategory = arr.filter(
          (event) => event.event_type.toLowerCase() === "festival"
        );
        break;
      default:
        filteredByCategory = arr;
        break;
    }

    return filteredByCategory;
  };

  const filterByGenre = (arr) => {
    const filterStatus = filters["genre"]?.map((genre) => genre.toLowerCase()); // array

    // If no genres are selected, return all events
    if (!filterStatus || filterStatus.length === 0) {
      return arr;
    }

    // Filter events that have at least one of the selected genres
    return arr.filter((event) => {
      if (!event.genres) return false;

      const eventGenres = event.genres
        .split(",")
        .map((genre) => genre.trim().toLowerCase());

      return eventGenres.some((eventGenre) =>
        filterStatus.includes(eventGenre.toLowerCase())
      );
    });
  };

  const filterByAge = (arr) => {
    let filteredByAge = [];
    const filterStatus = filters["leeftijd"];

    switch (filterStatus) {
      case "18+":
        filteredByAge = arr.filter((event) => +event.minimum_age <= 18);
        break;
      case "21+":
        filteredByAge = arr.filter(
          (event) => +event.minimum_age <= 21 && +event.minimum_age > 18
        );
        break;
      case "23+":
        filteredByAge = arr.filter(
          (event) => +event.minimum_age <= 23 && +event.minimum_age > 21
        );
        break;
      default:
        filteredByAge = arr; // "All ages" shows all events
        break;
    }
    return filteredByAge;
  };

  const filterByCity = (arr) => {
    let filteredByCity = [];
    const filterStatus = filters["city"];

    if (filterStatus && filterStatus.toLowerCase() === "all_cities") {
      filteredByCity = arr;
    } else if (!filterStatus || filterStatus === "") {
      // Handle case when filterStatus is empty or undefined
      filteredByCity = arr.filter((event) => event.city.includes(defaultCity));
    } else {
      filteredByCity = arr.filter((event) => event.city.includes(filterStatus));
    }

    return filteredByCity;
  };

  const changeCity = (city) => {
    localStorage.setItem("city", city);
    // Mark that user has set their own filters
    localStorage.setItem("userSetFilters", "true");

    setTabLoading(true);
    setFilters((prev) => ({
      ...prev,
      city: city,
    }));
    setCurrentCity(city);

    filterAll();

    setTimeout(() => {
      setTabLoading(false);
    }, 200);
  };

  const { t } = useTranslation();

  const showOnlineEventsSection = onlineEvents.length > 0;

  useEffect(() => {
    // Try to detect scrolling in any element
    const handleScrollAnywhere = () => {
      // Get scroll position from the documentElement (most reliable cross-browser)
      const windowScroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

      // Check if window is at the very top (0 position)
      if (windowScroll <= 50) {
        // Only check containers if window is at the top
        const containers = document.querySelectorAll(".scroller");
        let containerScrolled = false;

        containers.forEach((container) => {
          if (container.scrollTop > 10) {
            containerScrolled = true;
          }
        });

        // If window is at top and no containers are scrolled, immediately set hasScrolled to false
        if (!containerScrolled) {
          setHasScrolled(false);
        }
      } else {
        // If window is scrolled, immediately set hasScrolled to true
        setHasScrolled(true);
      }
    };

    // Initial check
    handleScrollAnywhere();

    // Add multiple event listeners to catch all possible scroll events
    window.addEventListener("scroll", handleScrollAnywhere, { passive: true });
    document.addEventListener("scroll", handleScrollAnywhere, {
      passive: true,
    });

    // Force checking the scroll position at regular intervals
    const intervalId = setInterval(handleScrollAnywhere, 10);

    // Also apply a fallback class after some time has passed
    const timeoutId = setTimeout(() => {
      if (!hasScrolled) {
        setHasScrolled(true);
      }
    }, 5000);

    return () => {
      window.removeEventListener("scroll", handleScrollAnywhere);
      document.removeEventListener("scroll", handleScrollAnywhere);
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [hasScrolled]);

  const getCityDisplayText = (cities) => {
    if (!cities || cities.length === 0) return t("all_cities");
    if (cities.length === 1) return cities[0];
    return t("multiple_cities", { count: cities.length });
  };

  return (
    <div className="bg-[#2a0744] min-h-screen relative">
      <div className="bg-[#0B0415] flex items-center justify-center relative flex-col">
        <div className="max-lg:w-[100vw] lg:w-[95%] max-w-[1400px] relative">
          <TopBar
            setLanguage={setLanguage}
            eventData={eventData}
            formattedCurrentDate={formattedCurrentDate}
          />
          <div
            className={`transition-all max-lg:pb-[20px] lg:py-[60px] ${
              hasScrolled ? "" : "max-lg:pt-[90px] "
            }`}
          >
            <div
              className={`max-lg:bg-[#12041f] transition-all max-lg:fixed max-lg:left-0 max-lg:right-0 max-lg:w-full flex items-center justify-between ${
                hasScrolled
                  ? "pt-[70px] max-lg:backdrop-blur-lg max-lg:z-[999]"
                  : ""
              }`}
            >
              <div className="flex items-center justify-start gap-3 max-lg:overflow-x-auto max-lg:w-full max-lg:pb-2 max-lg:scrollbar-hide">
                {/* Filters */}
                <DiscoverEvent
                  filters={filters}
                  setFilters={setFilters}
                  token={token}
                  city={currentCity}
                  genres={genres}
                  changeCity={changeCity}
                />
              </div>
              <div className="max-lg:hidden flex items-center justify-end gap-3">
                {/* Search Bar */}
                <SearchBar
                  eventData={eventData}
                  formattedCurrentDate={formattedCurrentDate}
                  onSearchDismiss={() => {}}
                  isSearchActive={isSearchActive}
                  setIsSearchActive={setIsSearchActive}
                />
              </div>
            </div>
            <h1 className="max-lg:px-[16px] text-start w-full pt-[60px] lg:pt-[60px] text-white lg:text-[72px] text-[36px] leading-[40px] lg:leading-[80px]">
              {t("guestlist")} & Community tickets in{" "}
              <br className="max-lg:hidden" />
              <span className="text-[#C9A6F5]">
                {getCityDisplayText(filters.cities)}
              </span>
            </h1>
          </div>
          <hr className="absolute left-0 right-0 w-full border-r border-[#AD7AEF80]" />
        </div>

        <div
          className="scroller min-h-[66.6vh] flex justify-center w-full"
          style={{
            background:
              "linear-gradient(to bottom, #0B0415 0%, #1d0630 40vh, #2a0744 60vh)",
          }}
        >
          <div className="max-w-[1400px] lg:w-[95%] max-lg:w-[100vw]">
            <h2 className="max-lg:hidden text-white max-lg:!px-[16px] pt-[50px]">
              {t("discover_events")}
            </h2>

            {/* Spin & Win Events Section - Only visible to admins */}
            {!isSearchActive && (
              <SpinWinEventSection
                formattedCurrentDate={formattedCurrentDate}
                filters={filters}
                currentCity={currentCity}
                filterFunctions={{
                  filterByDate,
                  filterByCategory,
                  filterByGenre,
                  filterByAge,
                  filterByCity,
                }}
              />
            )}

            {trendingEvents.length > 0 && (
              <div className="mt-[24px] lg:mt-[32px]">
                <TrendingEvents trendingEvents={trendingEvents} />
              </div>
            )}

            <section className="max-lg:!px-[16px] zoek-evenementen !pb-[80px]">
              {eventsLoading || tabLoading ? (
                <div className="w-full">
                  <EventItemPreloader />

                  {/* {isDivider && ( */}
                  <div className="card-divider"></div>
                  {/* )} */}
                </div>
              ) : filteredData.length > 0 ? (
                <>
                  <EventsList
                    eventsData={filteredData}
                    currentCity={currentCity}
                    isDivider={showOnlineEventsSection}
                    formattedCurrentDate={formattedCurrentDate}
                    language={language}
                    cities={filters.cities}
                  />
                </>
              ) : (
                <>
                  <NoEvents />
                </>
              )}

              {/* <NoEvents /> */}
            </section>

            {showOnlineEventsSection && (
              <>
                {/* <div className="card-divider"></div> */}
                <section>
                  <div className="">
                    <h2 className="binnenkort">{t("coming_soon")}</h2>
                    <p className="text-white text-p">
                      {t("want_to_be_reminded")}
                    </p>
                  </div>
                </section>
              </>
            )}
            {showOnlineEventsSection && (
              <section className="pb-[80px]">
                <Carousel
                  onlineEvents={onlineEvents}
                  city={currentCity}
                  formattedCurrentDate={formattedCurrentDate}
                />
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
