import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import { CustomSelect } from "./home/Home";
import Icon from "../components/Icon";
import { cities } from "../cities";
import { availableGenres } from "../helpers/helpers";
import { supabase } from "../supabaseClient";
import { useTranslation } from "react-i18next";
import ProfileSidebar from "../components/ProfileSidebar";
import ProfileGenresForm from "../components/ProfileGenresForm";
import ProfileInfoForm from "../components/ProfileInfoForm";

export default function ProfileInfo({ language, setLanguage }) {
  const user = useSelector(selectUser);

  const [formData, setFormData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    date_of_birth: user.date_of_birth,
    gender: user.gender,
    location: user.location,
    genres: user.genres?.split(", ") ?? [],
  });

  const [citySearch, setCitySearch] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);

  useEffect(() => {
    setCitySearch(user.location);
  }, [user.location]);

  const changeCitySearch = (e) => {
    setCitySearch(e.target.value);
    const filtered = cities.filter((city) =>
      city.toLowerCase().startsWith(citySearch.toLowerCase())
    );
    setFilteredCities(filtered);
  };

  const addOrRemoveGenre = (genre) => {
    if (formData.genres.includes(genre)) {
      const genreCopy = [...formData.genres];

      const i = formData.genres?.indexOf(genre);
      genreCopy.splice(i, 1);
      // Remove genre
      setFormData({ ...formData, genres: genreCopy });
    } else {
      // Add genre
      setFormData({ ...formData, genres: [...formData.genres, genre] });
    }
  };

  const navigate = useNavigate();

  const saveProfile = async () => {
    try {
      // First update auth user metadata
      const { data: authUpdate, error: authError } = await supabase.auth
        .updateUser({
          data: {
            ...formData,
            genres: formData.genres?.join(", ") ?? "",
          },
        });

      if (authError) throw authError;

      // Then update the public.User table
      const { error: userError } = await supabase
        .from("User")
        .update({
          first_name: formData.first_name,
          last_name: formData.last_name,
          date_of_birth: formData.date_of_birth,
          gender: formData.gender,
          location: formData.location,
          genres: formData.genres?.join(", ") ?? "",
        })
        .eq("auth_user_id", user.id);

      if (userError) throw userError;

      navigate("/profile");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div className="bg-[#0a0215]">
      <div
        className="hero-section relative min-h-[100vh]"
        style={{ background: "#0a0215" }}
      >
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/profile">
              <Icon
                icon="heroicons:arrow-left"
                className="text-white h-12 w-12"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="lg:w-[80%] max-lg:w-full">
            <div className="flex flex-col items-start text-start max-lg:hidden pt-[60px]">
              <h1 className="text-start text-[50px]">
                Hallo {user.first_name}
              </h1>
              <hr className="border-[#302640] mt-[20px] w-full " />
            </div>
            <div className="flex max-lg:flex-col w-full gap-12">
              <div className="max-lg:hidden w-[20%]">
                <ProfileSidebar user={user} language={language} setLanguage={setLanguage} />
              </div>
              <div className="lg:w-[40%]">
                <ProfileInfoForm
                  formData={formData}
                  setFormData={setFormData}
                  saveProfile={saveProfile}
                  citySearch={citySearch}
                  setCitySearch={setCitySearch}
                  filteredCities={filteredCities}
                  setFilteredCities={setFilteredCities}
                  changeCitySearch={changeCitySearch}
                />
              </div>
              <div className="flex justify-center lg:w-[40%]">
                <div className="lg:pt-12 lg:w-full max-lg:w-[90%]">
                  <ProfileGenresForm
                    availableGenres={availableGenres}
                    formData={formData}
                    addOrRemoveGenre={addOrRemoveGenre}
                  />
                </div>
              </div>
            </div>
            <div className="lg:pt-12 max-lg:w-full lg:w-[calc(100%-16rem)] flex max-lg:justify-center lg:ml-[calc(20%+3.5rem)]">
              <div className="lg:w-[40%] max-lg:w-[90%]">
                <button
                  type="submit"
                  onClick={() => {
                    saveProfile();
                  }}
                  className="mb-[100px] mt-[24px] !disabled:cursor-not-allowed disabled:bg-[#56466B] text-[16px] font-semibold btn enabled:bg-gradient-to-r shadow-md enabled:to-[#7721E5] enabled:from-[#6A1BD1] text-white w-full px-[20px] py-[14px] rounded-[8px] hover:text-white"
                >
                  Opslaan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
