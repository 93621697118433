import React from "react";
import { Link } from "react-router-dom";
import LanguageIcon from "../assets/Language.svg";
import TiktokIcon from "../assets/tiktok-icon.png";
import InstagramIcon from "../assets/instagram-icon.png";
import { supabase } from "../supabaseClient";
import { useState, useEffect } from "react";
import SuggestionsModal from "./SuggestionsModal";

export default function Footer({ language, setLanguage }) {
  const [cities, setCities] = useState([]);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  useEffect(() => {
    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data || []);
      });
  }, []);

  return (
    <div className="bg-[#12041f] border-t border-white/20 max-lg:pb-[100px]">
      <SuggestionsModal
        show={showFeedbackModal}
        setShow={setShowFeedbackModal}
        isFeedback={true}
      />
      <div className={`max-w-[1400px] w-[90%] mx-auto`}>
        <div className="py-[48px] grid grid-cols-4 max-lg:grid-cols-1 gap-[32px]">
          <div className="flex flex-col gap-[8px]">
            <div className="text-[18px] font-semibold text-white">
              Community Events
            </div>
            {cities.map((city) => (
              <Link
                key={city.id}
                to={`/?city=${city.name}`}
                className="text-[14px] text-white font-normal"
              >
                {city.name}
              </Link>
            ))}
          </div>
          <div className="flex flex-col gap-[8px]">
            <div className="text-[18px] font-semibold text-white">
              Community Lifestyle Deals
            </div>
            {cities.map((city) => (
              <Link
                key={city.id}
                to={`/deal?city=${city.name}`}
                className="text-[14px] text-white font-normal"
              >
                {city.name}
              </Link>
            ))}
          </div>
          <div className="flex flex-col gap-[8px]">
            <div className="text-[18px] font-semibold text-white">Support</div>
            <Link to={`/faq`} className="text-[14px] text-white font-normal">
              Frequently asked questions
            </Link>
            <button
              onClick={() => setShowFeedbackModal(true)}
              className="text-start text-[14px] text-white font-normal"
            >
              Give feedback
            </button>
          </div>
          <div className="flex flex-col gap-[8px]">
            <div className="text-[18px] font-semibold text-white">
              Work with us
            </div>
            <Link
              to="https://wa.me/31617476764"
              target="_blank"
              className="text-[14px] text-white font-normal"
            >
              Venues
            </Link>
            <Link
              to="https://wa.me/31617476764"
              target="_blank"
              className="text-[14px] text-white font-normal"
            >
              Promoters
            </Link>
            <Link
              to="https://wa.me/31617476764"
              target="_blank"
              className="text-[14px] text-white font-normal"
            >
              Artists
            </Link>
          </div>
        </div>
        <div className="flex lg:items-center justify-between max-lg:flex-col max-lg:gap-[16px]">
          <div className="text-[14px] text-white">
            © 2025 22NIGHT •{" "}
            <span className="">
              <Link className="text-[14px] font-normal" to={`/privacy`}>
                Privacy
              </Link>
            </span>{" "}
          </div>
          <div className="flex lg:items-center lg:gap-[8px] max-lg:flex-col">
            <div className="max-lg:mt-[12px] lg:p-[12px] flex items-center justify-start gap-[12px] !text-white rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg hover:cursor-pointer">
              <img
                src={LanguageIcon}
                className="h-[30px] w-[30px] text-white"
              />
              <select
                value={"language"}
                onChange={(e) => {
                  setLanguage(e.target.value);
                }}
                className="focus:ring-0 !outline-none !border-none w-[90%] cursor-pointer pl-[12px] text-[16px] rounded-lg font-normal !text-white bg-transparent appearance-none"
              >
                <option value="language" disabled>
                  Language
                </option>
                <option value="nl">Nederlands</option>
                <option value="en">English</option>
              </select>
            </div>
            <div className="flex lg:gap-[8px] max-lg:mt-[12px]">
              <Link
                to="https://www.tiktok.com/@22night.worldwide"
                target="_blank"
                className="lg:p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
              >
                <img
                  src={TiktokIcon}
                  className="h-[30px] w-[30px]"
                  alt="Tiktok logo"
                />
              </Link>
              <Link
                to="https://www.instagram.com/22night.worldwide/"
                target="_blank"
                className="p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
              >
                <img
                  src={InstagramIcon}
                  className="h-[35px] w-[35px]"
                  alt="Instagram logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
