import { Link, useNavigate } from "react-router-dom";
import { LogoSvg } from "../utils/svg";
import ArrowImg from "../assets/image svg/arrow-left.svg";
import popupImg from "../assets/image svg/Illustration.svg";
import "./thanks/thanksPage.css";
import { useTranslation } from "react-i18next";

const ThanksDealPage = () => {
  const navigate = useNavigate();

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(`/`);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const ticket_link = urlParams.get("link");
  const code = urlParams.get("code");

  const { t } = useTranslation();

  const msgHeader = t("here_is_your_community_deal");
  const msgText = t("email_with_confirmation");
  const msgBtn = t("back_to_home");

  return (
    <div>
      <section class="hero-section-single thanksPage min-h-screen">
        <div class="container-single">
          <div className="row">
            <div className="arrow">
              <a href="/" onClick={handleBackClick}>
                <img src={ArrowImg} alt="arrow" className="logo" />
              </a>
            </div>
            <div className="logo-Img">
              <a href="/">
                <LogoSvg />
              </a>
            </div>
          </div>
        </div>
        <div class="popup-content-box">
          <div class="popup-content">
            <img src={popupImg} class="Illustration" />
            {code ? (
              <>
                <p class="Hier text-xl font-semibold text-gray-700">
                  {msgHeader}
                </p>
                <p className="Je">
                  {t(
                    "use_the_code_below_during_checkout_to_claim_your_discount"
                  )}
                </p>
                <div className="max-lg:!pb-2 mt-4 bg-white border border-neutral-400 rounded-[4px] pl-4 pr-2 py-2 flex items-center justify-between w-fit">
                  <p className="mr-4 text-[18px] font-bold text-purple-600">
                    {code}
                  </p>
                  <button
                    className="hover:text-white !disabled:cursor-not-allowed disabled:bg-[#56466B] text-[16px] font-semibold btn enabled:bg-gradient-to-r shadow-md enabled:to-[#7721E5] enabled:from-[#6A1BD1] text-white px-[10px] py-[8px] rounded-[4px]"
                    onClick={() => {
                      navigator.clipboard.writeText(code);
                    }}
                  >
                    {t("copy_code")}
                  </button>
                </div>
                {/* <div className="mt-3 px-6 py-4 bg-purple-100 rounded-lg border-2 border-purple-400 shadow-md">
                  <p class="text-3xl font-bold text-purple-700 tracking-wider">
                    {code}
                  </p>
                </div> */}
              </>
            ) : (
              <>
                <p class="Hier">{msgHeader}</p>
                <p class="Je">{msgText}</p>
                <p className="Je">
                  {t("if_you_have_not_been_redirected")},{" "}
                  <span className="text-purple-400 font-semibold">
                    <Link
                      to={ticket_link}
                      target="_blank"
                      className="cursor-pointer active:text-purple-400 hover:text-purple-500"
                    >
                      {t("click_here")}.
                    </Link>
                  </span>
                </p>
              </>
            )}
            <a class="Terug" onClick={handleBackClick}>
              {msgBtn}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ThanksDealPage;
