import { useState, useEffect, useRef } from "react";
import { supabase } from "../supabaseClient";
import { Icon } from "@iconify/react";
import { cities } from "../cities";
import { defaultIcons } from "../helpers/helpers";
import * as XLSX from "xlsx";

const SpinWinTab = ({ event, refreshEvent }) => {
  const [prizes, setPrizes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPrize, setCurrentPrize] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [codeFiles, setCodeFiles] = useState([]);
  const [previewIcon, setPreviewIcon] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    icon: "",
    is_main_prize: false,
    gender: "",
    min_age: 0,
    max_age: 100,
    location: "",
    total_quantity: 1,
    redirect_url: "",
    genderFilterType: "include",
    includedGenders: [],
    excludedGenders: [],
    locationFilterType: "include",
    includedLocations: [],
    excludedLocations: [],
    locationSearch: "",
  });
  const [toast, setToast] = useState({ visible: false, message: "", type: "" });
  const fileInputRef = useRef(null);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [codeFile, setCodeFile] = useState(null);
  const [codes, setCodes] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [columns, setColumns] = useState([]);
  const [previewCodes, setPreviewCodes] = useState([]);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [selectedPrizeId, setSelectedPrizeId] = useState(null);
  const [manualCode, setManualCode] = useState("");
  const [showManualEntry, setShowManualEntry] = useState(false);
  const [uploadedCodes, setUploadedCodes] = useState([]);
  const [showUploadedCodes, setShowUploadedCodes] = useState(false);

  // Toast notification system
  const showToast = (message, type = "success") => {
    setToast({ visible: true, message, type });
    setTimeout(() => setToast({ visible: false, message: "", type: "" }), 3000);
  };

  const fetchPrizes = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("SpinWinPrizes")
      .select("*")
      .eq("event_id", event.id)
      .order("id", { ascending: true });

    if (error) {
      showToast("Failed to load prizes", "error");
      console.error(error);
    } else {
      setPrizes(data || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (event?.id) {
      fetchPrizes();
    }
  }, [event]);

  const handleSetLive = async () => {
    // Check if we have at least four prizes
    if (prizes.length < 4) {
      return showToast(
        "You need to add at least 4 prizes before setting live",
        "error"
      );
    }

    // Check if prizes have codes
    for (const prize of prizes) {
      const { count, error } = await supabase
        .from("SpinWinCodes")
        .select("id", { count: "exact" })
        .eq("prize_id", prize.id);

      if (error) {
        return showToast("Error checking prize codes", "error");
      }

      if (count < prize.total_quantity) {
        return showToast(
          `Prize "${prize.title}" needs ${prize.total_quantity} codes but only has ${count}`,
          "error"
        );
      }
    }

    // Set event to live
    const { error } = await supabase
      .from("Events")
      .update({ spin_win_live: true })
      .eq("id", event.id);

    if (error) {
      showToast("Failed to set event live", "error");
      console.error(error);
    } else {
      showToast("Spin & Win event is now live!");
      refreshEvent();
    }
  };

  const handleSetOffline = async () => {
    // Set event to offline
    const { error } = await supabase
      .from("Events")
      .update({ spin_win_live: false })
      .eq("id", event.id);

    if (error) {
      showToast("Failed to set event offline", "error");
      console.error(error);
    } else {
      showToast("Spin & Win event is now offline!");
      refreshEvent();
    }
  };

  const handleAddPrize = () => {
    setCurrentPrize(null);
    setFormData({
      title: "",
      icon: "",
      is_main_prize: false,
      gender: "",
      min_age: 0,
      max_age: 100,
      location: "",
      total_quantity: 1,
      redirect_url: "",
      win_chance: 100,
      genderFilterType: "include",
      includedGenders: [],
      excludedGenders: [],
      locationFilterType: "include",
      includedLocations: [],
      excludedLocations: [],
      locationSearch: "",
    });
    setPreviewIcon("");
    setIsModalVisible(true);
  };

  const handleEditPrize = (prize) => {
    setCurrentPrize(prize);

    // Parse gender data from the prize
    let includedGenders = prize.included_genders || [];
    let excludedGenders = prize.excluded_genders || [];
    let genderFilterType = "include";

    // Set the filter type based on which array has data
    if (excludedGenders.length > 0) {
      genderFilterType = "exclude";
    }

    // Parse location data from the prize
    let includedLocations = prize.included_locations || [];
    let excludedLocations = prize.excluded_locations || [];
    let locationFilterType = "include";

    // Set the filter type based on which array has data
    if (excludedLocations.length > 0) {
      locationFilterType = "exclude";
    }

    setFormData({
      title: prize.title,
      icon: prize.icon,
      is_main_prize: prize.is_main_prize,
      min_age: prize.min_age || 0,
      max_age: prize.max_age || 100,
      total_quantity: prize.total_quantity,
      redirect_url: prize.redirect_url,
      win_chance: prize.win_chance || 100,
      genderFilterType: genderFilterType,
      includedGenders: includedGenders,
      excludedGenders: excludedGenders,
      locationFilterType: locationFilterType,
      includedLocations: includedLocations,
      excludedLocations: excludedLocations,
      locationSearch: "",
    });
    setPreviewIcon(prize.icon);
    setIsModalVisible(true);
  };

  const handleDeletePrize = async (prizeId) => {
    const { error } = await supabase
      .from("SpinWinPrizes")
      .delete()
      .eq("id", prizeId);

    if (error) {
      showToast("Failed to delete prize", "error");
      console.error(error);
    } else {
      showToast("Prize deleted successfully");
      fetchPrizes();
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (name === "icon") {
      setPreviewIcon(value);
    }

    // Handle total_quantity changes
    if (name === "total_quantity") {
      const newQuantity = type === "number" ? (value === "" ? "" : Number(value)) : value;

      // If editing an existing prize, calculate the difference and adjust remaining_quantity
      if (currentPrize) {
        const quantityDifference = newQuantity - currentPrize.total_quantity;
        setFormData((prev) => ({
          ...prev,
          [name]: newQuantity,
          remaining_quantity: Math.max(0, (prev.remaining_quantity || 0) + quantityDifference)
        }));
      } else {
        // For new prizes, remaining_quantity equals total_quantity
        setFormData((prev) => ({
          ...prev,
          [name]: newQuantity,
          remaining_quantity: newQuantity
        }));
      }
    } else {
      // Handle other input changes normally
      setFormData((prev) => ({
        ...prev,
        [name]: type === "number" ? (value === "" ? "" : Number(value)) : value,
      }));
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "is_main_prize" ? value === "true" : value,
    }));
  };

  const handleLocationFilterTypeChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      locationFilterType: e.target.value,
    }));
  };

  const handleLocationSearch = (e) => {
    const searchTerm = e.target.value;
    setFormData((prev) => ({
      ...prev,
      locationSearch: searchTerm,
    }));

    // Filter cities based on search term
    if (searchTerm.trim()) {
      const filteredCities = cities.filter((city) =>
        city.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setLocationSuggestions(filteredCities.slice(0, 10)); // Limit to 10 suggestions
    } else {
      setLocationSuggestions([]);
    }
  };

  const handleSelectLocation = (location) => {
    if (formData.locationFilterType === "include") {
      if (!formData.includedLocations.includes(location)) {
        setFormData((prev) => ({
          ...prev,
          includedLocations: [...prev.includedLocations, location],
          locationSearch: "",
        }));
      }
    } else {
      if (!formData.excludedLocations.includes(location)) {
        setFormData((prev) => ({
          ...prev,
          excludedLocations: [...prev.excludedLocations, location],
          locationSearch: "",
        }));
      }
    }
    setLocationSuggestions([]);
  };

  const handleRemoveLocation = (location, listType) => {
    if (listType === "include") {
      setFormData((prev) => ({
        ...prev,
        includedLocations: prev.includedLocations.filter(
          (loc) => loc !== location
        ),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        excludedLocations: prev.excludedLocations.filter(
          (loc) => loc !== location
        ),
      }));
    }
  };

  const handleSavePrize = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Form validation
    if (
      !formData.title ||
      !formData.icon ||
      !formData.total_quantity ||
      !formData.redirect_url
    ) {
      setLoading(false);
      return showToast("Please fill out all required fields", "error");
    }

    // Format gender data for storage
    let genderData = "";
    if (
      formData.genderFilterType === "include" &&
      formData.includedGenders.length > 0
    ) {
      genderData = JSON.stringify({
        type: "include",
        values: formData.includedGenders,
      });
    } else if (
      formData.genderFilterType === "exclude" &&
      formData.excludedGenders.length > 0
    ) {
      genderData = JSON.stringify({
        type: "exclude",
        values: formData.excludedGenders,
      });
    }

    // Format location data for storage
    let locationData = "";
    if (
      formData.locationFilterType === "include" &&
      formData.includedLocations.length > 0
    ) {
      locationData = JSON.stringify({
        type: "include",
        values: formData.includedLocations,
      });
    } else if (
      formData.locationFilterType === "exclude" &&
      formData.excludedLocations.length > 0
    ) {
      locationData = JSON.stringify({
        type: "exclude",
        values: formData.excludedLocations,
      });
    }

    const prizeData = {
      event_id: event.id,
      title: formData.title,
      icon: formData.icon,
      is_main_prize: formData.is_main_prize,
      included_genders: formData.includedGenders,
      excluded_genders: formData.excludedGenders,
      min_age: formData.min_age,
      max_age: formData.max_age,
      included_locations: formData.includedLocations,
      excluded_locations: formData.excludedLocations,
      total_quantity: formData.total_quantity,
      redirect_url: formData.redirect_url,
      win_chance: formData.win_chance,
      remaining_quantity:
        currentPrize?.remaining_quantity || formData.total_quantity,
    };

    console.log("prizeData", prizeData);

    if (currentPrize) {
      // Update existing prize
      const { error } = await supabase
        .from("SpinWinPrizes")
        .update(prizeData)
        .eq("id", currentPrize.id);

      if (error) {
        showToast("Failed to update prize", "error");
        console.error(error);
      } else {
        showToast("Prize updated successfully");
        setIsModalVisible(false);
        fetchPrizes();
      }
    } else {
      // Create new prize
      const { error } = await supabase.from("SpinWinPrizes").insert(prizeData);

      if (error) {
        showToast("Failed to create prize", "error");
        console.error(error);
      } else {
        showToast("Prize created successfully");
        setIsModalVisible(false);
        fetchPrizes();
      }
    }

    setLoading(false);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCodeFiles(Array.from(e.target.files));
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setCodeFile(file);

    if (file.name.endsWith(".csv")) {
      // Handle CSV files
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const text = event.target.result;
          processCSVData(text);
        } catch (err) {
          showToast(
            "Error reading CSV file. Please make sure it's valid.",
            "error"
          );
        }
      };
      reader.readAsText(file);
    } else if (file.name.match(/\.(xlsx|xls)$/)) {
      // Handle Excel files
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          processExcelData(jsonData);
        } catch (err) {
          showToast(
            "Error reading Excel file. Please make sure it's valid.",
            "error"
          );
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      showToast("Please upload a valid CSV or Excel file.", "error");
    }
  };

  const processCSVData = (text) => {
    const rows = text.split("\n");
    const data = rows.map((row) => row.split(","));
    processData(data);
  };

  const processExcelData = (data) => {
    processData(data);
  };

  const processData = (data) => {
    if (data.length === 0) {
      showToast("The file appears to be empty.", "error");
      return;
    }

    // Get headers (first row) and clean them
    const headers = data[0].map((header) => header?.trim()).filter(Boolean);
    setColumns(headers);

    // Store all rows, cleaning empty/whitespace-only values
    const cleanedData = data
      .slice(1)
      .map((row) => row.map((cell) => cell?.toString().trim()))
      .filter((row) => row.some((cell) => cell)); // Only keep rows that have at least one non-empty cell

    setCodes(cleanedData);

    // If there's only one column, auto-select it
    if (headers.length === 1) {
      setSelectedColumn(headers[0]);
      updatePreviewCodes(headers[0]);
    }
  };

  const updatePreviewCodes = (column) => {
    const columnIndex = columns.indexOf(column);
    if (columnIndex === -1) return;

    // Get first 5 codes for preview
    const preview = codes
      .slice(0, 5)
      .map((row) => row[columnIndex])
      .filter(Boolean);
    setPreviewCodes(preview);
  };

  const openCodeModal = async (prizeId) => {
    setSelectedPrizeId(prizeId);
    setShowCodeModal(true);
    setShowManualEntry(false);
    setShowUploadedCodes(false);

    // Reset the file upload state
    setCodeFile(null);
    setCodes([]);
    setSelectedColumn("");
    setColumns([]);
    setPreviewCodes([]);

    // Fetch uploaded codes for this prize
    await fetchUploadedCodes(prizeId);
  };

  const fetchUploadedCodes = async (prizeId) => {
    try {
      const { data, error, count } = await supabase
        .from("SpinWinCodes")
        .select("*", { count: "exact" })
        .eq("prize_id", prizeId)
        .order("is_claimed", { ascending: false })
        .order("created_at", { ascending: false });

      if (error) {
        console.error("Error fetching codes:", error);
        setUploadedCodes([]);
        return [];
      }

      setUploadedCodes(data || []);
      return data || [];
    } catch (error) {
      console.error("Error fetching codes:", error);
      setUploadedCodes([]);
      return [];
    }
  };

  const handleRemoveCode = async (codeId) => {
    if (window.confirm("Are you sure you want to delete this code?")) {
      try {
        const { error } = await supabase
          .from("SpinWinCodes")
          .delete()
          .eq("id", codeId);

        if (error) {
          showToast("Failed to delete code", "error");
          console.error(error);
        } else {
          showToast("Code deleted successfully");
          fetchUploadedCodes(selectedPrizeId);
          fetchPrizes(); // Refresh the prizes list to update remaining counts
        }
      } catch (error) {
        showToast("Failed to delete code", "error");
        console.error(error);
      }
    }
  };

  const submitCodes = async () => {
    if (!selectedColumn || !selectedPrizeId) {
      showToast("Please select a column containing the codes.", "error");
      return;
    }

    setUploading(true);

    try {
      // Get prize info and current code count
      const { data: prize } = await supabase
        .from("SpinWinPrizes")
        .select("total_quantity")
        .eq("id", selectedPrizeId)
        .single();

      const { count: existingCodesCount, error: countError } = await supabase
        .from("SpinWinCodes")
        .select("id", { count: "exact" })
        .eq("prize_id", selectedPrizeId);

      if (countError) {
        setUploading(false);
        showToast("Error checking existing codes", "error");
        return;
      }

      const columnIndex = columns.indexOf(selectedColumn);
      const validCodes = codes
        .map((row) => row[columnIndex])
        .filter((code) => code && code.trim() !== "")
        .map((code) => code.trim().replace(/'/g, ""));

      if (validCodes.length === 0) {
        setUploading(false);
        return showToast(
          "No valid codes found in the selected column",
          "error"
        );
      }

      // Insert codes
      const codeObjects = validCodes.map((code) => ({
        prize_id: selectedPrizeId,
        code: code,
        is_claimed: false,
      }));

      const { error } = await supabase.from("SpinWinCodes").insert(codeObjects);

      if (error) {
        showToast("Failed to upload codes", "error");
        console.error(error);
      } else {
        const newTotal = existingCodesCount + validCodes.length;
        const remaining = prize.total_quantity - newTotal;

        showToast(
          `${validCodes.length} codes uploaded successfully. ${
            remaining > 0
              ? `${remaining} more needed.`
              : "All required codes uploaded!"
          }`
        );
        setShowCodeModal(false);
        setCodeFile(null);
        setCodes([]);
        setSelectedColumn("");
        setColumns([]);
        setPreviewCodes([]);
        fetchPrizes();
      }
    } catch (error) {
      showToast("Failed to process codes", "error");
      console.error(error);
    }

    setUploading(false);
  };

  const submitManualCode = async () => {
    if (!manualCode.trim() || !selectedPrizeId) {
      showToast("Please enter a valid code", "error");
      return;
    }

    setUploading(true);

    try {
      const { error } = await supabase.from("SpinWinCodes").insert({
        prize_id: selectedPrizeId,
        code: manualCode.trim(),
        is_claimed: false,
      });

      if (error) {
        if (error.code === "23505") {
          // Unique constraint violation
          showToast("This code already exists", "error");
        } else {
          showToast("Failed to add code", "error");
          console.error(error);
        }
      } else {
        showToast("Code added successfully");
        setManualCode("");
        fetchPrizes(); // Refresh the prizes list
      }
    } catch (error) {
      showToast("Failed to add code", "error");
      console.error(error);
    }

    setUploading(false);
  };

  const calculateEligibleUsers = () => {
    // This is a placeholder function. You should implement the logic to calculate the number of eligible users based on the criteria
    return 0; // Placeholder return, actual implementation needed
  };

  // Add a handler for gender filter type change
  const handleGenderFilterTypeChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      genderFilterType: e.target.value,
    }));
  };

  // Add a handler for adding a gender to either include or exclude list
  const handleAddGender = (gender) => {
    if (formData.genderFilterType === "include") {
      if (!formData.includedGenders.includes(gender)) {
        setFormData((prev) => ({
          ...prev,
          includedGenders: [...prev.includedGenders, gender],
        }));
      }
    } else {
      if (!formData.excludedGenders.includes(gender)) {
        setFormData((prev) => ({
          ...prev,
          excludedGenders: [...prev.excludedGenders, gender],
        }));
      }
    }
  };

  // Add a handler for removing a gender from either include or exclude list
  const handleRemoveGender = (gender, listType) => {
    if (listType === "include") {
      setFormData((prev) => ({
        ...prev,
        includedGenders: prev.includedGenders.filter((g) => g !== gender),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        excludedGenders: prev.excludedGenders.filter((g) => g !== gender),
      }));
    }
  };

  // Add new function to delete all codes
  const handleDeleteAllCodes = async () => {
    if (
      !window.confirm(
        "Are you sure you want to delete ALL codes for this prize?"
      )
    ) {
      return;
    }

    if (
      !window.confirm("This action CANNOT be undone. Are you absolutely sure?")
    ) {
      return;
    }

    try {
      const { error } = await supabase
        .from("SpinWinCodes")
        .delete()
        .eq("prize_id", selectedPrizeId)
        .eq("is_claimed", false); // Only delete unclaimed codes

      if (error) {
        showToast("Failed to delete codes", "error");
        console.error(error);
      } else {
        showToast("All unclaimed codes deleted successfully");
        fetchUploadedCodes(selectedPrizeId);
        fetchPrizes(); // Refresh the prizes list to update remaining counts
      }
    } catch (error) {
      showToast("Failed to delete codes", "error");
      console.error(error);
    }
  };

  return (
    <div className="p-4">
      {/* Toast Notification */}
      {toast.visible && (
        <div
          className={`fixed top-4 right-4 p-4 rounded-md shadow-lg z-50 ${
            toast.type === "error" ? "bg-red-500" : "bg-green-500"
          } text-white`}
        >
          {toast.message}
        </div>
      )}

      <div className="flex justify-between mb-4">
        <button
          className={`flex items-center px-4 py-2 rounded-md text-white ${
            event?.spin_win_live
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          onClick={handleAddPrize}
          disabled={event?.spin_win_live}
        >
          <Icon icon="heroicons:plus" className="mr-1" />
          Add Prize
        </button>

        {event?.spin_win_live ? (
          <button
            className="px-4 py-2 rounded-md text-white bg-red-600 hover:bg-red-700"
            onClick={handleSetOffline}
          >
            Set Offline
          </button>
        ) : (
          <button
            className={`px-4 py-2 rounded-md text-white ${
              prizes.length === 0
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
            onClick={handleSetLive}
            disabled={prizes.length === 0}
          >
            Set Live
          </button>
        )}
      </div>

      {/* Table */}
      <div className="overflow-x-auto bg-white rounded-lg shadow">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider w-[10%]">
                Icon
              </th>
              <th className="px-4 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider w-[25%]">
                Title
              </th>
              <th className="px-4 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider w-[10%]">
                Main Prize
              </th>
              <th className="px-4 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider w-[25%]">
                Criteria
              </th>
              <th className="px-4 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider w-[10%]">
                Quantity
              </th>
              <th className="px-4 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider w-[10%]">
                Remaining
              </th>
              <th className="px-4 py-3 text-left text-[14px] font-medium text-gray-500 uppercase tracking-wider w-[10%]">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan="7" className="px-4 py-4 text-center">
                  <div className="flex justify-center">
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
                  </div>
                </td>
              </tr>
            ) : prizes.length === 0 ? (
              <tr>
                <td
                  colSpan="7"
                  className="px-4 py-4 text-center text-[14px] text-gray-500"
                >
                  No prizes added yet
                </td>
              </tr>
            ) : (
              prizes.map((prize) => (
                <tr key={prize.id} className="hover:bg-gray-50">
                  <td className="px-4 py-4 whitespace-nowrap text-center">
                    {prize.icon ? (
                      <Icon icon={prize.icon} className="text-[20px] mx-auto" />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-[14px] font-medium text-gray-900">
                    {prize.title}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-[14px] text-gray-500">
                    {prize.is_main_prize ? (
                      <span className="px-2 inline-flex text-[12px] leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Yes
                      </span>
                    ) : (
                      <span className="px-2 inline-flex text-[12px] leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                        No
                      </span>
                    )}
                  </td>
                  <td className="px-4 py-4 text-[14px] text-gray-500">
                    <div className="space-y-1">
                      {prize.gender && (
                        <div>
                          {(() => {
                            try {
                              const genderData = JSON.parse(prize.gender);
                              if (
                                genderData.type === "include" &&
                                genderData.values.length > 0
                              ) {
                                return (
                                  <div className="flex items-center">
                                    <span className="font-medium mr-1">
                                      Gender:
                                    </span>{" "}
                                    Only {genderData.values.join(", ")}
                                  </div>
                                );
                              } else if (
                                genderData.type === "exclude" &&
                                genderData.values.length > 0
                              ) {
                                return (
                                  <div className="flex items-center">
                                    <span className="font-medium mr-1">
                                      Gender:
                                    </span>{" "}
                                    Exclude {genderData.values.join(", ")}
                                  </div>
                                );
                              }
                              return null;
                            } catch (e) {
                              // Handle legacy format
                              return (
                                <div className="flex items-center">
                                  <span className="font-medium mr-1">
                                    Gender:
                                  </span>{" "}
                                  {prize.gender}
                                </div>
                              );
                            }
                          })()}
                        </div>
                      )}
                      {prize.min_age && (
                        <div className="flex items-center">
                          <span className="font-medium mr-1">Min Age:</span>{" "}
                          {prize.min_age}
                        </div>
                      )}
                      {prize.max_age && (
                        <div className="flex items-center">
                          <span className="font-medium mr-1">Max Age:</span>{" "}
                          {prize.max_age}
                        </div>
                      )}
                      {prize.location && (
                        <div>
                          {(() => {
                            try {
                              const locationData = JSON.parse(prize.location);
                              if (
                                locationData.type === "include" &&
                                locationData.values.length > 0
                              ) {
                                return (
                                  <div className="flex items-center">
                                    <span className="font-medium mr-1">
                                      Location:
                                    </span>{" "}
                                    Only{" "}
                                    {locationData.values.length > 3
                                      ? `${locationData.values
                                          .slice(0, 3)
                                          .join(", ")} +${
                                          locationData.values.length - 3
                                        } more`
                                      : locationData.values.join(", ")}
                                  </div>
                                );
                              } else if (
                                locationData.type === "exclude" &&
                                locationData.values.length > 0
                              ) {
                                return (
                                  <div className="flex items-center">
                                    <span className="font-medium mr-1">
                                      Location:
                                    </span>{" "}
                                    Exclude{" "}
                                    {locationData.values.length > 3
                                      ? `${locationData.values
                                          .slice(0, 3)
                                          .join(", ")} +${
                                          locationData.values.length - 3
                                        } more`
                                      : locationData.values.join(", ")}
                                  </div>
                                );
                              }
                              return null;
                            } catch (e) {
                              // Handle legacy format
                              return (
                                <div className="flex items-center">
                                  <span className="font-medium mr-1">
                                    Location:
                                  </span>{" "}
                                  {prize.location}
                                </div>
                              );
                            }
                          })()}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-[14px] text-gray-500 text-center">
                    {prize.total_quantity}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-[14px] text-gray-500 text-center">
                    {prize.remaining_quantity}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap">
                    <div className="flex flex-col space-y-2">
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditPrize(prize)}
                          disabled={event?.spin_win_live}
                          className={`px-3 py-1 rounded-md text-[12px] ${
                            event?.spin_win_live
                              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                              : "bg-blue-100 text-blue-800 hover:bg-blue-200"
                          }`}
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeletePrize(prize.id)}
                          disabled={event?.spin_win_live}
                          className={`px-3 py-1 rounded-md text-[12px] ${
                            event?.spin_win_live
                              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                              : "bg-red-100 text-red-800 hover:bg-red-200"
                          }`}
                        >
                          <Icon icon="heroicons:trash" />
                        </button>
                      </div>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => openCodeModal(prize.id)}
                          disabled={event?.spin_win_live}
                          className={`px-3 py-1 rounded-md text-[12px] ${
                            event?.spin_win_live
                              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                              : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                          }`}
                        >
                          Upload Codes{" "}
                          {prize.code_count > 0 && `(${prize.code_count})`}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Enhanced Modal */}
      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-7xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-[24px] font-semibold text-gray-800">
                {currentPrize ? "Edit Prize" : "Add New Prize"}
              </h3>
              <button
                onClick={() => setIsModalVisible(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <Icon icon="heroicons:x-mark" className="w-6 h-6" />
              </button>
            </div>

            <form onSubmit={handleSavePrize}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                {/* Left Column - Basic Information */}
                <div>
                  <h4 className="text-[18px] font-medium text-gray-700 mb-4 border-b pb-2">
                    Prize Information
                  </h4>

                  <div className="mb-5">
                    <label className="block text-[16px] font-medium text-gray-700 mb-2">
                      Prize Title*
                      <span className="text-[13px] text-gray-500 block mt-1">
                        Name of the prize as it will appear to users
                      </span>
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      className="w-full p-3 text-[16px] border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>

                  <div className="mb-5">
                    <label className="block text-[16px] font-medium text-gray-700 mb-2">
                      Icon Selection*
                      <span className="text-[13px] text-gray-500 block mt-1">
                        Choose an icon for this prize
                      </span>
                    </label>
                    <div className="grid grid-cols-4 gap-4 p-4 border-2 border-gray-300 rounded-lg">
                      {defaultIcons.map((icon) => (
                        <button
                          key={icon}
                          type="button"
                          className={`flex items-center justify-center p-4 rounded-lg transition-colors ${
                            formData.icon === icon
                              ? "bg-blue-600 text-white"
                              : "bg-white text-black hover:bg-gray-100"
                          }`}
                          onClick={() => {
                            setFormData({ ...formData, icon: icon });
                            setPreviewIcon(icon);
                          }}
                        >
                          <Icon icon={icon} className="h-8 w-8" />
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="mb-5">
                    <label className="block text-[16px] font-medium text-gray-700 mb-2">
                      Make this the Main Prize?
                      <span className="text-[13px] text-gray-500 block mt-1">
                        The main prize will be displayed with a different color
                      </span>
                    </label>
                    <select
                      name="is_main_prize"
                      value={formData.is_main_prize.toString()}
                      onChange={handleSelectChange}
                      className="w-full p-3 text-[16px] border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="true">Yes - This is a main prize</option>
                      <option value="false">
                        No - This is a regular prize
                      </option>
                    </select>
                  </div>

                  <div className="mb-5">
                    <label className="block text-[16px] font-medium text-gray-700 mb-2">
                      Quantity Available*
                      <span className="text-[13px] text-gray-500 block mt-1">
                        Total number of this prize that can be won
                      </span>
                    </label>
                    <input
                      type="number"
                      name="total_quantity"
                      value={formData.total_quantity}
                      onChange={handleInputChange}
                      min="1"
                      className="w-full p-3 text-[16px] border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>

                  <div className="mb-5">
                    <label className="block text-[16px] font-medium text-gray-700 mb-2">
                      Redirect URL*
                      <span className="text-[13px] text-gray-500 block mt-1">
                        Where users will be sent after winning this prize
                      </span>
                    </label>
                    <input
                      type="text"
                      name="redirect_url"
                      value={formData.redirect_url}
                      onChange={handleInputChange}
                      placeholder="https://"
                      className="w-full p-3 text-[16px] border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                </div>

                {/* Right Column - Eligibility Requirements */}
                <div>
                  <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                    <div className="text-[18px] font-medium text-gray-700 mb-4">
                      Eligibility Requirements
                      <div className="text-[12px] text-gray-500">
                        Set who can win this prize (leave blank for no
                        restrictions)
                      </div>
                    </div>

                    {/* Gender Selection */}
                    <div className="mb-5">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-[16px] font-medium text-gray-700">
                          Gender Requirement
                        </label>
                      </div>

                      <div className="text-[13px] text-gray-500 mb-2">
                        Set gender eligibility for this prize
                      </div>

                      {/* Filter type selection */}
                      <div className="flex space-x-4 mb-3">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            name="genderFilterType"
                            value="include"
                            checked={formData.genderFilterType === "include"}
                            onChange={handleGenderFilterTypeChange}
                            className="form-radio h-4 w-4 text-blue-600"
                          />
                          <span className="ml-2 text-[14px] text-gray-700">
                            Include
                          </span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            name="genderFilterType"
                            value="exclude"
                            checked={formData.genderFilterType === "exclude"}
                            onChange={handleGenderFilterTypeChange}
                            className="form-radio h-4 w-4 text-blue-600"
                          />
                          <span className="ml-2 text-[14px] text-gray-700">
                            Exclude
                          </span>
                        </label>
                      </div>

                      {/* Gender dropdown selection */}
                      <div className="flex space-x-2">
                        <select
                          className="flex-grow p-3 text-[16px] border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                          onChange={(e) => {
                            if (e.target.value) {
                              handleAddGender(e.target.value);
                              e.target.value = ""; // Reset the select after selection
                            }
                          }}
                          value=""
                        >
                          <option value="" disabled>
                            {formData.genderFilterType === "include"
                              ? "Select genders to include"
                              : "Select genders to exclude"}
                          </option>
                          <option value="Man">Man</option>
                          <option value="Vrouw">Vrouw</option>
                          <option value="Onzijdig">Onzijdig</option>
                        </select>
                      </div>

                      {/* Display for included genders */}
                      {formData.genderFilterType === "include" &&
                        formData.includedGenders.length > 0 && (
                          <div className="mt-3">
                            <div className="flex flex-wrap gap-2">
                              {formData.includedGenders.map((gender) => (
                                <div
                                  key={gender}
                                  className="bg-green-100 text-green-800 px-3 py-1 rounded-full flex items-center"
                                >
                                  <span className="text-[13px]">{gender}</span>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveGender(gender, "include")
                                    }
                                    className="ml-2 text-green-600 hover:text-green-800"
                                  >
                                    <Icon
                                      icon="heroicons:x-mark"
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                      {/* Display for excluded genders */}
                      {formData.genderFilterType === "exclude" &&
                        formData.excludedGenders.length > 0 && (
                          <div className="mt-3">
                            <div className="flex flex-wrap gap-2">
                              {formData.excludedGenders.map((gender) => (
                                <div
                                  key={gender}
                                  className="bg-red-100 text-red-800 px-3 py-1 rounded-full flex items-center"
                                >
                                  <span className="text-[13px]">{gender}</span>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveGender(gender, "exclude")
                                    }
                                    className="ml-2 text-red-600 hover:text-red-800"
                                  >
                                    <Icon
                                      icon="heroicons:x-mark"
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                      {/* Explanation text */}
                      <div className="mt-2 text-[13px] text-gray-500">
                        {formData.genderFilterType === "include"
                          ? formData.includedGenders.length === 0
                            ? "If no genders are selected, all genders will be eligible."
                            : "Only users with these genders will be eligible for this prize."
                          : formData.excludedGenders.length === 0
                          ? "If no genders are selected, all genders will be eligible."
                          : "Users with these genders will NOT be eligible for this prize."}
                      </div>
                    </div>

                    {/* Age Range Selection */}
                    <div className="mb-5">
                      <label className="block text-[16px] font-medium text-gray-700 mb-2">
                        Age Range
                        <span className="text-[13px] text-gray-500 block mt-1">
                          Restrict prize to users within specific age range
                        </span>
                      </label>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block text-[14px] text-gray-600 mb-1">
                            Minimum Age
                          </label>
                          <input
                            type="number"
                            name="min_age"
                            value={formData.min_age}
                            onChange={handleInputChange}
                            min="0"
                            max="100"
                            placeholder="No minimum"
                            className="w-full p-3 text-[16px] border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                        <div>
                          <label className="block text-[14px] text-gray-600 mb-1">
                            Maximum Age
                          </label>
                          <input
                            type="number"
                            name="max_age"
                            value={formData.max_age}
                            onChange={handleInputChange}
                            min="0"
                            max="100"
                            placeholder="No maximum"
                            className="w-full p-3 text-[16px] border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                      </div>
                      {formData.min_age && formData.max_age && (
                        <div className="text-[14px] text-blue-600 mt-2">
                          Users must be between {formData.min_age} and{" "}
                          {formData.max_age} years old
                        </div>
                      )}
                    </div>

                    {/* Location Selection */}
                    <div className="mb-5">
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-[16px] font-medium text-gray-700">
                          Location Requirements
                        </label>
                      </div>

                      <div className="text-[13px] text-gray-500 mb-2">
                        Restrict prize to users from specific locations
                      </div>

                      {/* Filter type selection */}
                      <div className="flex space-x-4 mb-3">
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            name="locationFilterType"
                            value="include"
                            checked={formData.locationFilterType === "include"}
                            onChange={handleLocationFilterTypeChange}
                            className="form-radio h-4 w-4 text-blue-600"
                          />
                          <span className="ml-2 text-[14px] text-gray-700">
                            Include
                          </span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="radio"
                            name="locationFilterType"
                            value="exclude"
                            checked={formData.locationFilterType === "exclude"}
                            onChange={handleLocationFilterTypeChange}
                            className="form-radio h-4 w-4 text-blue-600"
                          />
                          <span className="ml-2 text-[14px] text-gray-700">
                            Exclude
                          </span>
                        </label>
                      </div>

                      {/* Location search input */}
                      <div className="relative">
                        <input
                          type="text"
                          placeholder={`Search for a location to ${formData.locationFilterType}...`}
                          value={formData.locationSearch}
                          onChange={handleLocationSearch}
                          className="w-full p-3 text-[16px] border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                          autoComplete="off"
                        />
                        {formData.locationSearch && (
                          <button
                            type="button"
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                locationSearch: "",
                              }))
                            }
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                          >
                            <Icon icon="heroicons:x-mark" className="w-5 h-5" />
                          </button>
                        )}
                      </div>

                      {/* Location suggestions dropdown */}
                      {formData.locationSearch &&
                        locationSuggestions.length > 0 && (
                          <div className="absolute z-10 w-[300px] mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
                            {locationSuggestions.map((location) => (
                              <div
                                key={location}
                                className="p-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleSelectLocation(location)}
                              >
                                {location}
                              </div>
                            ))}
                          </div>
                        )}

                      {/* Display for included locations */}
                      {formData.locationFilterType === "include" &&
                        formData.includedLocations.length > 0 && (
                          <div className="mt-3">
                            <div className="flex flex-wrap gap-2">
                              {formData.includedLocations.map((location) => (
                                <div
                                  key={location}
                                  className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full flex items-center"
                                >
                                  <span className="text-[13px]">
                                    {location}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveLocation(location, "include")
                                    }
                                    className="ml-2 text-blue-600 hover:text-blue-800"
                                  >
                                    <Icon
                                      icon="heroicons:x-mark"
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                      {/* Display for excluded locations */}
                      {formData.locationFilterType === "exclude" &&
                        formData.excludedLocations.length > 0 && (
                          <div className="mt-3">
                            <div className="flex flex-wrap gap-2">
                              {formData.excludedLocations.map((location) => (
                                <div
                                  key={location}
                                  className="bg-orange-100 text-orange-800 px-3 py-1 rounded-full flex items-center"
                                >
                                  <span className="text-[13px]">
                                    {location}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveLocation(location, "exclude")
                                    }
                                    className="ml-2 text-orange-600 hover:text-orange-800"
                                  >
                                    <Icon
                                      icon="heroicons:x-mark"
                                      className="w-4 h-4"
                                    />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                      {/* Explanation text */}
                      <div className="mt-2 text-[13px] text-gray-500">
                        {formData.locationFilterType === "include"
                          ? formData.includedLocations.length === 0
                            ? "If no locations are selected, users from all locations will be eligible."
                            : "Only users from the selected locations will be eligible for this prize."
                          : formData.excludedLocations.length === 0
                          ? "If no locations are selected, users from all locations will be eligible."
                          : "Users from the selected locations will NOT be eligible for this prize."}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-8 pt-4 border-t">
                <button
                  type="button"
                  onClick={() => setIsModalVisible(false)}
                  className="mr-4 px-6 py-3 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-[16px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`px-6 py-3 rounded-md text-white text-[16px] ${
                    loading ? "bg-blue-400" : "bg-blue-600 hover:bg-blue-700"
                  }`}
                  disabled={loading}
                >
                  {loading ? (
                    <span className="flex items-center">
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
                      Saving...
                    </span>
                  ) : (
                    "Save Prize"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Code Upload Modal */}
      {showCodeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-2xl">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-[20px] font-semibold text-gray-800">
                Manage Codes
              </h3>
              <button
                onClick={() => setShowCodeModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <Icon icon="heroicons:x-mark" className="w-6 h-6" />
              </button>
            </div>

            <div className="flex mb-4">
              <button
                onClick={() => {
                  setShowManualEntry(false);
                  setShowUploadedCodes(false);
                }}
                className={`px-4 py-2 text-[14px] ${
                  !showManualEntry && !showUploadedCodes
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-700"
                } rounded-l-md`}
              >
                Upload File
              </button>
              <button
                onClick={() => {
                  setShowManualEntry(true);
                  setShowUploadedCodes(false);
                }}
                className={`px-4 py-2 text-[14px] ${
                  showManualEntry && !showUploadedCodes
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                Manual Entry
              </button>
              <button
                onClick={() => {
                  setShowManualEntry(false);
                  setShowUploadedCodes(true);
                }}
                className={`px-4 py-2 text-[14px] ${
                  showUploadedCodes
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-700"
                } rounded-r-md`}
              >
                View Codes ({uploadedCodes.length})
              </button>
            </div>

            {!showManualEntry && !showUploadedCodes ? (
              <>
                <div className="mt-4">
                  <label className="block text-[16px] font-medium text-gray-700 mb-2">
                    Upload CSV or Excel File
                    <span className="text-[13px] text-gray-500 block mt-1">
                      File should contain the codes to be used for this prize
                    </span>
                  </label>
                  <input
                    type="file"
                    accept=".csv, .xlsx, .xls"
                    onChange={handleFileUpload}
                    className="w-full text-[14px] text-black bg-white border-2 border-gray-300 p-2 rounded-lg"
                  />
                  <p className="text-[12px] text-gray-500 mt-2">
                    Note: Duplicate codes will be automatically ignored during
                    upload.
                  </p>
                </div>

                {columns.length > 0 && (
                  <div className="mt-4">
                    <label className="block text-[16px] font-medium text-gray-700 mb-2">
                      Select Code Column
                    </label>
                    <select
                      className="w-full text-[14px] text-black bg-white border-2 border-gray-300 p-2 rounded-lg"
                      value={selectedColumn}
                      onChange={(e) => {
                        setSelectedColumn(e.target.value);
                        updatePreviewCodes(e.target.value);
                      }}
                    >
                      <option value="">Select code column</option>
                      {columns.map((column, index) => (
                        <option key={index} value={column}>
                          {column}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {previewCodes.length > 0 && (
                  <div className="mt-4">
                    <label className="block text-[16px] font-medium text-gray-700 mb-2">
                      Preview (first 5 codes)
                    </label>
                    <div className="bg-gray-100 p-3 rounded-lg">
                      <ul className="list-disc pl-5">
                        {previewCodes.map((code, index) => (
                          <li key={index} className="text-[14px]">
                            {code}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={() => setShowCodeModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-[14px] text-gray-700 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={submitCodes}
                    disabled={!selectedColumn || uploading}
                    className={`px-4 py-2 rounded-md text-[14px] text-white ${
                      !selectedColumn || uploading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                    }`}
                  >
                    {uploading ? (
                      <span className="flex items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                        Uploading...
                      </span>
                    ) : (
                      "Upload Codes"
                    )}
                  </button>
                </div>
              </>
            ) : showManualEntry ? (
              <>
                <div className="mt-4">
                  <label className="block text-[16px] font-medium text-gray-700 mb-2">
                    Enter Code Manually
                    <span className="text-[13px] text-gray-500 block mt-1">
                      Add one code at a time
                    </span>
                  </label>
                  <input
                    type="text"
                    value={manualCode}
                    onChange={(e) => setManualCode(e.target.value)}
                    placeholder="Enter code"
                    className="w-full text-[14px] text-black bg-white border-2 border-gray-300 p-2 rounded-lg"
                  />
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={() => setShowCodeModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-[14px] text-gray-700 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={submitManualCode}
                    disabled={!manualCode.trim() || uploading}
                    className={`px-4 py-2 rounded-md text-[14px] text-white ${
                      !manualCode.trim() || uploading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                    }`}
                  >
                    {uploading ? (
                      <span className="flex items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                        Adding...
                      </span>
                    ) : (
                      "Add Code"
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="mt-4">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-[16px] font-medium text-gray-700">
                      Uploaded Codes
                      <span className="text-[13px] text-gray-500 block mt-1">
                        {uploadedCodes.length} codes uploaded
                      </span>
                    </label>
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        placeholder="Search codes..."
                        className="px-3 py-1 border border-gray-300 rounded-md text-[14px]"
                        onChange={(e) => {
                          const searchTerm = e.target.value.toLowerCase();
                          if (searchTerm) {
                            const filtered = uploadedCodes.filter((code) =>
                              code.code.toLowerCase().includes(searchTerm)
                            );
                            setUploadedCodes(filtered);
                          } else {
                            fetchUploadedCodes(selectedPrizeId);
                          }
                        }}
                      />
                      <button
                        onClick={() => fetchUploadedCodes(selectedPrizeId)}
                        className="px-3 py-1 bg-gray-100 rounded-md text-[14px] hover:bg-gray-200"
                      >
                        <Icon icon="heroicons:arrow-path" className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={handleDeleteAllCodes}
                    className="my-3 px-3 py-1 bg-red-100 text-red-800 rounded-md text-[14px] hover:bg-red-200"
                  >
                    Delete all unclaimed codes
                  </button>
                  <div className="border border-gray-300 rounded-lg overflow-hidden">
                    <div className="max-h-[400px] overflow-y-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 sticky top-0">
                          <tr>
                            <th className="px-4 py-2 text-left text-[14px] font-medium text-gray-500">
                              Code
                            </th>
                            <th className="px-4 py-2 text-left text-[14px] font-medium text-gray-500">
                              Status
                            </th>
                            <th className="px-4 py-2 text-left text-[14px] font-medium text-gray-500">
                              Date Added
                            </th>
                            <th className="px-4 py-2 text-left text-[14px] font-medium text-gray-500">
                              Date Claimed
                            </th>
                            <th className="px-4 py-2 text-left text-[14px] font-medium text-gray-500">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {uploadedCodes.length === 0 ? (
                            <tr>
                              <td
                                colSpan="5"
                                className="px-4 py-4 text-center text-gray-500"
                              >
                                No codes uploaded yet
                              </td>
                            </tr>
                          ) : (
                            uploadedCodes.map((code) => (
                              <tr
                                key={code.id}
                                className={code.is_claimed ? "bg-gray-50" : ""}
                              >
                                <td className="px-4 py-2 text-[14px]">
                                  {code.code}
                                </td>
                                <td className="px-4 py-2">
                                  {code.is_claimed ? (
                                    <span className="px-2 inline-flex text-[12px] leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                      Claimed
                                    </span>
                                  ) : (
                                    <span className="px-2 inline-flex text-[12px] leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                      Available
                                    </span>
                                  )}
                                </td>
                                <td className="px-4 py-2 text-[14px] text-gray-500">
                                  {new Date(
                                    code.created_at
                                  ).toLocaleDateString()}
                                </td>
                                <td className="px-4 py-2 text-[14px] text-gray-500">
                                  {code.claimed_at
                                    ? new Date(
                                        code.claimed_at
                                      ).toLocaleDateString()
                                    : "-"}
                                </td>
                                <td className="px-4 py-2 text-[14px]">
                                  {!code.is_claimed && (
                                    <button
                                      onClick={() => handleRemoveCode(code.id)}
                                      className="text-red-600 hover:text-red-800"
                                      title="Delete code"
                                    >
                                      <Icon
                                        icon="heroicons:trash"
                                        className="w-4 h-4"
                                      />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SpinWinTab;
