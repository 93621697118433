import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import Icon from "../components/Icon";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import CreateEventModal from "../components/CreateEventModal";
import CreateCommissionModal from "../components/CreateCommissionModal";

export default function ManageEvents() {
  const [events, setEvents] = useState([]);
  const [archivedEvents, setArchivedEvents] = useState([]);
  const [revenueEvents, setRevenueEvents] = useState([]);

  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [showCommissionModal, setShowCommissionModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [commissions, setCommissions] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);

  const { city } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const amsterdamTime = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Amsterdam",
    });

    // Fetch Published Events
    const fetchPublishedEvents = async () => {
      const query = supabase
        .from("Events")
        .select("*, venue:Venue (*), attendees:EventSignUp (*)")
        .gte("datetime", new Date(amsterdamTime).toISOString())
        .order("datetime", { ascending: true });

      if (city) {
        query.eq("city", city);
      }

      const { data, error } = await query;
      if (error) console.error("Error fetching published events:", error);
      else setEvents(data);
    };

    // Fetch Archived Events
    const fetchArchivedEvents = async () => {
      const archiveQuery = supabase
        .from("Events")
        .select("*, venue:Venue (*), attendees:EventSignUp (*)")
        .lte("datetime", new Date(amsterdamTime).toISOString())
        .gte("datetime", dayjs(amsterdamTime).subtract(60, "days").toISOString())
        .eq("submitted_commission", true)
        .order("datetime", { ascending: false });

      if (city) {
        archiveQuery.eq("city", city);
      }

      const { data, error } = await archiveQuery;
      if (error) console.error("Error fetching archived events:", error);
      else setArchivedEvents(data);
    };

    // Fetch Revenue Events
    const fetchRevenueEvents = async () => {
      const now = dayjs().toDate().toISOString();

      const revenueQuery = supabase
        .from("Events")
        .select("*, venue:Venue (*), attendees:EventSignUp (*)")
        .lt("datetime", now)
        .eq("submitted_commission", false)
        .order("datetime", { ascending: false });

      if (city) {
        revenueQuery.eq("city", city);
      }

      const { data, error } = await revenueQuery;
      if (error) console.error("Error fetching revenue events:", error);
      else setRevenueEvents(data);
    };

    fetchPublishedEvents();
    fetchArchivedEvents();
    fetchRevenueEvents();
  }, [city]);

  const duplicateEvent = async (eventId) => {
    try {
      // Fetch the event to duplicate
      const { data: eventToDuplicate, error: fetchError } = await supabase
        .from("Events")
        .select("*")
        .eq("id", eventId)
        .single();

      if (fetchError) throw fetchError;

      // Create a new event object with the same properties
      const newEvent = {
        ...eventToDuplicate,
        id: undefined,
        name: `${eventToDuplicate.name} (Copy)`,
        datetime: dayjs(new Date().toISOString()).add(1, "day").toISOString(), // Set date to tomorrow
        created_at: new Date().toISOString(),
      };

      // Insert the new event
      await supabase.from("Events").insert(newEvent).single();

      // Fetch the newly inserted event
      const { data: insertedEvent, error: fetchNewEventError } = await supabase
        .from("Events")
        .select("*, venue:Venue (*), attendees:EventSignUp (*)")
        .eq("name", newEvent.name)
        .single();

      if (fetchNewEventError) throw fetchNewEventError;

      // Add the new event to the events state
      setEvents((prevEvents) => [insertedEvent, ...prevEvents]);

      // Update the events state
      // setEvents((prevEvents) => [...prevEvents, insertedEvent]);

      console.log("Event duplicated successfully");
    } catch (error) {
      console.error("Error duplicating event:", error.message);
    }
  };

  const deleteEvent = async (eventId) => {
    try {
      // Show confirmation dialog
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this event?"
      );

      if (!isConfirmed) {
        return; // User cancelled the deletion
      }

      // Delete the event from the database
      const { error } = await supabase
        .from("Events")
        .delete()
        .eq("id", eventId);

      if (error) throw error;

      // Remove the event from the local state
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventId)
      );
      setArchivedEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventId)
      );

      console.log("Event deleted successfully");
    } catch (error) {
      console.error("Error deleting event:", error.message);
    }
  };

  const formatDateTime = (datetime) => {
    return dayjs(datetime).local("nl").format("D MMMM HH:mm");
  };

  // published or archive
  const [activeTab, setActiveTab] = useState("published");

  const filterEventsBySearch = (eventsArray) => {
    if (!searchTerm || searchTerm.trim() === "") {
      return eventsArray;
    }

    const term = searchTerm.toLowerCase();
    return eventsArray.filter(event =>
      event.name.toLowerCase().includes(term) ||
      (event.venue?.name && event.venue.name.toLowerCase().includes(term)) ||
      (event.city && event.city.toLowerCase().includes(term))
    );
  };

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <CreateEventModal
        show={showCreateEventModal}
        setShow={setShowCreateEventModal}
        create={showCreateEventModal}
        setEvents={setEvents}
      />

      <CreateCommissionModal
        show={showCommissionModal}
        onClose={() => setShowCommissionModal(false)}
        event={selectedEvent}
        onSubmit={({ totalRevenue, totalTickets, commissions }) => {
          supabase
            .from("Events")
            .update({
              revenue: totalRevenue,
              amount_inside: totalTickets,
              commissions: commissions,
              submitted_commission: true,
            })
            .eq("id", selectedEvent.id)
            .then(({ data, error }) => {
              if (error) {
                console.error("Error submitting commissions:", error);
              } else {
                console.log("Commissions submitted successfully:", data);
                // Refresh the revenueEvents
                setRevenueEvents(prev => prev.filter(event => event.id !== selectedEvent.id));
                setShowCommissionModal(false);
                // Optionally, refresh archived events
              }
            });
        }}
      />

      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Manage events {city ? `in ${city}` : ""}
        </h1>
        <div className="flex items-center gap-4">
          <div className="relative w-80">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <Icon icon="heroicons:magnifying-glass" className="h-6 w-6 text-gray-500" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[16px] rounded-lg block w-full pl-12 px-3 py-5"
              placeholder="Search events..."
            />
            {searchTerm && (
              <button
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => setSearchTerm("")}
              >
                <Icon icon="heroicons:x-mark" className="h-6 w-6 text-gray-500" />
              </button>
            )}
          </div>
          <button
            onClick={() => {
              setShowCreateEventModal(true);
            }}
            className="bg-white py-5 px-12 rounded-lg shadow-md font-semibold text-black !text-[20px] flex items-center justify-center gap-2"
          >
            <Icon icon="heroicons:plus" className="h-9 w-9" />
          </button>
        </div>
      </div>
      <div className="flex justify-start items-center gap-3 mt-12 text-[18px]">
        <button
          onClick={() => setActiveTab("published")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "published" ? "border-white" : "border-transparent"
          }`}
        >
          Published ({events.length})
        </button>
        <button
          onClick={() => setActiveTab("revenue")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "revenue" ? "border-white" : "border-transparent"
          }`}
        >
          Revenue Sheet ({revenueEvents.length})
        </button>
        <button
          onClick={() => setActiveTab("archive")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "archive" ? "border-white" : "border-transparent"
          }`}
        >
          Archive ({archivedEvents.length})
        </button>
      </div>
      <Table className="mt-8 mb-[100px]">
        <Table.Head className="max-lg:hidden">
          {activeTab === "archive" ? (
            <>
              <Table.HeadCell className="text-3xl w-[20%] capitalize">
                Datum
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[35%] capitalize">
                Naam event
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[15%] capitalize">
                Commissie
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[15%] capitalize">
                Aantal binnen
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[10%] capitalize">
                Revenue
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[5%] capitalize">
                Actions
              </Table.HeadCell>
            </>
          ) : (
            <>
              <Table.HeadCell className="text-3xl w-[30%] capitalize">
                Titel
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[10%] capitalize">
                Categorie
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[20%] capitalize">
                Event
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[10%] capitalize">
                Dag
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[20%] capitalize">
                Gepubliceerd
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[5%] capitalize">
                Aanmeldingen
              </Table.HeadCell>
              <Table.HeadCell className="text-3xl w-[5%] capitalize">
                Actions
              </Table.HeadCell>
            </>
          )}
        </Table.Head>
        <Table.Body className="divide-y">
          {filterEventsBySearch(
            activeTab === "published"
              ? events
              : activeTab === "archive"
                ? archivedEvents
                : revenueEvents
          ).length === 0 ? (
            <Table.Row className="bg-white">
              <Table.Cell colSpan={activeTab === "archive" ? 6 : 7} className="text-center py-8">
                <div className="flex flex-col items-center">
                  <Icon icon="heroicons:magnifying-glass" className="h-12 w-12 text-gray-400 mb-2" />
                  <span className="text-2xl text-gray-500">
                    {searchTerm
                      ? `No ${activeTab === "published" ? "published" : activeTab === "archive" ? "archived" : "revenue"} events found matching "${searchTerm}"`
                      : `No ${activeTab === "published" ? "published" : activeTab === "archive" ? "archived" : "revenue"} events available`}
                  </span>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : (
            filterEventsBySearch(
              activeTab === "published"
                ? events
                : activeTab === "archive"
                  ? archivedEvents
                  : revenueEvents
            ).map((event, index) => {
              return (
                <Table.Row
                  key={event.id}
                  onClick={() => {
                    if (activeTab === "revenue") {
                      setSelectedEvent(event);
                      setCommissions([{ amount: 0, tickets: 0 }]);
                      setTotalRevenue(0);
                      setShowCommissionModal(true);
                    } else {
                      navigate(`/admin/event/${event.id}`);
                    }
                  }}
                  className={`max-lg:flex flex-col max-lg:!w-[95vw] cursor-pointer hover:bg-blue-100 transition-all ${
                    index % 2 === 1 ? "bg-neutral-100" : "bg-white"
                  }`}
                >
                  {activeTab === "archive" ? (
                    <>
                      <Table.Cell className="lg:w-[20%] p-4 lg:p-16 text-2xl">
                        {formatDateTime(event.datetime)}
                      </Table.Cell>
                      <Table.Cell className="lg:w-[35%] text-2xl font-medium text-gray-900">
                        {event.name}
                      </Table.Cell>
                      <Table.Cell className="lg:w-[15%] text-2xl">
                        {event.commissions ? JSON.stringify(event.commissions) : '-'}
                      </Table.Cell>
                      <Table.Cell className="lg:w-[15%] text-2xl">
                        {event.amount_inside || '-'}
                      </Table.Cell>
                      <Table.Cell className="lg:w-[10%] text-2xl">
                        €{event.revenue || '0'}
                      </Table.Cell>
                      <Table.Cell className="flex items-start lg:flex-col lg:justify-center gap-2 lg:w-[5%]">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            duplicateEvent(event.id);
                          }}
                          className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                        >
                          <Icon
                            icon="heroicons:document-duplicate"
                            className="h-10 w-10"
                          />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteEvent(event.id);
                          }}
                          className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                        >
                          <Icon icon="heroicons:trash" className="h-10 w-10" />
                        </button>
                      </Table.Cell>
                    </>
                  ) : (
                    <>
                      <Table.Cell className="lg:w-[30%] p-4 lg:p-16 text-2xl lg:whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {event.name}
                      </Table.Cell>
                      <Table.Cell className="max-lg:hidden text-2xl w-[10%] ">
                        {event.category}
                      </Table.Cell>
                      <Table.Cell className="max-lg:hidden text-2xl w-[20%]">
                        {formatDateTime(event.datetime)}
                      </Table.Cell>
                      <Table.Cell className="max-lg:hidden text-2xl w-[10%]">
                        {dayjs(event.datetime).format("dddd")}
                      </Table.Cell>
                      <Table.Cell className="max-lg:hidden text-2xl w-[20%]">
                        {dayjs(event.created_at).format("DD MMMM HH:mm")}
                      </Table.Cell>
                      <Table.Cell className="max-lg:hidden text-2xl w-[5%]">
                        {event.attendees?.length}
                      </Table.Cell>
                      <Table.Cell className="flex items-start lg:flex-col lg:justify-center gap-2 lg:w-[5%]">
                        {activeTab !== "revenue" && (
                          <>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                duplicateEvent(event.id);
                              }}
                              className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                            >
                              <Icon
                                icon="heroicons:document-duplicate"
                                className="h-10 w-10"
                              />
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteEvent(event.id);
                              }}
                              className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                            >
                              <Icon icon="heroicons:trash" className="h-10 w-10" />
                            </button>
                          </>
                        )}
                        {activeTab === "revenue" && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedEvent(event);
                              setCommissions([{ amount: 0, tickets: 0 }]);
                              setTotalRevenue(0);
                              setShowCommissionModal(true);
                            }}
                            className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                          >
                            <Icon icon="heroicons:cash" className="h-10 w-10" />
                          </button>
                        )}
                      </Table.Cell>
                    </>
                  )}
                </Table.Row>
              );
            })
          )}
        </Table.Body>
      </Table>
    </div>
  );
}
