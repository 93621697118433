import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import Icon from "../components/Icon";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import EditDealModal from "../components/EditDealModal";

export default function ManageDeals() {
  const [deals, setDeals] = useState([]);
  const [allDeals, setAllDeals] = useState([]);

  const [showCreateEventModal, setshowCreateEventModal] = useState(false);

  const { category } = useParams();

  const [activeTab, setActiveTab] = useState("published");

  useEffect(() => {
    const query = supabase
      .from("Deals")
      .select("*, signups:LifestyleSignUp (*), category:LifestyleCategory!inner(*)")
      .order("index", { ascending: true });

    if (category) {
      query.eq("LifestyleCategory.name", category);
    }

    query.then((res) => {
      const fetchedDeals = res.data || [];
      setAllDeals(fetchedDeals);
      setDeals(fetchedDeals.filter(deal =>
        activeTab === "published" ? deal.active : !deal.active
      ));
    });
  }, [category, activeTab]);

  const duplicateDeal = async (dealId) => {
    try {
      // Fetch the event to duplicate
      const { data: dealToDuplicate, error: fetchError } = await supabase
        .from("Deals")
        .select("*")
        .eq("id", dealId)
        .single();

      if (fetchError) throw fetchError;

      // Create a new event object with the same properties
      const newDeal = {
        ...dealToDuplicate,
        id: undefined,
        name: `${dealToDuplicate.name} (Copy)`,
        created_at: new Date().toISOString(),
      };

      // Insert the new event
      await supabase.from("Deals").insert(newDeal).single();

      // Fetch the newly inserted event
      const { data: insertedEvent, error: fetchNewEventError } = await supabase
        .from("Deals")
        .select("*, signups:LifestyleSignUp (*)")
        .eq("name", newDeal.name)
        .single();

      if (fetchNewEventError) throw fetchNewEventError;

      // Add the new event to the events state
      setDeals((prevDeals) => [insertedEvent, ...prevDeals]);

      // Update the events state
      // setEvents((prevEvents) => [...prevEvents, insertedEvent]);

      console.log("Event duplicated successfully");
    } catch (error) {
      console.error("Error duplicating event:", error.message);
    }
  };

  const deleteDeal = async (dealId) => {
    try {
      // Show confirmation dialog
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this deal? All signups will be deleted."
      );

      if (!isConfirmed) {
        return; // User cancelled the deletion
      }

      // Delete the event from the database
      const { error } = await supabase.from("Deals").delete().eq("id", dealId);

      if (error) throw error;

      // Remove the event from the local state
      setDeals((prevDeals) => prevDeals.filter((deal) => deal.id !== dealId));

      console.log("Deal deleted successfully");
    } catch (error) {
      console.error("Error deleting event:", error.message);
    }
  };

  const toggleDealStatus = async (dealId, currentStatus) => {
    try {
      const { error } = await supabase
        .from("Deals")
        .update({ active: !currentStatus })
        .eq("id", dealId);

      if (error) throw error;

      setAllDeals(prevDeals =>
        prevDeals.map(deal =>
          deal.id === dealId ? { ...deal, active: !currentStatus } : deal
        )
      );

      setDeals(prevDeals => prevDeals.filter(deal => deal.id !== dealId));

    } catch (error) {
      console.error("Error updating deal status:", error.message);
    }
  };

  const navigate = useNavigate();

  const publishedCount = allDeals.filter(deal => deal.active).length;
  const archivedCount = allDeals.filter(deal => !deal.active).length;

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <EditDealModal
        show={showCreateEventModal}
        setShow={(show) => {
          setshowCreateEventModal(show);
        }}
        create={true}
        currentDeal={null}
      />
      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Manage deals {category ? `in ${category}` : ""}
        </h1>
        <button
          onClick={() => {
            setshowCreateEventModal(true);
          }}
          className="bg-white py-5 px-12 rounded-lg shadow-md font-semibold text-black !text-[20px] flex items-center justify-center gap-2"
        >
          <Icon icon="heroicons:plus" className="h-9 w-9" />
        </button>
      </div>
      <div className="flex justify-start items-center gap-3 mt-12 text-[18px]">
        <button
          onClick={() => setActiveTab("published")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "published" ? "border-white" : "border-transparent"
          }`}
        >
          Published ({publishedCount})
        </button>
        <button
          onClick={() => setActiveTab("archive")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "archive" ? "border-white" : "border-transparent"
          }`}
        >
          Archive ({archivedCount})
        </button>
      </div>
      <Table className="mt-8 mb-[100px]">
        <Table.Head className="max-lg:hidden">
          <Table.HeadCell className="text-3xl w-[10%] capitalize">
            Order
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[40%] capitalize">
            Titel
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[22.5%] capitalize">
            Gepubliceerd
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[22.5%] capitalize">
            Aanmeldingen
          </Table.HeadCell>
          <Table.HeadCell className="text-3xl w-[5%] capitalize">
            Actions
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {deals.map((deal, index) => {
            return (
              <Table.Row
                onClick={() => {
                  navigate(`/admin/deal/${deal.id}`);
                }}
                className={`max-lg:flex flex-col max-lg:!w-[95vw] cursor-pointer hover:bg-blue-100 transition-all ${
                  index % 2 === 1 ? "bg-neutral-100" : "bg-white"
                }`}
              >
                <Table.Cell className="lg:w-[10%] p-4 lg:p-16 text-2xl lg:whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {deal.index || '-'}
                </Table.Cell>
                <Table.Cell className="lg:w-[40%] p-4 lg:p-16 text-2xl lg:whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {deal.name}
                </Table.Cell>
                <Table.Cell className="max-lg:hidden text-2xl w-[22.5%]">
                  {dayjs(deal.created_at).format("DD MMMM HH:mm")}
                </Table.Cell>
                <Table.Cell className="max-lg:hidden text-2xl w-[22.5%]">
                  {deal.signups?.length}
                </Table.Cell>
                <Table.Cell className="flex items-start lg:flex-col lg:justify-center gap-2 lg:w-[5%]">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDealStatus(deal.id, deal.active);
                    }}
                    className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                  >
                    <Icon
                      icon={deal.active ? "heroicons:archive-box" : "heroicons:arrow-uturn-up"}
                      className="h-10 w-10"
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      duplicateDeal(deal.id);
                    }}
                    className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                  >
                    <Icon
                      icon="heroicons:document-duplicate"
                      className="h-10 w-10"
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteDeal(deal.id);
                    }}
                    className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                  >
                    <Icon icon="heroicons:trash" className="h-10 w-10" />
                  </button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
