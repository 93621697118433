import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import Logo from "../components/Logo";
import TopBar from "../components/TopBar";

const CHAT_CATEGORIES = [
  { id: "guestlist_chat", label: "Guestlist chats" },
  { id: "community_deals", label: "Community deals" },
  { id: "find_housemate", label: "Find a house(mate)" },
];

const CommunityChats = ({ setLanguage }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchChats();
  }, []);

  const fetchChats = async () => {
    try {
      const { data, error } = await supabase
        .from("CommunityChat")
        .select("*")
        .eq("active", true)
        .order("index", { ascending: true });

      if (error) throw error;
      setChats(data);
    } catch (error) {
      console.error("Error fetching chats:", error);
    } finally {
      setLoading(false);
    }
  };

  const groupChatsByCategory = (chats) => {
    const grouped = {};
    CHAT_CATEGORIES.forEach((category) => {
      grouped[category.id] = chats
        .filter((chat) => chat.category === category.id)
        // We don't need to sort here since the data is already sorted from the query
        // but we can add it as a safeguard
        .sort((a, b) => (a.index || 0) - (b.index || 0));
    });
    return grouped;
  };

  if (loading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center hero-section">
        <Logo />
      </div>
    );
  }

  const groupedChats = groupChatsByCategory(chats);

  return (
    <div className="min-h-screen bg-[#12041f]">
      <TopBar setLanguage={setLanguage} />
      <div className="pt-[80px] lg:pb-[40px] px-[10px] lg:px-[100px]">
        <div className="!normal-case text-center xl:text-start w-full text-white !text-[25px] lg:!text-[32px] font-semibold pb-[32px]">
          Join our Whatsapp Community
        </div>

        {CHAT_CATEGORIES.map((category) => {
          const categoryChats = groupedChats[category.id];
          if (!categoryChats || categoryChats.length === 0) return null;

          return (
            <section key={category.id} className="mb-[40px]">
              <h2 className="text-white text-[22px] leading-[26px] pb-[10px]">
                {category.label}
              </h2>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px]">
                {categoryChats.map((chat) => (
                  <a
                    key={chat.id}
                    href={chat.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex h-[90px] justify-center items-center event-content text-white hover:scale-[102%] transition-all p-[24px] rounded-[12px] bg-[#262123] hover:bg-[#26212380]"
                  >
                    <h3 className="text-[25px] leading-[24px] text-white font-bold mb-2">
                      {chat.name}
                    </h3>
                  </a>
                ))}
              </div>
            </section>
          );
        })}
      </div>
    </div>
  );
};

export default CommunityChats;
