import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "./Logo";
import Icon from "./Icon";
import Sidebar from "./Sidebar";
import ProfileIcon from "../assets/profile-icon.png";
import SearchIcon from "../assets/search-icon.png";
import { useSelector } from "react-redux";
import SearchBar from "./SearchBar";

export default function TopBar({
  setLanguage,
  eventData,
  formattedCurrentDate,
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [mobileSearchActive, setMobileSearchActive] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const user = useSelector((state) => state.user);

  useEffect(() => {
    // Try to detect scrolling in any element
    const handleScrollAnywhere = () => {
      // Get scroll position from the documentElement (most reliable cross-browser)
      const windowScroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

      // Try to detect if scrolling is happening in a container
      const isScrolled = windowScroll > 1000;

      // Set scrolled state if window is scrolled
      if (isScrolled) {
        setHasScrolled(true);
      } else {
        // Find if any container is scrolled
        const containers = document.querySelectorAll(".scroller");
        let containerScrolled = false;

        containers.forEach((container) => {
          if (container.scrollTop > 1000) {
            containerScrolled = true;
          }
        });

        setHasScrolled(isScrolled || containerScrolled);
      }
    };

    // Initial check
    handleScrollAnywhere();

    // Add multiple event listeners to catch all possible scroll events
    window.addEventListener("scroll", handleScrollAnywhere, { passive: true });
    document.addEventListener("scroll", handleScrollAnywhere, {
      passive: true,
    });

    // Force checking the scroll position at regular intervals
    const intervalId = setInterval(handleScrollAnywhere, 1000);

    // Also apply a fallback class after some time has passed
    const timeoutId = setTimeout(() => {
      if (!hasScrolled) {
        setHasScrolled(true);
      }
    }, 5000);

    return () => {
      window.removeEventListener("scroll", handleScrollAnywhere);
      document.removeEventListener("scroll", handleScrollAnywhere);
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [hasScrolled]);

  const handleSearchClick = () => {
    setIsSearchActive(true);
    // Scroll to top of page
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Toggle search active state
    setMobileSearchActive(!mobileSearchActive);
  };

  const handleSearchDismiss = () => {
    setMobileSearchActive(false);
  };

  return (
    <>
      <div
        className={`lg:hidden w-full flex items-center justify-center fixed top-0 z-[9999] transition-all duration-300 ${
          hasScrolled
            ? "bg-[#12041f]/80 backdrop-blur-2xl py-[16px]"
            : "bg-transparent py-[32px]"
        }`}
      >
        <div className="flex justify-between px-[16px] w-full items-center">
          <button
            onClick={() => {
              setSidebarOpen(true);
            }}
          >
            <Icon
              icon="heroicons:bars-3"
              className="w-[24px] h-[24px] text-neutral-100"
              alt="Menu"
            />
          </button>
          <Link
            className={`flex items-center justify-center pl-[40px] ${
              isHomePage ? "pl-[40px]" : "pl-[0px]"
            }`}
            to="/?city=all_cities"
          >
            <Logo />
          </Link>
          <div className="flex items-center gap-[12px]">
            <button
              onClick={isHomePage ? handleSearchClick : null}
              className={!isHomePage ? "opacity-0" : ""}
            >
              <img
                src={SearchIcon}
                className="w-[28px] h-[28px]"
                alt="Search"
              />
            </button>
            <Link
              to={`${user.id ? `/profile` : "/email-login"}`}
              className="flex items-center"
            >
              <img
                src={ProfileIcon}
                className="w-[28px] h-[28px]"
                alt="Profile"
              />
            </Link>
          </div>
        </div>

        {/* Mobile Search Bar */}
        <div
          className={`absolute z-[100000] top-full left-0 right-0 w-full px-[16px] py-[12px] bg-[#12041f]/95 backdrop-blur-md transition-all duration-300 ${
            mobileSearchActive ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <SearchBar
            eventData={eventData}
            formattedCurrentDate={formattedCurrentDate}
            isMobile={true}
            onSearchDismiss={handleSearchDismiss}
            isSearchActive={isSearchActive}
            setIsSearchActive={setIsSearchActive}
          />
        </div>
      </div>

      {/* Mobile Sidebar Drawer */}
      <div
        className={`fixed inset-0 z-[1000000] lg:hidden transition-opacity duration-300 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        {/* Backdrop */}
        <div
          className="absolute inset-0 bg-[#000000]"
          onClick={() => setSidebarOpen(false)}
        />

        {/* Sidebar */}
        <div
          className={`fixed top-0 left-0 h-full w-[100vw] bg-[#000000] transform transition-transform duration-300 ease-in-out ${
            sidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="relative h-full z-[1000000]">
            {/* Close button */}
            <button
              onClick={() => setSidebarOpen(false)}
              className="absolute top-[28px] left-[24px] text-white"
            >
              <Icon icon="heroicons:x-mark" className="w-[30px] h-[30px]" />
            </button>

            {/* Sidebar content */}
            <Sidebar
              setLanguage={setLanguage}
              isMobile={true}
              onClose={() => setSidebarOpen(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
