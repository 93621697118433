import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "../assets/search-icon.png";
import { TrendingCheckSvg, TrendingStarSvg } from "../utils/svg";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { adjustTime } from "../helpers/helpers";

const SearchItem = ({ searchItem, formattedCurrentDate }) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    const registerFrom = adjustTime(searchItem.signup_from);

    if (formattedCurrentDate < registerFrom) return;
    navigate(`/single-event?eventId=${searchItem.id}`, {
      state: searchItem.id,
    });
  };

  // Use different icon based on event category
  const isGuestlist = searchItem.category === "Gastenlijst";

  return (
    <div
      onClick={handleItemClick}
      className="max-w-full search-result-item flex p-3 hover:bg-neutral-100 rounded-[12px] lg:cursor-pointer  gap-3 transition-all"
    >
      <div className="search-icon mr-3 mt-3">
        {isGuestlist ? <TrendingCheckSvg /> : <TrendingStarSvg />}
      </div>
      <div className="search-content flex-1 overflow-hidden">
        <h5 className="text-[#262123] text-[16px] font-medium truncate">
          {searchItem.name}
        </h5>
        <p className="text-[#595155] text-[14px]">
          {dayjs(searchItem.datetime).format("D MMM HH:mm")} • {searchItem.city}{" "}
          • {searchItem.category}
        </p>
      </div>
    </div>
  );
};

const SearchBar = ({
  eventData,
  formattedCurrentDate,
  isMobile = false,
  onSearchDismiss,
  isSearchActive,
  setIsSearchActive,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchRef = useRef(null);
  const { t } = useTranslation();

  const [trendingEvents, setTrendingEvents] = useState([]);

  // Use useEffect to calculate trendingEvents
  useEffect(() => {
    const trending =
      eventData
        ?.filter(
          (event) =>
            event.trending === true &&
            event.signup_from < formattedCurrentDate &&
            event.signup_until > formattedCurrentDate
        )
        .slice(0, 3) || [];

    setTrendingEvents(trending);
  }, [eventData, formattedCurrentDate]);

  // Then your existing useEffect can use trendingEvents safely
  useEffect(() => {
    if (searchValue.trim() === "") {
      setSearchResults(trendingEvents);
    } else {
      const filtered =
        eventData
          ?.filter(
            (event) =>
              event.name.toLowerCase().includes(searchValue.toLowerCase()) &&
              event.signup_from < formattedCurrentDate &&
              event.signup_until > formattedCurrentDate
          )
          .slice(0, 5) || [];
      setSearchResults(filtered);
    }
  }, [searchValue, eventData, formattedCurrentDate, trendingEvents]);

  // Handle click outside to close search
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        // setIsSearchActive(false);
        onSearchDismiss();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, onSearchDismiss]);

  return (
    <div
      className={`z-[100000] relative ${isMobile ? "w-full" : "w-full"}`}
      ref={searchRef}
    >
      {/* Overlay when search is active */}
      {isSearchActive && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[1000] h-screen w-screen"
          onClick={() => {
            setIsSearchActive(false);
            onSearchDismiss();
          }}
        />
      )}

      <div className={`relative ${isMobile ? "w-full" : "w-[300px]"} z-[100001]`}>
        <img
          src={SearchIcon}
          className={`h-[24px] w-[24px] absolute z-10 left-[16px] top-[50%] transform -translate-y-[50%] ${
            isSearchActive && "brightness-[50%]"
          }`}
          alt="Search icon"
        />
        <input
          type="text"
          className={`!text-[16px] font-medium w-full rounded-[50px] py-[14px] pl-[48px] pr-[16px] focus:!outline-none focus:!ring-0 focus:!border-transparent placeholder:text-neutral-300 transition-all duration-200 ${
            isSearchActive
              ? "bg-white text-black placeholder:text-neutral-500"
              : "bg-[#1D1726] text-white"
          }`}
          placeholder={t("search_placeholder")}
          autoComplete="off"
          name="search"
          onFocus={() => setIsSearchActive(true)}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
      </div>

      {/* Search Results Dropdown */}
      {isSearchActive && (
        <div
          className={`absolute z-[100001] ${
            isMobile
              ? "left-0 right-0 w-full mt-[12px]"
              : "left-[-100px] w-[400px] mt-[12px]"
          } p-4 bg-white rounded-[16px] shadow-lg overflow-hidden border border-[#AD7AEF]`}
        >
          <div className="p-3">
            <h6 className="text-[16px] font-medium text-[#262123]">
              {searchValue.trim() === "" ? "Trending" : "Search results"}
            </h6>
          </div>

          <div className="max-h-[350px] overflow-y-auto custom-scrollbar">
            {searchResults.length > 0 ? (
              searchResults.map((item, index) => (
                <SearchItem
                  key={item.id}
                  searchItem={item}
                  formattedCurrentDate={formattedCurrentDate}
                />
              ))
            ) : (
              <div className="p-4 text-center text-[#595155]">
                {t("no_results_found")}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
