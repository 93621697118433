import React, { useState, useEffect } from "react";
import { Modal } from "flowbite-react";

export default function CreateCommissionModal({
  show,
  onClose,
  event,
  onSubmit,
}) {
  // Initialize commissions from event.commissions if it exists
  const initialCommissions = event?.commissions
    ? event.commissions.split(',').map(amount => ({
        amount: amount.trim(),
        tickets: ''
      }))
    : [{ amount: '', tickets: '' }];

  const [commissions, setCommissions] = useState(initialCommissions);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [errors, setErrors] = useState({});

  // Reset commissions when modal is opened with new event
  useEffect(() => {
    if (event?.commissions) {
      const initialCommissions = event.commissions.split(',').map(amount => ({
        amount: amount.trim(),
        tickets: ''
      }));
      setCommissions(initialCommissions);
    } else {
      setCommissions([{ amount: '', tickets: '' }]);
    }
    setTotalRevenue(0);
    setErrors({});
  }, [event]);

  const handleCommissionChange = (index, field, value) => {
    let processedValue = value;

    if (field === "amount") {
      // Replace commas with dots and remove any non-numeric characters except dots
      processedValue = value.replace(/,/g, ".").replace(/[^0-9.]/g, "");

      // Ensure only one dot is present
      const parts = processedValue.split(".");
      if (parts.length > 2) {
        processedValue = parts[0] + "." + parts.slice(1).join("");
      }
    }


    const updatedCommissions = [...commissions];
    updatedCommissions[index][field] = processedValue;
    setCommissions(updatedCommissions);
    calculateTotalRevenue(updatedCommissions);
  };

  const addCommission = () => {
    setCommissions([...commissions, { amount: "", tickets: "" }]);
  };

  const removeCommission = (index) => {
    const updatedCommissions = commissions.filter((_, i) => i !== index);
    setCommissions(updatedCommissions);
    calculateTotalRevenue(updatedCommissions);
  };

  const calculateTotalRevenue = (commissionsList) => {
    const total = commissionsList.reduce((acc, curr) => {
      const amt = parseFloat(curr.amount) || 0;
      const tickets = parseInt(curr.tickets) || 0;
      return acc + (amt * tickets);
    }, 0);
    setTotalRevenue(total);
  };

  const validate = () => {
    let tempErrors = {};
    commissions.forEach((commission, index) => {
      if (!commission.amount || isNaN(commission.amount)) {
        tempErrors[`amount_${index}`] = "Valid amount is required.";
      }
      if (!commission.tickets || isNaN(commission.tickets)) {
        tempErrors[`tickets_${index}`] = "Valid ticket count is required.";
      }
    });
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validate()) return;

    const commissionsString = commissions
      .map((c) => parseFloat(c.amount).toFixed(2))
      .join(", ");
    const totalTickets = commissions.reduce(
      (acc, c) => acc + parseInt(c.tickets) || 0,
      0
    );

    onSubmit({
      totalRevenue: parseFloat(totalRevenue.toFixed(2)),
      totalTickets,
      commissions: commissionsString,
    });
  };

  return (
    <Modal show={show} onClose={onClose} size="6xl">
      <Modal.Header className="flex justify-center items-center">
        <span className="text-[24px]">
          Fill out the commissions for {event?.name}
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          {commissions.map((commission, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row items-end gap-4"
            >
              <div className="flex-1">
                <label className="block text-[14px] font-medium text-gray-700">
                  Amount (€)
                </label>
                <input
                  type="number"
                  step="0.01"
                  value={commission.amount}
                  onChange={(e) =>
                    handleCommissionChange(index, "amount", e.target.value)
                  }
                  className={`mt-1 block w-full px-4 py-2 border text-[16px] ${
                    errors[`amount_${index}`]
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                />
                {errors[`amount_${index}`] && (
                  <p className="mt-1 text-[16px] text-red-500">
                    {errors[`amount_${index}`]}
                  </p>
                )}
              </div>
              <div className="flex-1">
                <label className="block text-[14px] font-medium text-gray-700">
                  Tickets Sold
                </label>
                <input
                  type="number"
                  value={commission.tickets}
                  onChange={(e) =>
                    handleCommissionChange(index, "tickets", e.target.value)
                  }
                  className={`mt-1 block w-full px-4 py-2 border text-[16px] ${
                    errors[`tickets_${index}`]
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                />
                {errors[`tickets_${index}`] && (
                  <p className="mt-1 text-[16px] text-red-500">
                    {errors[`tickets_${index}`]}
                  </p>
                )}
              </div>
              <div className="flex items-center">
                {commissions.length > 1 && (
                  <button
                    onClick={() => removeCommission(index)}
                    className="mt-6 md:mt-0 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none text-[16px]"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            onClick={addCommission}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none text-[16px]"
          >
            Add Commission
          </button>
          <div className="mt-6">
            <h3 className="text-[28px] font-semibold text-gray-700">
              Total Revenue: €{totalRevenue.toFixed(2)}
            </h3>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={handleSubmit}
          className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none text-[20px]"
        >
          Move to archived
        </button>
        <button
          onClick={onClose}
          className="px-6 py-3 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none text-[20px]"
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}
