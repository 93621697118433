import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import Icon from "../components/Icon";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import CreateTokenModal from "../components/CreateTokenModal";

export default function ManageTokens() {
  const [tokens, setTokens] = useState([]);
  const [showCreateTokenModal, setShowCreateTokenModal] = useState(false);
  const [activeTab, setActiveTab] = useState("published");
  const navigate = useNavigate();

  useEffect(() => {
    fetchTokens();
  }, [activeTab]);

  const fetchTokens = async () => {
    // First get tokens
    const { data: tokenData, error: tokenError } = await supabase
      .from("Token")
      .select("*")
      .eq("active", activeTab === "published")
      .order("created_at", { ascending: false });

    if (tokenError) return;

    // For each token, get the count of signups
    const tokensWithCounts = await Promise.all(
      tokenData.map(async (token) => {
        const { count, error: countError } = await supabase
          .from("EventSignUp")
          .select("*", { count: "exact", head: true })
          .eq("token_id", token.id);

        return {
          ...token,
          signup_count: countError ? 0 : count || 0,
        };
      })
    );

    setTokens(tokensWithCounts);
  };

  const createToken = async (name) => {
    const { data, error } = await supabase
      .from("Token")
      .insert({
        name,
        active: true,
      })
      .select()
      .single();

    if (!error) {
      setTokens([data, ...tokens]);
    }
  };

  const archiveToken = async (tokenId, currentStatus) => {
    const { error } = await supabase
      .from("Token")
      .update({ active: !currentStatus })
      .eq("id", tokenId);

    if (!error) {
      setTokens(tokens.filter((token) => token.id !== tokenId));
    }
  };

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <CreateTokenModal
        show={showCreateTokenModal}
        setShow={setShowCreateTokenModal}
        onSubmit={createToken}
      />

      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Manage Tokens
        </h1>
        <button
          onClick={() => setShowCreateTokenModal(true)}
          className="bg-white py-5 px-12 rounded-lg shadow-md font-semibold text-black !text-[20px] flex items-center justify-center gap-2"
        >
          <Icon icon="heroicons:plus" className="h-9 w-9" />
        </button>
      </div>

      <div className="flex justify-start items-center gap-3 mt-12 text-[18px]">
        <button
          onClick={() => setActiveTab("published")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "published" ? "border-white" : "border-transparent"
          }`}
        >
          Active Tokens
        </button>
        <button
          onClick={() => setActiveTab("archive")}
          className={`border-b-2 p-3 text-white ${
            activeTab === "archive" ? "border-white" : "border-transparent"
          }`}
        >
          Archived Tokens
        </button>
      </div>

      <Table className="mt-8">
        <Table.Head className="p-3 h-[50px]">
          <Table.HeadCell className="text-[20px] capitalize">
            Name
          </Table.HeadCell>
          <Table.HeadCell className="text-[20px] capitalize">
            Created At
          </Table.HeadCell>
          <Table.HeadCell className="text-[20px] capitalize">
            Total Signups
          </Table.HeadCell>
          <Table.HeadCell className="text-[20px] capitalize">
            Actions
          </Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {tokens.map((token) => (
            <Table.Row
              key={token.id}
              className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer"
              onClick={() => navigate(`/admin/token/${token.id}`)}
            >
              <Table.Cell className="text-[16px] font-medium text-gray-900 dark:text-white">
                {token.name}
              </Table.Cell>
              <Table.Cell className="text-[16px] text-gray-900 dark:text-white">
                {dayjs(token.created_at).format("DD MMMM YYYY")}
              </Table.Cell>
              <Table.Cell className="text-[16px] text-gray-900 dark:text-white">
                {token.signup_count}
              </Table.Cell>
              <Table.Cell>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    archiveToken(token.id, token.active);
                  }}
                  className="p-2 hover:bg-neutral-300 rounded-lg transition-all"
                >
                  <Icon
                    icon={
                      token.active
                        ? "heroicons:archive-box"
                        : "heroicons:arrow-uturn-up"
                    }
                    className="h-10 w-10"
                  />
                </button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
