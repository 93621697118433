import EventItem from "./EventItem";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { adjustTime } from "../helpers/helpers";
import "dayjs/locale/nl";
import { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import InternshipCard from "./InternshipCard";
import InternshipModal from "./InternshipModal";
import { useTranslation } from "react-i18next";
dayjs.extend(utc);
dayjs.extend(timezone);

const EventsList = ({
  eventsData,
  currentCity,
  isDivider,
  formattedCurrentDate,
  language,
  cities,
}) => {
  const [registerableEvents, setRegisterableEvents] = useState([]);
  const [showInternshipModal, setShowInternshipModal] = useState(false);
  const [internships, setInternships] = useState([]);
  const [internshipDates, setInternshipDates] = useState([]);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setRegisterableEvents(
      eventsData
        .filter((event) => {
          const registerFrom = adjustTime(event.signup_from);
          const registerUntil = adjustTime(event.signup_until);
          // console.log(event.EvenName,'registerFRom', registerFrom, 'registerUntil', registerUntil, 'formattedCurrentDate', formattedCurrentDate)
          return (
            formattedCurrentDate > registerFrom &&
            formattedCurrentDate < registerUntil
          );
        })
        .map((event) => {
          const dateObject = dayjs(event.datetime).utc().locale(language);

          return {
            ...event,
            fulldate: {
              date: dateObject.format("D MMMM"),
              day: dateObject.format("dddd"),
            },
          };
        })
    );
  }, [eventsData, formattedCurrentDate, language]);

  useEffect(() => {
    supabase
      .from("InternshipDates")
      .select("*")
      .then(({ data, error }) => {
        if (!error && data) {
          setInternshipDates(data);
        }
      });

    // Fetch internships when needed
    supabase
      .from("Internship")
      .select("*")
      .then(({ data, error }) => {
        if (!error && data) {
          setInternships(data);
        }
      });
  }, []);

  const getEventDate = (datetime) => {
    return dayjs(datetime).locale(language).format("D MMMM");
  };

  const getEventDay = (datetime) => {
    return dayjs(datetime).locale(language).format("dddd");
  };

  const groupedByDate = registerableEvents.reduce((grouped, event) => {
    const date = getEventDate(event.datetime);
    const timestamp = dayjs(event.datetime).valueOf();

    if (!grouped[date]) {
      grouped[date] = {
        date: date,
        day: getEventDay(event.datetime),
        timestamp: timestamp,
        data: [],
        showInternshipCard: internshipDates.some((internshipDate) => {
          return (
            dayjs(internshipDate.date).format("YYYY-MM-DD") ===
            dayjs(event.datetime).format("YYYY-MM-DD")
          );
        }),
      };
    }

    grouped[date].data.push(event);

    return grouped;
  }, {});

  const groupedArray = Object.values(groupedByDate);

  // Sort the groups based on the timestamp (date) of the events
  groupedArray.sort((a, b) => a.timestamp - b.timestamp);

  // Sort events within each group by their datetime
  groupedArray.forEach((group) => {
    group.data.sort(
      (a, b) => dayjs(a.datetime).valueOf() - dayjs(b.datetime).valueOf()
    );
  });

  // For mobile, limit to 7 days unless showAllEvents is true
  const displayedEvents = !showAllEvents
    ? groupedArray.slice(0, 7)
    : groupedArray;

  const getCityDisplayText = (cities) => {
    if (!cities || cities.length === 0) return t("all_cities");
    if (cities.length === 1) return cities[0];
    return t("multiple_cities", { count: cities.length });
  };

  return (
    <div className="w-full">
      {displayedEvents.map((group, index) => (
        <EventItem
          key={group.id}
          eventsGroupData={group}
          currentCity={currentCity}
          language={language}
          showInternshipCard={group.showInternshipCard}
          internships={internships}
        />
      ))}

      {/* Show "Load more" button on mobile if there are more than 7 days of events */}
      {!showAllEvents && groupedArray.length > 7 && (
        <div className="flex flex-col items-center justify-center mt-8 mb-4">
          <h3 className="text-white text-[22px] leading-[26px] font-semibold text-center pb-[12px]">
            {t("discover_more_events_in_city")} <br />
            {getCityDisplayText(cities)}
          </h3>
          <button
            onClick={() => setShowAllEvents(true)}
            className="white-button transition-all"
          >
            Toon meer
          </button>
        </div>
      )}
      {isDivider && <div className="card-divider"></div>}
    </div>
  );
};

export default EventsList;
